import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';

function ObserverRef({ onIntersect, disabled }) {
  const observerRef = useRef(null);
  const intersectCallbackRef = useRef(onIntersect);

  useEffect(() => {
    intersectCallbackRef.current = onIntersect;
  }, [onIntersect]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && !disabled) {
          intersectCallbackRef.current();
        }
      },
      { threshold: 0 }
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => observer.disconnect();
  }, [disabled]);

  return <div ref={observerRef} />;
}

ObserverRef.propTypes = {
  onIntersect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ObserverRef.defaultProps = {
  disabled: false,
};

export default ObserverRef;
