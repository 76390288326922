import { api } from './index';

export const createOrder = (data, params = {}) => api.post(`/orders/`, data, { params });

export const readOrders = (params, signal) => api.get(`/orders/`, { signal, params });

export const readOrder = (orderId, params = {}) => api.get(`/orders/${orderId}`, { params });

export const patchOrder = (orderId, data, params = {}) =>
  api.patch(`/orders/${orderId}/`, data, { params });

export const calculateOrder = (data, params = {}) =>
  api.post(`/orders/calculate/`, data, { params });

export const closeOrder = (orderId, data = {}, params = {}) =>
  api.post(`/orders/${orderId}/close/`, data, { params });

export const cancelOrder = (orderId, data = {}, params = {}) =>
  api.post(`/orders/${orderId}/cancel/`, data, { params });

export const payOrder = (orderId, data = {}, params = {}) =>
  api.post(`/orders/${orderId}/pay/`, data, { params });

export const unpayOrder = (orderId, data, params = {}) =>
  api.post(`/orders/${orderId}/unpay/`, data, { params });

export const sendInvoice = (orderId) => api.post(`/orders/${orderId}/send-invoice/`);

export const approveOrder = (orderId, params = {}) =>
  api.post(`/orders/${orderId}/approve/`, {}, { params });

export const readExportStatus = (exportId) => api.get(`/export/${exportId}/`);

export const requestPaymentForOrder = (orderId) =>
  api.post(`/orders/${orderId}/request-payment/`, {});

export const revokeAuthorizationForOrder = (orderId, data, params = {}) =>
  api.post(`/orders/${orderId}/revoke-authorization/`, data, { params });

export const createTerminalTransaction = (orderId, data, params = {}) =>
  api.post(`/orders/${orderId}/terminal-transaction/`, data, { params });