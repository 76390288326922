import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const { White, Green100, Green600 } = theme.colors;

const Svg = ({ fill, fillInner, fillCheck, classes }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <g clipPath="url(#clip0_7108_6953)">
      <circle cx="16" cy="16" r="16" fill={fill} />
      <g clipPath="url(#clip1_7108_6953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
          fill={fillInner}
        />
        <path
          d="M19.7071 13.2929C20.0976 13.6834 20.0976 14.3166 19.7071 14.7071L15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929C12.6834 14.9024 13.3166 14.9024 13.7071 15.2929L15 16.5858L18.2929 13.2929C18.6834 12.9024 19.3166 12.9024 19.7071 13.2929Z"
          fill={fillCheck}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7108_6953">
        <rect width="32" height="32" fill={fillCheck} />
      </clipPath>
      <clipPath id="clip1_7108_6953">
        <rect width="16" height="16" fill={fillCheck} transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  fillInner: PropTypes.string,
  fillCheck: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: Green100,
  fillInner: Green600,
  fillCheck: White,
  classes: '',
};

export default Svg;
