import { atom } from 'recoil';

export const openOrderAtom = atom({
    key: 'checkout-open-order',
    default: null,
});

export const customerFieldsAtom = atom({
    key: 'checkout-customer-fields',
    default: {
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
    },
});

export const enablePhoneOnCheckoutAtom = atom({
    key: 'checkout-enable-phone',
    default: false,
});

export const submittingOrderAtom = atom({
    key: 'checkout-submitting-order',
    default: false,
});

export const selectedSavedCardIdAtom = atom({
    key: 'checkout-selected-saved-card-id',
    default: null,
});

export const savedCardGatewayPaymentMethodAtom = atom({
    key: 'checkout-saved-card-gateway-payment-method',
    default: null,
});

export const selectedPaymentStrategyAtom = atom({
    key: 'checkout-selected-payment-strategy',
    default: null,
});

export const saveCardAtom = atom({
    key: 'checkout-save-card',
    default: false,
});

export const billingAtom = atom({
    key: 'checkout-billing',
    default: {
        billing_address: null,
    },
});

export const fulfillmentSubscriptionOptionsAtom = atom({
    key: 'checkout-fulfillment-subscription-options',
    default: [],
});

export const shouldUseStoreCreditAtom = atom({
    key: 'checkout-should-use-store-credit',
    default: false,
});

export const savedCardsAtom = atom({
    key: 'checkout-saved-cards',
    default: [],
});

export const customerNoteAtom = atom({
    key: 'checkout-customer-note',
    default: '',
});

export const checkoutErrorsAtom = atom({
    key: 'checkout-errors',
    default: {},
});
