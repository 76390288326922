import { useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { patchOrder, payOrder } from 'api/storefront/orders';
import { useTranslation } from 'react-i18next';
import Card from 'components/styled/Card';
import { SQUARE_GATEWAY, STRIPE_GATEWAY, LOCAL_PAY_GATEWAY, CUSTOM } from '../../PaymentComponents';
import { accountAtom, customerAtom } from 'state/storefront/accountState';
import { useRecoilValue } from 'recoil';
import SaveCard from '../../PaymentComponents/SaveCard';
import { CircularLoadingBar } from 'assets/svgIcons';
import Pay from '../../PaymentComponents/Pay/Pay';
import { formatDateTime, formatMoney } from 'helpers/localeHelpers';
import Alert from 'components/styled/Alert';
import { currencyAtom } from 'state/common/currency';
import { checkoutPaymentErrorsAtom } from 'state/storefront/storeState';
import CheckoutAchAuthorization from '../../PaymentComponents/CheckoutAchAuthorization';
import GatewayPaymentMethods from 'components/GatewayPaymentMethods/index.js';
import CheckoutSavedPayments from '../../PaymentComponents/CheckoutSavedPayments';
import { getGatewayDetails } from 'helpers/helpers';
import { readPayrixGatewayCustomers } from 'api/storefront/payment-gateways';
import useApi from 'hooks/common/api';
import { useEffect } from 'react';
import { readCustomerCards } from 'api/storefront/customers';

const DeferredPaymentStep = ({ order, setOrder, loading }) => {
  const { t } = useTranslation();
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [selectedSavedCardId, setSelectedSavedCardId] = useState(null);
  const paymentRef = useRef();
  const [saveCard, setSaveCard] = useState(false);
  const customer = useRecoilValue(customerAtom);
  const paymentErrors = useRecoilValue(checkoutPaymentErrorsAtom);

  const history = useHistory();
  const { priceListSlug, uidb64, token } = useParams();
  const account = useRecoilValue(accountAtom);
  const [savedCards, setSavedCards] = useState([]);

  const isPaymentGateway =
    order?.payment?.payment_strategy?.type === STRIPE_GATEWAY ||
    order?.payment?.payment_strategy?.type === SQUARE_GATEWAY ||
    order?.payment?.payment_strategy?.type === LOCAL_PAY_GATEWAY;

  const cardTitle = t('storefront/store/deferred-checkout-payment--title', { order_id: order?.id });
  const dueDate = formatDateTime(order?.payment?.due_date, 'dddd, LL');
  const nameDescription = order?.customer?.first_name
    ? t('storefront/store/deferred-checkout-payment--description--name', {
      name: order?.customer?.first_name,
    })
    : '';
  const dueDateDescription = dueDate
    ? t('storefront/store/deferred-checkout-payment--description--due-date', {
      due_date: dueDate,
    })
    : '';
  const cardDescription = `${nameDescription} ${dueDateDescription}`;
  const currency = useRecoilValue(currencyAtom);
  const amountDue = formatMoney(order?.payment?.balance, currency);
  const sameCustomerOnOrder = order?.customer?.id === customer?.id;
  const [selectedGatewayPaymentMethod, setSelectedGatewayPaymentMethod] = useState();
  const payForOrder = async (options) => {
    const payedOrder = await payOrder(order?.id, {
      ...options,
      gateway_payment_method: selectedGatewayPaymentMethod?.payment?.gateway_payment_method,
    });
    setOrder(payedOrder.data);
  };
  const { request: payrixCustomersRequest } = useApi(readPayrixGatewayCustomers);

  const _getPaymentStrategyGatewayPaymentMethod = (strategy) => {
    return {
      gateway_payment_method:
        strategy?.payment_strategy_gateway_payment_methods?.[0]?.gateway_payment_method,
      payment_method_name:
        strategy?.payment_strategy_gateway_payment_methods?.[0]?.payment_method_name,
    };
  };

  const _getGatewayPaymentMethod = async (strategy) => {
    const savedCardGatewayPaymentMethod = await _getSavedCardGatewayPaymentMethod(strategy);
    const paymentStrategyGatewayPaymentMethod = _getPaymentStrategyGatewayPaymentMethod(strategy);
    return savedCardGatewayPaymentMethod || paymentStrategyGatewayPaymentMethod;
  };

  const _getSavedCardGatewayPaymentMethod = async (strategy) => {
    if (customer?.id && strategy?.type !== CUSTOM) {
      const savedCardGatewayPaymentMethod = await fetchCustomerCards(strategy);
      if (savedCardGatewayPaymentMethod?.id)
        return {
          gateway_payment_method: savedCardGatewayPaymentMethod?.id,
          payment_method_name: savedCardGatewayPaymentMethod?.payment_method_name,
        };
    }
    return null;
  };
  const fetchCustomerCards = async (strategy) => {
    setSavedCards([]);
    try {
      const resp = await readCustomerCards({
        gateway: strategy?.gateway_short,
      });
      if (resp?.data && resp?.data.length > 0) {
        setSavedCards(resp?.data);
        setSelectedSavedCardId(resp?.data[0]?.source_id);
        return resp?.data[0]?.gateway_payment_method;
      }
    } catch (e) {
      console.error('Error fetching saved cards: ', e);
    } finally {
    }
  };
  const initializeGatewayPaymentMethod = async (strategy) => {
    const gatewayPaymentMethod = await _getGatewayPaymentMethod(strategy);
    setSelectedGatewayPaymentMethod({
      payment: gatewayPaymentMethod,
    });
  };
  useEffect(() => {
    if (order?.payment?.payment_strategy?.id)
      initializeGatewayPaymentMethod(order?.payment?.payment_strategy);
  }, [order?.payment?.payment_strategy]);

  return (
    <>
      <div className="checkout__confirmation_payment col-span-3 sm:col-span-2">
        {submittingOrder && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-white z-20 flex flex-col items-center justify-center">
            <CircularLoadingBar />
            {t('storefront/store/checkout--payment-submitting-text')}
          </div>
        )}
        {paymentErrors.length > 0 && (
          <Alert
            id="payment-error"
            type="error"
            title={t('Error')}
            classes="mb-6"
            description={
              <ul className="list-disc ml-4">
                {paymentErrors.map((error, i) => (
                  // eslint-disable-next-line
                  <li key={error + i}>{error}</li>
                ))}
              </ul>
            }
          />
        )}
        <Card title={cardTitle} text={cardDescription} classes="flex mb-4">
          {order?.payment?.payment_strategy?.type !== CUSTOM && (
            <>
              <CheckoutSavedPayments
                selectedPaymentStrategy={order?.payment?.payment_strategy}
                selectedSavedCardId={selectedSavedCardId}
                setSelectedSavedCardId={setSelectedSavedCardId}
                showSavedCards={true}
                setOrder={setSelectedGatewayPaymentMethod}
                gatewayPaymentMethods={
                  order?.payment?.payment_strategy?.payment_strategy_gateway_payment_methods
                }
                savedCards={savedCards}
              />
              {!selectedSavedCardId && (
                <>
                  <GatewayPaymentMethods
                    ref={paymentRef}
                    loading={loading}
                    gatewayDetails={getGatewayDetails(
                      order?.payment?.payment_strategy?.gateway_short,
                      order?.payment?.payment_strategy?.gateway_key,
                      account?.payrix_merchant_id,
                      order?.customer?.id,
                      payrixCustomersRequest,
                      account?.country_code
                    )}
                    gatewayPaymentMethods={
                      order?.payment?.payment_strategy?.payment_strategy_gateway_payment_methods
                    }
                    modalStyling={false}
                    selectedPaymentMethod={order?.payment?.payment_strategy?.payment_strategy_gateway_payment_methods?.find(
                      (method) =>
                        method.gateway_payment_method ===
                        selectedGatewayPaymentMethod?.payment?.gateway_payment_method
                    )}
                    onPaymentMethodChange={(gatewayPaymentMethodId) =>
                      setSelectedGatewayPaymentMethod({
                        payment:
                          order?.payment?.payment_strategy?.payment_strategy_gateway_payment_methods?.find(
                            (method) => method.gateway_payment_method === gatewayPaymentMethodId
                          ),
                      })
                    }
                  />
                  <CheckoutAchAuthorization
                    hasOutstandingBalance={false}
                    orderNeedsApproval={false}
                    isPaymentGateway={isPaymentGateway}
                    isPayLater={false}
                    gatewayPaymentMethod={order?.payment?.gatewayPaymentMethod}
                    formattedBalance={amountDue}
                    saveCard={saveCard}
                  />
                </>
              )}
            </>
          )}
          {!!customer?.id && !selectedSavedCardId && isPaymentGateway && sameCustomerOnOrder && (
            <SaveCard saveCard={saveCard} setSaveCard={setSaveCard} />
          )}
        </Card>

        <Pay
          order={order}
          selectedSavedCardId={selectedSavedCardId}
          redirectToComplete={() => {
            history.push(`/deferred-checkout/${uidb64}/${token}/complete`);
          }}
          buttonText={t('storefront/store/deferred-checkout--payment-pay-cta', {
            total: amountDue,
          })}
          saveCard={saveCard}
          setSubmittingOrder={setSubmittingOrder}
          paymentRef={paymentRef}
          onPay={async (options) => {
            await payForOrder(options);
          }}
          isPaymentGateway={isPaymentGateway}
        />
      </div>
    </>
  );
};

export default DeferredPaymentStep;
