import PropTypes from 'prop-types';

const FooterSection = ({ title, children }) => {
  return (
    <div className="store__footer--column">
      {!!title && <span className="text-sm text-Grey500 mb-2">{title}</span>}
      <div className="flex flex-col gap-2 text-sm min-w-48">{children}</div>
    </div>
  );
};

FooterSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

FooterSection.defaultProps = {
  title: '',
  children: null,
};

export default FooterSection;
