import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button/Button';

const FulfillmentSummaryTitle = ({ fulfillmentType, fulfillmentName, editFulfillment }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between mb-3">
      <h4>
        {t('storefront/cart/fulfillment-info', { fulfillmentType })} · {fulfillmentName}
      </h4>
      <Button classes="button button--none text-Green600" onClick={() => editFulfillment()}>
        {t('Edit')}
      </Button>
    </div>
  );
};

FulfillmentSummaryTitle.propTypes = {
  fulfillmentType: PropTypes.string,
  fulfillmentName: PropTypes.string,
};

FulfillmentSummaryTitle.defaultProps = {
  fulfillmentType: '',
  fulfillmentName: '',
};

export default FulfillmentSummaryTitle;
