import { api } from './index';
const EXPAND_ORDER_FIELDS =
  'order_template.order_entries.product,order_template.order_entries.package_price_list_entry,order_template.price_list,order_template.fulfillment.availability';

export const readOrderSubscriptions = (params) =>
  api.get('/order-subscriptions/', {
    params: { expand: EXPAND_ORDER_FIELDS, ...params },
  });

export const cancelOrderSubscription = (subscriptionPlanId) =>
  api.post(`/order-subscriptions/${subscriptionPlanId}/cancel/`);
