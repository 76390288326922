import { useHistory, useParams } from 'react-router-dom';
import { ChevronSmallUp } from 'assets/svgIcons';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { useOrder } from 'hooks/storefront';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';

const Checkout = () => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { push } = useHistory();
  const { priceList: priceListHook } = useOrder();
  const account = useRecoilValue(accountAtom);
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const accountName = account?.business_name || '';
  const priceListName = priceList?.name || priceListHook?.name || '';

  //methods
  const navigateToShop = () => {
    if (!priceListSlug && !!priceListHook?.slug) {
      push(`/${priceListHook?.slug}`);
    } else {
      push(`/${priceListSlug}`);
    }
  };

  return (
    <div>
      <a
        onClick={navigateToShop}
        className="flex items-center text-sm text-Grey500 cursor-pointer hover:text-Green600">
        <ChevronSmallUp classes="mr-2 transform -rotate-90" />
        {t('storefront/store/checkout--header-back')}
      </a>
      <h1>{t('storefront/store/checkout--header-title', { name: accountName })} </h1>
      <span>{priceListName}</span>
    </div>
  );
};
export default Checkout;
