import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.90847 3.71849C10.826 2.08733 13.1745 2.08733 14.092 3.71849L20.7884 15.6231C21.6883 17.223 20.5322 19.1998 18.6966 19.1998H5.30388C3.4683 19.1998 2.31219 17.223 3.2121 15.6231L9.90847 3.71849ZM13.2001 15.5999C13.2001 16.2626 12.6629 16.7999 12.0001 16.7999C11.3374 16.7999 10.8001 16.2626 10.8001 15.5999C10.8001 14.9372 11.3374 14.3999 12.0001 14.3999C12.6629 14.3999 13.2001 14.9372 13.2001 15.5999ZM12.0001 5.9999C11.3374 5.9999 10.8001 6.53716 10.8001 7.1999V10.7999C10.8001 11.4626 11.3374 11.9999 12.0001 11.9999C12.6629 11.9999 13.2001 11.4626 13.2001 10.7999V7.1999C13.2001 6.53716 12.6629 5.9999 12.0001 5.9999Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
};

export default Svg;
