import { SpinnerSmall } from 'assets/svgIcons/index';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { currencyAtom } from 'state/common/currency';
import Money from 'components/styled/Money';

const SummaryItem = ({ label, value, loading, highlight }) => {
  const currency = useRecoilValue(currencyAtom);

  const labelClasses = `${highlight ? 'text-lg font-bold text-Grey800' : 'text-Grey500'}`;
  const valueClasses = `${highlight ? 'text-lg font-bold text-Grey800' : 'text-Grey800'}`;

  return (
    <div className="flex justify-between items-center">
      <span className={labelClasses}>{label}</span>
      <span className={valueClasses}>
        {loading ? <SpinnerSmall classes="mr-1" /> : <Money value={value} currency={currency} />}
      </span>
    </div>
  );
};

SummaryItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  highlight: PropTypes.bool,
};

SummaryItem.defaultProps = {
  label: '',
  value: '',
  highlight: false,
};

export default SummaryItem;
