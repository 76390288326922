import PropTypes from 'prop-types';
import X from 'assets/svgIcons/X';
import './Tag.css';
import theme from 'styles/theme';

const Tag = ({ classes, onClick, name, Icon, reversed, showButton, iconProps }) => (
  <span className={`tag ${classes}`}>
    {!reversed && name}
    {showButton && (
      <button className="ml-1" onClick={(e) => onClick(e)}>
        <Icon {...iconProps} />
      </button>
    )}
    {reversed && name}
  </span>
);

Tag.propTypes = {
  classes: PropTypes.string,
  onClick: PropTypes.func,
  name: PropTypes.string,
  Icon: PropTypes.element,
  reversed: PropTypes.bool,
  showButton: PropTypes.bool,
  iconProps: PropTypes.object,
};

Tag.defaultProps = {
  classes: '',
  onClick: () => {},
  name: '',
  Icon: X,
  reversed: false,
  showButton: true,
  iconProps: { stroke: theme.colors.Grey400 },
};

export default Tag;
