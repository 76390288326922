export const DEFAULT_ADDRESS_FORM_STATE = {
  formatted_address: undefined,
  latitude: undefined,
  longitude: undefined,
  route: undefined,
  street_number: undefined,
  country: undefined,
  country_code: undefined,
  administrative_area_level_1: undefined,
  administrative_area_level_1_code: undefined,
  administrative_area_level_2: undefined,
  administrative_area_level_2_code: undefined,
  locality: undefined,
  sublocality: undefined,
  postal_code: undefined,
  premise: undefined,
  subpremise: undefined,
  place_id: undefined,
  street_address: undefined,
  is_type_region: undefined,
};

export { default } from './AddressForm';
