import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={`${classes}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
