import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66667 9.99992H6.675M10 9.99992H10.0083M13.3333 9.99992H13.3417M17.5 9.99992C17.5 13.6818 14.1421 16.6666 10 16.6666C8.71728 16.6666 7.50976 16.3803 6.45389 15.8758L2.5 16.6666L3.66249 13.5666C2.9263 12.5352 2.5 11.3118 2.5 9.99992C2.5 6.31802 5.85786 3.33325 10 3.33325C14.1421 3.33325 17.5 6.31802 17.5 9.99992Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
