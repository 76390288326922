import { ExclamationCircleFilled } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import theme from 'styles/theme';
import { formatProblemJSONErrors, scrollTo } from 'helpers/helpers';
import { authenticate } from 'api/storefront/auth';
import { readCustomer } from 'api/storefront/customers';
import { useStorefrontAuth } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import Button from 'components/styled/Button';
import InputText from 'components/styled/InputText';
import ModalPortal from 'components/styled/ModalPortal/ModalPortal';

const Login = ({
  onClose,
  setForgotPassword,
  activated,
  reset,
  setCartActive,
  onLoginRedirect,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const isCheckout = useRouteMatch('/:priceListSlug/checkout');

  // states
  const { setAuth } = useStorefrontAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const [resetExpired, setResetExpired] = useState(false);
  const [redirect, setRedirect] = useState();

  const queryParams = useLocation().search;

  useEffect(() => {
    setForgotPassword(false);
    if (activated) setAlreadyActivated(activated);
    if (reset) setResetExpired(reset);
    const redirectParam = new URLSearchParams(queryParams).get('redirect');
    setRedirect(!!redirectParam ? redirectParam : onLoginRedirect);
    history.replace({
      search: '',
    });
  }, []);

  const fetchCustomerPriceListMemberships = async () => {
    const resp = await readCustomer();
    const { price_list_memberships } = resp?.data;
    return price_list_memberships;
  };

  //methods
  const login = async () => {
    try {
      const resp = await authenticate(email, password);
      setAuth(resp.data);
      onClose();

      if (!!onLoginRedirect) {
        history.push(onLoginRedirect);
        scrollTo(0, 0);
        return;
      }

      if (isCheckout) {
        location.reload();
        return;
      }

      const priceListMemberships = await fetchCustomerPriceListMemberships();

      if (priceListMemberships.length === 1) {
        history.push(`/${priceListMemberships[0].price_list_slug}`);
      } else if (redirect) {
        history.push(`/${redirect}`);
      }
      location.reload();
    } catch (e) {
      setErrors(formatProblemJSONErrors(e?.response?.data));
    }
  };

  const onCreateAccount = () => {
    setCartActive(false);
    if (!!redirect) {
      history.push(`/register?redirect=${redirect}`);
    } else if (onLoginRedirect) {
      history.push(`/register?redirect=${onLoginRedirect}`);
    } else {
      history.push('/register');
    }
    onClose();
  };

  return (
    <ModalPortal
      containerClasses="fixed"
      classes="overflow-y-hidden !p-6"
      handleClose={onClose}
      target="storefront-login-modal">
      {alreadyActivated && (
        <Alert
          id="login-error-alert"
          classes="mb-6"
          type="error"
          description="This account has already been activated. Please login to continue."
        />
      )}
      {resetExpired && (
        <Alert
          id="login-error-alert"
          classes="mb-6"
          type="error"
          description="Password reset link is invalid. Please request a new password reset"
        />
      )}
      <h1 className="mb-4">{t('storefront/store/login--title')}</h1>
      <InputText
        id="email"
        name="email"
        type="email"
        labelText={t('storefront/store/login--email')}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        classes="mb-4"
        error={errors?.email}
      />
      <InputText
        id="password"
        name="password"
        labelText={
          <div className="w-full flex justify-between items-center">
            <h4>{t('Password')}</h4>
            <span
              className="text-Grey400 cursor-pointer"
              onClick={() => setShowPassword((prevState) => !prevState)}>
              {showPassword ? t('Hide') : t('Show')}
            </span>
          </div>
        }
        type={showPassword ? 'text' : 'password'}
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        classes="mb-4"
        error={errors?.password}
      />
      {errors?.detail && (
        <div className="flex flex-row space-x-2 w-full mb-4">
          <ExclamationCircleFilled stroke={theme.colors.Red500} />
          <p className="text-sm text-Red700">{errors?.detail}</p>
        </div>
      )}
      <div className="flex mb-4">
        <Button
          classes="text-sm text-right button button--none text-Green600 flex-1"
          onClick={() => setForgotPassword(true)}>
          {t('storefront/store/login--forgot-password')}
        </Button>
      </div>
      <Button classes="mb-2" onClick={login}>
        {t('Login')}
      </Button>
      <span className="text-sm text-center login--create-account">
        <Trans i18nKey="storefront/store/login--create-account">
          Or <button onClick={() => onCreateAccount()}>create an account</button>
        </Trans>
      </span>
    </ModalPortal>
  );
};

Login.propTypes = { onClose: PropTypes.func, onLoginRedirect: PropTypes.string };

Login.defaultProps = { onClose: () => {}, onLoginRedirect: '' };

export default Login;
