import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes, stroke }) => (
  <svg
    width="20"
    height="20"
    className={classes}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3V17"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 14L14 17L17 14"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 8L5.5 3L8 8"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 12H8L3 17H8"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
  stroke: theme.colors.Grey400,
};

export default Svg;
