import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${classes}`}>
    <path
      d="M5 13L9 17L19 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={stroke}
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
