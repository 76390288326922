import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ON, PRODUCT_LISTING_FORMATS, VIEWPORT_SIZES } from 'helpers/constants';
import { screenSizeAtom } from 'state/appState';
import { accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import ProductCardGridView from './GridView/ProductCardGridView';
import ProductCardListView from './ListView/ProductCardListView';
import ProductCardMobileView from './MobileView/ProductCardMobileView';

const ProductsViewManager = ({ setErrors, products }) => {
  const { width } = useRecoilValue(screenSizeAtom);
  const isMobile = width < VIEWPORT_SIZES.sm;

  const account = useRecoilValue(accountAtom);

  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const { priceList: priceListHook } = useOrder();
  const { priceListSlug } = useParams();
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const { product_listing_format: productListingFormat } =
    priceList?.price_list_storefront_configuration || PRODUCT_LISTING_FORMATS.GRID;
  const displayDiscount = priceList?.display_discount;
  const displaySubscription = !!priceList?.subscription_settings_enabled;
  const displayRemainingInventory = account?.storefront_configuration?.display_remaining_inventory;

  const getViewClassName = () => {
    return isMobile
      ? 'store__product-mobile-view'
      : productListingFormat === PRODUCT_LISTING_FORMATS.LIST
      ? 'store__product-list-view'
      : 'store__products-grid-view';
  };

  return (
    <div className={getViewClassName()}>
      {products.map((product) => {
        return isMobile ? (
          <ProductCardMobileView
            key={product.id}
            setErrors={setErrors}
            product={product}
            displayDiscount={displayDiscount}
            displaySubscription={displaySubscription}
            displayRemainingInventory={displayRemainingInventory}
          />
        ) : productListingFormat === PRODUCT_LISTING_FORMATS.LIST ? (
          <ProductCardListView
            key={product.id}
            setErrors={setErrors}
            product={product}
            displayDiscount={displayDiscount}
            displaySubscription={displaySubscription}
            displayRemainingInventory={displayRemainingInventory}
          />
        ) : (
          <ProductCardGridView
            key={product.id}
            setErrors={setErrors}
            product={product}
            displayDiscount={displayDiscount}
            displaySubscription={displaySubscription}
            displayRemainingInventory={displayRemainingInventory}
          />
        );
      })}
    </div>
  );
};

ProductsViewManager.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    inventory: PropTypes.number,
    name: PropTypes.string,
    package_price_list_entries: PropTypes.array,
    price_list: PropTypes.number,
    price_list_category: PropTypes.number,
    priority_order: PropTypes.number,
    product: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.string,
  }),
  setErrors: PropTypes.func,
};

ProductsViewManager.defaultProps = {
  product: {},
  setErrors: () => {},
};

export default ProductsViewManager;
