import { api } from './index';

const EXPAND_ORDER_FIELDS =
  'order_entries.product,order_entries.package_price_list_entry,price_list,price_list.price_list_order_subscription_settings,payment.gateway_payment_method';
export const OMIT_ORDER_FIELDS =
  'payment.last_failed_transaction,payment.authorized_transaction,fulfillment.subscription_options_available';

export const createOrder = (data) =>
  api.post(`/orders/`, data, {
    params: { expand: EXPAND_ORDER_FIELDS, omit: OMIT_ORDER_FIELDS },
  });

export const readCart = (data, params) => api.post(`/orders/cart/`, data, { params });

export const readOrder = (orderId, params) =>
  api.get(`/orders/${orderId}`, {
    params: { expand: EXPAND_ORDER_FIELDS, omit: OMIT_ORDER_FIELDS, ...params },
  });

export const readOrders = async (params) => api.get('/orders/', { params });

export const calculateOrder = (data) =>
  api.post(`/orders/calculate/`, data, {
    params: { expand: EXPAND_ORDER_FIELDS, omit: OMIT_ORDER_FIELDS },
  });

export const patchOrder = async (orderId, data) =>
  await api.patch(`/orders/${orderId}/`, data, {
    params: { expand: EXPAND_ORDER_FIELDS, omit: OMIT_ORDER_FIELDS },
  });

export const openOrder = (orderId, data) =>
  api.post(`/orders/${orderId}/open/`, data, {
    params: { expand: EXPAND_ORDER_FIELDS, omit: OMIT_ORDER_FIELDS },
  });

export const payOrder = (orderId, data, params) =>
  api.post(`/orders/${orderId}/pay/`, data, { params });

export const readDeferredOrder = (uid, token) => {
  return api.get(`orders/deferred/${uid}/${token}`, {
    params: {
      expand: `${EXPAND_ORDER_FIELDS},payment.payment_strategy`,
      omit: OMIT_ORDER_FIELDS,
    },
  });
};

export const duplicateOrder = (orderId, data) =>
  api.post(`/orders/${orderId}/duplicate/`, data, {
    params: { expand: 'price_list' },
  });

export const cancelOrderCart = (orderId) => api.post(`/orders/${orderId}/cancel-cart/`);
