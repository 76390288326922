import { DEFAULT_COLOR_SET } from 'helpers/constants';

export function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function getRgbFromHex(hex) {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `${r}, ${g}, ${b}`;
}

export const setThemeDefault = () => {
  const theme = DEFAULT_COLOR_SET?.reduce((acc, color) => {
    acc[`--theme-color-${color.colorId}`] = getRgbFromHex(color.hexColor);
    return acc;
  }, {});
  applyTheme(theme);
};
