import { Route, Switch, Redirect } from 'react-router-dom';
import { CUSTOMER, SELLER } from '.';
import Enter from './Enter';

const Impersonate = () => {
  return (
    <Switch>
      <Route name="Exit Impersonation" path="/impersonate/exit">
        <Redirect to="/backoffice/logout" />
      </Route>
      <Route name="Impersonate Seller" path="/impersonate/ll-user/:userId">
        <Enter actor={SELLER} />
      </Route>
      <Route name="Impersonate Customer" path="/impersonate/customer/:userId">
        <Enter actor={CUSTOMER} />
      </Route>
    </Switch>
  );
};

Impersonate.propTypes = {};

export default Impersonate;
