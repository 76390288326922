import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="24"
    height="24"
    className={classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.03647 11.5547C8.59343 10.8901 9.06982 10 9.86852 10H14.1315C14.9302 10 15.4066 10.8901 14.9635 11.5547L12.8321 14.7519C12.4362 15.3457 11.5638 15.3457 11.1679 14.7519L9.03647 11.5547Z"
      fill={stroke}
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
