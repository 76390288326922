import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import { formatDateTime } from 'helpers/localeHelpers';

const PayNowPayLater = ({}) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { order, setOrder, loading } = useOrder(priceListSlug);

  return (
    <div className="flex flex-col border rounded-lg mb-8">
      <div className="flex px-4 py-4 ">
        <InputCheckboxRadio
          type="radio"
          name="pay_now"
          id="pay_now"
          label={t('storefront/store/checkout--payment-pay-now')}
          help={t('storefront/store/checkout--payment-pay-now-description')}
          checked={!order?.payment?.pay_later}
          inputClasses="flex-none"
          loading={loading}
          onChange={() =>
            setOrder({
              payment: { pay_later: false },
            })
          }
        />
      </div>
      <div className="flex border-t first:border-none px-4 py-4">
        <InputCheckboxRadio
          type="radio"
          name="pay_later"
          id="pay_later"
          label={t('storefront/store/checkout--payment-pay-later')}
          help={t('storefront/store/checkout--payment-pay-later-description', {
            due_date: formatDateTime(order?.payment?.due_date, 'dddd, LL'),
          })}
          checked={order?.payment?.pay_later}
          inputClasses="flex-none"
          loading={loading}
          onChange={() =>
            setOrder({
              payment: { pay_later: true },
            })
          }
        />
      </div>
    </div>
  );
};

PayNowPayLater.propTypes = {};

export default PayNowPayLater;
