// e.g. 2024-01-22 => Monday January 22nd 2024
export default (isoDateString) => {
  // todo: when upgraded to i18n v4 https://www.i18next.com/misc/json-format#i18next-json-v4 use built in ordinal rules.
  const _integerToOrdinalString = (n) => {
    const enOrdinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
    const suffixes = new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ]);
    const rule = enOrdinalRules.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix}`;
  };

  const _weekdayIntegerToFulfillmentAvailabilityRepeatDayField = (n) => {
    return [
      'repeat_on_sunday',
      'repeat_on_monday',
      'repeat_on_tuesday',
      'repeat_on_wednesday',
      'repeat_on_thursday',
      'repeat_on_friday',
      'repeat_on_saturday',
    ][n];
  };

  const date = new Date(isoDateString?.replace(/-/g, '/')); // https://stackoverflow.com/a/31732581
  const day = date.getDate(); // 22
  const dayString = _integerToOrdinalString(day); // 22nd
  const weekday = date.getDay(); // 1
  const weekdayString = date.toLocaleDateString('en-CA', {
    weekday: 'long',
    timeZone: 'UTC',
  }); // Monday
  const weekdayMonthOccurrence = Math.floor((day + 6) / 7); // 4
  const weekdayMonthOccurrenceString = _integerToOrdinalString(weekdayMonthOccurrence); // 4th
  return {
    date,
    day: {
      numeric: day,
      string: dayString,
    },
    weekday: {
      numeric: weekday,
      string: weekdayString,
    },
    weekdayMonthOccurrence: {
      numeric: weekdayMonthOccurrence,
      string: weekdayMonthOccurrenceString,
    },
    repeatDayField: _weekdayIntegerToFulfillmentAvailabilityRepeatDayField(weekday),
  };
};
