import { uniqBy } from 'lodash';
import {
  FILTER_OUT_OF_STOCK,
  FILTER_SUBSCRIPTIONS_AVAILABLE,
  FILTER_SEARCH,
  FILTER_ON_SALE_TOGGLE,
} from 'pages/storefront/Store/components';
import { useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ERR_CANCELED, ON, SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { api } from 'api/storefront';
import { readPriceListProducts } from 'api/storefront/price-list-products';
import { accountAtom } from 'state/storefront/accountState';
import { productsAtom } from 'state/storefront/storeState';
import useSearchParamsState from 'hooks/common/searchParamsState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';

const FILTER_CATEGORY = 'price_list_category';
const FILTER_TAGS = 'tags';
const FILTER_VENDORS = 'vendors';
const ORDERING_CATEGORY = 'price_list_category__priority_order';
const ORDERING_PRIORITY = 'priority_order';

export const DEFAULT_PRODUCT_STATE = {
  results: [],
  count: 0,
  next: '',
  errors: {},
  loading: false,
  search: '',
  ordering: `${ORDERING_CATEGORY},${ORDERING_PRIORITY}`,
  page_size: 50,
  hideOutOfStock: false,
  subscriptionsAvailable: false,
  page: 1,
};

const useProducts = () => {
  const [products, setProducts] = useRecoilState(productsAtom);
  const { priceList } = useOrder();

  const storefrontSubscriptionsFlagEnabled = useTreatment('storefront_subscriptions') == ON;
  const storefrontSubscriptionsEnabled = storefrontSubscriptionsFlagEnabled;

  const account = useRecoilValue(accountAtom);
  const controller = useRef(new AbortController());
  const { getFilterValue, getAllFilterValues } = useSearchParamsState();
  const { storefront_configuration: configuration } = account;
  const displayOutOfStockSetting = configuration?.display_out_of_stock;

  const filters = {
    [FILTER_CATEGORY]: getAllFilterValues(FILTER_CATEGORY),
    [FILTER_TAGS]: getAllFilterValues(FILTER_TAGS),
    [FILTER_VENDORS]: getAllFilterValues(FILTER_VENDORS),
  };

  const search = getFilterValue(FILTER_SEARCH) || '';
  const onSale = getFilterValue(FILTER_ON_SALE_TOGGLE) === 'true' || false;
  const hideOutOfStock = getFilterValue(FILTER_OUT_OF_STOCK) === 'true' || false;
  const subscriptionsAvailable = getFilterValue(FILTER_SUBSCRIPTIONS_AVAILABLE) === 'true' || false;

  // TODO: Refactor function
  const fetchProducts = async (e, reset) => {
    if (!priceList?.id) return;
    controller?.current?.abort('Refetching, cancelling previous request');
    setProducts((prevState) => ({ ...prevState, loading: true }));

    const { next, page_size, results, ordering } = products;
    try {
      controller.current = new AbortController();
      const { signal } = controller?.current;
      const params = new URLSearchParams({
        page: 1,
        page_size,
        ordering,
        [FILTER_CATEGORY]: filters[FILTER_CATEGORY].join(','),
        [FILTER_TAGS]: filters[FILTER_TAGS].join(','),
        [FILTER_VENDORS]: filters[FILTER_VENDORS].join(','),
        [FILTER_ON_SALE_TOGGLE]: onSale,
        [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
        [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
      });

      if (search) params.append(FILTER_SEARCH, search);
      const resp =
        next && !reset
          ? await api(next)
          : await readPriceListProducts(priceList?.id, params, signal);

      // if > first page, combine new products with previous products
      let serverResults =
        next && !reset ? uniqBy([...results, ...resp.data?.results], 'id') : resp?.data?.results;
      if (!storefrontSubscriptionsEnabled) {
        serverResults.forEach((product) => {
          product.package_price_list_entries.forEach((packagePriceListEntry) => {
            if (
              packagePriceListEntry.packagepricelistentrysubscriptionsettings?.order_options ===
              SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION
            ) {
              packagePriceListEntry.packagepricelistentrysubscriptionsettings.order_options =
                SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME;
            }
          });
        });
        serverResults.forEach((product) => {
          product.package_price_list_entries = product.package_price_list_entries.filter(
            (packagePriceListEntry) =>
              packagePriceListEntry.packagepricelistentrysubscriptionsettings?.order_options !==
              SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
          );
        });
        serverResults = serverResults.filter(
          (product) => product.package_price_list_entries.length > 0
        );
      }

      setProducts((prevState) => ({
        ...prevState,
        results: serverResults,
        count: resp.data?.count,
        next: resp.data?.next,
        loading: false,
      }));
    } catch (err) {
      console.error(err);
      if (err.code !== ERR_CANCELED)
        setProducts((prevState) => ({
          ...prevState,
          errors: { error: err?.message },
          loading: false,
        }));
    }
  };

  return { products, setProducts, fetchProducts, onSale, hideOutOfStock, subscriptionsAvailable };
};

export default useProducts;
