import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DAY_OF_WEEK_LONG_MONTH_LONG_DAY_YEAR, formatDateTime } from 'helpers/localeHelpers';

const FulfillmentSummaryDate = ({ fulfillmentType, fulfillmentDate, pickupTime }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row mb-2 justify-between">
      {fulfillmentDate && (
        <div className="flex flex-col">
          <h4 className="text-Grey500">{t(fulfillmentType)} Date</h4>
          <h4 className="text-Grey800">
            {formatDateTime(fulfillmentDate, DAY_OF_WEEK_LONG_MONTH_LONG_DAY_YEAR)}
          </h4>
        </div>
      )}
      {pickupTime && (
        <div className="flex flex-col">
          <h4 className="text-Grey500">{t('Pickup Time')}</h4>
          <h4 className="text-Grey800">{pickupTime}</h4>
        </div>
      )}
      <div />
    </div>
  );
};

FulfillmentSummaryDate.propTypes = {
  fulfillmentType: PropTypes.string,
  fulfillmentDate: PropTypes.string,
  pickupTime: PropTypes.string,
};

FulfillmentSummaryDate.defaultProps = {
  fulfillmentType: '',
  fulfillmentDate: '',
  pickupTime: '',
};

export default FulfillmentSummaryDate;
