import { useRef, useEffect, useState } from 'react';

// Handles click outside of component to close menu
// No need for parent open/close state management
export const ClickOutside = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const { current } = ref;

    // If there is a react-select element within the element
    // by the time this check happens to see if it contains the element
    // the select has been removed by the DOM. We can get around this by
    // checking to see if the target is a react-select option
    // also portals, as they are on the edge of the DOM
    if (
      current &&
      !current.contains(event.target) &&
      !event.target?.classList?.contains('react-select__option') &&
      !event.target?.closest('.menu-dropdown--portal')
    ) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

// El is a document node to position it from
// childNode is the element that should be positioned
// determines which side of the page the element is on, will
// position in the opposite direction to ensure it will fit
export const useCoords = (el, childNode) => {
  const [coords, setCoords] = useState({ left: 0, top: 0 });

  const elementIsOnRightSideOfScreen = (pos) => {
    const { innerWidth } = window;
    return pos > innerWidth / 2;
  };

  const calculatePosition = () => {
    if (!!el && !!childNode) {
      const { bottom, left, right, width } = el?.getBoundingClientRect();
      const elPositionIncludingHalfOfWidth = left + width / 2;

      const scrollY = window.scrollY;
      const scrollX = window.scrollX;

      if (elementIsOnRightSideOfScreen(elPositionIncludingHalfOfWidth)) {
        const childNodeWidth = childNode?.getBoundingClientRect()?.width;
        setCoords({ top: bottom + scrollY, left: right - childNodeWidth + scrollX });
      } else {
        setCoords({ top: bottom + scrollY, left: left + scrollX });
      }
    } else {
      setCoords({ top: 0, left: 0 });
    }
  };

  useEffect(() => {
    calculatePosition();
  }, [el, childNode]);

  return coords;
};
