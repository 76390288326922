import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg className={`animate-spin-slow ${classes}`} width='100' height='100' viewBox='0 0 100 100'>
      <circle
      stroke={stroke}
      strokeLinecap="round"
      cx="50"
      cy="50"
      r="20"
      strokeWidth="4"
      fill="none"
      strokeDasharray="160"
      strokeDashoffset="100"
      strokemitterlimit="0"
      transform="rotate(-90 ) translate(-100 0)"
      />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Green600,
  classes: '',
};

export default Svg;
