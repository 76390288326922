import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="20"
    height="20"
    className={classes}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1667 6.66668H15.8333C16.7538 6.66668 17.5 7.41287 17.5 8.33334V13.3333C17.5 14.2538 16.7538 15 15.8333 15H14.1667V18.3333L10.8333 15H7.5C7.03976 15 6.6231 14.8135 6.32149 14.5119M6.32149 14.5119L9.16667 11.6667H12.5C13.4205 11.6667 14.1667 10.9205 14.1667 10V5.00001C14.1667 4.07954 13.4205 3.33334 12.5 3.33334H4.16667C3.24619 3.33334 2.5 4.07954 2.5 5.00001V10C2.5 10.9205 3.24619 11.6667 4.16667 11.6667H5.83333V15L6.32149 14.5119Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
