import { PropTypes } from 'prop-types';
import { Fragment } from 'react';
import useProducts from 'hooks/storefront/products';
import { ORDERING_CATEGORY } from '..';
import Footer from './Footer';
import ProductCategoryCard from './ProductCategoryCard';
import ProductsViewManager from './Views/ProductViewManager';

const ProductsContainer = ({ setErrors }) => {
  const { products } = useProducts();
  const { ordering, loading, results } = products;
  // methods
  const generateProductCards = () => {
    if (ordering.includes(ORDERING_CATEGORY)) {
      const listOfCategories = [
        ...new Map(
          results.map(
            ({
              price_list_category: id,
              price_list_category_name: name,
              price_list_category_description: description,
            }) => [id, { id, name, description }]
          )
        ).values(),
      ];

      // for each category, if there is products for it render them
      // reduce to avoid mapping twice
      // sort by priority_order for products and categories
      return listOfCategories.reduce((result, { id, name, description }) => {
        const categoryProducts = results.filter((prod) => prod.price_list_category === id);
        if (categoryProducts.length > 0) {
          result.push(
            <Fragment key={`${id}-${name}`}>
              <ProductCategoryCard
                name={name}
                description={description}
                count={categoryProducts.length}
              />
              <ProductsViewManager
                key={`${id}-${name}-products`}
                products={categoryProducts}
                setErrors={setErrors}
              />
            </Fragment>
          );
        }
        return result;
      }, []);
    }

    return (
      <div className="mt-6">
        <ProductsViewManager products={products?.results} setErrors={setErrors} />
      </div>
    );
  };

  return (
    <div className="store__products-container">
      {generateProductCards()}
      <Footer loading={loading} />
    </div>
  );
};

ProductsContainer.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      inventory: PropTypes.number,
      name: PropTypes.string,
      package_price_list_entries: PropTypes.array,
      price_list: PropTypes.number,
      price_list_category: PropTypes.number,
      priority_order: PropTypes.number,
      product: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      thumbnail: PropTypes.string,
    })
  ),
  setErrors: PropTypes.func,
  total: PropTypes.number,
};

ProductsContainer.defaultProps = {
  setErrors: () => {},
  priceListId: null,
  loading: false,
  ordering: 'price_list_category',
};

export default ProductsContainer;
