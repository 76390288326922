import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import Button from 'components/styled/Button';
import SidePopout from 'components/styled/SidePopout';
import { ACCOUNT } from './..';
import { useStorefrontAuth } from 'hooks/storefront';
import { accountAtom, customerAtom, loginActiveAtom } from 'state/storefront/accountState';
import {
  Logout,
  ClipboardCheck,
  ClipboardDropDown,
  User,
  Key,
  CreditCard,
  CurrencyDollarSmall,
  X,
} from 'assets/svgIcons';
import Links from './Header/Links';
import { STORE_CREDITS } from 'state/backoffice/featureSelectors';

const HamburgerMenu = forwardRef(
  ({ setIsHamburgerVisible, isHamburgerVisible, previousSlug, setModal }, ref) => {
    const { t } = useTranslation();
    const { priceListSlug } = useParams();
    const { available_features, storefront_configuration: configuration } =
      useRecoilValue(accountAtom);
    const setLoginActive = useSetRecoilState(loginActiveAtom);
    const customer = useRecoilValue(customerAtom);
    const name = configuration?.store_name || '';
    const { clearAuth } = useStorefrontAuth();
    const resetCustomer = useResetRecoilState(customerAtom);

    const storeCreditFeatureEnabled = available_features?.find(
      (feature) => feature.name === STORE_CREDITS
    )?.enabled;

    return (
      <div ref={ref} className="store__hamburger">
        <SidePopout
          direction="LEFT"
          isOpen={isHamburgerVisible}
          onClose={() => setIsHamburgerVisible((prevState) => !prevState)}>
          <div className="w-screen h-full bg-White flex flex-col p-4">
            <div className="flex items-center justify-between">
              <Link
                to={{ pathname: `/${!priceListSlug?.includes(ACCOUNT) ? priceListSlug : ''}` }}
                className="mt-0">
                <h3 className="font-bold text-Grey600">{name}</h3>
              </Link>
              <div onClick={setIsHamburgerVisible}>
                <X />
              </div>
            </div>
            <Links setModal={setModal} previousSlug={previousSlug} />
            <hr className="mt-4" />
            {!customer?.id ? (
              <Button classes="button--white mt-4 w-full" onClick={() => setLoginActive(true)}>
                {t('storefront/store/shop/header--login')}
              </Button>
            ) : (
              <>
                <Link to="/account">
                  <h3 className="font-bold text-Grey600">{t('My Account')}</h3>
                </Link>
                <Link to="/account/order-history">
                  <ClipboardCheck classes="mr-3" />
                  {t('Order History')}
                </Link>
                <Link to="/account/account-settings">
                  <User classes="mr-3" />
                  {t('Account Settings')}
                </Link>
                <Link to="/account/price-lists">
                  <ClipboardDropDown classes="mr-3" />
                  {t('Price Lists')}
                </Link>
                <Link to="/account/saved-cards">
                  <CreditCard classes="mr-3" />
                  {t('Saved Cards')}
                </Link>
                {storeCreditFeatureEnabled && (
                  <Link to="/account/store-credit">
                    <CurrencyDollarSmall classes="mr-3" />
                    {t('Store Credit')}
                  </Link>
                )}
                <div
                  key="logout"
                  className="flex items-center text-left pt-4 hover:bg-Grey100"
                  onClick={async () => {
                    await clearAuth();
                    await resetCustomer();
                    location.href = '/';
                  }}>
                  <Logout />
                  <span className="ml-3 text-Grey600 text-base">
                    {t('storefront/store/shop/header--logout')}
                  </span>
                </div>
              </>
            )}
          </div>
        </SidePopout>
      </div>
    );
  }
);

HamburgerMenu.propTypes = {
  setIsHamburgerVisible: PropTypes.func,
  setModal: PropTypes.func,
  isHamburgerVisible: PropTypes.bool,
  previousSlug: PropTypes.string,
};

HamburgerMenu.defaultProps = {
  isHamburgerVisible: false,
  setIsHamburgerVisible: () => {},
  setModal: () => {},
  previousSlug: '',
};

export default HamburgerMenu;
