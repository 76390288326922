import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { VIEWPORT_SIZES } from 'helpers/constants';
import { screenSizeAtom } from 'state/appState';
import SidePopout from 'components/styled/SidePopout';
import FilterPanel from './FilterPanel';

const StorefrontSidebar = ({ filterPanelVisible, setFilterPanelVisible, ref, backgroundColor }) => {
  const { width } = useRecoilValue(screenSizeAtom);

  return (
    <div ref={ref}>
      {VIEWPORT_SIZES.lg <= width ? (
        <FilterPanel backgroundColor={backgroundColor} />
      ) : (
        filterPanelVisible && (
          <div ref={ref}>
            <SidePopout
              direction="LEFT"
              isOpen={filterPanelVisible}
              onClose={() => setFilterPanelVisible(!filterPanelVisible)}
              showOverlay
              overlayClasses="top-[149px]">
              <FilterPanel backgroundColor={backgroundColor} />
            </SidePopout>
          </div>
        )
      )}
    </div>
  );
};

StorefrontSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.string,
  priceList: PropTypes.object,
  filtersSet: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  onSale: PropTypes.bool,
  setOnSale: PropTypes.func,
  hideOutOfStock: PropTypes.bool,
  setHideOutOfStock: PropTypes.func,
  subscriptionsAvailable: PropTypes.bool,
  setSubscriptionsAvailable: PropTypes.func,
  filterPanelVisible: PropTypes.bool,
  setFilterPanelVisible: PropTypes.func,
  setProducts: PropTypes.func,
  ref: PropTypes.object,
  backgroundColor: PropTypes.string,
};

StorefrontSidebar.defaultProps = {
  children: undefined,
  classes: '',
  priceList: [],
  filtersSet: undefined,
  filters: undefined,
  setFilters: () => {},
  onSale: false,
  setOnSale: () => {},
  hideOutOfStock: false,
  setHideOutOfStock: () => {},
  subscriptionsAvailable: false,
  setSubscriptionsAvailable: () => {},
  filterPanelVisible: false,
  setFilterPanelVisible: () => {},
  setProducts: () => {},
  ref: undefined,
  backgroundColor: '',
};

export default StorefrontSidebar;
