import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'components/styled/Tag';
import VendorContactInfo from './VendorContactInfo';

const VendorCard = ({ vendorData, classes }) => {
  const { t } = useTranslation();

  const { image_full, name, location, description, email, phone_number, website, certifications } =
    vendorData;

  return (
    <div className={`${classes}`}>
      <div classes="flex flex-col">
        <div className="flex flex-row items-center">
          {image_full && (
            <img
              alt="logo"
              src={image_full}
              className="h-12 max-w-[200px] object-contain rounded-md mr-3"
            />
          )}
          <div>
            <h3 className="text-sm font-bold text-Grey800">{name}</h3>
            <span className="text-sm text-Grey500">{location} </span>
          </div>
        </div>
        <div className="flex flex-col">
          {description && (
            <div className="mt-4">
              <div className="text-Grey800" dangerouslySetInnerHTML={{ __html: description }} />
              <hr className="my-4" />
            </div>
          )}

          <div className="flex flex-col sm:flex-row justify-between flex-wrap">
            {website && <VendorContactInfo title={t('Website')} text={website} link={website} />}
            {certifications?.length > 0 ? (
              <VendorContactInfo
                title={t('connections/vendor-form/vendor-info--certifications')}
                text={
                  <div className="flex flex-row mt-2 flex-wrap">
                    {certifications.map((certification) => (
                      <Tag
                        name={certification.name}
                        showButton={false}
                        classes="storefront--green"
                      />
                    ))}
                  </div>
                }
              />
            ) : null}
            {email && (
              <VendorContactInfo title={t('Contact Email')} text={email} link={`mailto:${email}`} />
            )}
            {phone_number && <VendorContactInfo title={t('Phone')} text={phone_number} />}
          </div>
        </div>
      </div>
    </div>
  );
};

VendorCard.propTypes = {
  vendorData: PropTypes.object.isRequired,
  classes: PropTypes.string,
};

VendorCard.defaultProps = {
  classes: '',
};

export default VendorCard;
