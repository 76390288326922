import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageSlider from './ImageSlider';
import useApi from 'hooks/common/api';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { readPriceListProduct } from 'api/storefront/price-list-products';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';
import { useOrder } from 'hooks/storefront';
import { subscriptionsSelector } from 'state/storefront/accountState';

const ProductImageHoverCarrousel = ({ productId, src, alt, soldOut, classes }) => {
  const { priceListSlug } = useParams();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const { priceList: priceListHook } = useOrder();
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const { request: readPriceListProductRequest, loading: priceListProductLoading } =
    useApi(readPriceListProduct);

  const [imagesCarrousel, setImagesCarrousel] = useState([src]);
  const [imagesAlreadySearched, setImagesAlreadySearched] = useState(false);

  const loadImagesCarrousel = () => {
    const fetchProductImages = async () => {
      const resp = await readPriceListProductRequest(priceList?.id, productId);
      if (!resp) return;
      setImagesCarrousel(resp?.images?.map((image) => image.display));
      setImagesAlreadySearched(true);
    };

    fetchProductImages();
  };

  return (
    <>
      {!!imagesCarrousel && (
        <div
          className="relative pb-[100%]"
          onMouseOver={() => {
            if (!imagesAlreadySearched && !soldOut) loadImagesCarrousel();
          }}>
          <ImageSlider imageUrls={imagesCarrousel} classes={classes} />
        </div>
      )}
    </>
  );
};

ProductImageHoverCarrousel.propTypes = {
  product: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  soldOut: PropTypes.bool,
  classes: PropTypes.string,
};

ProductImageHoverCarrousel.defaultProps = {
  src: '',
  alt: '',
  soldOut: false,
  classes: '',
};

export default ProductImageHoverCarrousel;
