import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// TODO: Make readAutocomplete prop to support all apps
import { readAutocomplete } from 'api/storefront/locations';
import Button from 'components/styled/Button/Button';
import InputSelect from 'components/styled/InputSelect';

const InputAddress = (props) => {
  const { t } = useTranslation();

  const { label, types, value, showCopyButton, readAutocompleteApi } = props;

  const fetchLocations = async (input) => {
    try {
      const response = await readAutocompleteApi({ input, types });
      return response.data.map((prediction) => ({
        label: prediction.description,
        value: prediction.place_id,
        ...prediction,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(value?.label);
  };

  return (
    <>
      <InputSelect
        id={`input-address-${label}`}
        key={`input-address-${label}`}
        classes="flex-1"
        isAsync
        isClearable
        menuPortal
        menuPortalTarget={document.body}
        loadOptions={fetchLocations}
        placeholder={t('global/input-address--placeholder')}
        components={{
          IndicatorsContainer: () => null,
          NoOptionsMessage: () => (
            <div className="text-center py-2">
              <span>{t('global/input-address--no-options-message')}</span>
            </div>
          ),
        }}
        {...props}
      />
      {!!showCopyButton && (
        <Button
          classes="button--white md:ml-2 mt-4 md:mt-6"
          onClick={handleCopyAddress}
          disabled={!value?.label}>
          {t('global/input-address--copy-address')}
        </Button>
      )}
    </>
  );
};

InputAddress.propTypes = {
  label: PropTypes.string,
  types: PropTypes.string,
  showCopyButton: PropTypes.bool,
  readAutocompleteApi: PropTypes.func,
};

InputAddress.defaultProps = {
  label: '',
  types: encodeURIComponent('geocode'),
  showCopyButton: false,
  readAutocompleteApi: readAutocomplete,
};

export default InputAddress;
