import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';
import { CUSTOM } from '../../Store/components/PaymentComponents';
import { formatDateTime } from 'helpers/localeHelpers';

const Instructions = ({ selectedPaymentStrategy, singleStrategy }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { order } = useOrder(priceListSlug);
  const showPaymentDue = selectedPaymentStrategy?.type === CUSTOM && order?.payment?.due_date;
  const showPaymentInstructions = selectedPaymentStrategy?.instructions;

  return (
    <div>
      {showPaymentInstructions && (
        <>
          {!singleStrategy && (
            <h3 className="text-lg mb-4 mt-4">
              {t('storefront/store/checkout--complete-fulfillment-instructions-title', {
                type: selectedPaymentStrategy?.name,
              })}
            </h3>
          )}
          <div
            className="rich-text-editor-render text-Gray500 text-sm"
            dangerouslySetInnerHTML={{
              __html: selectedPaymentStrategy?.instructions,
            }}
          />
        </>
      )}
      {showPaymentDue && (
        <div className="mt-4">
          <h4>{t('storefront/store/checkout--payment-payment-due')}</h4>
          <p className="mt-4">{formatDateTime(order?.payment?.due_date, 'dddd, LL')}</p>
        </div>
      )}
    </div>
  );
};

Instructions.propTypes = {};

export default Instructions;
