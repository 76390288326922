import axios from 'axios';
import { getDomainParts } from 'helpers/helpers';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/storefront/v2/`,
  timeout: 60000,
  headers: {
    Subdomain: getDomainParts()['subdomain'], // storefront must include subdomain header
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // if the token is expired, reload the page. This should only happen if a
      // user has kept the storefront open longer than the lifetime of a token
      // which should be pretty rare, but this is just in case.
      // location.reload();
    }
    return Promise.reject(error); // bubble error up
  }
);
