import PropTypes from 'prop-types';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SpinnerSmall } from 'assets/svgIcons';
import { customerAtom } from 'state/storefront/accountState';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import { capitalize } from 'helpers/helpers';

const CheckoutSavedPayments = forwardRef(
  (
    {
      selectedPaymentStrategy,
      selectedSavedCardId,
      setSelectedSavedCardId,
      showSavedCards,
      setOrder,
      gatewayPaymentMethods,
      savedCards,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const cardRef = useRef(null);
    // state
    const [loading, setLoading] = useState(false);
    const customer = useRecoilValue(customerAtom);
    const [differentCard, setDifferentCard] = useState(false);

    useImperativeHandle(ref, () => ({
      async getSourceId() {
        if (!cardRef.current) return null;
        const resp = await cardRef.current.tokenize();
        return resp?.token;
      },
    }));

    // effects
    useEffect(() => {
      if (!!selectedPaymentStrategy?.type && showSavedCards && customer?.id) {
        setDifferentCard(false);
      }
    }, [selectedPaymentStrategy?.id]);

    return loading ? (
      <SpinnerSmall classes="my-6 self-center" />
    ) : (
      <>
        {savedCards.length > 0 && (
          <>
            <h2 className="px-0 mb-3">{t('Saved Payments')}</h2>
            <div className="px-4">
              {savedCards.map(({ brand, last4, source_id, gateway_payment_method }) => (
                <InputCheckboxRadio
                  id={`${source_id}`}
                  name={`${source_id}`}
                  key={`${source_id}`}
                  checked={selectedSavedCardId === source_id}
                  onChange={() => {
                    setDifferentCard(false);
                    setSelectedSavedCardId(source_id);
                    setOrder({
                      payment: { gateway_payment_method: gateway_payment_method?.id },
                    });
                  }}
                  loading={loading}
                  labelClasses="mb-4"
                  label={t(`storefront/store/checkout--payment-save-card-ending-in`, {
                    brand: capitalize(brand),
                    last4,
                  })}
                  type="radio"
                />
              ))}
              <InputCheckboxRadio
                id={`different-card`}
                name={`different-card`}
                key={`different-card`}
                checked={differentCard}
                onChange={() => {
                  setDifferentCard(true);
                  setSelectedSavedCardId();
                  setOrder({
                    payment: {
                      gateway_payment_method: gatewayPaymentMethods?.length
                        ? gatewayPaymentMethods?.[0]?.gateway_payment_method
                        : null,
                    },
                  });
                }}
                loading={loading}
                label={t(`storefront/store/checkout--payment-save-payment-different-payment`)}
                type="radio"
              />
            </div>
          </>
        )}
      </>
    );
  }
);

CheckoutSavedPayments.propTypes = {
  selectedPaymentStrategy: PropTypes.object,
  selectedSavedCardId: PropTypes.string,
  setSelectedSavedCardId: PropTypes.func,
  showSavedCards: PropTypes.bool,
};

export default CheckoutSavedPayments;
