import PropTypes from 'prop-types';
import Button from 'components/styled/Button';
import { formatProblemJSONErrors, scrollTo } from 'helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { checkoutPaymentErrorsAtom } from 'state/storefront/storeState';
import { checkoutErrorsAtom } from 'pages/storefront/Checkout/CheckoutState';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';

const Pay = ({
  selectedSavedCardId,
  redirectToComplete,
  disabled,
  classes,
  buttonClasses,
  buttonText,
  order,
  setOrder,
  saveCard,
  paymentRef,
  setSubmittingOrder,
  onPay,
  isPaymentGateway,
  payCallback,
  validateCheckoutOrder,
  setErrors
}) => {
  const { t } = useTranslation();
  const isJsonString = (s) => {
    try {
      JSON.parse(s);
    } catch (e) {
      return false;
    }
    return true;
  };
  const setCheckoutPaymentErrors = useSetRecoilState(checkoutPaymentErrorsAtom);
  const quickCheckout = useTreatment('storefront-quick-checkout') === ON;

  const _validateSourceId = (options) => {
    const hasStoreCreditLessThanBalance =
      order?.payment?.store_credit_amount > 0 ? order?.payment?.balance > 0 : true;

    if (
      isPaymentGateway &&
      !options.source_id &&
      !order?.payment?.pay_later &&
      hasStoreCreditLessThanBalance &&
      order?.payment?.balance > 0
    ) {
      setErrors((prevState) => ({ ...prevState, source_id: t('storefront/store/checkout--payment--source-id-error') }));
      return false;
    }
    return true
  }

  const _validateOrder = (options) => {
    const isSourceIdValid = _validateSourceId(options)
    const isOrderValid = validateCheckoutOrder()
    return isSourceIdValid && isOrderValid
  }


  const quickPay = async () => {
    setSubmittingOrder(true);
    setErrors({})
    try {
      const options = await paymentOptions();
      const isValid = _validateOrder(options)
      if (!isValid) {
        scrollTo(0, 0);
        return
      }
      await onPay(options);
      redirectToComplete();
    } catch (e) {
      setErrors((prevState) => ({ ...prevState, error: e.message }));
      scrollTo(0, 0);
    } finally {
      setSubmittingOrder(false);
    }
  }


  const pay = async () => {
    setSubmittingOrder(true);
    setCheckoutPaymentErrors([]);
    try {
      const options = await paymentOptions();
      const hasStoreCreditLessThanBalance =
        order?.payment?.store_credit_amount > 0 ? order?.payment?.balance > 0 : true;

      if (
        isPaymentGateway &&
        !options.source_id &&
        !order?.payment?.pay_later &&
        hasStoreCreditLessThanBalance &&
        order?.payment?.balance > 0
      )
        throw new Error(t('storefront/store/checkout--payment--source-id-error'));
      await onPay(options);
      payCallback();
      redirectToComplete();
    } catch (e) {
      // Gateway errors
      setCheckoutPaymentErrors((prevState) => [...prevState, e?.response?.data?.detail]);

      // Billing Address form errors
      if (isJsonString(e?.message)) {
        const formattedErrors = JSON.parse(e?.message);
        setCheckoutPaymentErrors((prevState) => [...prevState, ...Object.values(formattedErrors)]);
      }

      scrollTo(0, 0);
      if (!!setOrder) {
        setOrder();
      }
    } finally {
      setSubmittingOrder(false);
    }
  };

  const paymentOptions = async () => {
    const options = {};
    if (isPaymentGateway) {
      if (!!selectedSavedCardId) {
        options['source_id'] = selectedSavedCardId;
        options['is_saved_card'] = true;
      } else {
        const resp = await paymentRef?.current?.getSourceId();
        options['source_id'] = resp?.token;
      }
      if (saveCard) {
        options['save_card'] = true;
      }
    }
    return options;
  };

  return (
    <div className={`flex ${classes}`}>
      <Button
        id="storefront/store/checkout--payment-place-button"
        disabled={disabled}
        classes={`button flex-1 ${buttonClasses}`}
        onClick={async () => {
          if (quickCheckout) {
            await quickPay();
          } else {
            await pay();
          }
        }}>
        {buttonText}
      </Button>
    </div>
  );
};

Pay.propTypes = {
  order: PropTypes.object,
  selectedSavedCardId: PropTypes.number,
  redirectToComplete: PropTypes.func,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  buttonClasses: PropTypes.string,
  buttonText: PropTypes.string,
  setOrder: PropTypes.func,
  saveCard: PropTypes.bool,
  setSubmittingOrder: PropTypes.func,
  onPay: PropTypes.func,
  payCallback: PropTypes.func,
};

Pay.defaultProps = {
  order: {},
  selectedSavedCardId: undefined,
  redirectToComplete: () => { },
  disabled: false,
  classes: '',
  buttonClasses: '',
  buttonText: '',
  setOrder: undefined,
  saveCard: false,
  setSubmittingOrder: () => { },
  payCallback: () => { },
  setErrors: () => { },
  validateCheckoutOrder: () => true
};

export default Pay;
