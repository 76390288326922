import { SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ON } from 'helpers/constants';
import { getGatewayDetails } from 'helpers/helpers';
import { readPayrixGatewayCustomers } from 'api/storefront/payment-gateways';
import { readPriceListPaymentStrategies } from 'api/storefront/price-lists';
import { accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { checkoutPaymentErrorsAtom } from 'state/storefront/storeState';
import useApi from 'hooks/common/api';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import GatewayPaymentMethods from 'components/GatewayPaymentMethods/index.js';
import Card from 'components/styled/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import { CUSTOM } from '../../Store/components/PaymentComponents';
import CheckoutAchAuthorization from '../../Store/components/PaymentComponents/CheckoutAchAuthorization';
import CheckoutSavedPayments from '../../Store/components/PaymentComponents/CheckoutSavedPayments';
import SaveCard from '../../Store/components/PaymentComponents/SaveCard';
import { PICKUP } from '../index';
import CheckoutNeedsApprovalMessage from './CheckoutNeedsApprovalMessage';
import DeliveryBillingAddress from './DeliveryBillingAddress.js';
import Instructions from './Instructions.js';
import PayNowPayLater from './PayNowPayLater.js';
import PickupBillingAddress from './PickupBillingAddress.js';
import StoreCredit from './StoreCredit.js';

const Payment = forwardRef(
  (
    {
      selectedPaymentStrategy,
      selectPaymentStrategy,
      selectedSavedCardId,
      setSelectedSavedCardId,
      shouldUseStoreCredit,
      setShouldUseStoreCredit,
      customer,
      hasOutstandingBalance,
      orderNeedsApproval,
      isPaymentGateway,
      isPayLater,
      gatewayPaymentMethod,
      formattedBalance,
      saveCard,
      savedCards,
      setSaveCard,
      billing,
      setBilling,
      isFullPaidByStoreCredit,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { priceListSlug } = useParams();
    const { priceList: priceListHook } = useOrder();
    const { order, setOrder } = useOrder(priceListSlug);
    const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
    const upfrontFulfillmentEnabled =
      useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
    const priceList = upfrontFulfillmentEnabled
      ? priceListHook
      : useRecoilValue(priceListAtomFamily(priceListSlug));

    // states
    const [loading, setLoading] = useState(true);
    const [paymentStrategies, setPaymentStrategies] = useState([]);
    const account = useRecoilValue(accountAtom);

    const { request: payrixCustomersRequest } = useApi(readPayrixGatewayCustomers);

    const setPaymentErrors = useSetRecoilState(checkoutPaymentErrorsAtom);

    const cardTitle =
      paymentStrategies.length === 1
        ? t('storefront/store/checkout--payment-method-title--single', {
            name: paymentStrategies[0]?.name,
          })
        : t('storefront/store/checkout--payment-method-title');

    const fetchOrderPaymentStrategies = async () => {
      setLoading(true);
      try {
        const resp = await readPriceListPaymentStrategies(priceList?.id, { page_size: 75 }); // temporary page_size
        if (resp?.data?.results) {
          setPaymentStrategies(resp?.data?.results);
          await selectPaymentStrategy(resp?.data?.results[0]);
        }
      } catch (e) {
        console.error('Error fetching payment strategies: ', e);
      } finally {
        setLoading(false);
      }
    };

    const showStoreCredit = () => {
      return (
        !!customer?.id &&
        (customer?.store_credit_balance > 0 || order?.payment?.store_credit_amount > 0)
      );
    };

    // effects
    useEffect(() => {
      fetchOrderPaymentStrategies();
    }, []);

    return (
      <Card
        classes="flex my-4 rounded-none sm:rounded-xl"
        id="storefront/store/checkout--payment-method">
        {(loading && paymentStrategies.length === 0) ||
        (shouldUseStoreCredit &&
          order?.payment?.balance &&
          customer?.store_credit_balance >= order?.total) ? (
          <SpinnerSmall classes="my-6 self-center" />
        ) : (
          <>
            {showStoreCredit() && (
              <StoreCredit
                shouldUseStoreCredit={shouldUseStoreCredit}
                setShouldUseStoreCredit={setShouldUseStoreCredit}
              />
            )}
            {order?.needs_payment_strategies && (
              <div className="space-y-4">
                <h2>{cardTitle}</h2>
                {paymentStrategies.length > 1 ? (
                  <div className="payment-options">
                    {paymentStrategies.map((ps) => (
                      <div key={ps?.id} className="payment-option">
                        <InputCheckboxRadio
                          type="radio"
                          id={ps?.id}
                          name={ps.name}
                          label={ps.name}
                          loading={loading}
                          checked={ps.id === selectedPaymentStrategy?.id}
                          onChange={() => selectPaymentStrategy(ps)}></InputCheckboxRadio>
                      </div>
                    ))}
                  </div>
                ) : (
                  paymentStrategies.length === 0 && (
                    <span>{t('storefront/store/checkout--payment-method-none')}</span>
                  )
                )}
                {selectedPaymentStrategy?.type !== CUSTOM && (
                  // TODO: check if pay later enabled
                  <>
                    {selectedPaymentStrategy?.pay_later && <PayNowPayLater />}
                    {!order?.payment?.pay_later && (
                      <>
                        <CheckoutSavedPayments
                          selectedPaymentStrategy={selectedPaymentStrategy}
                          selectedSavedCardId={selectedSavedCardId}
                          setSelectedSavedCardId={setSelectedSavedCardId}
                          showSavedCards={true}
                          setOrder={setOrder}
                          gatewayPaymentMethods={
                            selectedPaymentStrategy?.payment_strategy_gateway_payment_methods
                          }
                          savedCards={savedCards}
                        />
                        {!selectedSavedCardId && (
                          <>
                            <GatewayPaymentMethods
                              ref={ref}
                              loading={loading}
                              gatewayDetails={getGatewayDetails(
                                selectedPaymentStrategy?.gateway_short,
                                selectedPaymentStrategy?.gateway_key,
                                account?.payrix_merchant_id,
                                order?.customer?.id,
                                payrixCustomersRequest,
                                account?.country_code
                              )}
                              gatewayPaymentMethods={
                                selectedPaymentStrategy?.payment_strategy_gateway_payment_methods
                              }
                              modalStyling={false}
                              selectedPaymentMethod={order?.payment?.gateway_payment_method}
                              onPaymentMethodChange={(gatewayPaymentMethodId) => {
                                setOrder({
                                  payment: { gateway_payment_method: gatewayPaymentMethodId },
                                });
                              }}
                              setPaymentErrors={setPaymentErrors}
                            />
                            <CheckoutNeedsApprovalMessage
                              hasOutstandingBalance={hasOutstandingBalance}
                              orderNeedsApproval={orderNeedsApproval}
                              isPaymentGateway={isPaymentGateway}
                              isPayLater={isPayLater}
                              gatewayPaymentMethod={gatewayPaymentMethod}
                            />
                            <CheckoutAchAuthorization
                              hasOutstandingBalance={hasOutstandingBalance}
                              orderNeedsApproval={orderNeedsApproval}
                              isPaymentGateway={isPaymentGateway}
                              isPayLater={isPayLater}
                              gatewayPaymentMethod={gatewayPaymentMethod}
                              formattedBalance={formattedBalance}
                              saveCard={saveCard}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Instructions
                  selectedPaymentStrategy={selectedPaymentStrategy}
                  singleStrategy={paymentStrategies.length === 1}
                />
                {order?.fulfillment?.fulfillment_strategy_type !== PICKUP ? (
                  <DeliveryBillingAddress billing={billing} setBilling={setBilling} />
                ) : (
                  <PickupBillingAddress billing={billing} setBilling={setBilling} />
                )}
                {!!customer?.id &&
                  !selectedSavedCardId &&
                  isPaymentGateway &&
                  !order?.payment?.pay_later && (
                    <SaveCard saveCard={saveCard} setSaveCard={setSaveCard} />
                  )}
              </div>
            )}
          </>
        )}
      </Card>
    );
  }
);

Payment.propTypes = {
  selectedPaymentStrategy: PropTypes.object,
  selectPaymentStrategy: PropTypes.func,
  selectedSavedCardId: PropTypes.object,
  setSelectedSavedCardId: PropTypes.func,
};

export default Payment;
