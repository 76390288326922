export const createMultiInputOption = (name, id) => ({
  label: name,
  value: name,
  id,
  notSubmitted: true,
});

export const generateRandomNumber = () => Math.floor(Math.random() * 100000000);

const generateRandomString = () => Math.random().toString(36).substr(2, 5);

export const generateRandomKey = () => generateRandomString() + generateRandomString();

export const convertToDaysArray = (repeatObject) => {
  const daysMapping = {
    repeat_on_sunday: 0,
    repeat_on_monday: 1,
    repeat_on_tuesday: 2,
    repeat_on_wednesday: 3,
    repeat_on_thursday: 4,
    repeat_on_friday: 5,
    repeat_on_saturday: 6,
  };

  const result = [];

  for (const key in repeatObject) {
    if (repeatObject.hasOwnProperty(key) && !repeatObject[key]) {
      result.push(daysMapping[key]);
    }
  }

  return result;
};

export const convertToDaysString = (availability, t) => {
  const daysMapping = {
    repeat_on_sunday: 'Sunday',
    repeat_on_monday: 'Monday',
    repeat_on_tuesday: 'Tuesday',
    repeat_on_wednesday: 'Wednesday',
    repeat_on_thursday: 'Thursday',
    repeat_on_friday: 'Friday',
    repeat_on_saturday: 'Saturday',
  };

  const result = [];

  for (const key in availability) {
    if (availability.hasOwnProperty(key) && availability[key] && daysMapping[key]) {
      result.push(`${t(daysMapping[key])}`);
    }
  }

  return result.join(', ');
};
