import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import { ON, VIEWPORT_SIZES } from 'helpers/constants';
import { screenSizeAtom } from 'state/appState';
import useTreatment from 'hooks/common/splitTreatment';
import { subscriptionsSelector } from 'state/storefront/accountState';
import PriceListsDropdown from '../ProductsHeader/PriceListsDropdown';

const Links = ({ previousSlug, setModal }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { storefront_configuration } = useRecoilValue(accountAtom);
  const { width } = useRecoilValue(screenSizeAtom);
  const { custom_links, enable_about_us, about_tab_title } = storefront_configuration;
  const aboutSelected = location.pathname === '/about';

  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;

  return (
    <div className="store__header-links">
      <Link className={`${!aboutSelected ? 'selected' : ''}`} to={`/${previousSlug}`}>
        {t('storefront/store/shop/header--shop')}
      </Link>
      {!!enable_about_us && (
        <Link className={`${aboutSelected ? 'selected' : 'text-Grey500'}`} to={`/about`}>
          {about_tab_title}
        </Link>
      )}
      {custom_links?.map(({ title, link }) => (
        <Link
          className="text-Grey500"
          key={`${title}-${link}`}
          to={{ pathname: link }}
          target="_blank">
          {t(title)}
        </Link>
      ))}
      {!upfrontFulfillmentEnabled && (
        <>
          <h4
            className="font-bold text-Grey600"
            onClick={() =>
              setModal({
                id: 'pickup-locations',
                title: t('Pickup Locations'),
                hasCloseButton: true,
              })
            }>
            {t('storefront/store/shop/pickup-locations')}
          </h4>
          <h4
            className="font-bold text-Grey600"
            onClick={() =>
              setModal({
                id: 'delivery-availability',
                title: t('Add Delivery Address'),
                hasCloseButton: true,
              })
            }>
            {t('storefront/store/shop/delivery-availability')}
          </h4>
        </>
      )}

      {VIEWPORT_SIZES.lg > width && <PriceListsDropdown isInHamburger />}
    </div>
  );
};

Links.propTypes = {
  previousSlug: PropTypes.string,
  setModal: PropTypes.func,
};

Links.defaultProps = {
  previousSlug: '',
  setModal: () => {},
};

export default Links;
