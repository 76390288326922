import { useTranslation } from 'react-i18next';

const InventoryRemaining = ({
  track_inventory,
  is_by_weight,
  displayRemainingInventorySetting,
  max_units_per_order,
  soldOut,
  available,
}) => {
  const { t } = useTranslation();

  if (track_inventory || is_by_weight || max_units_per_order)
    return (
      <div className="flex flex-row space-x-1">
        {track_inventory && (
          <span className="text-Grey500">
            {displayRemainingInventorySetting
              ? available
                ? t('storefront/store/shop/product--in-stock-counted', { count: available })
                : soldOut
                ? t('storefront/store/shop/product--sold-out')
                : t('storefront/store/shop/product--in-stock')
              : t('storefront/store/shop/product--in-stock')}
          </span>
        )}
        {track_inventory && max_units_per_order > 0 && (
          <div className="flex flex-row space-x-1">
            <span>&middot;</span>
            <span className="text-Grey500">
              {t('storefront/product-details/package--max-units', {
                maxUnitsPerOrder: max_units_per_order,
              })}
            </span>
          </div>
        )}
      </div>
    );
  return null;
};

export default InventoryRemaining;
