import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { readAutocomplete } from 'api/storefront/locations';
import EditableInputSelect from 'components/EditableInputSelect';

const AddressAutocomplete = (props) => {
  const { t } = useTranslation();

  const {
    handleSelect,
    inputValue,
    handleAddressChange,
    setInputValue,
    types,
    components,
    sessionToken,
    classes,
    error,
    label,
    readAutocompleteAPI,
  } = props;
  const autocomplete = async (input, addresstypes, addressComponents) => {
    try {
      const response = await readAutocompleteAPI({
        input,
        types: addresstypes,
        components: JSON.stringify(addressComponents),
        session_token: sessionToken,
      });
      return response?.data ? response?.data : [];
    } catch (err) {
      console.error(err);
    }
  };

  const [predictions, setPredictions] = useState([]);

  const handleInputChange = async (input) => {
    handleAddressChange(input);
    setInputValue(input);
    try {
      const results = await autocomplete(input, types, components);
      setPredictions(results);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <EditableInputSelect
      labelText={label}
      handleSelect={handleSelect}
      inputValue={inputValue}
      handleInputValue={handleInputChange}
      predictions={predictions}
      setPredictions={setPredictions}
      error={error}
      classes={classes}
    />
  );
};

AddressAutocomplete.propTypes = {
  label: PropTypes.string,
  types: PropTypes.string,
  components: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.string,
  handleSelect: PropTypes.func,
  inputValue: PropTypes.string,
  handleStreetAddressChange: PropTypes.func,
  setInputValue: PropTypes.func,
  sessionToken: PropTypes.string,
  error: PropTypes.string,
  readAutocompleteAPI: PropTypes.func,
};

AddressAutocomplete.defaultProps = {
  label: '',
  types: 'geocode|establishment',
  components: undefined,
  classes: '',
  handleSelect: PropTypes.func,
  inputValue: undefined,
  handleAddressChange: () => {},
  setInputValue: () => {},
  sessionToken: '',
  error: '',
  readAutocompleteAPI: readAutocomplete,
};

export default AddressAutocomplete;
