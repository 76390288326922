import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';
import InputSelect from 'components/styled/InputSelect/InputSelect';
import { readPriceLists } from 'api/storefront/price-lists';
import useAssociationState, {
  DEFAULT_CRUD,
  DEFAULT_ASSOCIATION_STATE,
} from 'hooks/common/associationState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { api } from 'api/storefront';
import { useOrder } from 'hooks/storefront';
import { subscriptionsSelector } from 'state/storefront/accountState';

const PriceListsDropdown = () => {
  const { push, go } = useHistory();
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { priceList: orderPriceList } = useOrder();
  const { associationState, setAssociationStateValue, handleRead } = useAssociationState(
    {
      ...DEFAULT_CRUD,
      read: async (params) =>
        await readPriceLists({
          ...params,
          page_size: 20,
        }),
    },
    { ...DEFAULT_ASSOCIATION_STATE, useNext: false },
    api
  );
  const { results, count, loading, search } = associationState;
  const selectedPriceList = results?.find(({ slug }) => slug === priceListSlug);

  const dropdownSplitEnabled = useTreatment('storefront--pricelist-dropdown') == ON;
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const priceList = upfrontFulfillmentEnabled
    ? orderPriceList
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  if (!dropdownSplitEnabled) return null;
  if (count <= 1 && search === '') return null;
  return (
    <div
      className="store__price-lists-dropdown"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <span>{t('storefront/store/shop/price-list--title')}</span>
      <InputSelect
        isLoading={loading}
        isAsync
        defaultOptions
        defaultValue={priceList}
        label={t('Price List')}
        loadOptions={async () => await handleRead()}
        value={selectedPriceList || null}
        handleChange={(e) => {
          push(`/${e?.slug}`);
          go(0);
        }}
        formatOptionLabel={(option) => option?.name}
        getOptionValue={(option) => option?.id}
        handleInputChange={(e) => setAssociationStateValue('search', e)}
      />
    </div>
  );
};

PriceListsDropdown.displayName = 'PriceListsDropdown';
PriceListsDropdown.propTypes = {};
PriceListsDropdown.defaultProps = {};

export default PriceListsDropdown;
