import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';

const ClosedBanner = () => {
  const account = useRecoilValue(accountAtom);
  const storefrontConfiguration = account?.storefront_configuration;

  return storefrontConfiguration?.store_open ? null : (
    <div
      className="store__closed-bar rich-text-editor-render w-full"
      dangerouslySetInnerHTML={{
        __html: storefrontConfiguration?.store_closed_description,
      }}
    />
  );
};

export default ClosedBanner;
