import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Card from 'components/styled/Card';
import InputText from 'components/styled/InputText';
import Button from 'components/styled/Button';
import { customerAtom, accountAtom } from 'state/storefront/accountState';
import AddressForm from 'components/AddressForm';
import { debounce } from 'helpers/helpers';

const PickupBillingAddress = ({ billing, setBilling }) => {
  const { t } = useTranslation();
  const customer = useRecoilValue(customerAtom);
  const account = useRecoilValue(accountAtom);

  // states
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  const debouncedSetBilling = useCallback(
    debounce(async (...args) => {
      try {
        await setBilling(...args);
      } catch (error) {}
    }, 1000),
    [billing?.billing_address]
  );

  return (
    <Card
      title={t('storefront/store/checkout--payment-billing-address-title')}
      text={t('storefront/store/checkout--payment-billing-address--desc')}
      classes="mb-4 rounded-none sm:rounded-xl border-0 p-0"
      id="storefront/store/checkout--payment-billing-address">
      {!showBillingAddress && (
        <Button
          onClick={() => {
            setShowBillingAddress(true);
            setBilling(billing?.billing_address || customer?.default_billing_address);
          }}
          classes="button button--white">
          {t('storefront/store/checkout--payment-billing-address-show')}
        </Button>
      )}
      {showBillingAddress && (
        <>
          <div className="flex gap-x-8 mb-0">
            <InputText
              id="storefront/store/checkout--payment-billing-first-name"
              labelText={t('storefront/store/checkout--payment-billing-first-name-label')}
              value={billing?.billing_address?.first_name}
              onChange={(e) =>
                setBilling({ ...billing.billing_address, first_name: e.target.value })
              }
            />
            <InputText
              id="storefront/store/checkout--payment-billing-last-name"
              labelText={t('storefront/store/checkout--payment-billing-last-name-label')}
              value={billing?.billing_address?.last_name}
              onChange={(e) =>
                setBilling({ ...billing.billing_address, last_name: e.target.value })
              }
            />
          </div>
          <div className="pt-6">
            <AddressForm
              handleChange={(address) =>
                debouncedSetBilling({
                  ...address,
                  first_name: billing?.billing_address?.first_name,
                  last_name: billing?.billing_address?.last_name,
                })
              }
              address={billing?.billing_address || {}}
              defaultCountryCode={account?.business_address?.country_code}
            />
          </div>
        </>
      )}
    </Card>
  );
};

PickupBillingAddress.propTypes = {
  billing: PropTypes.object,
  setBilling: PropTypes.func,
};

export default PickupBillingAddress;
