import { api } from '.';

export const readCustomer = () => {
  return api.get('customers/current/');
};

export const readCustomerCards = async (params) => {
  return await api.get('customers/cards/', { params });
};

export const createCustomer = (data) => {
  return api.post('customers/', data);
};

export const passwordResetRequest = (data) => {
  return api.post('customers/reset-password-request', data);
};

export const passwordReset = (data) => {
  return api.post('customers/reset-password', data);
};

export const patchCustomer = (data) => api.patch(`/customers/${data.id}/`, data);

export const addCustomerCard = (data, params) => api.post('/customers/cards/', data, { params });

export const deleteCustomerCard = (source_id, params) =>
  api.delete(`/customers/cards/${source_id}`, { params });
