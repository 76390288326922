import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <rect x="2" y="2" width="16" height="16" rx="8" fill={theme.colors.Blue200} />
    <path
      d="M10.7929 12.2929C10.4024 12.6834 10.4024 13.3166 10.7929 13.7071C11.1834 14.0976 11.8166 14.0976 12.2071 13.7071L15.2071 10.7071C15.5976 10.3166 15.5976 9.68342 15.2071 9.29289L12.2071 6.29289C11.8166 5.90237 11.1834 5.90237 10.7929 6.29289C10.4024 6.68342 10.4024 7.31658 10.7929 7.70711L12.0858 9H5C4.44772 9 4 9.44772 4 10C4 10.5523 4.44772 11 5 11H12.0858L10.7929 12.2929Z"
      fill={theme.colors.Blue700}
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
};

export default Svg;
