import PropTypes from 'prop-types';
import './InfoList.css';

const InfoListTitle = ({ title, classes }) => {
  return (
    title && (
      <div className={`info-list--title ${classes}`}>
        <h4>{title}</h4>
        <hr />
      </div>
    )
  );
};

InfoListTitle.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
};

InfoListTitle.defaultProps = {
  title: '',
  classes: '',
};

export default InfoListTitle;
