import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom, customerAtom } from 'state/storefront/accountState';
import { useOrder } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import Button from 'components/styled/Button';
import PolkaDotBanner from 'components/styled/PolkaDotBanner';
import SidePopout from 'components/styled/SidePopout';
import CartItem from './CartItem';
import CartTotals from './CartTotals';

const Cart = ({ onClose, isOpen }) => {
  // constants
  const { t } = useTranslation();
  const history = useHistory();
  const { priceListSlug } = useParams();

  // state
  const account = useRecoilValue(accountAtom);
  const customer = useRecoilValue(customerAtom);
  const [errors, setErrors] = useState([]);
  const { loading, order, setOrder } = useOrder(priceListSlug);
  const storefrontConfiguration = account?.storefront_configuration;

  return (
    <SidePopout classes="store__cart-popout" direction="RIGHT" isOpen={isOpen} onClose={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="store__cart-popout--inner">
        <div className="flex flex-col flex-1 overflow-y-scroll">
          <h2 className="text-xl p-4 pt-6 font-bold text-Grey600 flex items-center justify-between">
            {t('storefront/store/shop/cart--title')}
            <span className="text-Green600 cursor-pointer" onClick={onClose}>
              {t('storefront/store/shop/cart--close')}
            </span>
          </h2>
          <div className="flex-grow p-4">
            {order?.order_entries.length < 1 ? (
              <PolkaDotBanner
                classes="mt-4 px-4 text-center"
                message={<p>{t('storefront/store/shop/cart--empty')}</p>}
              />
            ) : (
              <>
                {order.order_entries.map((item) => (
                  <CartItem
                    key={`${item.product.id}-${item.package_price_list_entry.id}`}
                    {...item}
                    setOrder={setOrder}
                    loading={loading}
                  />
                ))}
                <CartTotals loading={loading} {...order} />
              </>
            )}
          </div>
          {errors.length > 0 && (
            <Alert
              id="cart-alert"
              type="error"
              title={t('storefront/store/errors--title')}
              classes="col-span-4"
              closeButton={true}
              handleClose={() => setErrors([])}
              description={
                <ul className="list-disc ml-4">
                  {errors.map((error, i) => (
                    // eslint-disable-next-line
                    <li key={error + i}>{error}</li>
                  ))}
                </ul>
              }
            />
          )}
        </div>
        <div className="w-full py-2 px-4 border-t border-Grey200 h-auto relative bottom-0 bg-white block">
          {storefrontConfiguration?.store_open ? (
            !storefrontConfiguration?.enable_guest_checkout && !customer?.id ? (
              <Button
                disabled={loading || order?.order_entries.length < 1}
                classes="w-full text-center flex items-center justify-center"
                id="storefront/store/shop/cart--login"
                onClick={() => {
                  history.push(`?login=true&redirect=${priceListSlug}/checkout`);
                  onClose();
                }}>
                {t('Login')}
              </Button>
            ) : (
              <Button
                disabled={loading || order?.order_entries.length < 1}
                classes="w-full text-center flex items-center justify-center"
                id="storefront/store/shop/cart--checkout"
                onClick={async () => {
                  if (loading) return; // wait until cart sync
                  onClose();
                  history.push(`/${priceListSlug}/checkout`);
                }}>
                {t('storefront/store/shop/cart--checkout')}
              </Button>
            )
          ) : (
            <Button
              disabled={true}
              classes="w-full text-center flex items-center justify-center"
              id="storefront/store/shop/cart--store-closed">
              {t('storefront/store/shop/cart--closed')}
            </Button>
          )}
        </div>
      </div>
    </SidePopout>
  );
};

Cart.propTypes = { onClose: PropTypes.func, isOpen: PropTypes.bool };
Cart.defaultProps = { onClose: () => { }, isOpen: false };

export default Cart;
