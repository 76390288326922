import PropTypes from 'prop-types';
import './PolkaDotBanner.css';

const PolkaDotBanner = ({ classes, message }) => (
  <div className={`polka-dot-banner polkadot-bg ${classes}`}>{message}</div>
);

PolkaDotBanner.propTypes = {
  classes: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

PolkaDotBanner.defaultProps = {
  classes: '',
  message: '',
};

export default PolkaDotBanner;
