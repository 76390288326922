import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { formatProblemJSONErrors, replaceThenMergeArrays } from 'helpers/helpers';
import { TABLE_CONSTANTS } from 'helpers/constants';
import { api } from 'api/backoffice';
const { DEFAULT_API_SIZE, SEARCH, FILTERS } = TABLE_CONSTANTS;

// If the user changes a table state value the next
// url from the server is no longer valid, so don't use it
const CANCEL_USE_NEXT_URL = [SEARCH, DEFAULT_API_SIZE, FILTERS];

/*
  ASSOCIATION STATE 
  Use when managing an array of tags, certifications, internal-tags, etc...
  Has paginated API support, along with basic filters.

  To use REST calls, on initialization pass CRUD object shaped like
  {
    create
    read
    update
    delete
  }
  where each key is associated with an API endpoint
*/

export const DEFAULT_ASSOCIATION_STATE = {
  results: [],
  count: 0,
  next: '',
  previous: '',
  errors: {},
  loading: false,
  search: '',
  page: 1,
  page_size: DEFAULT_API_SIZE,
  pages: [],
  filters: [],
  params: {},
  useNext: true,
};

export const DEFAULT_CRUD = {
  create: async () => console.error('Association Create not implemented'),
  read: async () => console.error('Association Read not implemented'),
  update: async () => console.error('Association Update not implemented'),
  delete: async () => console.error('Association Delete not implemented'),
};

const useAssociationState = (
  CRUD = DEFAULT_CRUD,
  defaultState = DEFAULT_ASSOCIATION_STATE,
  rootApi = api
) => {
  const [associationState, setAssociationState] = useState(defaultState);
  const controller = useRef(new AbortController());

  const setAssociationStateValue = (key, value) => {
    setAssociationState((prevState) => ({
      ...prevState,
      [key]: value,
      useNext: CANCEL_USE_NEXT_URL.includes(key) ? false : prevState.useNext,
    }));
  };

  const handleRead = async (readCallback = () => {}) => {
    controller.current?.abort('Refetching, cancelling previous request');

    setAssociationState((prevState) => ({ ...prevState, loading: true }));
    try {
      controller.current = new AbortController();
      const { signal } = controller?.current;
      const { next, search, useNext, page, page_size, params } = associationState;
      const resp =
        useNext && next
          ? await rootApi(next, signal)
          : await CRUD.read(
              {
                page_size,
                page,
                search,
                ...params,
              },
              signal
            );

      if (resp?.data) {
        const { data } = resp;
        const { count, results, next } = data;
        const numberOfPages = Math.ceil(count / page_size);

        const associationResults = useNext
          ? replaceThenMergeArrays(associationState.results, results, 'id')
          : results;

        setAssociationState((prevState) => ({
          ...prevState,
          page,
          count,
          results: associationResults.map((r) => ({ ...r, value: r.id, label: r.name })),
          next,
          pages: [...Array(numberOfPages + 1).keys()].slice(1),
          errors: {},
        }));
        // return for isAsync InputSelect
        return associationResults.map((r) => ({ ...r, value: r.id, label: r.name }));
      }
    } catch (err) {
      console.error(err);
      if (err.name !== 'CanceledError')
        setAssociationStateErrors(formatProblemJSONErrors(err?.response?.data));
    } finally {
      setAssociationState((prevState) => ({
        ...prevState,
        loading: false,
        useNext: defaultState?.useNext || true,
      }));
      readCallback();
    }
  };

  // { first_name: "Must include capitals"}
  // populates the error property of corresponding association key
  // #todo: update for new error handling
  const setAssociationStateErrors = (errors) => {
    const newAssociationState = { ...associationState };
    for (const key in errors) {
      newAssociationState.errors[key] = errors[key];
    }
    setAssociationState(newAssociationState);
  };

  // removes a filter by its id
  const handleRemoveFilter = (id) =>
    setAssociationState((prevState) => ({
      ...prevState,
      page: 1,
      next: '',
      filters: [...prevState.filters.filter((f) => f.id !== id)],
      search: id === SEARCH ? '' : prevState.search,
    }));

  // adds a filter, overwrites existing ids
  const handleAddFilter = (id, type, name, value) =>
    setAssociationState((prevState) => ({
      ...prevState,
      page: 1,
      next: '',
      filters: [...prevState.filters.filter((f) => f.id !== id), { id, type, name, value }],
    }));

  // called on search box submission, adds a search filter
  const handleSearch = () => {
    const { search } = associationState;
    if (search.length > 0) {
      handleAddFilter(SEARCH, SEARCH, search, search); //todo: translate
    } else {
      handleRemoveFilter(SEARCH, SEARCH);
    }
  };

  const handleDelete = async (id) => {
    setAssociationState((prevState) => ({ ...prevState, loading: true }));
    try {
      await CRUD.delete(id);
      handleRead();
    } catch (err) {
      console.error(err);
      setAssociationStateErrors(formatProblemJSONErrors(err?.response?.data));
    } finally {
      setAssociationState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleUpdate = async (id, data) => {
    setAssociationState((prevState) => ({ ...prevState, loading: true }));
    try {
      await CRUD.update(id, data);
      handleRead();
    } catch (err) {
      console.error(err);
      setAssociationStateErrors(formatProblemJSONErrors(err?.response?.data));
    } finally {
      setAssociationState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleCreate = async (data, callback) => {
    setAssociationState((prevState) => ({ ...prevState, search: '', loading: true }));
    try {
      const created = await CRUD.create(data);
      callback(created.data);
    } catch (err) {
      console.error(err);
      setAssociationStateErrors(formatProblemJSONErrors(err?.response?.data));
    } finally {
      setAssociationState((prevState) => ({ ...prevState, loading: false }));
      handleRead();
    }
  };

  const toPayload = (key, state) =>
    state.map(({ value }, priority_order) => ({
      [key]: value,
      priority_order,
    }));
  useEffect(() => handleRead(), [associationState.search, associationState.filters]);

  return {
    associationState,
    setAssociationStateValue,
    setAssociationStateErrors,
    handleRemoveFilter,
    handleAddFilter,
    handleSearch,
    handleCreate,
    handleRead,
    handleUpdate,
    handleDelete,
    toPayload,
  };
};

export default useAssociationState;

export const DEFAULT_ASSOCIATION_PROP_TYPES = {
  associationState: PropTypes.func,
  setAssociationStateValue: PropTypes.func,
  setAssociationStateErrors: PropTypes.func,
  handleRemoveFilter: PropTypes.func,
  handleAddFilter: PropTypes.func,
  handleSearch: PropTypes.func,
  handleCreate: PropTypes.func,
  handleRead: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  toPayload: PropTypes.func,
};
