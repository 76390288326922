import { uniqBy } from 'lodash';
import { FLEXIBLE } from 'pages/backoffice/Fulfillment/components';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link, Switch, Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  IMAGE_WIDTH_TYPES,
  ON,
  PRODUCT_LISTING_FORMATS,
  SUBSCRIPTION_ORDER_OPTIONS,
} from 'helpers/constants';
import { api } from 'api/storefront';
import {
  readPriceListCategories,
  readPriceListProducts,
  readPriceListTags,
  readPriceListVendors,
  readPriceListUnCategorizedCount,
} from 'api/storefront/price-list-products';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import { accountAtom } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { ClickOutside } from 'hooks/common/events';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import { ProductsContainer, ProductsHeader, Hero } from '.';
import {
  FILTER_CATEGORY,
  FILTER_ON_SALE,
  FILTER_OUT_OF_STOCK,
  FILTER_SUBSCRIPTIONS_AVAILABLE,
  FILTER_PAGE_SIZE,
  FILTER_TAGS,
  FILTER_SEARCH,
  FILTER_VENDORS,
  FILTER_UNCATEGORIZED_VALUE,
  ORDERING_CATEGORY,
  ORDERING_PRIORITY,
} from '.';
import Loading from '../Loading';
import { PICKUP, DELIVERY } from './Checkout/FulfillmentStep';
import ClosedBanner from './ClosedBanner';
import FulfillmentModals from './Modals/FulfillmentModals';
import Product from './Product';
import StorefrontSidebar from './StorefrontSidebar';

const Shop = ({ modal, setModal }) => {
  const { priceListSlug } = useParams();
  const { order } = useOrder();
  const { t } = useTranslation();

  // states
  const storefrontSubscriptionsFlagEnabled = useTreatment('storefront_subscriptions') == ON;
  const orderFulfillmentSubscriptionsAvailable = !!order?.subscriptions_available;
  const storefrontSubscriptionsEnabled =
    storefrontSubscriptionsFlagEnabled && orderFulfillmentSubscriptionsAvailable;

  const account = useRecoilValue(accountAtom);
  const priceList = useRecoilValue(priceListAtomFamily(priceListSlug));
  const [products, setProducts] = useState({
    results: [],
    count: 0,
    next: '',
    errors: {},
    loading: false,
    search: '',
    filters: {
      [FILTER_CATEGORY]: [],
      [FILTER_TAGS]: [],
      [FILTER_VENDORS]: [],
    },
    ordering: `${ORDERING_CATEGORY},${ORDERING_PRIORITY}`,
    page_size: 50,
    onSale: false,
    hideOutOfStock: false,
    subscriptionsAvailable: false,
  });
  const [pickupLocations, setPickupLocations] = useState([]);
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [hasDeliveryLocation, setHasDeliveryLocation] = useState(false);
  const [categories, setCategories] = useState({
    data: [],
    page: 0,
    more: FILTER_PAGE_SIZE,
  });
  const [tags, setTags] = useState({
    data: [],
    page: 0,
    more: FILTER_PAGE_SIZE,
  });
  const [vendors, setVendors] = useState({
    data: [],
    page: 0,
    more: FILTER_PAGE_SIZE,
  });

  const fetchUncategorizedData = async () => {
    const resp = await readPriceListUnCategorizedCount(priceList.id, {
      [FILTER_CATEGORY]: `${filters[FILTER_CATEGORY].map((f) => f.value)}`,
      [FILTER_TAGS]: `${filters[FILTER_TAGS].map((f) => f.value)}`,
      [FILTER_VENDORS]: `${filters[FILTER_VENDORS].map((f) => f.value)}`,
      [FILTER_ON_SALE]: displayDiscount ? onSale : false,
      [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
      [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
      [FILTER_SEARCH]: search,
    });
    return {
      id: FILTER_UNCATEGORIZED_VALUE,
      product_count: resp?.data?.count,
      name: t('Uncategorized'),
    };
  };

  const addUncategorizedFilter = async (next, totalCategoryCount) => {
    if (!next && priceList?.display_uncategorized_products && totalCategoryCount > 0) {
      const uncategorizedData = await fetchUncategorizedData();
      if (uncategorizedData?.product_count > 0)
        filtersSet[FILTER_CATEGORY].set((prevState) => ({
          data: [...prevState.data, uncategorizedData],
          page: prevState.page,
          more: prevState.more,
        }));
    }
  };

  const fetchFilterData = async (filter) => {
    const newPage = filtersSet[filter].page + 1;

    const resp = await filtersSet[filter].apiReadMethod(priceList.id, {
      page_size: FILTER_PAGE_SIZE,
      page: newPage,
      [FILTER_ON_SALE]: displayDiscount ? onSale : false,
      [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
      [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
    });

    filtersSet[filter].set((prevState) => ({
      data: newPage > 1 ? [...prevState.data, ...resp?.data?.results] : resp?.data?.results,
      page: newPage,
      more: Math.max(0, Math.min(FILTER_PAGE_SIZE, resp?.data?.count - newPage * FILTER_PAGE_SIZE)),
    }));

    if (filter === FILTER_CATEGORY) addUncategorizedFilter(resp?.data?.next, resp?.data?.count);

    countFilter(filter, [...filtersSet[filter].data, ...resp?.data?.results]);
  };

  const filtersSet = {
    [FILTER_CATEGORY]: {
      title: t('storefront/store/shop/filters--categories'),
      data: categories.data,
      more: categories.more,
      page: categories.page,
      set: setCategories,
      loadMore: () => fetchFilterData(FILTER_CATEGORY),
      expanded: true,
      apiReadMethod: readPriceListCategories,
    },
    [FILTER_TAGS]: {
      title: t('storefront/store/shop/filters--tags'),
      data: tags.data,
      more: tags.more,
      page: tags.page,
      set: setTags,
      loadMore: () => fetchFilterData(FILTER_TAGS),
      expanded: true,
      apiReadMethod: readPriceListTags,
    },
    [FILTER_VENDORS]: {
      title: t('storefront/store/shop/filters--vendors'),
      data: vendors.data,
      more: vendors.more,
      page: vendors.page,
      set: setVendors,
      loadMore: () => fetchFilterData(FILTER_VENDORS),
      expanded: true,
      apiReadMethod: readPriceListVendors,
    },
  };

  const fetchFilters = async () => {
    for (const filter in filtersSet) {
      const newPage = filtersSet[filter].page + 1;

      const resp = await filtersSet[filter].apiReadMethod(priceList.id, {
        page_size: FILTER_PAGE_SIZE,
        page: newPage,
        [FILTER_ON_SALE]: displayDiscount ? onSale : false,
        [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
        [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
        // [FILTER_UNCATEGORIZED]: false,
      });
      filtersSet[filter].set({
        data: [...filtersSet[filter].data, ...resp?.data?.results],
        page: newPage,
        more: Math.max(
          0,
          Math.min(FILTER_PAGE_SIZE, resp?.data?.count - newPage * FILTER_PAGE_SIZE)
        ),
      });

      if (filter === FILTER_CATEGORY) addUncategorizedFilter(resp?.data?.next, resp?.data?.count);
    }
  };

  const countFilters = async () => {
    for (const filter in filtersSet) countFilter(filter, filtersSet[filter].data);
  };

  const countFilter = async (filter, dataset) => {
    if (!dataset) return;

    const allFiltersCount = Object.values(filters).reduce((acc, val) => acc + val.length, 0);
    const showFullCount =
      filtersSet[filter] === filtersSet[FILTER_CATEGORY] &&
      filters[FILTER_CATEGORY].length > 0 &&
      filters[FILTER_CATEGORY].length === allFiltersCount &&
      search === '';

    // load all pages of filtersSet[filter].apiReadMethod to const results
    let results = [];
    let currentPage = 0;
    let loadNextPage = true;
    const uncategorizedData = await fetchUncategorizedData();

    while (loadNextPage) {
      currentPage += 1;
      const resp = await filtersSet[filter].apiReadMethod(priceList.id, {
        page_size: FILTER_PAGE_SIZE,
        page: currentPage,

        page_size: FILTER_PAGE_SIZE,
        page: currentPage,
        ...(showFullCount
          ? {}
          : {
              [FILTER_CATEGORY]: `${filters[FILTER_CATEGORY].map((f) => f.value)}`,
              [FILTER_TAGS]: `${filters[FILTER_TAGS].map((f) => f.value)}`,
              [FILTER_VENDORS]: `${filters[FILTER_VENDORS].map((f) => f.value)}`,
              [FILTER_ON_SALE]: displayDiscount ? onSale : false,
              [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
              [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
              [FILTER_SEARCH]: search,
            }),
      });
      results = [...results, ...resp?.data?.results, uncategorizedData];
      loadNextPage = resp?.data?.next;
    }

    filtersSet[filter].set((prev) => ({
      ...prev,
      data: prev.data?.map((c) => {
        const newCount = results.find((r) => r.id === c.id)?.product_count || 0;
        return { ...c, product_count: newCount };
      }),
    }));
  };

  const { isComponentVisible: filterPanelVisible, setIsComponentVisible: setFilterPanelVisible } =
    ClickOutside(false);

  const { storefront_configuration: configuration } = account;
  const banner = configuration?.store_header_image;
  const displayOutOfStockSetting = configuration?.display_out_of_stock;
  const displayDiscount = priceList?.display_discount;
  const storeHeaderImageWidth =
    configuration?.store_header_image_width || IMAGE_WIDTH_TYPES.FIXED_WIDTH;

  // methods
  const fetchProducts = async () => {
    setProducts((prevState) => ({ ...prevState, loading: true }));
    const { next, page_size, results } = products;
    try {
      const resp = next
        ? await api(next)
        : await readPriceListProducts(
            priceList?.id,
            // TODO: Convert to url params
            {
              page: 1,
              page_size,
              ordering,
              [FILTER_CATEGORY]: `${filters[FILTER_CATEGORY].map((f) => f.value)}`,
              [FILTER_TAGS]: `${filters[FILTER_TAGS].map((f) => f.value)}`,
              [FILTER_VENDORS]: `${filters[FILTER_VENDORS].map((f) => f.value)}`,
              [FILTER_ON_SALE]: displayDiscount ? onSale : false,
              [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
              [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
              [FILTER_SEARCH]: search,
              // [FILTER_UNCATEGORIZED]: priceList?.display_uncategorized_products
              //   ? filters[FILTER_CATEGORY].findIndex((f) => f.value === null) > -1
              //     ? true
              //     : undefined
              //   : undefined,
            }
          );

      // if > first page, combine new products with previous products
      let serverResults = next
        ? uniqBy([...results, ...resp.data?.results], 'id')
        : resp?.data?.results;

      if (!storefrontSubscriptionsEnabled) {
        serverResults.forEach((product) => {
          product.package_price_list_entries.forEach((packagePriceListEntry) => {
            if (
              packagePriceListEntry.packagepricelistentrysubscriptionsettings?.order_options ===
              SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION
            ) {
              packagePriceListEntry.packagepricelistentrysubscriptionsettings.order_options =
                SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME;
            }
          });
        });
        serverResults.forEach((product) => {
          product.package_price_list_entries = product.package_price_list_entries.filter(
            (packagePriceListEntry) =>
              packagePriceListEntry.packagepricelistentrysubscriptionsettings?.order_options !==
              SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
          );
        });
        serverResults = serverResults.filter(
          (product) => product.package_price_list_entries.length > 0
        );
      }

      setProducts((prevState) => ({
        ...prevState,
        results: serverResults,
        count: resp.data?.count,
        next: resp.data?.next,
      }));
    } catch (err) {
      console.error(err);
      setProducts((prevState) => ({ ...prevState, errors: { error: err?.message } }));
    } finally {
      setProducts((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const fetchPickupLocations = async () => {
    try {
      const resp = await readPriceListFulfillmentStrategies(priceList?.id, {
        fulfillment_type: PICKUP,
      });
      if (resp?.data?.results) {
        setPickupLocations(
          resp.data?.results?.filter(
            ({ availability }) =>
              availability?.type === FLEXIBLE || availability?.available_dates?.length > 0
          )
        );
      }
    } catch (err) {
      console.error(err);
      setProducts((prevState) => ({ ...prevState, errors: { error: err?.message } }));
    }
  };

  const fetchNumberOfDeliveryStrategies = async () => {
    try {
      const resp = await readPriceListFulfillmentStrategies(priceList?.id, {
        fulfillment_type: DELIVERY,
      });
      const { results } = resp?.data;
      const availableDeliveryLocations = results.filter(
        ({ availability }) =>
          availability?.type === FLEXIBLE || availability?.available_dates?.length > 0
      );
      setDeliveryLocations(availableDeliveryLocations);
      setHasDeliveryLocation(!!availableDeliveryLocations.length);
    } catch (e) {
      console.error('Error fetching delivery plans', e);
      setProducts((prevState) => ({ ...prevState, errors: { error: e?.message } }));
    }
  };

  useEffect(async () => {
    if (priceList?.id) {
      await fetchPickupLocations();
      await fetchNumberOfDeliveryStrategies();
    }
  }, [priceList]);

  const {
    results,
    search,
    ordering,
    onSale,
    hideOutOfStock,
    subscriptionsAvailable,
    errors,
    filters,
    loading,
    count,
    next,
  } = products;

  // effects
  useEffect(() => {
    if (priceList?.id) fetchFilters();
  }, [priceList?.id]);

  useEffect(async () => {
    if (priceList?.id) {
      setProducts((prevState) => ({
        ...prevState,
        next: '',
        results: [],
      }));
      await countFilters();
    }
  }, [search, ordering, filters, onSale, hideOutOfStock, subscriptionsAvailable]);

  useEffect(async () => {
    if (priceList?.id && !next && !results?.length) await fetchProducts();
  }, [priceList?.id, next]);

  return (
    <Switch>
      <Route path="/:priceListSlug/product/:productId">
        <Product availableFulfillmentOptions={[...pickupLocations, ...deliveryLocations]} />
      </Route>
      <Route path={['/:priceListSlug', '/']}>
        <>
          {priceList?.id && (
            <ProductsHeader
              setSearch={(search) => setProducts((prevState) => ({ ...prevState, search }))}
              ordering={ordering}
              setOrdering={(ordering) => setProducts((prevState) => ({ ...prevState, ordering }))}
              setFilterPanelVisible={setFilterPanelVisible}
              setModal={setModal}
              pickupLocations={pickupLocations}
              hasDeliveryLocation={hasDeliveryLocation}
            />
          )}
          <ClosedBanner />
          <StorefrontSidebar
            priceList={priceList}
            filtersSet={filtersSet}
            filters={filters}
            setFilters={(filters) => setProducts((prevState) => ({ ...prevState, filters }))}
            onSale={onSale}
            setOnSale={() =>
              setProducts((prevState) => ({
                ...prevState,
                onSale: !prevState.onSale,
              }))
            }
            hideOutOfStock={hideOutOfStock}
            setHideOutOfStock={() =>
              setProducts((prevState) => ({
                ...prevState,
                hideOutOfStock: !prevState.hideOutOfStock,
              }))
            }
            subscriptionsAvailable={subscriptionsAvailable}
            setSubscriptionsAvailable={() =>
              setProducts((prevState) => ({
                ...prevState,
                subscriptionsAvailable: !prevState.subscriptionsAvailable,
              }))
            }
            filterPanelVisible={filterPanelVisible}
            setFilterPanelVisible={setFilterPanelVisible}
            setProducts={setProducts}>
            {storeHeaderImageWidth === IMAGE_WIDTH_TYPES.FULL_WIDTH && <Hero banner={banner} />}
            {/* TODO: use instead of store__shop when GridView is active */}
            <div
              className={`${
                account?.storefront_configuration?.product_listing_format ==
                PRODUCT_LISTING_FORMATS.GRID
                  ? 'store__shop--grid-view'
                  : 'store__shop'
              }`}>
              {storeHeaderImageWidth === IMAGE_WIDTH_TYPES.FIXED_WIDTH && <Hero banner={banner} />}
              <div>
                {/* TODO: remove empty P tag check once we use new package for rich text editor */}
                {account?.storefront_configuration?.store_header ||
                (account?.storefront_configuration?.store_message &&
                  account?.storefront_configuration?.store_message !== '<p></p>') ? (
                  <Alert
                    id="shop-message"
                    type="storefront-theme"
                    classes="mt-4 mx-3"
                    hasIcon={false}
                    title={account?.storefront_configuration?.store_header}
                    description={
                      <div
                        className="rich-text-editor-render"
                        dangerouslySetInnerHTML={{
                          __html: account?.storefront_configuration?.store_message,
                        }}
                      />
                    }
                  />
                ) : null}
                {Object.keys(errors).length > 0 && (
                  <Alert
                    id="shop-alert"
                    type="error"
                    title={t('storefront/store/errors--title')}
                    classes="col-span-4 my-4 mx-3"
                    closeButton={true}
                    handleClose={() => setProducts((prevState) => ({ ...prevState, errors: {} }))}
                    description={
                      <ul className="list-disc ml-4">
                        {Object.keys(errors).map((key, i) => (
                          // eslint-disable-next-line
                          <li key={key + i}>{errors[key]}</li>
                        ))}
                      </ul>
                    }
                  />
                )}
                {priceList?.id ? (
                  <>
                    {loading && results?.length === 0 ? (
                      <div className="col-span-4 sm:col-span-3 mt-4">
                        <Loading copy={t('Loading products...')} />
                      </div>
                    ) : (
                      <ProductsContainer
                        products={results}
                        count={count}
                        setErrors={() => setProducts((prevState) => ({ ...prevState, errors: {} }))}
                        loading={loading}
                        ordering={ordering}
                        displayDiscount={displayDiscount}
                      />
                    )}
                  </>
                ) : loading ? (
                  <Loading />
                ) : (
                  <span className="col-span-4">
                    {t('Invalid price list. Please log in, or contact the supplier.')}
                    <Link className="block mt-4" to="/">
                      {t('Go to storefront.')}
                    </Link>
                  </span>
                )}
              </div>
              <FulfillmentModals
                modal={modal}
                pickupLocations={pickupLocations}
                setModal={setModal}
                loading={products.loading}
              />
            </div>
          </StorefrontSidebar>
        </>
      </Route>
    </Switch>
  );
};

export default Shop;
