import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './Checkout.css';
import CheckoutHeaderBack from './components/CheckoutHeaderBack';
import CheckoutFooter from './components/CheckoutFooter';
import CheckoutRouteHandler from './components/CheckoutRouteHandler';
import CheckoutSummary from './components/CheckoutSummary';
import CheckoutContainer from './CheckoutContainer';
import useCheckoutOrder from './hooks/useCheckoutOrder';
import { useEffect } from 'react';

const Checkout = ({ }) => {
    const { t } = useTranslation();
    const { priceListSlug, orderId } = useParams();

    const { loading, openOrder, setOpenOrder, order, setOrder, isCheckedOut, totalUnitQuantity, priceList, fetchOpenOrder } = useCheckoutOrder(priceListSlug, orderId);

    useEffect(() => {
        if (!!isCheckedOut) fetchOpenOrder();
        else setOpenOrder(null)
    }, [isCheckedOut]);

    return (
        <>
            <CheckoutHeaderBack />
            {priceList?.id && order?.id ? (
                <div className="checkout__container" id="checkout__container">
                    <CheckoutRouteHandler openOrder={openOrder} />
                    <CheckoutSummary
                        loading={loading}
                        openOrder={openOrder}
                        order={order}
                        setOrder={setOrder}
                        isCheckedOut={isCheckedOut}
                        totalUnitQuantity={totalUnitQuantity}
                    />
                    {/* <CheckoutFooter classes="hidden sm:flex" /> */}
                </div>
            ) : (
                <span>loading</span>
            )}
        </>
    );
};

export default Checkout;
