import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button';
import { ShoppingCart, SpinnerSmall } from 'assets/svgIcons';
import { fulfillmentWizardActiveAtom } from 'state/storefront/storeState';
import { useSetRecoilState } from 'recoil';

const Cart = ({ cartActive, onCartClick }) => {
  const { priceListSlug } = useParams();
  const { loading, totalUnitQuantity, isFulfillmentComplete } = useOrder(priceListSlug);
  const setWizardActive = useSetRecoilState(fulfillmentWizardActiveAtom);

  return (
    <Button
      id="store__header_actions--cart"
      disabled={false}
      classes="button button--primary flex"
      onClick={() => {
        if (isFulfillmentComplete) setWizardActive(false);
        else setWizardActive(true);
        onCartClick(!cartActive);
      }}>
      <ShoppingCart classes="mr-2 self-center" stroke="white" width={18} height={18} />
      {loading ? (
        <SpinnerSmall classes="ml-2 self-center" stroke="white" width={18} height={18} />
      ) : (
        <span className="text-white">{totalUnitQuantity}</span>
      )}
    </Button>
  );
};

Cart.propTypes = { cartActive: PropTypes.bool, onCartClick: PropTypes.func };

export default Cart;
