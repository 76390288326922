import { PlusSmall, MinusSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import Button from 'components/styled/Button';
import InputNumber from '../InputNumber';
import './InputPlusMinus.css';

const InputPlusMinus = ({
  value,
  handleChange,
  onBlur,
  name,
  id,
  classes,
  inputClasses,
  inputNumberClasses,
  placeholder,
  min,
  max,
  step,
  error,
  disabled,
  loading,
  disableDecimals,
}) => (
  <div className={`input-pm ${classes}`}>
    <Button
      classes="button button--pagination"
      onClick={() => handleChange(Number(value) - step < min ? min : Number(value) - step)}
      tabIndex={0}
      disabled={value <= min || disabled}>
      <MinusSmall />
    </Button>
    <InputNumber
      min={min}
      max={max}
      step={step}
      value={value}
      placeholder={placeholder}
      id={id}
      name={name}
      disabled={disabled}
      classes={`mx-0.5 w-auto ${inputNumberClasses}`}
      inputClasses={`text-sm text-center py-1 w-16 h-8.5 ${inputClasses}`}
      loading={loading}
      onChange={handleChange}
      onBlur={onBlur}
      hasArrowButtons={false}
      error={error}
      disableDecimals={disableDecimals}
    />
    <Button
      classes="button button--pagination"
      onClick={() => handleChange(Number(value) + step)}
      disabled={(max && value >= max) || disabled}
      tabIndex={0}>
      <PlusSmall />
    </Button>
  </div>
);

InputPlusMinus.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.string,
  inputClasses: PropTypes.string,
  inputNumberClasses: PropTypes.string,
  placeholder: PropTypes.string,
  step: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  help: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  disableDecimals: PropTypes.bool,
};

InputPlusMinus.defaultProps = {
  value: '',
  handleChange: () => {},
  onBlur: () => {},
  classes: '',
  inputClasses: '',
  inputNumberClasses: '',
  placeholder: '',
  step: 1,
  label: '',
  help: '',
  disabled: false,
  error: '',
  loading: false,
  min: 0,
  max: '',
  disableDecimals: true,
};

export default InputPlusMinus;
