import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <g clipPath="url(#clip0_1800_20302)">
      <path
        d="M9.04896 3.42664C9.34833 2.50537 10.6517 2.50538 10.951 3.42664L12.0208 6.71864C12.1547 7.13063 12.5386 7.40957 12.9718 7.40958L16.4333 7.40971C17.402 7.40975 17.8047 8.6493 17.0211 9.21871L14.2208 11.2534C13.8703 11.508 13.7237 11.9594 13.8575 12.3714L14.927 15.6635C15.2263 16.5847 14.1719 17.3508 13.3882 16.7815L10.5878 14.747C10.2373 14.4924 9.76272 14.4924 9.41225 14.747L6.61179 16.7815C5.82809 17.3508 4.77367 16.5847 5.07297 15.6635L6.14249 12.3714C6.27634 11.9594 6.1297 11.508 5.77924 11.2534L2.97894 9.21871C2.19528 8.6493 2.59804 7.40975 3.56672 7.40971L7.02818 7.40958C7.46137 7.40957 7.8453 7.13063 7.97918 6.71864L9.04896 3.42664Z"
        stroke={stroke}
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_1800_20302">
        <rect width="20" height="20" fill={fill} transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  fill: theme.colors.White,
  classes: '',
};

export default Svg;
