import PropTypes from 'prop-types';

const Svg = ({ classes }) => (
  <svg
    width="76"
    height="40"
    viewBox="0 0 76 40"
    fill="none"
    className={classes}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M61.5194 5L60.0185 6.77379" stroke="#6D5C3E" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M74.5194 26.3106C73.5219 22.919 67.2293 20.458 67.5092 13.6652C67.8305 5.86845 66.3847 1.84953 61.1638 1.04574C55.9429 0.24196 46.5441 10.1285 49.5963 22.989C50.7919 28.027 54.7386 30.5363 59.1545 31.4641C64.6503 32.6188 70.8729 31.3238 73.4237 29.4048"
      stroke="#A7967A"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M55.7786 6.48103L52.2747 2.77393"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M59.3699 33.6104L59.9839 38.3177M59.9839 38.3177L55.4812 37.1579M59.9839 38.3177L54.3896 38.9999"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.8198 17.2368C65.2544 19.4396 66.2865 23.8451 63.5975 24.7316C60.9085 25.6181 58.8714 21.911 57.3232 20.4604"
      stroke="#A7967A"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M13.2677 32.9409V36.0078" stroke="#6D5C3E" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M20.6732 32.1182V34.5119M18.8779 34.5867L25.4606 35.2599"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.0984 36.0078L18.2795 36.681"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1.29921 25.5354C10.2201 31.9402 26.4915 33.0419 29.7992 20.2244C30.9546 15.7472 31.3701 0.999998 22.9921 1C14.6142 1 15.154 10.1293 12.5197 13.6417C9.15354 18.1299 3.09449 15.5866 1 19.1772M13.3425 18.0551C12.0459 19.8255 9.99134 23.6654 12.1457 24.8622C14.3 26.0591 18.5787 21.8202 20.4488 19.5512"
      stroke="#A7967A"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M26.9567 9.52772L32.2677 8.85449"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M23.1417 5.93701L23.3661 8.18111"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M14.0906 36.3069L17.7559 38.9998M21.8701 34.9604L24.4882 37.0549"
      stroke="#6D5C3E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
};

export default Svg;
