import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, dimensions }) => (
  <svg
    width={dimensions}
    height={dimensions}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      d="M12 6V12M12 12V18M12 12H18M12 12L6 12"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey500,
  classes: '',
  dimensions: '24',
};

export default Svg;
