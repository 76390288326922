import PropTypes from 'prop-types';
import './Modal.css';
import X from 'assets/svgIcons/X';
import { KEY_CODES } from 'helpers/constants';
import Card from '../Card';
import Button from '../Button';
import { useEffect } from 'react';

const Modal = ({
  containerClasses,
  classes,
  onClose,
  title,
  description,
  children,
  hasCloseButton,
  childMarginBottom,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);
  return (
    <div
      className={`modal--container ${containerClasses}`}
      onClick={(e) => onClose(e)}
      role="button"
      aria-hidden
      onKeyDown={(e) => e.keyCode === KEY_CODES.ESCAPE && onClose(e)}>
      <Card
        classes={`modal ${classes}`}
        onClick={(e) => e.stopPropagation()}
        childMarginBottom={childMarginBottom}>
        {hasCloseButton && (
          <Button
            classes="button modal--close-button"
            onClick={(e) => onClose(e)}
            onKeyDown={(e) => e.keyCode === KEY_CODES.ESCAPE && onClose(e)}>
            <X />
          </Button>
        )}
        {title && <h3 className="modal--title mb-1 p-6 pb-0">{title}</h3>}
        {description && <p className="modal--description">{description}</p>}
        {children}
      </Card>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.string,
  containerClasses: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  childMarginBottom: PropTypes.bool,
};

Modal.defaultProps = {
  children: undefined,
  classes: '',
  containerClasses: '',
  title: '',
  description: '',
  onClose: () => {},
  hasCloseButton: false,
  childMarginBottom: false,
};

export default Modal;
