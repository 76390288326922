import ReadMoreDescription from "components/styled/ReadMoreDescription";
import { BLANK_TEXT_EDITOR } from "helpers/constants";
import { useTranslation } from "react-i18next";

const FulfillmentSummaryInstructions = ({ fulfillmentType, instructions }) => {

    const { t } = useTranslation();
    return (
        instructions &&
            instructions !== BLANK_TEXT_EDITOR ? (
            <ReadMoreDescription
                titleClasses="text-Grey500"
                title={t(`${fulfillmentType} Instructions`)}
                desc={
                    <div className="quick-checkout-fulfillment-instructions"
                        dangerouslySetInnerHTML={{
                            __html: instructions,
                        }}
                    />
                }
            />
        ) : null
    );
};

export default FulfillmentSummaryInstructions;
