import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './PayrixInputCreditCard.css';
import theme from 'styles/theme';
import { usePayrix } from 'hooks/common/payrix';
import InputText from 'components/styled/InputText';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
const { Grey300 } = theme.colors;

const PayrixInputCreditCard = forwardRef(
  ({ payrixPublicKey, merchantID, customerId, payrixCustomersApi, setError, countryCode }, ref) => {
    const { t } = useTranslation();
    const payFields = usePayrix(payrixPublicKey, merchantID, countryCode);

    const cardHolderRef = useRef(null);
    const cardNumberRef = useRef(null);
    const cardCvcRef = useRef(null);
    const cardExpiryRef = useRef(null);
    const cardZipRef = useRef(null);

    const [zipCode, setZipCode] = useState();
    const [zipCodeError, setZipCodeError] = useState();

    const tokenize = async () => {
      try {
        if (!zipCode) {
          setZipCodeError('Zip code is required');
          return;
        }
        payFields.config.billingAddress = {
          zip: zipCode,
        };
        return await new Promise((resolve, reject) => {
          payFields.onFinish = (response) => {
            if (response?.errors?.length > 0) {
              reject(new Error(`${response?.errors[0]?.field}: ${response?.errors[0]?.msg}`)); // Reject the Promise with a validation error
              return;
            }
            const responseData = {
              id: response?.data[0]?.id,
              token: response?.data[0]?.token,
              card: {
                last4: response?.data[0]?.payment?.number,
              },
            };

            resolve(responseData); // Resolve the Promise with the response
          };

          payFields.onValidationFailure = () => {
            reject(new Error('Validation failure')); // Reject the Promise with a validation error
          };

          payFields.submit();
        });
      } catch (e) {
        console.error('error', e);
        throw e; // Rethrow the error to be caught by the caller
      }
    };

    useImperativeHandle(ref, () => ({
      tokenize, // Directly expose the tokenize function
    }));

    useEffect(async () => {
      if (payFields) {
        const fetchPayrixCustomer = async () => {
          const resp = await payrixCustomersApi({ customer: customerId });
          const payrixCustomerId = resp?.[0]?.payrix_customer_id;
          if (payrixCustomerId) payFields.config.customer = payrixCustomerId;
        };

        payFields.reload();
        payFields.jQuery = jQuery.noConflict();
        payFields.config.mode = 'token';
        // fetchPayrixCustomer();
        payFields.fields = [
          {
            type: 'number',
            element: `#${cardNumberRef?.current?.id}`,
          },
          {
            type: 'cvv',
            element: `#${cardCvcRef?.current?.id}`,
          },
          {
            type: 'expiration',
            element: `#${cardExpiryRef?.current?.id}`,
          },
        ];
        payFields.customizations.optionalFields = [
          '#address1',
          '#city',
          '#state',
          '#email',
          '#phone',
        ];

        payFields.customizations.placeholders = {
          '#expiration': 'MM/YY',
          '#payment_cvv': 'CVV',
          '#payment_number': '0000 0000 0000 0000',
        };
        payFields.customizations.style = {
          '.input': {
            boxSizing: 'border-box',
            lineHeight: '1.5em',
            fontSize: '14px',
            fontWeight: '200',
            border: `${Grey300} 1px solid`,
            borderRadius: '4px',
            color: '#111827',
            width: '100%',
            height: '42px',
            padding: '0 10px',
          },
          '.form-error': {
            color: '#B91C1C',
          },
        };

        payFields.appendIframe();
      }
    }, [payFields]);

    return (
      <div>
        {payFields ? (
          <>
            <form className="payrix-input-cc grid gap-2 grid-cols-1">
              <div
                ref={cardNumberRef}
                id="card-number"
                className="input-credit-card--form-row col-span-3"></div>
              <div className="flex flex-row col-span-3">
                <div
                  ref={cardExpiryRef}
                  id="card-expiry"
                  className="input-credit-card--form-row"></div>
                <div
                  ref={cardCvcRef}
                  id="card-cvc"
                  className="input-credit-card--form-row pl-2"></div>
                <div className="card-zip-input input-credit-card--form-row pl-2 flex-grow">
                  <InputText
                    inputClasses="box-border leading-6 text-sm font-light border-gray-300 border rounded color-text-primary w-full h-[42px] px-4 text-Grey900"
                    id="card-zip"
                    placeholder="Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    error={zipCodeError}
                  />
                </div>
              </div>
            </form>
          </>
        ) : (
          <p>{t('Loading')}</p> // Display a loading message while PayFields is being loaded
        )}
      </div>
    );
  }
);

export default PayrixInputCreditCard;
