import { useHistory, useLocation } from 'react-router-dom';

// todo: in react-router-dom v6 there is a hook for just interacting with search params
// https://reactrouter.com/en/main/hooks/use-search-params
const useSearchParamsState = () => {
  const { push, replace } = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const _isValueEmpty = (value) => {
    switch (value) {
      case '':
      case null:
      case undefined:
        return true;
      default:
        return false;
    }
  };

  const _pushToHistory = () => push({ search: searchParams.toString() });
  const _replaceHistory = () => replace({ search: searchParams.toString() });

  // Unique key value pairs; replace value or delete
  const setSearchParamsState = (newState, pushToHistory = true) => {
    Object.entries(newState).forEach(([key, value]) => {
      if (_isValueEmpty(value)) searchParams.delete(key);
      else setFilter(key, value);
    });
    pushToHistory ? _pushToHistory() : _replaceHistory();
  };

  const removeFilters = (filters = {}, pushToHistory = true) => {
    Object.entries(filters)?.forEach(([key, value]) => {
      if (checkFilterExists(key, value)) {
        _deleteFilter(key, value);
      }
    });
    pushToHistory ? _pushToHistory() : _replaceHistory();
  };

  const _deleteFilter = (key, value) => {
    _isValueEmpty(value) ? searchParams.delete(key) : searchParams.delete(key, value);
  };

  const checkFilterExists = (key, value) =>
    _isValueEmpty(value) ? searchParams.has(key) : searchParams.has(key, value);

  // not unique key value pairs; can have multiple of each param
  const appendFilter = (key, value) => {
    searchParams.append(key, value);
    _pushToHistory();
  };

  // Remove single filter by key
  const removeFilter = (key = '', pushToHistory = true) => {
    if (checkFilterExists(key)) {
      _deleteFilter(key);
    }
    pushToHistory ? _pushToHistory() : _replaceHistory();
  };

  // Removes specific value from a filter
  // NOTE: Does not work for true/false values, use removeFilter instead
  // true does not equal "true" therefore the filter fails
  const removeFilterValue = (key, value, pushToHistory = true) => {
    const values = searchParams.getAll(key).filter((v) => v != value);
    searchParams.delete(key);
    values.forEach((v) => searchParams.append(key, v));
    pushToHistory ? _pushToHistory() : _replaceHistory();
  };

  const setFilter = (key, value) => {
    searchParams.set(key, value);
  };

  // key value for unique key
  const getFilterValue = (key) => searchParams.get(key);

  // key value for key with multiple values/instances
  const getAllFilterValues = (key) => searchParams.getAll(key);

  return {
    setSearchParamsState,
    removeFilters,
    appendFilter,
    getFilterValue,
    checkFilterExists,
    setFilter,
    getAllFilterValues,
    removeFilter,
    removeFilterValue,
  };
};

export default useSearchParamsState;
