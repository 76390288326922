import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from 'components/styled/Card';
import Alert from 'components/styled/Alert';
import CheckoutFooter from '../Checkout/CheckoutFooter';

const Complete = ({ order, text, children, alertTranslationKey }) => {
  const { t } = useTranslation();

  return (
    <div className="col-span-3 sm:col-span-2" id="storefront/store/checkout--complete">
      <Card className="mb-4">
        <h2>
          {t('storefront/store/checkout--complete-title', {
            name: order?.customer?.first_name,
          })}
        </h2>
        <Alert
          id="complete-order-alert"
          title={t(alertTranslationKey, {
            orderNumber: order?.id,
          })}
        />
        <h2>{t('storefront/store/checkout--complete-subtitle')}</h2>
        <p>{text}</p>
        {children}
      </Card>
      <CheckoutFooter />
    </div>
  );
};

Complete.propTypes = {
  order: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Complete.defaultProps = {
  order: {},
  children: undefined,
  text: undefined,
};

export default Complete;
