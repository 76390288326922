import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75125 1.03666C6.60751 0.873862 5.71679 2.01081 6.1486 3.08235L6.34193 3.56209C2.80568 5.08477 1.02138 9.08718 2.44813 12.6277C3.03648 14.0876 4.08032 15.2317 5.35454 15.9713C5.8322 16.2485 6.44416 16.0861 6.7214 15.6084C6.99863 15.1308 6.83616 14.5188 6.3585 14.2416C5.45032 13.7144 4.71701 12.907 4.30317 11.8801C3.31363 9.42458 4.51735 6.55077 7.08975 5.41781L7.28127 5.89305C7.71308 6.96459 9.14324 7.16627 9.85453 6.25592L11.7175 3.8716C12.4277 2.96262 11.8889 1.62559 10.7469 1.46304L7.75125 1.03666ZM14.6781 4.03963C14.2004 3.76245 13.5885 3.925 13.3113 4.4027C13.0341 4.88039 13.1967 5.49233 13.6744 5.76951C14.5828 6.29662 15.3163 7.10413 15.7302 8.13127C16.7198 10.5868 15.5161 13.4606 12.9437 14.5936L12.7522 14.1183C12.3204 13.0468 10.8902 12.8451 10.1789 13.7555L8.31595 16.1398C7.60572 17.0488 8.14452 18.3858 9.28656 18.5483L12.2822 18.9747C13.4259 19.1375 14.3167 18.0006 13.8848 16.929L13.6915 16.4493C17.2277 14.9266 19.012 10.9242 17.5853 7.38372C16.9968 5.92346 15.9527 4.77918 14.6781 4.03963Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
};

export default Svg;
