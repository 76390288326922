import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    className={classes}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="14" fill="white" />
    <path
      d="M14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289L21.7071 13.2929C22.0976 13.6834 22.0976 14.3166 21.7071 14.7071L15.7071 20.7071C15.3166 21.0976 14.6834 21.0976 14.2929 20.7071C13.9024 20.3166 13.9024 19.6834 14.2929 19.2929L18.5858 15L7 15C6.44772 15 6 14.5523 6 14C6 13.4477 6.44772 13 7 13H18.5858L14.2929 8.70711C13.9024 8.31658 13.9024 7.68342 14.2929 7.29289Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey600,
  classes: '',
};

export default Svg;
