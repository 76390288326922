import React from 'react';
import PropTypes from 'prop-types';

const VendorContactInfo = ({ title, text, link }) => {
  return (
    <div className="flex flex-col break-all my-2 sm:pr-4 w-full sm:w-1/2">
      <span className="text-Grey500">{title}</span>

      {link ? (
        <a className="text-sm text-Green600" href={link} target="_blank" rel="noreferrer">
          {text}
        </a>
      ) : (
        <div className="text-sm text-Green600"> {text} </div>
      )}
    </div>
  );
};

VendorContactInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

VendorContactInfo.defaultProps = {
  title: '',
  text: '',
  link: '',
};

export default VendorContactInfo;
