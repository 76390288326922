import { datadogRum } from '@datadog/browser-rum';
import { isEqual } from 'lodash';
import {
  STRIPE_GATEWAY,
  SQUARE_GATEWAY,
  LOCAL_PAY_GATEWAY,
  PAYRIX_GATEWAY,
} from 'pages/storefront/Store/components/PaymentComponents/index';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { formatMoney } from 'helpers/localeHelpers';
import { readOrder } from 'api/storefront/orders';
import { currencyAtom } from 'state/common/currency';
import { accountAtom, customerAtom } from 'state/storefront/accountState';
import useApi from 'hooks/common/api';
import { useOrder } from 'hooks/storefront/order';
import {
  billingAtom,
  checkoutErrorsAtom,
  customerNoteAtom,
  openOrderAtom,
  saveCardAtom,
  shouldUseStoreCreditAtom,
  submittingOrderAtom,
} from '../CheckoutState';
import { DELIVERY } from '../index';
import useCheckoutCustomer from './useCheckoutCustomer';
import useCheckoutFulfillment from './useCheckoutFulfillment';
import useCheckoutPaymentStrategy from './useCheckoutPaymentStrategy';

const useCheckoutOrder = (priceListSlug, orderId) => {
  const { request: requestOrder } = useApi(readOrder);

  const [customer, setCustomer] = useRecoilState(customerAtom);

  const [openOrder, setOpenOrder] = useRecoilState(openOrderAtom);
  const [billing, setBillingValue] = useRecoilState(billingAtom);
  const [submittingOrder, setSubmittingOrder] = useRecoilState(submittingOrderAtom);
  const [saveCard, setSaveCard] = useRecoilState(saveCardAtom);
  const [shouldUseStoreCredit, setShouldUseStoreCredit] = useRecoilState(shouldUseStoreCreditAtom);
  const [customerNote, setCustomerNote] = useRecoilState(customerNoteAtom);

  const account = useRecoilValue(accountAtom);
  const enablePhoneOnCheckout = account?.storefront_configuration?.enable_phone_on_checkout;

  const {
    loading,
    order,
    setOrder,
    totalUnitQuantity,
    priceList,
    orderEntriesErrors,
    quickOpenOrder: placeOrder,
    clearCart,
    errors,
    setErrors,
    isFulfillmentComplete,
  } = useOrder(priceListSlug);

  const {
    customerFields,
    setCustomerFields,
    savedCards,
    setSavedCards,
    selectedSavedCardId,
    setSelectedSavedCardId,
    savedCardGatewayPaymentMethod,
    setSavedCardGatewayPaymentMethod,
    showStoreCredit,
    fetchCustomerCards,
    fetchCustomer,
    updateCustomerFields,
    compareAndUpdateCustomer,
    validateCustomerFields,
  } = useCheckoutCustomer(order, customer, setCustomer);

  const {
    selectedPaymentStrategy,
    setSelectedPaymentStrategy,
    selectPaymentStrategy,
    isStoreCreditPayment,
  } = useCheckoutPaymentStrategy(
    priceList,
    order,
    setOrder,
    customer,
    setSaveCard,
    setSelectedSavedCardId,
    fetchCustomerCards
  );

  const {
    fulfillmentSubscriptionOptions,
    setFulfillmentSubscriptionOptions,
    fetchFulfillmentSubscriptionOptions,
  } = useCheckoutFulfillment(order, priceList, setOrder);

  const isCheckedOut = location.pathname?.includes('/complete');
  const isBillingOutOfSync = !isEqual(order?.billing_address, billing?.billing_address);
  const paymentStepComplete = order?.order_entries?.length > 0;
  const isPaymentGateway =
    selectedPaymentStrategy?.type === STRIPE_GATEWAY ||
    selectedPaymentStrategy?.type === SQUARE_GATEWAY ||
    selectedPaymentStrategy?.type === LOCAL_PAY_GATEWAY ||
    selectedPaymentStrategy?.type === PAYRIX_GATEWAY;
  const orderNeedsApproval = order?.order_entries?.some((entry) => entry.needs_approval);
  const hasOutstandingBalance =
    !order?.payment || (!!order?.payment && order?.payment?.balance > 0);
  const currency = useRecoilValue(currencyAtom);
  const formattedBalance = formatMoney(order?.payment?.balance, currency);
  const formattedPaymentBalance = formatMoney(order?.payment?.balance, currency);
  const isFullPaidByStoreCredit =
    order?.payment?.store_credit_amount > 0 && order?.payment?.store_credit_amount >= order?.total;

  const fetchOpenOrder = async () => {
    if (!!orderId) {
      const _order = await requestOrder(orderId);
      setOpenOrder(_order);
    } else {
      setOpenOrder(order);
    }
  };

  const setBilling = async (value) => {
    setBillingValue((prevState) => ({ ...prevState, billing_address: value }));
  };

  const _updateBillingIfCustomerUpdated = () => {
    if (billing?.billing_address) {
      setBilling({
        ...billing?.billing_address,
        first_name: customerFields?.first_name,
        last_name: customerFields?.last_name,
      });
    }
  };

  const _getShippingNameUpdate = (first_name, last_name) =>
    order?.fulfillment?.type_display === DELIVERY
      ? {
          shipping_address: {
            first_name,
            last_name,
          },
        }
      : undefined;

  const updateCustomerInOrder = async () => {
    const { email, first_name, last_name, phone } = customerFields;

    const isPhoneRequired = enablePhoneOnCheckout ? phone : true;

    if (email && first_name && last_name && isPhoneRequired) {
      _updateBillingIfCustomerUpdated();

      await setOrder({
        fulfillment: _getShippingNameUpdate(first_name, last_name),
        customer_info: {
          ...customerFields,
        },
      });
    }
  };

  const validateCheckoutOrder = () => {
    const orderErrors = {};

    const customerFormErrors = validateCustomerFields();

    const combinedErrors = {
      ...orderErrors,
      ...customerFormErrors,
    };

    if (Object.keys(combinedErrors).length > 0) {
      setErrors(combinedErrors);
      return false;
    }
    return true;
  };

  const onPay = async (options) => {
    if (customer?.id) await compareAndUpdateCustomer();
    const patchOrderData = {
      ...(customerNote && { customer_note: customerNote }),
      ...(isBillingOutOfSync ? billing : {}),
    };
    await setOrder(patchOrderData);
    await placeOrder(options);
    datadogRum.addAction('order-completed', { order_id: order?.id, order_total: order?.total });
    datadogRum.stopSession();
    setFulfillmentSubscriptionOptions([]);
    clearCart();
  };

  return {
    fetchOpenOrder,
    openOrder,
    setOpenOrder,
    order,
    setOrder,
    loading,
    totalUnitQuantity,
    priceList,
    orderEntriesErrors,
    isCheckedOut,
    setBilling,
    billing,
    isBillingOutOfSync,
    submittingOrder,
    setSubmittingOrder,
    paymentStepComplete,
    isPaymentGateway,
    orderNeedsApproval,
    hasOutstandingBalance,
    currency,
    formattedBalance,
    formattedPaymentBalance,
    shouldUseStoreCredit,
    setShouldUseStoreCredit,
    isFullPaidByStoreCredit,
    customerFields,
    setCustomerFields,
    savedCards,
    setSavedCards,
    selectedSavedCardId,
    setSelectedSavedCardId,
    savedCardGatewayPaymentMethod,
    setSavedCardGatewayPaymentMethod,
    showStoreCredit,
    fetchCustomerCards,
    selectedPaymentStrategy,
    setSelectedPaymentStrategy,
    selectPaymentStrategy,
    isStoreCreditPayment,
    customer,
    saveCard,
    setSaveCard,
    fetchCustomer,
    placeOrder,
    clearCart,
    errors,
    setErrors,
    updateCustomerFields,
    compareAndUpdateCustomer,
    updateCustomerInOrder,
    enablePhoneOnCheckout,
    customerNote,
    setCustomerNote,
    fulfillmentSubscriptionOptions,
    setFulfillmentSubscriptionOptions,
    fetchFulfillmentSubscriptionOptions,
    onPay,
    validateCheckoutOrder,
    isFulfillmentComplete,
  };
};

export default useCheckoutOrder;
