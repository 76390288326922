import CartLineItems from 'pages/storefront/Cart/components/ActiveOrder/components/Order/CartLineItems';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ON } from 'helpers/constants';
import { subscriptionsSelector } from 'state/storefront/accountState';
import useTreatment from 'hooks/common/splitTreatment';
import { CartItem, CartTotals } from '..';

const Summary = ({ loading, order, setOrder, readOnly, totalUnitQuantity }) => {
  const { t } = useTranslation();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;

  // methods
  const updateCartItemQuantity = async (id, storefront_unit_quantity) => {
    setOrder({ order_entries: [{ id, storefront_unit_quantity }] });
  };

  return (
    <section className="p-4 sm:p-0">
      {!!upfrontFulfillmentEnabled ? (
        <CartLineItems order={order} readOnly={readOnly} />
      ) : (
        <>
          <CartTotals loading={loading} {...order} />
          <hr className="mt-2 mb-3" />
          {order?.order_entries?.map((item) => {
            return (
              <CartItem
                key={`${item.id}-${item.package_price_list_entry?.id}`}
                loading={loading}
                updateCartItemQuantity={(value) => updateCartItemQuantity(item.id, value)}
                readOnly={readOnly}
                {...item}
                setOrder={setOrder}
              />
            );
          })}
        </>
      )}
    </section>
  );
};
export default Summary;
