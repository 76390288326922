import { Search } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { KEY_CODES } from 'helpers/constants';
import { debounce } from 'helpers/helpers';
import useSearchParamsState from 'hooks/common/searchParamsState';
import InputText from 'components/styled/InputText';
import { FILTER_SEARCH } from '..';

const SearchBar = ({ setSearch }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const { getFilterValue } = useSearchParamsState();
  const searchFromURL = getFilterValue(FILTER_SEARCH);
  const debouncedSetSearch = useCallback(debounce(setSearch, 300), []);
  const [inputValue, setInputValue] = useState(searchFromURL);

  useEffect(() => {
    const urlSearchTermUpdated = inputValue != searchFromURL;
    if (urlSearchTermUpdated) {
      setInputValue(searchFromURL || '');
    }
  }, [searchFromURL]);

  return (
    <InputText
      id="store__products-header--search-bar"
      classes="lg:mr-4 lg:max-w-xs"
      placeholder={t('storefront/store/shop/product-search--title')}
      IconRight={Search}
      value={inputValue}
      onKeyDown={(e) =>
        e.keyCode === KEY_CODES.ENTER && setSearch(searchFromURL, new URLSearchParams(search))
      }
      onClear={() => {
        setSearch('');
        setInputValue('');
      }}
      onChange={(e) => {
        const { value } = e.target;
        setInputValue(value);
        const searchParams = new URLSearchParams(search);
        debouncedSetSearch(value, searchParams);
      }}
    />
  );
};

SearchBar.propTypes = {
  setSearch: PropTypes.func.isRequired,
};

export default SearchBar;
