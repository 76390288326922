import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ classes, stroke = 'white', dimensions = 20 }) => (
    <svg width={dimensions} height="auto" className={classes} viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 1H13.5M13.5 1V11M13.5 1L1.5 13" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

Svg.propTypes = {
    dimensions: PropTypes.number,
    classes: PropTypes.string,
    stroke: PropTypes.string,
};

Svg.defaultProps = {
    stroke: theme.colors.Grey400,
    dimensions: 20,
    classes: '',
};

export default Svg;
