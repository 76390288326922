import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from 'state/storefront/accountState';
import FooterSection from './FooterSection';

const AccountInfo = () => {
  const { t } = useTranslation();
  const auth = useRecoilValue(authAtom);

  if (!auth.userId) return null;

  const links = [
    {
      title: t('Order History'),
      link: '/account/order-history',
    },
    {
      title: t('global/Subscriptions'),
      link: '/account/subscription-history',
    },
    {
      title: t('Account Settings'),
      link: '/account/account-settings',
    },
    {
      title: t('Price Lists'),
      link: '/account/price-lists',
    },
  ];

  return (
    <FooterSection title={t('Your Account')}>
      {links.map(({ title, link }) => (
        <Link to={{ pathname: link }} key={`${title}-${link}`}>
          {title}
        </Link>
      ))}
    </FooterSection>
  );
};

export default AccountInfo;
