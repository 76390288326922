import { CircleArrows } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { joinStrings } from 'helpers/helpers';
import { getProductSubscriptionOptions } from 'helpers/subscriptionHelpers';
import Button from 'components/styled/Button/Button';
import ProductImage from '../../ProductImage';
import PackageCardListView from './PackageCardListView';

const ProductCardListView = ({
  product,
  displayDiscount,
  setErrors,
  displaySubscription,
  displayRemainingInventory,
}) => {
  const history = useHistory();
  const { priceListSlug } = useParams();
  const { search } = useLocation();
  const { t } = useTranslation();

  const {
    id: productId,
    name,
    tags,
    description,
    inventory,
    package_price_list_entries: packages,
    track_inventory,
    vendor,
  } = product;

  const WORDS_TO_SHOW = 100;
  const shortDescription = description?.split(' ').slice(0, WORDS_TO_SHOW).join(' ');

  const productSubscriptionOptions = getProductSubscriptionOptions(packages);

  const packages_ = displaySubscription
    ? packages
    : packages.filter(
        (p) =>
          p.packagepricelistentrysubscriptionsettings?.order_options ==
            SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME ||
          p.packagepricelistentrysubscriptionsettings?.order_options ==
            SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
          p.packagepricelistentrysubscriptionsettings == null // no packagepricelistentrysubscriptionsettings yet, default is ONE TIME
      );

  return (
    <div className="store__products--product-list-view" data-testid="product__card--container">
      <div className="flex h-full flex-1">
        <ProductImage classes="mr-4 min-w-44 w-44 h-44" product={product} />
        <div className="w-full flex flex-col flex-1 justify-between">
          <div className="flex items-center justify-between">
            <Link to={`/${priceListSlug}/product/${productId}`}>
              <h2 className="font-bold text-xl cursor-pointer text-Grey800 mb-0.5">{name}</h2>
            </Link>
          </div>
          <span className="text-Grey500 text-sm">
            {joinStrings([vendor?.name, tags?.join(', ')], ' · ')}
          </span>
          <div className="flex-1 pt-6">
            <span
              className="store_products--products-description"
              dangerouslySetInnerHTML={{ __html: shortDescription + ' ' }}
            />
            <span
              className="text-sm text-Green600 font-bold cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: `/${priceListSlug}/product/${productId}`,
                  search,
                });
              }}>
              {t('storefront/store/shop/product--see-more')}
            </span>
          </div>
          <div className="flex flex-col justify-start mt-3" onClick={(e) => e.stopPropagation()}>
            {packages_?.length > 1 && (
              <span className="text-sm my-1 text-Grey500">
                {t('storefront/store/shop/product--options-available', {
                  count: packages_.length,
                })}
              </span>
            )}
            <div className="flex flex-col w-full space-y-3">
              {packages_?.map((_package) => (
                <PackageCardListView
                  key={_package.id}
                  inventory={inventory}
                  {..._package}
                  productName={name}
                  track_inventory={track_inventory}
                  displayDiscount={displayDiscount}
                  setErrors={setErrors}
                  displayRemainingInventory={displayRemainingInventory}
                />
              ))}
              {displaySubscription &&
                (productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION ||
                  productSubscriptionOptions ===
                    SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION) && (
                  <Button
                    classes="button button--white w-fit self-end flex flex-row items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: `/${priceListSlug}/product/${productId}`,
                        search,
                      });
                    }}>
                    {t('storefront/store/subscription-options')}
                    <CircleArrows stroke="gray" classes="ml-2" dimensions="18" />
                  </Button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductCardListView.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    inventory: PropTypes.number,
    name: PropTypes.string,
    package_price_list_entries: PropTypes.array,
    price_list: PropTypes.number,
    price_list_category: PropTypes.number,
    priority_order: PropTypes.number,
    product: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.string,
    track_inventory: PropTypes.bool,
  }),
  setErrors: PropTypes.func,
  displayDiscount: PropTypes.bool,
};

ProductCardListView.defaultProps = {
  setErrors: () => {},
  displayDiscount: false,
};

export default ProductCardListView;
