import { Disclosure } from '@headlessui/react';
import { ChevronSmallUp } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import FilterSetOption from './FilterSetOption';

const FilterSet = ({ title, options, onFilter, more, loadMore, showCount, expanded }) => {
  const { t } = useTranslation();

  return (
    <Disclosure as="div" defaultOpen={expanded} className="w-full">
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full flex items-center my-2">
            <h3 className="flex-grow text-left font-bold">{title}</h3>
            <span className="ml-6">
              <ChevronSmallUp classes={!open ? 'rotate-90' : 'rotate-180'} />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="pt-2">
            <div className="space-y-2">
              {options.map((option, i) => {
                const { label, value, count, checked } = option;
                return (
                  <div key={option.value} className="flex">
                    <InputCheckboxRadio
                      id={`${title}-${i}`}
                      name={`${title}-${value}`}
                      label={<FilterSetOption label={label} count={count} showCount={showCount} />}
                      checked={checked}
                      onChange={() => onFilter({ label, value })}
                    />
                  </div>
                );
              })}
              {more > 0 && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div className="text-sm text-Green600 cursor-pointer" onClick={loadMore}>
                  {`+ ${t('storefront/store/shop/filters--view-more', { filterCount: more })}`}
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

FilterSet.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      count: PropTypes.number,
      checked: PropTypes.bool,
    })
  ),
  onFilter: PropTypes.func,
  more: PropTypes.number,
  loadMore: PropTypes.func,
  showCount: PropTypes.bool,
  expanded: PropTypes.bool,
};

FilterSet.defaultProps = {
  title: '',
  options: [],
  onFilter: () => {},
  more: 0,
  loadMore: () => {},
  showCount: true,
  expanded: false,
};

export default FilterSet;
