import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, useParams, useHistory } from 'react-router-dom';
import DeferredPaymentStep from './DeferredPaymentStep';
import { readDeferredOrder } from 'api/storefront/orders';
import Summary from '../Summary';
import DeferredCompleteStep from './DeferredCheckoutComplete';
import Navigation from '../Navigation';

const DeferredCheckout = ({ isMobile }) => {
  const { t } = useTranslation();
  const { priceListSlug, uidb64, token } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      setLoading(true);
      const resp = await readDeferredOrder(uidb64, token);
      setOrder(resp.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const navigationRoutes = [
    {
      rank: 1,
      path: `/deferred-checkout/${uidb64}/${token}/payment`,
      label: isMobile ? t('Payment') : t('storefront/store/deferred-checkout--navigation-payment'),
    },
    {
      rank: 2,
      path: `/deferred-checkout/${uidb64}/${token}/complete`,
      label: isMobile
        ? t('Order placed')
        : t('storefront/store/deferred-checkout--navigation-confirmation'),
    },
  ];

  const totalUnitQuantity = order?.order_entries?.reduce(
    (a, b) => a + b.storefront_unit_quantity,
    0
  );

  return (
    <>
      <Navigation navigationRoutes={navigationRoutes} />
      {!loading ? (
        <div className="flex flex-col w-checkout max-w-full sm:grid sm:grid-cols-3 sm:gap-6">
          <Switch>
            <Route path="/deferred-checkout/:uidb64/:token/payment">
              <DeferredPaymentStep order={order} setOrder={setOrder} loading={loading} />
            </Route>
            <Route path="/deferred-checkout/:uidb64/:token/complete">
              <DeferredCompleteStep order={order} />
            </Route>
            <Route exact path={`/deferred-checkout/:uidb64/:token`}>
              <Redirect to={`/deferred-checkout/${uidb64}/${token}/payment`} />
            </Route>
          </Switch>
          <Summary order={order} readOnly={true} totalUnitQuantity={totalUnitQuantity} />
        </div>
      ) : (
        <span>loading</span>
      )}
    </>
  );
};

export default DeferredCheckout;
