import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes, spin, width, height }) => (
  <svg
    className={`text-white ${classes} ${spin ? 'animate-spin' : ''}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={width}
    height={height}>
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke={stroke} strokeWidth="4" />
    <path
      className="opacity-75 rounded"
      fill={stroke}
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  spin: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
  spin: true,
  width: 24,
  height: 24,
};

export default Svg;
