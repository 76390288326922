import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { accountAtom } from 'state/storefront/accountState';

const Disabled = () => {
  const { business_name, contact_email } = useRecoilValue(accountAtom);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center text-center mt-20 gap-4 max-w-lg">
      <h1>{t('storefront/error/disabled', { name: business_name })}</h1>
      <p>{t('storefront/error/disabled--desc', { name: business_name, email: contact_email })}</p>
    </div>
  );
};

export default Disabled;
