import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4102 8.15538C11.7722 8.57252 12.4038 8.61726 12.8209 8.2553C13.2381 7.89335 13.2828 7.26176 12.9208 6.84462L11.4102 8.15538ZM8.58977 11.8446C8.22782 11.4275 7.59624 11.3827 7.1791 11.7447C6.76195 12.1067 6.71722 12.7382 7.07917 13.1554L8.58977 11.8446ZM11 5.83333C11 5.28105 10.5523 4.83333 10 4.83333C9.44772 4.83333 9 5.28105 9 5.83333H11ZM9 14.1666C8.99999 14.7189 9.44769 15.1667 9.99998 15.1667C10.5523 15.1667 11 14.719 11 14.1667L9 14.1666ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5V18.5C14.6944 18.5 18.5 14.6944 18.5 10H16.5ZM10 16.5C6.41015 16.5 3.5 13.5899 3.5 10H1.5C1.5 14.6944 5.30558 18.5 10 18.5V16.5ZM3.5 10C3.5 6.41015 6.41015 3.5 10 3.5V1.5C5.30558 1.5 1.5 5.30558 1.5 10H3.5ZM10 3.5C13.5899 3.5 16.5 6.41015 16.5 10H18.5C18.5 5.30558 14.6944 1.5 10 1.5V3.5ZM10 9C9.47953 9 9.05357 8.85755 8.78693 8.67979C8.51331 8.49738 8.5 8.35519 8.5 8.33333H6.5C6.5 9.23195 7.04634 9.9231 7.67753 10.3439C8.31572 10.7694 9.13976 11 10 11V9ZM8.5 8.33333C8.5 8.31148 8.51331 8.16929 8.78693 7.98687C9.05357 7.80911 9.47953 7.66667 10 7.66667V5.66667C9.13976 5.66667 8.31572 5.89732 7.67753 6.32277C7.04634 6.74357 6.5 7.43471 6.5 8.33333H8.5ZM10 7.66667C10.7187 7.66667 11.2164 7.93194 11.4102 8.15538L12.9208 6.84462C12.2502 6.07172 11.132 5.66667 10 5.66667V7.66667ZM10 11C10.5205 11 10.9464 11.1424 11.2131 11.3202C11.4867 11.5026 11.5 11.6448 11.5 11.6667H13.5C13.5 10.768 12.9537 10.0769 12.3225 9.6561C11.6843 9.23065 10.8602 9 10 9V11ZM9 5.83333V6.66667H11V5.83333H9ZM9.00002 13.3333L9 14.1666L11 14.1667L11 13.3334L9.00002 13.3333ZM10 12.3333C9.28131 12.3333 8.78364 12.068 8.58977 11.8446L7.07917 13.1554C7.74982 13.9283 8.86803 14.3333 10 14.3333L10 12.3333ZM11.5 11.6667C11.5 11.6885 11.4867 11.8307 11.2131 12.0131C10.9464 12.1909 10.5205 12.3333 10 12.3333V14.3333C10.8603 14.3333 11.6843 14.1027 12.3225 13.6772C12.9537 13.2564 13.5 12.5653 13.5 11.6667H11.5ZM9 6.66667L9.00002 13.3333L11 13.3333L11 6.66666L9 6.66667Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
