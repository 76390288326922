import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <rect x="2" y="2" width="16" height="16" rx="8" fill={theme.colors.Blue200} />
    <path
      d="M8 10C8 9.44772 8.44772 9 9 9H11C11.5523 9 12 9.44772 12 10C12 10.5523 11.5523 11 11 11H9C8.44772 11 8 10.5523 8 10Z"
      fill={theme.colors.Blue700}
    />
    <path
      d="M8 6C8.55228 6 9 6.44772 9 7C9 7.55228 8.55228 8 8 8C6.89543 8 6 8.89543 6 10C6 11.1046 6.89543 12 8 12C8.55228 12 9 12.4477 9 13C9 13.5523 8.55228 14 8 14C5.79086 14 4 12.2091 4 10C4 7.79086 5.79086 6 8 6Z"
      fill={theme.colors.Blue700}
    />
    <path
      d="M11 13C11 13.5523 11.4477 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C11.4477 12 11 12.4477 11 13Z"
      fill={theme.colors.Blue700}
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
};

export default Svg;
