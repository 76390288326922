import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 5.83333C13.4205 5.83333 14.1667 6.57953 14.1667 7.5M17.5 7.5C17.5 10.2614 15.2614 12.5 12.5 12.5C11.9949 12.5 11.5072 12.4251 11.0476 12.2858L9.16667 14.1667H7.5V15.8333H5.83333V17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V14.5118C2.5 14.2908 2.5878 14.0789 2.74408 13.9226L7.71423 8.95244C7.57491 8.49279 7.5 8.00514 7.5 7.5C7.5 4.73858 9.73858 2.5 12.5 2.5C15.2614 2.5 17.5 4.73858 17.5 7.5Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
