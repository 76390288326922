import { SpinnerSmall } from 'assets/svgIcons';
import { TagMd } from 'assets/svgIcons/index';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { FULFILLMENT_TYPES, PAID } from 'helpers/constants';
import { currencyAtom } from 'state/common/currency';
import Money from 'components/styled/Money';
import PaymentFees from './PaymentFees';

const CartTotals = ({
  loading,
  subtotal,
  subtotal_without_subscription_discount,
  subscription_discount_applied,
  is_subscription,
  tax,
  total,
  payment,
  fulfillment_fees,
  fulfillment_tax,
  fulfillment,
  order_entries,
  discount,
  coupon_discount_applied,
  coupon,
}) => {
  const { t } = useTranslation();
  const currency = useRecoilValue(currencyAtom);
  const requiresApproval = order_entries?.some(({ needs_approval }) => needs_approval);

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-3">
        <h5 className="text-Grey500">{t('storefront/store/cart--subtotal')}</h5>
        <h5 className="text-Grey600 flex">
          {loading ? (
            <SpinnerSmall classes="mr-1" />
          ) : (
            <Money value={subtotal_without_subscription_discount || subtotal} currency={currency} />
          )}
        </h5>
      </div>
      {is_subscription && subscription_discount_applied != 0 && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">{t('storefront/store/cart--subscription-discount')}</h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-subscription_discount_applied} currency={currency} />
            )}
          </h5>
        </div>
      )}

      <div className="flex justify-between items-center mb-3">
        <h5 className="text-Grey500">{t('storefront/store/cart--tax')}</h5>
        <h5 className="text-Grey600 flex">
          {loading ? <SpinnerSmall classes="mr-1" /> : <Money value={tax} currency={currency} />}
        </h5>
      </div>

      {discount > 0 && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">{t('storefront/store/cart--discount')}</h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-discount} currency={currency} />
            )}
          </h5>
        </div>
      )}

      {coupon_discount_applied > 0 && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500 flex">
            <TagMd />
            {coupon?.code}
          </h5>

          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-coupon_discount_applied} currency={currency} />
            )}
          </h5>
        </div>
      )}

      {fulfillment_fees > 0 && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">
            {fulfillment?.fulfillment_strategy_type === FULFILLMENT_TYPES.PICKUP
              ? t('storefront/store/cart--pickup-fee')
              : t('storefront/store/cart--delivery-fee')}
          </h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={fulfillment_fees} currency={currency} />
            )}
          </h5>
        </div>
      )}
      {fulfillment_tax > 0 && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">
            {fulfillment?.fulfillment_strategy_type === FULFILLMENT_TYPES.PICKUP
              ? t('storefront/store/cart--pickup-fee-tax')
              : t('storefront/store/cart--delivery-fee-tax')}
          </h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={fulfillment_tax} currency={currency} />
            )}
          </h5>
        </div>
      )}
      <PaymentFees payment={payment} loading={loading} />
      <hr />
      <div className="flex justify-between items-center my-3">
        <h5 className="text-Grey600 text-2xl">
          {payment?.status === PAID || !requiresApproval
            ? t('storefront/store/cart--total')
            : t('storefront/store/cart--estimated-total')}
        </h5>
        <h5 className="text-Grey600 text-2xl flex">
          {loading ? <SpinnerSmall classes="mr-1" /> : <Money value={total} currency={currency} />}
        </h5>
      </div>
      {!!payment?.store_credit_amount && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">{t('storefront/store/cart--store-credit')}</h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-payment.store_credit_amount} currency={currency} />
            )}
          </h5>
        </div>
      )}
      {!!payment?.payment_strategy_amount && (
        <div className="flex justify-between items-center mb-3">
          <h5 className="text-Grey500">{t('storefront/store/cart--payment')}</h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-payment?.payment_strategy_amount} currency={currency} />
            )}
          </h5>
        </div>
      )}
      <hr />
      {!isNaN(payment?.balance) && (
        <div className="flex justify-between items-center my-3">
          <h5 className="text-Grey500">{t('storefront/store/cart--amount-due')}</h5>
          <h5 className="text-Grey600 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={payment?.balance} currency={currency} />
            )}
          </h5>
        </div>
      )}
    </Fragment>
  );
};

CartTotals.propTypes = {
  loading: PropTypes.bool,
  subtotal: PropTypes.number,
  subtotal_without_subscription_discount: PropTypes.number,
  subscription_discount_applied: PropTypes.number,
  discount: PropTypes.number,
  is_subscription: PropTypes.bool,
  tax: PropTypes.number,
  total: PropTypes.number,
  fulfillment_fees: PropTypes.number,
  fulfillment_tax: PropTypes.number,
  fulfillment: PropTypes.object,
};

export default CartTotals;
