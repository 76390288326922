import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SquareInputCreditCard from './Square';
import StripeInputCreditCard from './Stripe';
import LocalPayInputCreditCard from './LocalPay';
import { SQUARE, STRIPE, LOCAL_PAY, PAYRIX } from 'helpers/constants';
import PayrixInputCreditCard from './Payrix';

const InputCreditCard = forwardRef(({ gatewayDetails, setError }, ref) => {
  const { gatewayShort, gatewayKey, payrixMerchantID, customerId, payrixCustomersApi, countryCode } =
    gatewayDetails;
  return (
    <>
      {gatewayShort === SQUARE && <SquareInputCreditCard ref={ref} locationId={gatewayKey} />}
      {gatewayShort === STRIPE && (
        <StripeInputCreditCard ref={ref} publishableKey={gatewayKey} setError={setError} />
      )}
      {gatewayShort === LOCAL_PAY && (
        <LocalPayInputCreditCard
          ref={ref}
          localPayPublishableKey={gatewayKey}
          setError={setError}
        />
      )}
      {gatewayShort === PAYRIX && (
        <PayrixInputCreditCard
          ref={ref}
          payrixPublicKey={gatewayKey}
          merchantID={payrixMerchantID}
          payrixCustomersApi={payrixCustomersApi}
          customerId={customerId}
          setError={setError}
          countryCode={countryCode}
        />
      )}
    </>
  );
});

InputCreditCard.propTypes = {
  gateway: PropTypes.oneOf(SQUARE, STRIPE, LOCAL_PAY, PAYRIX),
  squareLocationId: (props, propName, componentName) => {
    if (props?.gateway === SQUARE && !props.squareLocationId) {
      return new Error(
        `${propName} is required when gateway is of type ${SQUARE} in ${componentName}.`
      );
    }
  },
  stripePublishableKey: (props, propName, componentName) => {
    if (props?.gateway === STRIPE && !props.stripePublishableKey) {
      return new Error(
        `${propName} is required when gateway is of type ${STRIPE} in ${componentName}.`
      );
    }
  },
  localPayPublishableKey: (props, propName, componentName) => {
    if (props?.gateway === LOCAL_PAY && !props.localPayPublishableKey) {
      return new Error(
        `${propName} is required when gateway is of type ${LOCAL_PAY} in ${componentName}.`
      );
    }
  },
  setError: PropTypes.func,
};

InputCreditCard.defaultProps = {
  gateway: null,
  setError: () => { },
};

export default InputCreditCard;
export { STRIPE, SQUARE, LOCAL_PAY, PAYRIX };
