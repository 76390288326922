import PropTypes from 'prop-types';
import { generateRandomKey } from 'helpers/inputHelpers';
import './MenuDropdown.css';
import { DIRECTIONS } from 'helpers/constants';
const { TOP, RIGHT, BOTTOM, LEFT } = DIRECTIONS;
const CSS_DIRECTIONS = {
  [LEFT]: 'left-0 top-full',
  [RIGHT]: 'right-0 top-full',
  [TOP]: 'left-0 right-0 bottom-full',
  [BOTTOM]: 'left-0 right-0 top-full',
  none: '',
};

const MenuDropdown = ({ children, direction, classes }) => (
  <div
    className={`menu-dropdown ${direction === null ? '' : CSS_DIRECTIONS[direction]} ${classes}`}>
    <ul className="flex flex-col min-w-48">
      {children &&
        children.map((child) =>
          typeof child === 'string' ? <li key={generateRandomKey()}>{child}</li> : child
        )}
    </ul>
  </div>
);

MenuDropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.string,
  direction: PropTypes.string,
};

MenuDropdown.defaultProps = {
  children: undefined,
  classes: '',
  direction: 'LEFT',
};

export default MenuDropdown;
