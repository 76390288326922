import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import './NavigationTabs.css';

const NavigationTabs = ({ classes, routes, parentPath, navigateCallback, replaceHistory }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { [routes[0]?.key]: id } = useParams();
  const { replace } = useHistory();

  return (
    <div className={`navigation-tabs ${classes}`}>
      {routes.map(({ name, path, Icon }) => {
        const to = id ? `${parentPath}/${id}${path}` : `${parentPath}${path}`;
        const fullPath = parentPath + path;

        const pathsEqual = pathname.startsWith(fullPath);

        return replaceHistory ? (
          <h3
            key={path}
            onClick={() => replace(to)}
            className={`navigation-tabs--tab ${pathsEqual ? 'selected' : ''}`}>
            {Icon && <Icon classes="mr-2" dimensions="24" />}
            {t(name)}
          </h3>
        ) : (
          <Link
            key={path}
            to={{ pathname: to, state: { from: name } }}
            onClick={() => navigateCallback(name)}>
            <h3 className={`navigation-tabs--tab ${pathsEqual ? 'selected' : ''}`}>
              {Icon && <Icon classes="mr-2" dimensions="24" />}
              {t(name)}
            </h3>
          </Link>
        );
      })}
    </div>
  );
};

NavigationTabs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.string,
  parentPath: PropTypes.string,
  navigateCallback: PropTypes.func,
  replaceHistory: PropTypes.bool,
};

NavigationTabs.defaultProps = {
  routes: [],
  classes: '',
  parentPath: '',
  navigateCallback: () => {},
  replaceHistory: false,
};

export default NavigationTabs;
