import { Route } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { authAtom, loginActiveAtom } from 'state/storefront/accountState';
import { useEffect } from 'react';

const ProtectedCustomerRoute = ({ children, path, ...rest }) => {
  const auth = useRecoilValue(authAtom);
  const setLoginActive = useSetRecoilState(loginActiveAtom);

  useEffect(() => {
    if (!auth.userId) {
      setLoginActive(true)
    } else {
      setLoginActive(false)
    }
  }, [auth.userId]);

  return <Route {...rest} key={path} render={() => (auth.userId ? children : <></>)} />;
};

ProtectedCustomerRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  path: PropTypes.string,
};

ProtectedCustomerRoute.defaultProps = {
  children: undefined,
  path: '',
};

export default ProtectedCustomerRoute;
