import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DELIVERY, FLEXIBLE } from '.';
import { useRecoilValue } from 'recoil';
import { useOrder } from 'hooks/storefront';
import { useParams } from 'react-router-dom';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import { priceListAtomFamily } from 'state/storefront/storeState';
import FulfillmentAvailability from './FulfillmentAvailability';
import FulfillmentStrategyOption from './FulfillmentStrategyOption';
import CustomerNote from './CustomerNote';
import Card from 'components/styled/Card';
import Alert from 'components/styled/Alert';
import { getEarliestAvailableDate } from 'helpers/helpers';
import { customerAtom, accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import AddressForm from 'components/AddressForm';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';

const DeliveryAddress = ({
  orderFulfillment,
  setOrderFulfillment,
  selectedStrategy,
  setSelectedStrategy,
  customerNote,
  setCustomerNote,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const customer = useRecoilValue(customerAtom);
  const account = useRecoilValue(accountAtom);
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;

  // states
  const { order, priceList: priceListHook } = useOrder(priceListSlug);
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const [loading, setLoading] = useState(false);
  const [deliveryStrategies, setDeliveryStrategies] = useState([]);
  const [deliverableAddress, setDeliverableAddress] = useState(true);
  const [fulfillmentAddress, setFulfillmentAddress] = useState(
    order?.fulfillment?.shipping_address || undefined
  );

  const fetchDeliveryStrategies = async (address) => {
    let strategies = [];
    setLoading(true);
    try {
      const resp = await readPriceListFulfillmentStrategies(priceList?.id, {
        fulfillment_type: DELIVERY,
        address: JSON.stringify(address),
      });
      strategies = resp?.data?.results.filter(
        (strategy) =>
          strategy?.availability?.available_dates?.length > 0 ||
          strategy?.availability?.type === FLEXIBLE
      );
      setDeliveryStrategies(strategies);
      setDeliverableAddress(!!strategies.length);
      const applicableStrategies = strategies.filter(
        (s) => order?.total > s.minimum_purchase_amount
      );
      if (
        !!order?.fulfillment?.fulfillment_strategy &&
        applicableStrategies.find((s) => s.id === order?.fulfillment?.fulfillment_strategy)
      ) {
        setSelectedStrategy(
          applicableStrategies.find((s) => s.id === order?.fulfillment?.fulfillment_strategy)
        );
        setOrderFulfillment((prevState) => ({
          ...prevState,
          shipping_address: address,
          fulfillment_strategy: order?.fulfillment?.fulfillment_strategy,
          fulfillment_date: order?.fulfillment?.fulfillment_date,
        }));
      } else if (applicableStrategies.length === 1) {
        setSelectedStrategy(applicableStrategies[0]);
        setOrderFulfillment((prevState) => ({
          ...prevState,
          shipping_address: address,
          fulfillment_strategy: applicableStrategies[0]?.id,
          fulfillment_date: null,
        }));
      } else {
        setSelectedStrategy(null);
        setOrderFulfillment((prevState) => ({
          ...prevState,
          shipping_address: address,
          fulfillment_strategy: null,
          fulfillment_date: null,
        }));
      }
    } catch (e) {
      console.error('Error fetching delivery plans', e);
    } finally {
      setLoading(false);
      return strategies;
    }
  };

  // effects
  useEffect(async () => {
    if (!!order?.fulfillment?.shipping_address) {
      const strategies = await fetchDeliveryStrategies(order?.fulfillment?.shipping_address);
      const matchingDeliveryStrategy = strategies.find(
        (ds) => ds.id === order?.fulfillment?.fulfillment_strategy
      );
      if (!!matchingDeliveryStrategy) {
        setOrderFulfillment((prevState) => ({
          ...prevState,
          shipping_address: order?.fulfillment?.shipping_address,
          fulfillment_date: order?.fulfillment?.fulfillment_date,
          fulfillment_strategy: matchingDeliveryStrategy?.id,
        }));
        setSelectedStrategy(matchingDeliveryStrategy);
      }
    }
    if (!order?.fulfillment?.shipping_address && customer?.default_address) {
      setFulfillmentAddress(customer?.default_address || {});
      setOrderFulfillment((prevState) => ({ ...prevState }));
    } else {
      setFulfillmentAddress({});
    }
  }, []);

  useEffect(async () => {
    if (fulfillmentAddress && Object.keys(fulfillmentAddress)?.length > 0) {
      await fetchDeliveryStrategies(fulfillmentAddress);
    }
  }, [fulfillmentAddress]);

  return (
    <>
      <Card
        title={t('storefront/store/checkout--fulfillment-delivery-title')}
        classes="mb-6 rounded-none sm:rounded-xl"
        id="delivery-address">
        <>
          {!!errors.shipping_address && (
            <Alert
              id="fulfillment-strategy-shipping-address-alert"
              type="error"
              title={t('storefront/store/shop/cart/details--error-shipping_address')}
              classes="mb-6"
            />
          )}
          <div className="flex items-end w-full">
            {fulfillmentAddress && (
              <AddressForm
                handleChange={async (address) => {
                  if (!!address) setFulfillmentAddress(address);
                  setErrors((prevState) => ({
                    ...prevState,
                    strategy: '',
                    fulfillment_date: '',
                    shipping_address: '',
                  }));
                }}
                address={fulfillmentAddress}
                defaultCountryCode={account?.business_address?.country_code}
              />
            )}
          </div>
          {!deliverableAddress && !!fulfillmentAddress?.place_id && (
            <Alert
              id="delivery-not-available"
              title={t('storefront/store/checkout--fulfillment-delivery-address-none')}
              type="error"
            />
          )}
        </>
      </Card>
      {deliveryStrategies.length > 0 && (
        <Card
          id="delivery-date"
          classes="mb-6"
          title={t('storefront/store/checkout--fulfillment-title')}>
          <>
            {!!errors.strategy && (
              <Alert
                id="fulfillment-strategy-delivery-alert"
                type="error"
                title={t('storefront/store/shop/cart/details--error-strategy')}
                classes="mb-6"
              />
            )}
            <div className="flex flex-col border rounded-lg">
              {loading && deliveryStrategies.length === 0 ? (
                <SpinnerSmall classes="my-6 self-center" />
              ) : null}
              {deliveryStrategies.map((deliveryStrategy) => {
                return (
                  <FulfillmentStrategyOption
                    key={deliveryStrategy?.id}
                    loading={loading}
                    strategy={deliveryStrategy}
                    subtotal={order?.subtotal}
                    selected={selectedStrategy?.id === deliveryStrategy?.id}
                    onSelect={() => {
                      setOrderFulfillment({
                        ...orderFulfillment,
                        fulfillment_strategy: deliveryStrategy?.id,
                        fulfillment_date: deliveryStrategy?.availability?.available_dates?.length
                          ? getEarliestAvailableDate(
                              deliveryStrategy?.availability?.available_dates
                            ).available_date
                          : null,
                      });
                      setSelectedStrategy(deliveryStrategy);
                      setErrors((prevState) => ({
                        ...prevState,
                        strategy: '',
                        fulfillment_date: '',
                      }));
                    }}
                  />
                );
              })}
            </div>
            {!!errors.fulfillment_date && (
              <Alert
                id="fulfillment-strategy-fulfillment-date-alert"
                type="error"
                title={t('storefront/store/shop/cart/details--error-fulfillment_date')}
                classes="mb-4"
              />
            )}
            {selectedStrategy?.id && selectedStrategy?.availability?.type !== FLEXIBLE && (
              <FulfillmentAvailability
                type={DELIVERY}
                availability={selectedStrategy?.availability}
                orderFulfillment={orderFulfillment}
                setOrderFulfillment={setOrderFulfillment}
                setErrors={setErrors}
              />
            )}
            <CustomerNote
              initialValue={order?.customer_note}
              note={customerNote}
              setNote={setCustomerNote}
            />
          </>
        </Card>
      )}
    </>
  );
};

DeliveryAddress.propTypes = {
  orderFulfillment: PropTypes.object,
  setOrderFulfillment: PropTypes.func,
  selectedStrategy: PropTypes.object,
  setSelectedStrategy: PropTypes.func,
  customerNote: PropTypes.string,
  setCustomerNote: PropTypes.func,
  setErrors: PropTypes.func,
};

export default DeliveryAddress;
