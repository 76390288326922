import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/backoffice/v2/`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupBackofficeInterceptors = (clearAuth) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) clearAuth();
      return Promise.reject(error); // bubble error up
    }
  );
};
