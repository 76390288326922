import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes, width, height }) => (
  <svg
    className={classes}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.11996 6.90179C8.30571 6.77796 8.53517 6.66727 8.7999 6.5816L8.79991 8.61918C8.53518 8.53351 8.30571 8.42282 8.11996 8.29899C7.68303 8.0077 7.5999 7.73707 7.5999 7.60039C7.5999 7.46371 7.68303 7.19308 8.11996 6.90179Z"
      fill={fill}
    />
    <path
      d="M11.1999 13.4192L11.1999 11.3816C11.4646 11.4673 11.6941 11.578 11.8799 11.7018C12.3168 11.9931 12.3999 12.2637 12.3999 12.4004C12.3999 12.5371 12.3168 12.8077 11.8799 13.099C11.6941 13.2228 11.4647 13.3335 11.1999 13.4192Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9999 19.6004C15.3018 19.6004 19.5999 15.3023 19.5999 10.0004C19.5999 4.69846 15.3018 0.400391 9.9999 0.400391C4.69797 0.400391 0.399902 4.69846 0.399902 10.0004C0.399902 15.3023 4.69797 19.6004 9.9999 19.6004ZM11.1999 4.00039C11.1999 3.33765 10.6626 2.80039 9.9999 2.80039C9.33716 2.80039 8.7999 3.33765 8.7999 4.00039V4.11078C8.05398 4.25086 7.36407 4.52128 6.78868 4.90487C5.92267 5.48221 5.1999 6.41159 5.1999 7.60039C5.1999 8.78919 5.92267 9.71856 6.78868 10.2959C7.36408 10.6795 8.05399 10.9499 8.79992 11.09L8.79992 13.4195C8.33072 13.2671 7.98291 13.0387 7.7879 12.8139C7.35356 12.3134 6.59566 12.2597 6.09509 12.694C5.59452 13.1284 5.54083 13.8863 5.97518 14.3868C6.65017 15.1647 7.67137 15.6774 8.7999 15.8896L8.7999 16.0004C8.79989 16.6631 9.33714 17.2004 9.99988 17.2004C10.6626 17.2004 11.1999 16.6632 11.1999 16.0004L11.1999 15.89C11.9458 15.7499 12.6357 15.4795 13.2112 15.0959C14.0772 14.5186 14.7999 13.5892 14.7999 12.4004C14.7999 11.2116 14.0771 10.2822 13.2111 9.70487C12.6357 9.32128 11.9458 9.05086 11.1999 8.91078L11.1999 6.58129C11.6691 6.73364 12.0169 6.96211 12.2119 7.18684C12.6463 7.68741 13.4042 7.7411 13.9047 7.30675C14.4053 6.87241 14.459 6.11451 14.0246 5.61394C13.3497 4.83603 12.3284 4.32337 11.1999 4.11122V4.00039Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey300,
  classes: '',
  width: '24',
  height: '24',
};

export default Svg;
