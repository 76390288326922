import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Hero = forwardRef(({ banner, children, full, classes }) => (
  <section className={`store__hero ${!!banner && 'banner'} ${classes}`}>
    {banner && <img className="w-full" src={banner} alt={banner} />}
    {children && (
      <div className={`store__hero--msg ${!!banner && 'banner'} ${full && 'full'}`}>{children}</div>
    )}
  </section>
));

Hero.displayName = 'Hero';

Hero.propTypes = {
  banner: PropTypes.string,
  children: PropTypes.any,
  full: PropTypes.bool,
  classes: PropTypes.string,
};

Hero.defaultProps = {
  banner: undefined,
  children: undefined,
  full: true,
  classes: '',
};

export default Hero;
