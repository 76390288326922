import { capitalize } from 'helpers/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { countriesAtom } from 'state/common/countries';

const useCountries = (defaultIso2Code, defaultCountryOptionOnly) => {
  const { t } = useTranslation();

  const [states, setStates] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState({});
  const defaultStateLabel = t('State/Province');
  const [stateLabel, setStateLabel] = useState(defaultStateLabel);

  const countries = useRecoilValue(countriesAtom);

  const defaultCountryCodes = ['CA', 'AU', 'NZ', 'GB', 'US', 'IE'];
  const countriesList = defaultCountryOptionOnly
    ? [defaultCountry]
    : [
        ...countries
          ?.filter((country) => defaultCountryCodes?.includes(country.iso2))
          .map((country) => ({
            label: country.name,
            value: country.iso2,
          })),
        { type: 'divider' },
        ...countries
          ?.filter((country) => !defaultCountryCodes?.includes(country.iso2))
          .map((country) => ({
            label: country.name,
            value: country.iso2,
          })),
      ];

  const getCountryName = (countryCode) => {
    const country = countries.find((country) => country.iso2 === countryCode);

    return country ? country.name : '';
  };

  const setStatesForCountry = (iso2Code) => {
    const country = countries.find((country) => country.iso2 === iso2Code);

    const statesData = country
      ? country.states.map(({ name, state_code, type }) => ({
          label: name,
          value: state_code,
          type,
        }))
      : [];
    setStates(statesData);
    if (statesData.length > 0)
      setStateLabel(statesData[0].type ? capitalize(statesData[0].type) : defaultStateLabel);
  };

  useEffect(() => {
    if (defaultIso2Code) {
      setDefaultCountry({ label: getCountryName(defaultIso2Code), value: defaultIso2Code });
      setStatesForCountry(defaultIso2Code);
    }
  }, [defaultIso2Code]);

  return { states, setStates, countriesList, setStatesForCountry, defaultCountry, stateLabel };
};

export default useCountries;
