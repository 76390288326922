import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import Card from 'components/styled/Card';
import Button from 'components/styled/Button';
import { useOrder } from 'hooks/storefront';
import {
  DAY_OF_WEEK_MONTH_DAY_LONG,
  formatDateTime,
  formatMoney,
  formatTime,
} from 'helpers/localeHelpers';
import { ArrowDoubleRotatingFilledCircle, SpinnerSmall } from 'assets/svgIcons';
import { ACTIVE, FULFILLMENT_TYPES, SKIPPED } from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import { ListItem } from 'pages/storefront/Cart/components/Orders/OrderCard';
import { currencyAtom } from 'state/common/currency';
import Alert from 'components/styled/Alert/Alert';
import { SECONDARY } from 'components/styled/Alert';
import EmptyUpcomingSubscriptionCard from './EmptyUpcomingSubscriptionCard';

const UpcomingSubscriptionCard = ({ order, onSkip, loading }) => {
  const { t } = useTranslation();
  const { switchOrder } = useOrder();
  const { order_template, upcoming_draft_orders, status, customer_can_skip_order } = order;
  const { fulfillment } = order_template;
  const upcomingDraftOrder = upcoming_draft_orders[0];

  const isSubscriptionActive = upcomingDraftOrder?.status === ACTIVE;
  const isSubscriptionSkipped = upcomingDraftOrder?.status === SKIPPED;

  const location = fulfillment?.fulfillment_address;
  const isDelivery = fulfillment?.fulfillment_strategy_type !== FULFILLMENT_TYPES.PICKUP;
  const currency = useRecoilValue(currencyAtom);

  const formattedEditSubscriptionUntilDate =
    formatDateTime(upcomingDraftOrder?.opens_at, DAY_OF_WEEK_MONTH_DAY_LONG) || '-';
  const formattedEditSubscriptionUntilTime = formatTime(upcomingDraftOrder?.opens_at);

  const skipButtonContent = loading ? (
    <SpinnerSmall width={20} height={20} />
  ) : isSubscriptionSkipped ? (
    t('storefront/cart/orders/unskip')
  ) : (
    t('storefront/cart/orders/skip')
  );

  if (!upcomingDraftOrder) {
    if (status === ACTIVE) return <EmptyUpcomingSubscriptionCard />;
    return null;
  }

  return (
    <Card classes="store--account__subscriptions--upcoming-card" childMarginBottom={false}>
      <>
        <h3 className="text-Green600 font-bold text-sm flex items-center mb-1">
          <ArrowDoubleRotatingFilledCircle classes="mr-1" />
          {t('storefront/store/shop/cart/header--subscription-order')}
        </h3>
        {isSubscriptionSkipped && (
          <Alert
            classes="mb-4 !text-Beige900 items-center mt-4"
            type={SECONDARY}
            description={t('storefront/store/shop/cart/skip--alert')}
          />
        )}
        <h2 className="text-Grey700 text-lg mb-1">
          {!!fulfillment?.fulfillment_date
            ? t('storefront/store/shop/cart/header--fulfillment-date', {
                type: fulfillment?.type_display,
                date: formatDateTime(fulfillment?.fulfillment_date, DAY_OF_WEEK_MONTH_DAY_LONG),
              })
            : t('storefront/store/shop/cart/header--fulfillment', {
                type: fulfillment?.type_display,
              })}
        </h2>
        <span className="mb-4">
          {isDelivery
            ? t('storefront/store/shop/cart/header--deliver', { location })
            : !!fulfillment?.pickup_start_time && !!fulfillment?.pickup_end_time
            ? t('storefront/store/shop/cart/header--pickup-timeslot', {
                location,
                timeslot: `${fulfillment?.pickup_start_time} - ${fulfillment?.pickup_end_time}`,
              })
            : t('storefront/store/shop/cart/header--pickup', { location })}
        </span>
        <ListItem
          text={t('storefront/cart/orders/modify-until')}
          value={t('storefront/cart/orders/modify-until--date-time', {
            date: formattedEditSubscriptionUntilDate,
            time: formattedEditSubscriptionUntilTime,
          })}
        />
        <ListItem
          text={t('storefront/my-account/subscription-history/price-list')}
          value={upcomingDraftOrder?.price_list_name}
        />
        <ListItem
          text={t('storefront/my-account/subscription-history/items')}
          value={upcomingDraftOrder?.items}
        />
        <ListItem
          text={t('storefront/my-account/subscription-history/subtotal')}
          value={formatMoney(upcomingDraftOrder?.subtotal, currency)}
        />
        <ListItem text={t('global/Order Number')} value={upcomingDraftOrder?.order_id} />
        <div className="flex items-center justify-between gap-3 w-full">
          {customer_can_skip_order ? (
            <Button
              classes="button--white flex flex-1 justify-center"
              onClick={onSkip}
              disabled={loading}>
              {skipButtonContent}
            </Button>
          ) : null}
          {isSubscriptionActive && (
            <Button classes="flex-1" onClick={() => switchOrder(upcomingDraftOrder?.order_id)}>
              {t('storefront/my-account/subscription-history/modify')}
            </Button>
          )}
        </div>
      </>
    </Card>
  );
};

UpcomingSubscriptionCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    payment: PropTypes.object,
    order_entries: PropTypes.array,
    status: PropTypes.string,
  }),
};

UpcomingSubscriptionCard.defaultProps = {
  order: {},
};

export default UpcomingSubscriptionCard;
