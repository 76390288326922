import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import FooterSection from './FooterSection';

const StoreInfo = () => {
  const { storefront_configuration } = useRecoilValue(accountAtom);
  const { store_phone_number, store_address, store_email, store_name } = storefront_configuration;

  const location = store_address
    ? store_address.city
      ? `${store_address.city}, ${store_address.province}`
      : `${store_address.province}`
    : '';

  if (!store_name) return null;

  return (
    <FooterSection>
      <span className="font-bold">{store_name}</span>
      {location && <span className="text-Grey500">{location}</span>}
      <Link className="text-Green600" to={{ pathname: `mailto:${store_email}` }} target="_blank">
        {store_email}
      </Link>
      <p className="text-Grey600">{store_phone_number}</p>
    </FooterSection>
  );
};

export default StoreInfo;
