import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes, fill }) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79198 6C7.4062 6.88295 6.52516 7.5 5.5 7.5C4.11929 7.5 3 6.38071 3 5C3 3.61929 4.11929 2.5 5.5 2.5C6.52516 2.5 7.4062 3.11705 7.79198 4H11C12.6569 4 14 5.34315 14 7V11H16.208C16.5938 10.117 17.4748 9.5 18.5 9.5C19.8807 9.5 21 10.6193 21 12C21 13.3807 19.8807 14.5 18.5 14.5C17.4748 14.5 16.5938 13.883 16.208 13H14V17C14 18.6569 12.6569 20 11 20H7.79198C7.4062 20.883 6.52516 21.5 5.5 21.5C4.11929 21.5 3 20.3807 3 19C3 17.6193 4.11929 16.5 5.5 16.5C6.52516 16.5 7.4062 17.117 7.79198 18H11C11.5523 18 12 17.5523 12 17V13H11H7.79198C7.4062 13.883 6.52516 14.5 5.5 14.5C4.11929 14.5 3 13.3807 3 12C3 10.6193 4.11929 9.5 5.5 9.5C6.52516 9.5 7.4062 10.117 7.79198 11H11H12V7C12 6.44772 11.5523 6 11 6H7.79198Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
  fill: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
  fill: theme.colors.Grey400,
};

export default Svg;
