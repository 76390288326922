import { useCallback, useState } from 'react';

/**
 * Global state for queue processing.
 * Using a global object ensures consistency across multiple hook instances.
 */
const globalQueueState = {
  isProcessing: false,
  processingPromise: null,
};

/**
 * Global array to store pending updates.
 * Each item contains:
 * - updateFn: The function to execute
 * - resolve: Promise resolution callback
 * - reject: Promise rejection callback
 */
const updateQueue = [];

/**
 * Custom hook for managing sequential updates with a queue system.
 *
 * Key features:
 * - Ensures updates are processed one at a time
 * - Maintains order of operations
 * - Provides Promise-based interface for async operations
 * - Includes queue state monitoring and debugging capabilities
 */
const useAsyncQueue = () => {
  // Local state to trigger re-renders when queue processing status changes
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);

  /**
   * Updates the processing state both globally and locally
   * @param {boolean} isProcessing - New processing state
   * @param {Promise|null} promise - Optional promise being processed
   */
  const setProcessing = useCallback((isProcessing, promise = null) => {
    globalQueueState.isProcessing = isProcessing;
    globalQueueState.processingPromise = promise;
    setIsProcessingQueue(isProcessing);
  }, []);

  /**
   * Processes the next item in the queue.
   * - Executes the update function
   * - Handles success/failure
   * - Removes processed item
   * - Triggers processing of next item if queue not empty
   */
  const processNextInQueue = useCallback(async () => {
    if (updateQueue.length === 0) {
      setProcessing(false);
      return;
    }

    let currentItem;
    try {
      // Get first item but don't remove it until processing is complete
      currentItem = updateQueue[0];
      const result = await currentItem.updateFn();
      currentItem.resolve(result);
    } catch (error) {
      console.error('Error processing queue item:', error);
      if (currentItem) {
        currentItem.reject(error);
      }
    } finally {
      // Remove processed item and handle next steps
      updateQueue.shift();
      if (updateQueue.length > 0) {
        return processNextInQueue();
      } else {
        setProcessing(false);
      }
    }
  }, [setProcessing]);

  /**
   * Adds a new update function to the queue
   * @param {Function} updateFn - The function to be queued
   * @returns {Promise} Resolves when the update is processed
   *
   * Key behaviors:
   * - If queue is idle, starts processing immediately
   * - If queue is busy, adds to queue for later processing
   * - Returns a promise that resolves/rejects based on update result
   */
  const enqueueUpdate = useCallback(
    (updateFn) => {
      const promise = new Promise((resolve, reject) => {
        updateQueue.push({ updateFn, resolve, reject });
      });

      if (!globalQueueState.isProcessing) {
        setProcessing(true, promise);
        Promise.resolve().then(processNextInQueue);
      }

      return promise;
    },
    [processNextInQueue, setProcessing]
  );

  /**
   * Empties the queue and resets processing state
   * Useful for cleanup or error recovery
   */
  const clearQueue = useCallback(() => {
    updateQueue.length = 0;
    setProcessing(false);
  }, [setProcessing]);

  return {
    enqueueUpdate,
    isProcessingQueue,
    clearQueue,
    queueLength: updateQueue.length,
  };
};

export default useAsyncQueue;
