import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ fillTriangleBottom, fillTriangleTop, classes }) => (
  <svg
    className={`${classes}`}
    width="6"
    height="24"
    viewBox="0 0 6 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M0.0364664 14.5547C-0.40657 13.8901 0.0698217 13 0.868517 13H5.13148C5.93018 13 6.40657 13.8901 5.96353 14.5547L3.83205 17.7519C3.43623 18.3457 2.56377 18.3457 2.16795 17.7519L0.0364664 14.5547Z"
        fill={fillTriangleTop}
      />
      <path
        d="M5.96353 9.4453C6.40657 10.1099 5.93018 11 5.13148 11L0.868517 11C0.0698214 11 -0.40657 10.1099 0.0364665 9.4453L2.16795 6.24808C2.56377 5.65434 3.43623 5.65434 3.83205 6.24808L5.96353 9.4453Z"
        fill={fillTriangleBottom}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="6" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Svg.propTypes = {
  fillTriangleBottom: PropTypes.string,
  fillTriangleTop: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fillTriangleBottom: theme.colors.Grey300,
  fillTriangleTop: theme.colors.Grey300,
  classes: '',
};

export default Svg;
