import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SpinnerSmall } from 'assets/svgIcons';

const PickupLocations = ({ pickupLocations, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <div className="w-full p-4 flex justify-center">
          <SpinnerSmall />
        </div>
      ) : (
        <>
          {pickupLocations?.length > 0 ? (
            <div className="store-modal__pickup-locations">
              {pickupLocations?.map(({ id, name, address }) => (
                <div key={id} className="store-modal__pickup-locations--location">
                  <h4>{name}</h4>
                  <p>{address?.formatted_address}</p>
                </div>
              ))}
            </div>
          ) : (
            <p className="p-6">{t('No pickup locations.')}</p>
          )}
        </>
      )}
    </>
  );
};

PickupLocations.propTypes = {
  pickupLocations: PropTypes.arrayOf(PropTypes.shape({})),
};

PickupLocations.defaultProps = {
  pickupLocations: [],
};

export default PickupLocations;
