import PropTypes from 'prop-types';
import { SpinnerSmall } from 'assets/svgIcons';
import Money from 'components/styled/Money';
import { currencyAtom } from 'state/common/currency';
import { useRecoilValue } from 'recoil';

const Price = ({ loading, price, classes }) => {
  const currency = useRecoilValue(currencyAtom);

  return (
    <h4 className={`text-Grey600 flex items-center ${classes}`}>
      {loading ? <SpinnerSmall classes="mr-1" /> : <Money value={price} currency={currency} />}
    </h4>
  );
};

Price.propTypes = {
  loading: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  classes: PropTypes.string,
};

Price.defaultProps = {
  classes: '',
};

export default Price;
