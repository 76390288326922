import { authAtom as backofficeAuthAtom } from 'state/backoffice/userState';
import { api as backofficeApi } from 'api/backoffice';
import { getDomainParts } from 'helpers/helpers';
import PropTypes from 'prop-types';
import { useAuth } from './common/auth';

export const useBackofficeAuth = (redirect = true) => {
  const { auth, clearAuth, initializeAuth, setAuth } = useAuth(
    'backoffice',
    backofficeAuthAtom,
    backofficeApi
  );

  const setBackofficeAuth = (...args) => {
    const { slug } = setAuth(...args);
    const { subdomain, fqdn } = getDomainParts();
    if (subdomain !== slug && redirect) {
      // if mismatched, redirect
      window.location.replace(`${fqdn.replace(subdomain, slug)}/backoffice`);
    }
  };

  return { auth, clearAuth, initializeAuth, setAuth: setBackofficeAuth };
};

useBackofficeAuth.propTypes = {
  redirect: PropTypes.bool,
};

useBackofficeAuth.defaultProps = {
  redirect: true,
};
