import PropTypes from 'prop-types';
import { useState } from 'react';
import VendorCard from './VendorCard';

const Gallery = ({ images, vendor, classes }) => {
  // states
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  if (images?.length === 0) return null;
  return (
    <div className={`store__product--gallery ${classes}`}>
      {!!images[selectedImageIndex] && (
        <>
          <img
            className="border border-solid border-Grey200 rounded-lg w-full"
            src={images[selectedImageIndex]?.full}
            alt="product-image-highlight"
          />
          {images?.length > 1 && (
            <div
              className={`flex gap-1 sm:gap-2 mt-2 sm:mt-3 ${
                images?.length === 5 ? 'justify-between gap-0' : ''
              }`}>
              {images.map((image, i) => (
                <img
                  key={image?.thumbnail}
                  onClick={() => setSelectedImageIndex(i)}
                  className={`store__product--gallery__image ${
                    i === selectedImageIndex ? 'selected' : ''
                  }`}
                  src={image?.thumbnail}
                  alt={`product-image-${i}`}
                />
              ))}
            </div>
          )}
        </>
      )}
      {vendor?.id && <VendorCard vendorData={vendor} classes="hidden sm:block" />}
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
  classes: PropTypes.string,
  vendor: PropTypes.object,
};

Gallery.defaultProps = {
  images: [],
  classes: '',
  vendor: {},
};

export default Gallery;
