import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { debounce, getAvailableInventoryCount } from 'helpers/helpers';
import { formatMoney } from 'helpers/localeHelpers';
import { getMaxItemQuantity } from 'helpers/storefrontHelpers';
import { screenSizeAtom } from 'state/appState';
import { currencyAtom } from 'state/common/currency';
import { accountAtom } from 'state/storefront/accountState';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button';
import InputPlusMinus from 'components/styled/InputPlusMinus';
import Money from 'components/styled/Money';
import Tag from 'components/styled/Tag';

const PackageCardMobileView = ({
  id,
  name,
  package_price,
  inventory,
  inventory_per_unit,
  track_inventory,
  base_unit,
  charge_unit,
  is_by_weight,
  is_package_weight,
  is_weight_package,
  average_pack_weight,
  unit_price,
  original_package_price,
  displayDiscount,
  displayDetails,
  on_sale,
  max_units_per_order,
}) => {
  const { t } = useTranslation();
  const currency = useRecoilValue(currencyAtom);
  const { priceListSlug } = useParams();
  const { width } = useRecoilValue(screenSizeAtom);
  const { order, setOrder, loading } = useOrder(priceListSlug);
  const [quantity, setQuantity] = useState(1);

  const available = getAvailableInventoryCount(inventory, inventory_per_unit);
  const soldOut = track_inventory && !available;
  const cartProduct = order?.order_entries.find(
    (item) => id === item?.package_price_list_entry?.id
  );
  const unitName = is_by_weight
    ? is_package_weight && !is_weight_package
      ? charge_unit
      : base_unit
    : charge_unit;
  const account = useRecoilValue(accountAtom);
  const displayRemainingInventorySetting =
    account?.storefront_configuration?.display_remaining_inventory;

  // methods
  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(cartProduct?.storefront_unit_quantity);
      }
    }, 400),
    [cartProduct?.storefront_unit_quantity, order?.fulfillment, order?.fulfillment?.id]
  ); // wait 400ms for new input before calling update

  const addPackage = async () => {
    try {
      const entry = !!cartProduct
        ? {
            id: cartProduct?.id,
            storefront_unit_quantity: cartProduct?.storefront_unit_quantity + quantity,
          }
        : { package_price_list_entry: id, storefront_unit_quantity: quantity };
      await setOrder({ order_entries: [entry] });
    } catch (e) {
      console.error('Error setting product: ', e);
    }
  };

  // effects
  useEffect(() => {
    if (cartProduct) {
      setQuantity(cartProduct.storefront_unit_quantity);
    }
  }, [cartProduct]);

  const originalPrice = displayDiscount && on_sale ? original_package_price.toFixed(2) : null;

  const formattedUnitPrice = formatMoney(
    is_by_weight && !is_package_weight && is_weight_package
      ? (unit_price / average_pack_weight).toFixed(2)
      : unit_price,
    currency
  );

  return (
    <div
      className={`store__product--details__package-card flex flex-col justify-between ${
        displayDetails ? 'border border-Grey200 rounded-lg bg-white p-4 mt-2' : ''
      }`}>
      {displayDetails && (
        <>
          <div className="flex flex-col space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex flex-row space-x-2 items-center text-center">
                <span className="font-bold text-Grey700 text-base">{name}</span>
                {originalPrice !== null && (
                  <Tag
                    name={t('global/Sale')}
                    classes="storefront--green mr-2"
                    showButton={false}
                  />
                )}
              </div>
              <div className="flex flex-row space-x-2 items-center">
                {originalPrice !== null && (
                  <span
                    className={`line-through ${soldOut ? 'text-Grey400' : 'text-Grey500'} ml-1`}>
                    <Money value={originalPrice} currency={currency} />
                  </span>
                )}
                <h4
                  className={`font-bold text-base ${
                    soldOut
                      ? 'text-Grey400'
                      : originalPrice !== null
                      ? 'text-Green600'
                      : 'text-Grey700'
                  }`}>
                  <Money value={package_price} currency={currency} />
                </h4>
              </div>
            </div>
          </div>
          {track_inventory || is_by_weight ? (
            <div className="flex justify-between">
              <p>
                {is_by_weight &&
                  t('storefront/store/shop/product--package-details', {
                    averagePackWeight: average_pack_weight,
                    unitName,
                    unitPrice: formattedUnitPrice,
                  })}
              </p>
              {track_inventory && (
                <span className={`${soldOut ? 'text-Grey400' : 'text-Green600'}`}>
                  {displayRemainingInventorySetting
                    ? available
                      ? t('storefront/store/shop/product--in-stock-counted', { count: available })
                      : soldOut
                      ? t('storefront/store/shop/product--sold-out')
                      : t('storefront/store/shop/product--in-stock')
                    : t('storefront/store/shop/product--in-stock')}
                </span>
              )}
            </div>
          ) : null}
        </>
      )}
      <div className={`flex justify-between space-x-2 mt-2 items-center`}>
        {!!cartProduct ? (
          <span className="flex flex-col items-center text-Green600 text-center">
            {t('storefront/store/shop/product--n-packages-in-your-cart', {
              n: cartProduct?.storefront_unit_quantity,
              packageName: cartProduct?.package_price_list_entry?.name,
            })}
          </span>
        ) : (
          <Button
            data-dd-action-name="add-product-to-cart"
            disabled={loading || soldOut || !!cartProduct}
            classes={`button--storefront-primary ${!soldOut && 'button-green'} flex-grow button--green`}
            onClick={addPackage}>
            {soldOut
              ? t('storefront/store/shop/product--sold-out')
              : width < 400
              ? t('storefront/store/shop/product--add-to-cart')
              : t('storefront/store/shop/product--add-packages-to-cart', {
                  packageName: cartProduct?.package_price_list_entry?.name,
                })}
          </Button>
        )}
        <InputPlusMinus
          id={id}
          name={name}
          disabled={loading || soldOut}
          value={quantity}
          max={getMaxItemQuantity(track_inventory, available, max_units_per_order)}
          handleChange={(storefront_unit_quantity) => {
            setQuantity(storefront_unit_quantity);
            if (!!cartProduct) {
              if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
                debouncedSetOrder({
                  order_entries: [{ id: cartProduct?.id, storefront_unit_quantity }],
                });
              }
            }
          }}
        />
      </div>
    </div>
  );
};

PackageCardMobileView.propTypes = {
  id: PropTypes.number,
  product_price_list_entry: PropTypes.number,
  product_package: PropTypes.number,
  adjustment: PropTypes.bool,
  adjustment_type: PropTypes.number,
  adjustment_value: PropTypes.number,
  name: PropTypes.string,
  inventory_per_unit: PropTypes.number,
  unit_price: PropTypes.number,
  priority_order: PropTypes.number,
  package_price: PropTypes.number,
  price_per_unit: PropTypes.number,
  track_inventory: PropTypes.bool,
  displayDiscount: PropTypes.bool,
  displayDetails: PropTypes.bool,
};

PackageCardMobileView.defaultProps = {
  displayDiscount: false,
  displayDetails: true,
};

export default PackageCardMobileView;
