import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Confirmation } from './PaymentStep';
import { useOrder } from 'hooks/storefront';
import { useRecoilValue } from 'recoil';
import { priceListAtomFamily } from 'state/storefront/storeState';
import Complete from '../Complete';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';
import { subscriptionsSelector } from 'state/storefront/accountState';

const CheckoutComplete = ({ openOrder }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const history = useHistory();
  const { order: hookOrder, resetOrder, priceList: priceListHook } = useOrder(priceListSlug);
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const order = !!openOrder?.id ? openOrder : hookOrder;
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));

  // when they navigate from the page remove order
  useEffect(() => {
    if (!upfrontFulfillmentEnabled) {
      return history.listen((location) => {
        if (!location?.pathname?.includes('/complete')) {
          localStorage.removeItem(`${priceList?.id}-${priceListSlug}`);
          resetOrder();
        }
      });
    }
  }, [history]);

  return (
    <Complete
      order={order}
      text={
        <Trans i18nKey="storefront/store/checkout--complete-instructions">
          We've sent an order confirmation and reciept to
          <span className="font-semibold">{{ email: order?.customer?.email }}</span>. If the email
          does not arrive within two minutes please check your spam folder to see if it was routed
          there.
        </Trans>
      }
      children={
        <>
          <h2>{t('storefront/store/checkout--complete-details-title')}</h2>
          <Confirmation readOnly singleOrder={order} />
        </>
      }
      alertTranslationKey={'storefront/store/checkout--complete-order-number'}
    />
  );
};
export default CheckoutComplete;
