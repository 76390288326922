import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import FulfillmentStartingPrice from './FulfillmentStartingPrice';
import { PICKUP_TYPES } from '.';
import { formatMoney } from 'helpers/localeHelpers';
import { currencyAtom } from 'state/common/currency';
import { useRecoilValue } from 'recoil';
import { BLANK_TEXT_EDITOR } from 'helpers/constants';
import LinkToGoogleMaps from 'components/LinkToGoogleMaps';

const FulfillmentStrategyOption = ({ loading, subtotal, strategy, selected, onSelect }) => {
  const { t } = useTranslation();
  const {
    id,
    name,
    fee_amount,
    address,
    minimum_purchase_amount,
    type,
    availability,
    fee_exemption_amount,
    fee_exemption_amount_enabled,
  } = strategy;

  const belowMinimumPurchaseAmount = subtotal < minimum_purchase_amount;
  const aboveFeeExemptionAmount =
    !!fee_exemption_amount_enabled && !!fee_exemption_amount && subtotal >= fee_exemption_amount;
  const help = PICKUP_TYPES.includes(type) ? (
    <LinkToGoogleMaps
      fullAddress={address?.formatted_address}
      displayedAddress={address?.formatted_address}
    />
  ) : (
    ''
  );

  const currency = useRecoilValue(currencyAtom);
  const formattedMinimumPurchaseAmount = formatMoney(minimum_purchase_amount, currency);

  const fee = aboveFeeExemptionAmount ? 0 : fee_amount;

  return (
    <div className={`flex flex-col px-4 py-4 border-t first:border-none`}>
      <div className="flex">
        <InputCheckboxRadio
          disabled={loading || belowMinimumPurchaseAmount}
          id={id}
          name={name}
          label={name}
          help={help}
          checked={selected}
          onChange={onSelect}
          type="radio"
        />
        <FulfillmentStartingPrice price={fee} />
      </div>
      {!!availability?.instructions && availability?.instructions !== BLANK_TEXT_EDITOR && (
        <p
          className="rich-text-editor-render flex flex-col border-t mt-2 pt-2 ml-6"
          dangerouslySetInnerHTML={{ __html: availability?.instructions }}
        />
      )}
      {belowMinimumPurchaseAmount && (
        <div className="text-sm text-Red500 ml-6">
          {t('storefront/store/checkout--fulfillment-below-minimum-purchase', {
            name,
            minimum: formattedMinimumPurchaseAmount,
          })}
        </div>
      )}
    </div>
  );
};

FulfillmentStrategyOption.propTypes = {
  loading: PropTypes.bool,
  subtotal: PropTypes.number,
  strategy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    fee_amount: PropTypes.number,
    address: PropTypes.object,
    type: PropTypes.string,
  }),
};

export default FulfillmentStrategyOption;
