import { useTranslation } from 'react-i18next';
import Tag from 'components/styled/Tag';

const PackageName = ({ name, isOnSale }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row justify-start items-center">
      <span className="font-bold text-Grey800 text-base">{name}</span>
      {isOnSale && (
        <Tag name={t('global/Sale')} classes="storefront--green ml-2" showButton={false} />
      )}
    </div>
  );
};

export default PackageName;
