import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import Card from 'components/styled/Card/Card';
import useCheckoutOrder from '../hooks/useCheckoutOrder';
import ContactInfo from './ContactInfo';
import Customer from './Customer';

const CheckoutCustomer = ({ errors, setErrors }) => {
  const { priceListSlug } = useParams();
  const account = useRecoilValue(accountAtom);
  const [initialLoad, setInitialLoad] = useState(false);

  const {
    updateCustomerFields,
    customerFields,
    setCustomerFields,
    customer,
    updateCustomerInOrder,
    enablePhoneOnCheckout,
    order,
  } = useCheckoutOrder(priceListSlug);

  const requirePhoneNumber =
    account?.storefront_configuration?.require_phone_number || enablePhoneOnCheckout;

  const customerFieldsValid =
    !!customer?.id && (requirePhoneNumber ? customerFields?.phone?.length > 0 : true);

  const [editCustomer, setEditCustomer] = useState(false);

  const onSaveChanges = () => {
    setEditCustomer(false);
  };

  const onEdit = () => {
    setEditCustomer(true);
  };

  useEffect(() => {
    updateCustomerFields();
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      if (!customer?.id) {
        setEditCustomer(true);
        setInitialLoad(true);
      } else {
        if ((!order.customer || customer?.id !== order?.customer?.id) && customerFields?.email) {
          updateCustomerInOrder();
          setInitialLoad(true);
        } else if (order?.customer && customerFields?.email) {
          setInitialLoad(true);
        }
        setEditCustomer(!customerFieldsValid);
      }
    }
  }, [order]);

  return (
    <Card classes="rounded-b-none">
      {editCustomer ? (
        <Customer
          customerFields={customerFields}
          setCustomerFields={setCustomerFields}
          enablePhoneOnCheckout={enablePhoneOnCheckout}
          errors={errors}
          setErrors={setErrors}
          onSaveChanges={onSaveChanges}
          updateCustomerInOrder={updateCustomerInOrder}
          order={order}
        />
      ) : (
        <ContactInfo onEdit={onEdit} customerFields={customerFields} />
      )}
    </Card>
  );
};

export default CheckoutCustomer;
