import PropTypes from 'prop-types';
import theme from 'styles/theme';

const ArrowCurveRight = ({ stroke, classes, fill }) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      stroke={stroke}
      fill={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.33333 4.33333L12.6667 7.66667M12.6667 7.66667L9.33333 11M12.6667 7.66667H6C3.23858 7.66667 1 5.42809 1 2.66667V1"
    />
  </svg>
);

ArrowCurveRight.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  classes: PropTypes.string,
};

ArrowCurveRight.defaultProps = {
  stroke: theme.colors.Grey400,
  fill: theme.colors.White,
  classes: '',
};

export default ArrowCurveRight;
