import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GATEWAY_PAYMENT_METHOD } from 'helpers/constants';
import { tokenizeACH } from 'api/storefront/payrix';
import GatewayPaymentMethod from './GatewayPaymentMethod';

const GatewayPaymentMethods = forwardRef(
  (
    {
      loading,
      modalStyling,
      selectedPaymentMethod,
      onPaymentMethodChange,
      gatewayDetails,
      gatewayPaymentMethods,
      setPaymentErrors,
      tokenizeACH,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const gatewayPaymentMethodRef = useRef(null);
    useImperativeHandle(ref, () => ({
      async getSourceId() {
        if (!gatewayPaymentMethodRef.current) return null;
        const resp = await gatewayPaymentMethodRef.current.tokenize();
        return resp;
      },
    }));

    return (
      <>
        {gatewayPaymentMethods?.length > 1 && (
          <>
            {!modalStyling && <h2>{t('storefront/store/checkout--payment-option-title')}</h2>}
            <div className={'flex flex-col border rounded-lg mb-0 pb-0'}>
              {gatewayPaymentMethods?.map(
                ({ payment_method_name, gateway_payment_method }, index) => (
                  <GatewayPaymentMethod
                    key={`${payment_method_name}-${index}`}
                    ref={gatewayPaymentMethodRef}
                    loading={loading}
                    gatewayPaymentMethods={gatewayPaymentMethods}
                    gatewayPaymentMethodName={payment_method_name}
                    gatewayPaymentMethodId={gateway_payment_method}
                    selectedPaymentMethodName={selectedPaymentMethod?.payment_method_name}
                    onPaymentMethodChange={onPaymentMethodChange}
                    isSinglePaymentMethod={false}
                    gatewayDetails={gatewayDetails}
                    setPaymentErrors={setPaymentErrors}
                    tokenizeACH={tokenizeACH}
                  />
                )
              )}
            </div>
          </>
        )}
        {gatewayPaymentMethods?.length <= 1 && (
          <div className={modalStyling ? '' : 'flex flex-col border rounded-lg mb-0 pb-0'}>
            <GatewayPaymentMethod
              key={`${selectedPaymentMethod?.payment_method_name || GATEWAY_PAYMENT_METHOD.CARD}`}
              ref={gatewayPaymentMethodRef}
              loading={loading}
              gatewayPaymentMethods={gatewayPaymentMethods}
              gatewayPaymentMethodName={
                selectedPaymentMethod?.payment_method_name || GATEWAY_PAYMENT_METHOD.CARD
              }
              gatewayPaymentMethodId={selectedPaymentMethod?.id}
              selectedPaymentMethodName={selectedPaymentMethod?.payment_method_name}
              onPaymentMethodChange={onPaymentMethodChange}
              modalStyling={modalStyling}
              isSinglePaymentMethod={true}
              gatewayDetails={gatewayDetails}
              setPaymentErrors={setPaymentErrors}
              tokenizeACH={tokenizeACH}
            />
          </div>
        )}
      </>
    );
  }
);

GatewayPaymentMethods.propTypes = {
  loading: PropTypes.bool,
  modalStyling: PropTypes.bool,
  selectedPaymentMethod: PropTypes.object,
  onPaymentMethodChange: PropTypes.func,
  gatewayDetails: PropTypes.object,
  gatewayPaymentMethods: PropTypes.array,
  setPaymentErrors: PropTypes.func,
  tokenizeACH: PropTypes.func,
};

GatewayPaymentMethods.defaultProps = {
  tokenizeACH,
};

export default GatewayPaymentMethods;
