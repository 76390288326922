import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${classes}`}>
    <path
      d="M15 1L8 8L1 1"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
  width: 16,
  height: 9,
};

export default Svg;
