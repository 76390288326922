import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import useApi from 'hooks/common/api';
import { fulfillmentSubscriptionOptionsAtom } from '../CheckoutState';

const useCheckoutFulfillment = (order, priceList, setOrder) => {
  const [fulfillmentSubscriptionOptions, setFulfillmentSubscriptionOptions] = useRecoilState(
    fulfillmentSubscriptionOptionsAtom
  );

  const { request: readStrategy } = useApi(readPriceListFulfillmentStrategies);

  const checkSubscriptionOptions = async (results) => {
    if (!!results[0]?.fulfillment_strategy_subscription_frequencies) {
      if (results[0]?.fulfillment_strategy_subscription_frequencies.length === 1) {
        await setOrder({
          subscription_plan: {
            frequency_value: results[0]?.fulfillment_strategy_subscription_frequencies[0].count,
            frequency_unit: results[0]?.fulfillment_strategy_subscription_frequencies[0].unit,
          },
        });
      }
      setFulfillmentSubscriptionOptions(results[0]?.fulfillment_strategy_subscription_frequencies);
    }
  };

  const fetchFulfillmentSubscriptionOptions = async () => {
    const hasSubscriptionProducts = order?.order_entries?.some((entry) => {
      return entry?.is_subscription;
    });
    if (!hasSubscriptionProducts) return;

    const { results } = await readStrategy(priceList?.id, {
      id: order?.fulfillment?.fulfillment_strategy,
    });

    await checkSubscriptionOptions(results);
  };

  return {
    fulfillmentSubscriptionOptions,
    setFulfillmentSubscriptionOptions,
    fetchFulfillmentSubscriptionOptions,
  };
};

export default useCheckoutFulfillment;
