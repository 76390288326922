import Pay from "pages/storefront/Store/components/PaymentComponents/Pay/Pay";
import { CHECKOUT_COMPLETE_PATH } from "../index";
import { CUSTOM } from "pages/storefront/Store/components/PaymentComponents/index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { datadogRum } from '@datadog/browser-rum';

const PayButton = ({
    buttonClasses,
    classes,
    paymentStepComplete,
    orderEntriesErrors,
    submittingOrder,
    setSubmittingOrder,
    order,
    setOrder,
    selectedSavedCardId,
    priceListSlug,
    selectedPaymentStrategy,
    isStoreCreditPayment,
    formattedPaymentBalance,
    saveCard,
    paymentRef,
    isBillingOutOfSync,
    billing,
    isPaymentGateway,
    placeOrder,
    clearCart,
    compareAndUpdateCustomer,
    customer,
    customerNote,
    onPay,
    validateCheckoutOrder,
    setErrors,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    return (
        <Pay
            disabled={!paymentStepComplete || orderEntriesErrors || submittingOrder}
            order={order}
            selectedSavedCardId={selectedSavedCardId}
            redirectToComplete={() => push(`/${priceListSlug}/${CHECKOUT_COMPLETE_PATH}/${order?.id}`)}
            buttonText={
                selectedPaymentStrategy?.type === CUSTOM ||
                    order?.payment?.pay_later ||
                    isStoreCreditPayment
                    ? t('storefront/store/checkout--payment-place-cta')
                    : t('storefront/store/checkout--payment-place-and-pay-cta', {
                        amount: formattedPaymentBalance,
                    })
            }
            saveCard={saveCard}
            submittingOrder={submittingOrder}
            setSubmittingOrder={setSubmittingOrder}
            paymentRef={paymentRef}
            onPay={async (options) => {
                await onPay(options);
            }}
            isPaymentGateway={isPaymentGateway}
            setOrder={setOrder}
            buttonClasses={buttonClasses}
            classes={classes}
            validateCheckoutOrder={validateCheckoutOrder}
            setErrors={setErrors}
        />
    );
}

export default PayButton;