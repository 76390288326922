import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickOutside } from 'hooks/common/events';
import Button from './Button';
import MenuDropdown from './MenuDropdown';
import MenuDropdownPortal from './MenuDropdownPortal/MenuDropdownPortal';

const DropdownListButton = ({
  id,
  children,
  direction,
  classes,
  containerClasses,
  Content,
  openCallback,
  buttonClasses,
  dropdown,
  disabled,
  toggleOnPress,
  useMenuDropdownPortal,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = ClickOutside(false);
  useEffect(() => openCallback(isComponentVisible), [isComponentVisible]);
  return (
    <div ref={ref} className={`dropdown-list-button flex items-center ${containerClasses}`}>
      <Button
        id={id}
        disabled={disabled}
        classes={`static ${buttonClasses}`}
        onClick={(e) => {
          e.stopPropagation();
          if (toggleOnPress) {
            setIsComponentVisible(!isComponentVisible);
            return;
          }
          if (!isComponentVisible) setIsComponentVisible(!isComponentVisible);
        }}
        dropdown={dropdown}>
        {Content}
        {isComponentVisible &&
          (useMenuDropdownPortal ? (
            <MenuDropdownPortal direction={direction} ref={ref}>
              {children}
            </MenuDropdownPortal>
          ) : (
            <MenuDropdown direction={direction} classes={classes}>
              {children}
            </MenuDropdown>
          ))}
      </Button>
    </div>
  );
};

DropdownListButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.string,
  buttonClasses: PropTypes.string,
  containerClasses: PropTypes.string,
  Content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  direction: PropTypes.string,
  openCallback: PropTypes.func,
  dropdown: PropTypes.bool,
  disabled: PropTypes.bool,
  toggleOnPress: PropTypes.bool,
  useMenuDropdownPortal: PropTypes.bool,
};

DropdownListButton.defaultProps = {
  id: '',
  children: undefined,
  classes: '',
  containerClasses: '',
  buttonClasses: '',
  direction: 'LEFT',
  Content: undefined,
  openCallback: () => {},
  dropdown: false,
  disabled: false,
  toggleOnPress: false,
  useMenuDropdownPortal: false,
};

export default DropdownListButton;
