export const CUSTOMER_STATE = {
  id: '',
  company: '',
  email: '',
  email_cc: [],
  first_name: '',
  last_name: '',
  phone: '',
  store_credit_balance: 0,
  default_address: undefined,
  default_billing_address: undefined,
};

export const CUSTOMER_DEFAULT_ADDRESS_STATE = {
  city: '',
  country: '',
  country_code: [],
  first_name: null,
  last_name: null,
  formatted_address: '',
  place_id: '',
  postal_code: '',
  premise: null,
  province: null,
  route: '',
  street_address: '',
  street_number: '',
  subpremise: null,
};

export const CUSTOMER_DEFAULT_BILLING_ADDRESS_STATE = {
  city: '',
  country: '',
  country_code: [],
  first_name: null,
  last_name: null,
  formatted_address: '',
  place_id: '',
  postal_code: '',
  premise: null,
  province: null,
  route: '',
  street_address: '',
  street_number: '',
  subpremise: null,
};

export const ORDER_HISTORY_PAGE_SIZE = 20;
export { default } from './Account';
