import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrder } from 'hooks/storefront';
import PolkaDotBanner from 'components/styled/PolkaDotBanner/PolkaDotBanner';
import CartFulfillmentFreeMessage from './CartFulfillmentFreeMessage';
import CartItem from './CartItem';
import CartTotals from './CartTotals';

const CartLineItems = ({ order: openOrder, readOnly, showStoreCreditAlert }) => {
  const { t } = useTranslation();
  const { order, loading, setOrder } = useOrder();
  const [errors, setErrors] = useState([]);
  const currentOrder = openOrder?.id ? openOrder : order;
  const { order_entries } = currentOrder;

  return (
    <div>
      <div className="flex-grow">
        {order_entries?.length < 1 ? (
          <PolkaDotBanner
            classes="px-4 text-center"
            message={<p>{t('storefront/store/shop/cart--empty')}</p>}
          />
        ) : (
          <>
            <CartFulfillmentFreeMessage />
            {order_entries?.map((item) => (
              <CartItem
                key={item.id}
                {...item}
                order={currentOrder}
                setOrder={setOrder}
                readOnly={readOnly}
                loading={loading}
              />
            ))}
            <CartTotals
              loading={loading}
              {...(openOrder?.id ? openOrder : order)}
              showStoreCreditAlert={showStoreCreditAlert}
            />
          </>
        )}
      </div>
      {errors.length > 0 && (
        <Alert
          id="cart-alert"
          type="error"
          title={t('storefront/store/errors--title')}
          classes="col-span-4"
          closeButton={true}
          handleClose={() => setErrors([])}
          description={
            <ul className="list-disc ml-4">
              {errors.map((error, i) => (
                // eslint-disable-next-line
                <li key={error + i}>{error}</li>
              ))}
            </ul>
          }
        />
      )}
    </div>
  );
};

CartLineItems.propTypes = {
  order: PropTypes.object,
  readOnly: PropTypes.bool,
  showStoreCreditAlert: PropTypes.bool,
};

CartLineItems.defaultProps = {
  order: {},
  readOnly: false,
  showStoreCreditAlert: false,
};

export default CartLineItems;
