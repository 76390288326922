import { useState, useEffect } from 'react';
import { useScript, READY } from './script';
import { COUNTRIES } from 'helpers/constants';

export const usePayrix = (apiKey, merchantID, countryCode) => {
  const payrixStatus = useScript(countryCode === COUNTRIES.CA ? process.env.REACT_APP_PAYRIX_PAYFIELDS_SCRIPT_CA : process.env.REACT_APP_PAYRIX_PAYFIELDS_SCRIPT);
  const jQueryStatus = useScript('https://code.jquery.com/jquery-2.2.4.min.js');
  const [payFields, setPayFields] = useState(null);

  useEffect(() => {
    if (payrixStatus === READY && jQueryStatus === READY) {
      PayFields.config.apiKey = apiKey;
      PayFields.config.merchant = merchantID;

      setPayFields(PayFields);
    }
  }, [payrixStatus, jQueryStatus]);

  return payFields;
};
