import PropTypes from 'prop-types';
import DropdownListButton from '../DropdownListButton';

const LabeledDropdown = ({ label, options, selectedOptionLabel, onChange }) => {
  return (
    <DropdownListButton
      containerClasses="w-max"
      Content={
        <div classes="flex justify-between text-sm px-2">
          <span className="text-Grey500 pr-1">{label}</span>
          <span className="text-Grey800">{selectedOptionLabel}</span>
        </div>
      }
      useMenuDropdownPortal
      toggleOnPress
      buttonClasses="dropdown-list-button__dots bg-white w-full md:w-fit mt-2 md:mt-0">
      {options?.map((option) => (
        <div
          key={option?.value}
          className="flex items-center px-2 py-2 cursor-pointer hover:bg-Grey100"
          onClick={() => onChange(option)}>
          <span className="text-Grey800 text-base">{option?.label}</span>
        </div>
      ))}
    </DropdownListButton>
  );
};

LabeledDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptionLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LabeledDropdown;
