import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ON } from 'helpers/constants';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront/order';
import Alert from 'components/styled/Alert/Alert';
import { SUCCESS } from 'components/styled/Alert/index';
import Button from 'components/styled/Button/Button';
import CouponInput from './CouponInput';

const CartCoupon = () => {
  const { t } = useTranslation();
  const { order, setOrder } = useOrder();
  const [addCouponEnabled, setAddCouponEnabled] = useState(false);
  const couponCodesFeatureFlagEnabled = useTreatment('backoffice_coupon_codes') == ON;
  const appliedCode = order?.coupon?.code;

  const applyCouponCode = async (code) => _setCouponCode(code);

  const removeCouponCode = async () => _setCouponCode(null);

  const _setCouponCode = async (code) => {
    try {
      await setOrder({ coupon: { code } });
    } catch (error) {
      console.error(error); //todo: show the customer this error
    }
  };

  if (!couponCodesFeatureFlagEnabled) return null;

  if (!!appliedCode) {
    return (
      <div className="mt-2">
        <Alert
          id="coupon-applied-success"
          classes="my-4"
          type={SUCCESS}
          inline
          title={t('storefront/store/cart/coupon/coupon-added--title', { code: appliedCode })}
        />
        <Button classes="button--white w-full" onClick={removeCouponCode}>
          {t('storefront/store/cart/coupon/remove-coupon--title')}
        </Button>
      </div>
    );
  }

  if (addCouponEnabled) {
    return <CouponInput applyCouponCode={applyCouponCode} />;
  }

  return (
    <Button classes="button--white w-full mt-2" onClick={() => setAddCouponEnabled(true)}>
      {t('storefront/store/cart/coupon/add-coupon--title')}
    </Button>
  );
};

CartCoupon.propTypes = {};
export default CartCoupon;
