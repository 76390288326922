import { EXPORT_FILE_TYPE } from 'helpers/constants';
import { api } from 'api/storefront';

export const exportData = async (url, params = {}) => {
  const response = await api.get(url, {
    method: 'GET',
    responseType: 'blob', // important
    params,
  });

  const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
  const filename = response.headers['content-disposition'].split('filename=')[1];
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute(
    'download',
    `${filename}.${EXPORT_FILE_TYPE[response.headers['content-type']]}`
  );
  document.body.appendChild(link);
  link.click();
};

export const pluralizeWord = (word, count) => {
  if (count === 1) {
    return word;
  }
  return `${word}s`;
};

export const getMaxItemQuantity = (trackInventory, available, maxUnitsPerOrder) => {
  return maxUnitsPerOrder || (trackInventory ? available : null);
};
