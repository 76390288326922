import { generateRandomNumber } from 'helpers/inputHelpers';
import { useSetRecoilState } from 'recoil';
import { toastAtom } from 'state/appState';

export const useToast = (direction = 'right') => {
  const setToasts = useSetRecoilState(toastAtom);
  return (message, type = 'success', timer = 3000, loading = false) => {
    const id = generateRandomNumber();
    setToasts((prevState) => [...prevState, { id, timer, message, type, direction, loading }]);
    return () => setToasts((prevState) => [...prevState].filter((toast) => toast.id !== id));
  };
};
