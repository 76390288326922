import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 13V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V13M3 11H21L19.3216 19.3922C19.1346 20.3271 18.3138 21 17.3604 21H6.63961C5.68625 21 4.86542 20.3271 4.67845 19.3922L3 11Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
};

export default Svg;
