import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={classes}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.3999 14.1C2.3999 13.6581 2.75807 13.3 3.1999 13.3H12.7999C13.2417 13.3 13.5999 13.6581 13.5999 14.1C13.5999 14.5418 13.2417 14.9 12.7999 14.9H3.1999C2.75807 14.9 2.3999 14.5418 2.3999 14.1ZM5.03422 7.93429C5.34664 7.62187 5.85317 7.62187 6.16559 7.93429L7.1999 8.9686L7.1999 2.89998C7.1999 2.45815 7.55807 2.09998 7.9999 2.09998C8.44173 2.09998 8.7999 2.45815 8.7999 2.89998V8.96861L9.83422 7.93429C10.1466 7.62187 10.6532 7.62187 10.9656 7.93429C11.278 8.24671 11.278 8.75324 10.9656 9.06566L8.56559 11.4657C8.41556 11.6157 8.21208 11.7 7.9999 11.7C7.78773 11.7 7.58425 11.6157 7.43422 11.4657L5.03422 9.06566C4.7218 8.75324 4.7218 8.24671 5.03422 7.93429Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
};

export default Svg;
