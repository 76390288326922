import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={classes}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99992 3.3335V16.6668M16.6666 10.0002L3.33325 10.0002"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey500,
  classes: '',
};

export default Svg;
