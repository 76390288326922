import PropTypes from 'prop-types';
import './SidePopout.css';

const DIRECTIONS_CLOSED = {
  LEFT: '-left-full right-full',
  RIGHT: '-right-full left-full justify-end',
  TOP: '-top-full bottom-full items-start',
  BOTTOM: '-bottom-full top-full items-end',
};

const DIRECTIONS_OPENED = {
  LEFT: 'left-0 right-full',
  RIGHT: 'right-0 left-full justify-end',
  TOP: 'top-0 bottom-full items-start',
  BOTTOM: 'bottom-0 top-full items-end',
};

const SidePopout = ({
  children,
  direction,
  classes,
  isOpen,
  onClose,
  onKeyDown,
  showOverlay,
  overlayClasses,
}) => {
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => onClose(e)}
        onKeyDown={onKeyDown}
        className={`side-popout side-popout__${direction} side-popout__${
          isOpen ? 'open' : 'closed'
        } ${isOpen ? DIRECTIONS_OPENED[direction] : DIRECTIONS_CLOSED[direction]} ${classes}`}>
        {!!showOverlay && (
          <div
            className={`side-popout-overlay ${isOpen ? 'open' : ''} ${overlayClasses}`}
            onClick={(e) => onClose(e)}
          />
        )}
        {children}
      </div>
    </>
  );
};

SidePopout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.string,
  direction: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onKeyDown: PropTypes.func,
  showOverlay: PropTypes.bool,
  overlayClasses: PropTypes.string,
};

SidePopout.defaultProps = {
  children: undefined,
  classes: '',
  direction: 'LEFT',
  isOpen: false,
  onClose: () => {},
  onKeyDown: () => {},
  showOverlay: false,
  overlayClasses: '',
};

export default SidePopout;
