import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { ON, SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { PACKAGES_NEW_ON_SALE_TAG } from 'helpers/featureFlags';
import { formatMoney } from 'helpers/localeHelpers';
import { currencyAtom } from 'state/common/currency';
import useTreatment from 'hooks/common/splitTreatment';
import Money from 'components/styled/Money';

const PackageValueGridView = ({ package_, t, displayDiscount, soldOut }) => {
  const currency = useRecoilValue(currencyAtom);

  const useOnSaleField = useTreatment(PACKAGES_NEW_ON_SALE_TAG) == ON;
  const onSaleKey = useOnSaleField ? 'on_sale_toggle' : 'on_sale';

  const {
    average_pack_weight: averagePackWeight,
    name,
    charge_unit,
    base_unit,
    is_weight_package,
    is_package_weight,
    unit_price,
    subscription_price,
    package_price,
    original_package_price,
    packagepricelistentrysubscriptionsettings,
    [onSaleKey]: on_sale,
    on_sale_toggle,
    strikethrough_display_value,
  } = package_;

  const orderOptions = packagepricelistentrysubscriptionsettings?.order_options;
  const packagePrice =
    !!subscription_price && orderOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
      ? subscription_price
      : package_price;

  const originalPrice =
    on_sale && original_package_price > packagePrice ? original_package_price.toFixed(2) : null;

  const displayStrikethrough = useOnSaleField
    ? on_sale_toggle && strikethrough_display_value > 0
    : displayDiscount && !!originalPrice;

  const formattedOriginalPrice = formatMoney(
    useOnSaleField ? strikethrough_display_value : originalPrice,
    currency
  );

  const unitName = is_weight_package ? base_unit : charge_unit;

  const averagePricePerUnit = formatMoney(
    !is_package_weight && is_weight_package
      ? (unit_price / averagePackWeight).toFixed(2)
      : unit_price,
    currency
  );

  return (
    <div
      className={`flex flex-wrap justify-start w-full items-center ${
        soldOut ? 'text-Grey400' : ''
      }`}>
      <span
        className={`flex text-base font-bold mr-1 ${soldOut ? 'text-Grey400' : 'text-Grey800'}`}>
        <Money value={packagePrice} currency={currency} />
      </span>
      {!!displayStrikethrough && (
        <span className="flex line-through text-Grey500 pl-1 items-center mr-1">
          {formattedOriginalPrice}
        </span>
      )}
      <span className="flex text-Grey500 text-sm items-center mr-1">
        {/* prettier-ignore */}
        {t('storefront/store/shop/product/label-name', {
          name,
        })}
      </span>
      {!!averagePackWeight && (
        <span className="flex text-Grey500 text-xs items-center">
          {t('storefront/store/shop/product/average-pack-weight', {
            averagePackWeight,
            averagePricePerUnit,
            unitName,
          })}
        </span>
      )}
    </div>
  );
};

PackageValueGridView.propTypes = {
  package_: PropTypes.shape({
    id: PropTypes.number,
    product_price_list_entry: PropTypes.number,
    product_package: PropTypes.number,
    adjustment: PropTypes.bool,
    adjustment_type: PropTypes.number,
    adjustment_value: PropTypes.number,
    available: PropTypes.number,
    name: PropTypes.string,
    inventory_per_unit: PropTypes.number,
    unit_price: PropTypes.number,
    priority_order: PropTypes.number,
    price: PropTypes.number,
    price_per_unit: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  displayDiscount: PropTypes.bool,
  soldOut: PropTypes.bool,
};

PackageValueGridView.defaultProps = {
  setValue: () => {},
  displayDiscount: false,
  soldOut: false,
};

export default PackageValueGridView;
