import PropTypes from 'prop-types';

const LinkToGoogleMaps = ({ fullAddress, displayedAddress }) => (
  <a
    target="_blank"
    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`}>
    {displayedAddress}
  </a>
);

LinkToGoogleMaps.propTypes = {
  fullAddress: PropTypes.string,
  displayedAddress: PropTypes.string,
};

LinkToGoogleMaps.defaultProps = {
  fullAddress: '',
  displayedAddress: '',
};

export default LinkToGoogleMaps;
