import Alert from 'components/styled/Alert';
import moment from 'moment';
import { GATEWAY_PAYMENT_METHOD, ON } from 'helpers/constants';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';

const CheckoutAchAuthorization = ({
  hasOutstandingBalance,
  orderNeedsApproval,
  isPaymentGateway,
  isPayLater,
  gatewayPaymentMethod,
  formattedBalance,
  saveCard,
}) => {
  const { t } = useTranslation();
  const account = useRecoilValue(accountAtom);

  const isAch =
    hasOutstandingBalance &&
    isPaymentGateway &&
    !isPayLater &&
    gatewayPaymentMethod?.payment_method_name === GATEWAY_PAYMENT_METHOD.ACH;

  const isAchNeedsApproval = isAch && !!orderNeedsApproval;

  return (
    <>
      {isAch && (
        <Alert
          id="card-hold-info"
          classes="mb-4 mt-4"
          type="info"
          hasIcon
          description={
            <div>
              <p className="text-justify">
                <Trans i18nKey="global/checkout-ach-authorization">
                  By clicking "Place order and pay", you authorize
                  {{ seller_name: account?.business_name }} to debit the bank account specified
                  above for the amount of {{ order_amount: formattedBalance }} on
                  {{ today_date: moment().format('DD-MM-YYYY') }}. Once the payment is authorized
                  there can be no changes or corrections. You may amend or cancel this authorization
                  at any time by providing notice to {{ seller_name: account?.business_name }}.
                </Trans>
              </p>
              {(isAchNeedsApproval || saveCard) && (
                <>
                  <br />
                  <p className="text-justify">
                    <Trans i18nKey="global/ach-authorization-future-usage">
                      If you order from {{ seller_name: account?.business_name }} with any
                      regularity or periodically purchase additional products pursuant to these
                      terms, you authorize {{ seller_name: account?.business_name }} to debit the
                      specific bank account periodically. Orders and payments that fall outside the
                      regular authorized debits above will only be debited after your additional
                      authorization is obtained.
                    </Trans>
                  </p>
                </>
              )}
              <br></br>
              <p>{t('global/checkout-ach-authorization-agree')}</p>
            </div>
          }
        />
      )}
    </>
  );
};

CheckoutAchAuthorization.propTypes = {};
CheckoutAchAuthorization.defaultProps = {};

export default CheckoutAchAuthorization;
