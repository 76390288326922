import PaymentStep from './components/PaymentStep';
import { useRecoilValue } from 'recoil';
import CheckoutCustomer from './components/CheckoutCustomer';
import { checkoutPaymentErrorsAtom } from 'state/storefront/storeState';
import Alert from 'components/styled/Alert/Alert';
import { useTranslation } from 'react-i18next';
import PayButton from './components/CheckoutPayButton';
import { useParams } from 'react-router-dom';
import useCheckoutOrder from './hooks/useCheckoutOrder';
import { useRef } from 'react';
import CheckoutFooter from './components/CheckoutFooter';
import FulfillmentSummary from './components/FulfillmentSummary';



const CheckoutContainer = ({ }) => {
    const { t } = useTranslation();
    const { priceListSlug } = useParams();
    const paymentRef = useRef();

    const {
        order,
        setOrder,
        orderEntriesErrors,
        billing,
        isBillingOutOfSync,
        submittingOrder,
        setSubmittingOrder,
        paymentStepComplete,
        isPaymentGateway,
        formattedPaymentBalance,
        selectedSavedCardId,
        selectedPaymentStrategy,
        isStoreCreditPayment,
        customer,
        saveCard,
        placeOrder,
        clearCart,
        compareAndUpdateCustomer,
        customerNote,
        onPay,
        errors,
        setErrors,
        validateCheckoutOrder,
        isFulfillmentComplete } = useCheckoutOrder(priceListSlug);

    return (
        <>
            <div className="checkout__confirmation_payment col-span-3 sm:col-span-2 mt-4">
                {Object.values(errors).some((v) => !!v) ? (
                    <Alert
                        id="submission-errors-alert"
                        type="error"
                        title={t('storefront/store/shop/cart/details--error-title')}
                        classes="col-span-4 mb-4"
                        description={
                            <ul className="list-disc ml-4">
                                {Object.entries(errors).map(
                                    (error) => !!error[1] && <li key={error[0] + error[1]}>{error[1]}</li>
                                )}
                            </ul>
                        }
                    />
                ) : null}
                {!(Object.values(errors).some((v) => !!v)) && orderEntriesErrors && (
                    <Alert
                        id="payment-error"
                        type="error"
                        title={t('storefront/store/shop/cart--error-title')}
                        classes="mb-6"
                        description={t('storefront/store/shop/cart--error')}
                    />
                )}
                <CheckoutCustomer errors={errors} setErrors={setErrors} />
                <FulfillmentSummary
                    order={order}
                    isFulfillmentComplete={isFulfillmentComplete}
                />
                <PaymentStep paymentRef={paymentRef} />
                <CheckoutFooter classes="hidden sm:flex" />
            </div >
            <div className="fixed z-50 left-0 bottom-0 right-0 p-4 border-t bg-White flex justify-end">
                <PayButton buttonClasses="w-full xl" classes="sm:max-w-xs w-full"
                    paymentStepComplete={paymentStepComplete}
                    orderEntriesErrors={orderEntriesErrors}
                    submittingOrder={submittingOrder}
                    setSubmittingOrder={setSubmittingOrder}
                    order={order}
                    setOrder={setOrder}
                    selectedSavedCardId={selectedSavedCardId}
                    priceListSlug={priceListSlug}
                    selectedPaymentStrategy={selectedPaymentStrategy}
                    isStoreCreditPayment={isStoreCreditPayment}
                    formattedPaymentBalance={formattedPaymentBalance}
                    saveCard={saveCard}
                    paymentRef={paymentRef}
                    isBillingOutOfSync={isBillingOutOfSync}
                    billing={billing}
                    isPaymentGateway={isPaymentGateway}
                    placeOrder={placeOrder}
                    clearCart={clearCart}
                    compareAndUpdateCustomer={compareAndUpdateCustomer}
                    customer={customer}
                    customerNote={customerNote}
                    onPay={onPay}
                    validateCheckoutOrder={validateCheckoutOrder}
                    setErrors={setErrors}
                />
            </div>

        </>
    );
};

export default CheckoutContainer;
