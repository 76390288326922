import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes, stroke, width, height }) => (
  <svg
    className={classes}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill={stroke}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16667 1.5V4.83333M1.5 3.16667H4.83333M4 13.1667V16.5M2.33333 14.8333H5.66667M9.83333 1.5L11.7381 7.21429L16.5 9L11.7381 10.7857L9.83333 16.5L7.92857 10.7857L3.16667 9L7.92857 7.21429L9.83333 1.5Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
  stroke: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Svg.defaultProps = {
  classes: '',
  stroke: theme.colors.Green600,
  width: 18,
  height: 18,
};

export default Svg;
