import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.17157 14.8284C10.7337 16.3905 13.2663 16.3905 14.8284 14.8284M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      className="fill-on-hover no-stroke"
      x="8"
      y="8"
      width="2"
      height="4"
      rx="1"
      fill={stroke}
    />
    <rect
      className="fill-on-hover no-stroke"
      x="14"
      y="8"
      width="2"
      height="4"
      rx="1"
      fill={stroke}
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
};

export default Svg;
