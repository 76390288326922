import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      d="M2.5 6.6665L9.0755 11.0502C9.63533 11.4234 10.3647 11.4234 10.9245 11.0502L17.5 6.6665M4.16667 15.8332H15.8333C16.7538 15.8332 17.5 15.087 17.5 14.1665V5.83317C17.5 4.9127 16.7538 4.1665 15.8333 4.1665H4.16667C3.24619 4.1665 2.5 4.9127 2.5 5.83317V14.1665C2.5 15.087 3.24619 15.8332 4.16667 15.8332Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
  stroke: theme.colors.Grey400,
};

export default Svg;
