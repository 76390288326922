import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    className={`svg--clipboard-check-large ${classes}`}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2071 13.2929C9.81658 12.9024 9.18342 12.9024 8.79289 13.2929C8.40237 13.6834 8.40237 14.3166 8.79289 14.7071L10.2071 13.2929ZM11.5 16L10.7929 16.7071C11.1834 17.0976 11.8166 17.0976 12.2071 16.7071L11.5 16ZM16.2071 12.7071C16.5976 12.3166 16.5976 11.6834 16.2071 11.2929C15.8166 10.9024 15.1834 10.9024 14.7929 11.2929L16.2071 12.7071ZM18.5 7V19H20.5V7H18.5ZM17.5 20H7.5V22H17.5V20ZM6.5 19V7H4.5V19H6.5ZM7.5 6H9.5V4H7.5V6ZM15.5 6H17.5V4H15.5V6ZM7.5 20C6.94772 20 6.5 19.5523 6.5 19H4.5C4.5 20.6569 5.84315 22 7.5 22V20ZM18.5 19C18.5 19.5523 18.0523 20 17.5 20V22C19.1569 22 20.5 20.6569 20.5 19H18.5ZM20.5 7C20.5 5.34315 19.1569 4 17.5 4V6C18.0523 6 18.5 6.44772 18.5 7H20.5ZM6.5 7C6.5 6.44772 6.94772 6 7.5 6V4C5.84315 4 4.5 5.34315 4.5 7H6.5ZM8.79289 14.7071L10.7929 16.7071L12.2071 15.2929L10.2071 13.2929L8.79289 14.7071ZM12.2071 16.7071L16.2071 12.7071L14.7929 11.2929L10.7929 15.2929L12.2071 16.7071ZM11.5 4H13.5V2H11.5V4ZM13.5 6H11.5V8H13.5V6ZM11.5 6C10.9477 6 10.5 5.55228 10.5 5H8.5C8.5 6.65685 9.84315 8 11.5 8V6ZM14.5 5C14.5 5.55228 14.0523 6 13.5 6V8C15.1569 8 16.5 6.65685 16.5 5H14.5ZM13.5 4C14.0523 4 14.5 4.44772 14.5 5H16.5C16.5 3.34315 15.1569 2 13.5 2V4ZM11.5 2C9.84315 2 8.5 3.34315 8.5 5H10.5C10.5 4.44772 10.9477 4 11.5 4V2Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
};

export default Svg;
