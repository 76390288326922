import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImageSlider.css';

const ImageSlider = ({ imageUrls, classes }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    if (isHovering && imageUrls.length > 1) {
      const id = setInterval(() => {
        setCurrentImageIndex((prev) => (prev === imageUrls.length - 1 ? 0 : prev + 1));
      }, 1500);
      setIntervalId(id);
      return () => clearInterval(id);
    }

    return () => clearInterval(intervalId);
  }, [isHovering, imageUrls.length]);

  const handleMouseEnter = () => {
    setCurrentImageIndex((prev) => (prev === imageUrls.length - 1 ? 0 : prev + 1));
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setCurrentImageIndex(0);
  };

  return (
    <div
      className="slider-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {imageUrls.map((imageUrl, index) => (
        <img
          key={imageUrl}
          src={imageUrl}
          alt={`Slide ${index}`}
          className={`slider-image ${classes} ${index === currentImageIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

ImageSlider.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.string,
};

ImageSlider.defaultProps = {
  imageUrls: [],
  classes: '',
};

export default ImageSlider;
