import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ON } from 'helpers/constants';
import { readOrder } from 'api/storefront/orders';
import { subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useApi from 'hooks/common/api';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Navigation from '../Navigation/Navigation';
import Summary from '../Summary';
import './Checkout.css';
import CheckoutComplete from './CheckoutComplete';
import CheckoutFooter from './CheckoutFooter';
import CheckoutHeaderBack from './CheckoutHeaderBack';
import FulfillmentStep from './FulfillmentStep';
import PaymentStep from './PaymentStep';

const Checkout = ({ isMobile }) => {
  const { t } = useTranslation();
  const { priceListSlug, orderId } = useParams();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const {
    loading,
    order,
    setOrder,
    totalUnitQuantity,
    priceList: priceListHook,
  } = useOrder(priceListSlug);

  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const [openOrder, setOpenOrder] = useState(null);
  const { request: requestOrder } = useApi(readOrder);

  const fetchOrder = async () => {
    if (!!upfrontFulfillmentEnabled && !!orderId) {
      const _order = await requestOrder(orderId);
      setOpenOrder(_order);
    } else {
      setOpenOrder(order);
    }
  };

  const navigationRoutes = [
    {
      rank: 1,
      path: `/${priceListSlug}/checkout/delivery`,
      label: isMobile ? t('Delivery') : t('storefront/store/checkout--navigation-delivery'),
    },
    {
      rank: 2,
      path: `/${priceListSlug}/checkout/payment`,
      label: isMobile ? t('Payment') : t('storefront/store/checkout--navigation-payment'),
    },
    {
      rank: 3,
      path: `/${priceListSlug}/checkout/complete`,
      label: isMobile ? t('Confirmation') : t('storefront/store/checkout--navigation-confirmation'),
    },
  ];

  const isCheckedOut = location.pathname?.includes('/complete');

  useEffect(() => {
    if (!!isCheckedOut) fetchOrder();
  }, [isCheckedOut]);

  return (
    <>
      <CheckoutHeaderBack />
      <Navigation navigationRoutes={navigationRoutes} />
      {priceList?.id && order?.id ? (
        <div className="checkout__container" id="checkout__container">
          <Switch>
            <Route path={'/:priceListSlug/checkout/delivery'}>
              <FulfillmentStep isMobile={isMobile} />
            </Route>
            <Route path={'/:priceListSlug/checkout/payment'}>
              <PaymentStep isMobile={isMobile} />
            </Route>
            <Route
              path={[
                `/:priceListSlug/checkout/complete/:orderId`,
                '/:priceListSlug/checkout/complete',
              ]}>
              <CheckoutComplete openOrder={openOrder} />
            </Route>
            <Route exact path={'/:priceListSlug/checkout'}>
              <Redirect to={`/${priceListSlug}/checkout/delivery`} />
            </Route>
          </Switch>
          <Summary
            loading={loading}
            order={!!openOrder?.id ? openOrder : order}
            setOrder={setOrder}
            readOnly={isCheckedOut}
            totalUnitQuantity={
              !!openOrder?.id
                ? openOrder?.order_entries?.reduce(
                  (a, b) =>
                    a +
                    (b?.storefront_unit_quantity > 0
                      ? b?.storefront_unit_quantity
                      : b?.unit_quantity),
                  0
                )
                : totalUnitQuantity
            }
          />
          <CheckoutFooter classes="flex sm:hidden px-4" />
        </div>
      ) : (
        <span>loading</span>
      )}
    </>
  );
};

export default Checkout;
