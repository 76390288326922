import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={classes}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0699 12.3541L20.0699 12.3541C19.6478 12.7284 19.615 13.3743 19.9933 13.7897L19.9933 13.7898C21.3865 15.3196 21.508 17.6939 20.2211 19.3257C18.9518 20.9351 16.7067 21.3148 14.9694 20.2406L14.9694 20.2405C14.4745 19.9346 13.824 20.1089 13.5485 20.6214C12.5811 22.4206 10.4461 23.2136 8.54307 22.4548C6.61263 21.685 5.53074 19.5681 5.97237 17.5467C6.09234 16.9977 5.74079 16.4548 5.18823 16.3416L4.98762 17.3213L5.18823 16.3416C3.25863 15.9465 1.74905 14.1279 1.84599 11.9513L0.846976 11.9068L1.84599 11.9513C1.94253 9.78329 3.59056 8.17632 5.52785 8.01736L5.5279 8.01736C6.10184 7.97024 6.51907 7.45418 6.44742 6.88464C6.18734 4.81666 7.44965 2.8666 9.41179 2.34085C11.374 1.81508 13.4422 2.87273 14.2509 4.79369L14.251 4.79373C14.4737 5.32272 15.0931 5.56108 15.6137 5.31486L15.6137 5.31484C17.3706 4.48383 19.6017 5.05172 20.7692 6.88081L21.5953 6.35352L20.7692 6.88082C21.9413 8.71707 21.5438 11.0468 20.0699 12.3541Z"
      stroke={stroke}
      strokeWidth="2"
    />
    <circle cx="12" cy="12.5" r="2.5" stroke={stroke} strokeWidth="2" />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
