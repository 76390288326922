import { SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useEffect } from 'react';
import './InputCheckboxRadio.css';

const InputCheckboxRadio = ({
  checked,
  indeterminate,
  onChange,
  onKeyDown,
  name,
  id,
  labelClasses,
  inputClasses,
  internalLabelClasses,
  label,
  help,
  error,
  disabled,
  type,
  loading,
}) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (checkboxRef.current) checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <label
      htmlFor={id}
      className={`input-cr--label ${labelClasses} ${loading ? 'input-cr--label--loading' : ''} ${
        disabled ? 'disabled' : ''
      }`}>
      {loading ? (
        <span className="input-cr--loading">
          <SpinnerSmall />
        </span>
      ) : (
        <input
          ref={checkboxRef}
          id={id}
          name={name}
          className={`input-cr--input ${inputClasses} ${error ? 'input-cr--input__error' : ''}`}
          onChange={(e) => onChange(e)}
          onKeyDown={(e) => onKeyDown(e)}
          checked={checked}
          disabled={disabled}
          type={type}
        />
      )}
      <div className="input-cr--info">
        {label ? <h4 className={internalLabelClasses}>{label}</h4> : ''}
        {error ? <p className="input-text--error">{error}</p> : ''}
        {help ? <p>{help}</p> : ''}
      </div>
    </label>
  );
};

InputCheckboxRadio.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  internalLabelClasses: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  help: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
};

InputCheckboxRadio.defaultProps = {
  checked: false,
  indeterminate: false,
  onChange: () => {},
  onKeyDown: () => {},
  labelClasses: '',
  inputClasses: '',
  internalLabelClasses: '',
  label: '',
  help: '',
  disabled: false,
  error: '',
  type: 'checkbox',
  loading: false,
};

export default InputCheckboxRadio;
