import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={classes}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.66683 13.3333H5.00016C4.07969 13.3333 3.3335 12.5871 3.3335 11.6666V4.99992C3.3335 4.07944 4.07969 3.33325 5.00016 3.33325H11.6668C12.5873 3.33325 13.3335 4.07944 13.3335 4.99992V6.66658M8.3335 16.6666H15.0002C15.9206 16.6666 16.6668 15.9204 16.6668 14.9999V8.33325C16.6668 7.41278 15.9206 6.66658 15.0002 6.66658H8.3335C7.41302 6.66658 6.66683 7.41278 6.66683 8.33325V14.9999C6.66683 15.9204 7.41302 16.6666 8.3335 16.6666Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
