import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import InputText from 'components/styled/InputText';
import { ClickOutside } from 'hooks/common/events';
import './EditableInputSelect.css';
import { useKeyPress } from 'hooks/common/useKeyPress';
import { USE_KEY_PRESS_KEYS } from 'helpers/constants';

const { ARROW_DOWN, ARROW_UP, ENTER } = USE_KEY_PRESS_KEYS;

const EditableInputSelect = ({
  handleSelect,
  predictions,
  inputValue,
  handleInputValue,
  setPredictions,
  inputClasses,
  labelText,
  classes,
  error,
}) => {
  const [selected, setSelected] = useState(0);
  const { ref, isComponentVisible, setIsComponentVisible } = ClickOutside(false);

  const downPress = useKeyPress(ARROW_DOWN);
  const upPress = useKeyPress(ARROW_UP);
  const enterPress = useKeyPress(ENTER);

  const handlePredictionSelection = (prediction) => {
    handleSelect(prediction);
    setPredictions([]);
    setIsComponentVisible(true);
  };

  const handleInputBlur = () => {
    setIsComponentVisible(false);
  };

  useEffect(() => {
    if (predictions?.length > 0 && downPress) {
      setSelected((prevState) => (prevState < predictions?.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress]);
  useEffect(() => {
    if (predictions?.length > 0 && upPress) {
      setSelected((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);
  useEffect(() => {
    if (predictions?.length > 0 && enterPress) {
      handlePredictionSelection(predictions[selected]);
    }
  }, [selected, enterPress]);

  return (
    <div ref={ref} className={`editable-input-select ${classes}`}>
      <InputText
        labelText={labelText}
        className={`input-text--input ${inputClasses}`}
        value={inputValue}
        onChange={(e) => {
          if (!isComponentVisible) {
            setIsComponentVisible(true);
          }
          handleInputValue(e.target.value);
        }}
        onBlur={handleInputBlur}
        onFocus={() => setIsComponentVisible(true)}
        error={error}
      />
      {isComponentVisible && predictions?.length > 0 ? (
        <ul>
          {predictions?.map((prediction, i) => (
            <li
              className={`${selected === i ? 'selected' : ''}`}
              key={prediction.place_id}
              onMouseDown={(e) => {
                e.preventDefault();
                handlePredictionSelection(prediction);
              }}>
              {prediction.description}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

EditableInputSelect.propTypes = {
  inputClasses: PropTypes.string,
  classes: PropTypes.string,
  handleSelect: PropTypes.func,
  predictions: PropTypes.array,
  inputValue: PropTypes.string,
  handleInputValue: PropTypes.func,
  setPredictions: PropTypes.func,
  labelText: PropTypes.string,
  error: PropTypes.string,
};

EditableInputSelect.defaultProps = {
  inputClasses: '',
  classes: '',
  handleSelect: () => {},
  predictions: [],
  inputValue: undefined,
  handleInputValue: () => {},
  setPredictions: () => {},
  labelText: '',
  error: '',
};

export default EditableInputSelect;
