import { PAYMENT_GATEWAYS } from 'pages/storefront/Store/components/Account/SavedCards';
import { atom, selector } from 'recoil';
import { PRODUCT_LISTING_FORMATS } from 'helpers/constants';
import { priceListAtomFamily } from './storeState';

export const authAtom = atom({
  key: 'storefront_auth',
  default: {
    access: null,
    refresh: null,
    slug: null,
    accountId: null,
    userId: null,
  },
});

export const accountAtom = atom({
  key: 'storefront_account',
  default: {
    business_name: '',
    business_address: {
      city: '',
      province: '',
    },
    storefront_configuration: {
      store_open: false,
      store_name: '',
      store_closed_description: '',
      store_address: {
        formatted_address: '',
      },
      store_phone_number: '',
      store_email: '',
      website: '',
      twitter: '',
      facebook: '',
      instagram: '',
      about_title: '',
      about_description: '',
      display_out_of_stock: true,
      display_remaining_inventory: true,
      display_product_images: true,
      enable_guest_checkout: false,
      about_header_image: null,
      store_favicon_image: null,
      store_logo_image: null,
      custom_links: [],
      enable_about_us: true,
    },
    website: '',
    is_any_gateway_connected: false,
  },
});

export const customerAtom = atom({
  key: 'storefront_customer',
  default: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    store_credit_balance: 0,
    email_cc: [],
    price_list_membership: [],
  },
});

export const loginActiveAtom = atom({
  key: 'storefront_login',
  default: false,
});

export const privatePriceListAtom = atom({
  key: 'storefront_private_price_list',
  default: {},
});

export const priceListSubscriptionsEnabledAtom = atom({
  key: 'price_list_subscription_enabled',
  default: false,
});

export const gatewaysSelector = selector({
  key: 'gateways',
  get: ({ get }) => {
    const { connected_gateways } = get(accountAtom);
    let gatewaysData = PAYMENT_GATEWAYS.reduce((data, gateway) => {
      const filtered = connected_gateways?.find((item) => item?.gateway === gateway);
      if (filtered) return { ...data, [gateway]: filtered };
      return data;
    }, {});

    // Check if both 'local-pay' and 'payrix' are present and remove 'local-pay' if so
    if (gatewaysData['local-pay'] && gatewaysData['payrix']) {
      const { 'local-pay': _, ...rest } = gatewaysData;
      gatewaysData = rest;
    }

    return gatewaysData;
  },
});

// todo: remove with `storefront_upfront_fulfillment` flag

export const subscriptionsSelector = selector({
  key: 'subscriptions',
  get: ({ get }) => get(priceListSubscriptionsEnabledAtom) || false,
});
