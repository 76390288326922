import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button/Button';
import InputText from 'components/styled/InputText/InputText';

const CouponInput = ({ applyCouponCode }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');

  return (
    <div className="flex items-end mt-2">
      <InputText
        id="coupon-input"
        name="coupon-input"
        labelText={t('storefront/store/cart/coupon/coupon-input--label')}
        value={code}
        onChange={(e) => setCode((e.target.value || '').toUpperCase())}
      />
      <div className="ml-2">
        <Button onClick={() => applyCouponCode(code)}>
          {t('storefront/store/cart/coupon/apply-coupon--title')}
        </Button>
      </div>
    </div>
  );
};
CouponInput.propTypes = {};
export default CouponInput;
