import { Chevron } from 'assets/svgIcons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import theme from 'styles/theme';
import { SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { readPriceListProduct } from 'api/storefront/price-list-products';
import { accountAtom } from 'state/storefront/accountState';
import useApi from 'hooks/common/api';
import { useOrder } from 'hooks/storefront';
import MetaTags from 'components/MetaTags';
import Button from 'components/styled/Button';
import Gallery from 'components/styled/Gallery';
import ProductUnavailable from '../ProductsContainer/ProductUnavailable';
import Breadcrumbs from './Breadcrumbs';
import Details from './Details';
import ProductTabs from './ProductTabs';

const { transparent } = theme.colors;

const Product = ({ availableFulfillmentOptions }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { productId } = useParams();
  const { request: readPriceListProductRequest, loading } = useApi(readPriceListProduct);
  const { priceList: priceList } = useOrder();
  const displaySubscription = !!priceList?.subscription_settings_enabled;
  const account = useRecoilValue(accountAtom);
  const backgroundColor =
    account?.storefront_configuration?.custom_background_page_color ?? transparent;

  // states
  const [product, setProduct] = useState(undefined);
  const [available, setAvailable] = useState(true);
  const packages = displaySubscription
    ? product?.package_price_list_entries
    : product?.package_price_list_entries.filter(
        (p) =>
          p.packagepricelistentrysubscriptionsettings?.order_options ==
            SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME ||
          p.packagepricelistentrysubscriptionsettings?.order_options ==
            SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
          p.packagepricelistentrysubscriptionsettings == null // no packagepricelistentrysubscriptionsettings yet
      );

  // methods
  const fetchProduct = async () => {
    let resp = await readPriceListProductRequest(priceList?.id, productId);
    setAvailable(!!resp);
    setProduct(resp);
  };

  // effects
  useEffect(() => {
    if (!!priceList?.id) {
      // todo: this component should not render if the parent with the price list didn't
      fetchProduct();
    }
  }, [priceList]);

  if (loading) return <div className="store__product-container" />;
  else if (!available) return <ProductUnavailable />;

  const seoProps = product?.seo_tags;

  return (
    <div className="store__product-container" style={{ backgroundColor }}>
      <MetaTags {...seoProps} />
      <div className="store__product">
        <Breadcrumbs name={product?.name} />
        <div className="flex flex-col-reverse sm:flex-row w-full gap-4 sm:gap-6">
          <Details
            packages={packages}
            {...product}
            displayDiscount={priceList?.display_discount}
            availableFulfillmentOptions={availableFulfillmentOptions}
          />
          <div className="flex flex-col w-full sm:max-w-[50%]">
            <Gallery images={product?.images} />
            <ProductTabs
              description={product?.description}
              vendor={product?.vendor}
              classes="hidden sm:block"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
