import { useTranslation } from 'react-i18next';
import { SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import Button from 'components/styled/Button';

const CTAButton = ({
  loading,
  soldOut,
  cartProduct,
  quantityAddedToCartLabel,
  fulfillmentSupportsSubscriptions,
  selectedOrderOption,
  addPackage,
  fulfillment,
}) => {
  const { t } = useTranslation();

  return !!cartProduct ? (
    <span className="flex flex-col self-center text-Grey500 text-center">
      {t(quantityAddedToCartLabel, {
        n: cartProduct?.storefront_unit_quantity,
        packageName: cartProduct?.package_price_list_entry?.name,
      })}
    </span>
  ) : (
    <Button
      data-dd-action-name="add-product-to-cart"
      disabled={
        loading ||
        soldOut ||
        !!cartProduct ||
        (!fulfillmentSupportsSubscriptions &&
          selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION)
      }
      classes={`${
        !soldOut && 'button-green'
      } flex-grow flex flex-row w-full button--green items-center justify-center`}
      onClick={addPackage}>
      {soldOut
        ? t('storefront/store/shop/product--sold-out')
        : selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
        ? !fulfillmentSupportsSubscriptions &&
          selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
          ? t('storefront/product-details/add-subscription-to-cart-unsupported-fulfillment', {
              fulfillmentStrategyType: fulfillment?.type_display,
            })
          : t('storefront/product-details/add-subscription-to-cart')
        : t('storefront/store/shop/product--add-to-cart')}
    </Button>
  );
};

export default CTAButton;
