import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.6665 5H16.6665M6.6665 10H16.6665M6.6665 15H16.6665"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2.08317"
      y="9.58366"
      width="1.66667"
      height="0.833333"
      rx="0.416667"
      fill="#6B7280"
      stroke={stroke}
      strokeWidth="0.833333"
    />
    <rect
      x="2.08317"
      y="4.58366"
      width="1.66667"
      height="0.833333"
      rx="0.416667"
      fill="#6B7280"
      stroke={stroke}
      strokeWidth="0.833333"
    />
    <rect
      x="2.08317"
      y="14.5837"
      width="1.66667"
      height="0.833334"
      rx="0.416667"
      fill="#6B7280"
      stroke={stroke}
      strokeWidth="0.833334"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey500,
};

export default Svg;
