import { getMaxItemQuantity } from 'helpers/storefrontHelpers';
import InputPlusMinus from 'components/styled/InputPlusMinus';

const CartQuantityInput = ({
  id,
  name,
  loading,
  soldOut,
  cartProduct,
  setQuantity,
  quantity,
  track_inventory,
  available,
  max_units_per_order,
  debouncedSetOrder,
}) =>
  !!cartProduct && (
    <InputPlusMinus
      id={id}
      name={name}
      inputClasses="flex flex-row w-full justify-between"
      disabled={loading || soldOut}
      value={quantity}
      max={getMaxItemQuantity(track_inventory, available, max_units_per_order)}
      handleChange={(storefront_unit_quantity) => {
        setQuantity(storefront_unit_quantity);
        if (!!cartProduct) {
          if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
            debouncedSetOrder({
              order_entries: [{ id: cartProduct?.id, storefront_unit_quantity }],
            });
          }
        }
      }}
      min={0}
    />
  );

export default CartQuantityInput;
