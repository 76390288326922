import { Lock } from 'assets/svgIcons';
import { useTranslation } from 'react-i18next';

const GatewayHeading = ({ gatewayPaymentMethodName }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between mb-4">
        <div className="flex flex-col items-start">
          <h3 className="text-Grey600 mb-1">{t(`global/${gatewayPaymentMethodName}-details`)}</h3>
          <p>{t('Payment is safe, secure, and SSL encrypted.')}</p>
        </div>
        <Lock />
      </div>
    </>
  );
};

GatewayHeading.propTypes = {};

export default GatewayHeading;
