import { ChevronSmallUp } from 'assets/svgIcons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';

const CheckoutHeaderBack = () => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { push } = useHistory();
  const { priceList: priceListHook } = useOrder();

  //methods
  const navigateToShop = () => {
    if (!priceListSlug && !!priceListHook?.slug) {
      push(`/${priceListHook?.slug}`);
    } else {
      push(`/${priceListSlug}`);
    }
  };

  return (
    <div className="checkout__navigation mb-0">
      <div className="flex flex-col space-y-1">
        <a
          onClick={navigateToShop}
          className="flex items-center text-sm text-Grey500 cursor-pointer hover:text-Green600 self-start">
          <ChevronSmallUp classes="mr-2 transform -rotate-90" />
          {t('storefront/store/checkout--header-back')}
        </a>
        <span className="text-3xl font-bold text-Grey800 font-lora text-[28px] leading-10">
          {t('global/Checkout')}
        </span>
      </div>
    </div>
  );
};

export default CheckoutHeaderBack;
