import { Tab } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import './Tabs.css';

// https://headlessui.com/react/tabs

const Tabs = ({ tabs, classes, tabClasses, selectedIndex, setSelectedIndex }) => (
  <Tab.Group
    className={`tabs--group ${classes}`}
    onChange={setSelectedIndex}
    selectedIndex={selectedIndex}>
    <Tab.List>
      {tabs.map(({ id, name }) => (
        <Tab as={Fragment} key={id} id={id}>
          {({ selected }) => (
            <div
              className={`tab ${selected ? 'tab--selected' : 'hover:text-Grey700'} ${tabClasses}`}>
              {name}
            </div>
          )}
        </Tab>
      ))}
    </Tab.List>
  </Tab.Group>
);

Tabs.propTypes = {
  classes: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
  handleChange: PropTypes.func,
  tabClasses: PropTypes.string,
};

Tabs.defaultProps = {
  classes: '',
  tabs: [],
  handleChange: () => {},
  tabClasses: '',
};

export default Tabs;
