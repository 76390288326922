import { useAuth } from 'hooks/common/auth';
import { authAtom as storefrontAuthAtom } from 'state/storefront/accountState';
import { api as storefrontApi } from 'api/storefront';
import { authenticateAnonymously } from 'api/storefront/auth';

export const useStorefrontAuth = () => {
  const { auth, clearAuth, initializeAuth, setAuth } = useAuth(
    'storefront',
    storefrontAuthAtom,
    storefrontApi
  );

  // use base initialize, but auth storefront with anon if no auth exists
  const initializeStorefrontAuth = async () => {
    const { access } = initializeAuth();
    if (!access) {
      const resp = await authenticateAnonymously(); // if no existing auth, get anon auth
      setAuth(resp?.data);
      storefrontApi.defaults.headers.Authorization = `Bearer ${resp?.data?.access}`;
    }
  };

  return { auth, clearAuth, initializeAuth: initializeStorefrontAuth, setAuth };
};
