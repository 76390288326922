import React from 'react';
import Button from 'components/styled/Button/Button';
import PolkaDotBanner from 'components/styled/PolkaDotBanner/PolkaDotBanner';
import { useTranslation } from 'react-i18next';

const FulfillmentEmptyState = ({ editFulfillment }) => {
    const { t } = useTranslation();
    return (
        <div>
            <PolkaDotBanner
                classes="mt-4 text-center"
                message={<p>{t('storefront/checkout/fulfillment-summary/empty-state')}</p>}
            />
            <Button classes="button button--primary w-full" onClick={() => editFulfillment()}>{t('storefront/checkout/fulfillment-summary/empty-state-button')}</Button>
        </div>
    );
};

export default FulfillmentEmptyState;