import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { FULFILLMENT_AVAILABILITY_TYPES, ORDER_STATUSES, ACTIVE } from 'helpers/constants';
import { SUBSCRIPTION_STATUSES, CLEAR_CART_AFTER_N_DAYS } from 'helpers/constants';
import { collapseObjectToValue, formatProblemJSONErrors } from 'helpers/helpers';
import { patchOrder, createOrder, openOrder as openOrderApi } from 'api/storefront/orders';
import { readOrder } from 'api/storefront/orders';
import { readPriceList } from 'api/storefront/price-lists';
import {
  cartAtom,
  fulfillmentWizardActiveAtom,
  pendingOrderEntriesAtom,
} from 'state/storefront/storeState';
import useApi from 'hooks/common/api';
import { useToast } from 'hooks/common/toast';
import useAsyncQueue from 'hooks/common/useAsyncQueue';

const useOrderV2 = () => {
  const { t } = useTranslation();
  const setToast = useToast('left');
  const [cartState, setCartState] = useRecoilState(cartAtom);
  const resetOrder = useResetRecoilState(cartAtom);
  const { request: priceListRequest } = useApi(readPriceList, true);
  const { request: getOrderRequest } = useApi(readOrder);
  const [_, setFulfillmentWizardActive] = useRecoilState(fulfillmentWizardActiveAtom);
  const [pendingOrderEntries, setPendingOrderEntries] = useRecoilState(pendingOrderEntriesAtom);
  const { push } = useHistory();
  const { priceListSlug } = useParams();
  const [errors, setErrors] = useState({});

  const { enqueueUpdate } = useAsyncQueue();

  const _setLoading = (_loading) =>
    setCartState((prevState) => ({ ...prevState, loading: _loading }));

  // establish for which price list this order is for first.
  const initializeOrder = async (priceList, _order) => {
    _setLoading(true);
    _setPriceListState(priceList);
    let order = !!_order?.id ? _order : await getExistingOrCreateOrder(priceList);

    if (_validateOrder(order)) {
      _setOrderState({ ...order });
    } else {
      clearCart(priceList);
      order = await getExistingOrCreateOrder(priceList);
      _setOrderState({ ...order });
    }
    _setLoading(false);
  };

  const _validateOrder = (order) => {
    if (!_validateSubscriptionOrder(order)) {
      console.warn('Subscription Order skipped', order?.id);
      return false;
    }
    if (!_validateOrderIsNotExpired(order)) {
      {
        console.warn('Cart is expired', order?.id);
        return false;
      }
    }
    return true;
  };

  const _validateSubscriptionOrder = (order) => {
    const subscription_association = order?.subscription_association;

    if (
      subscription_association &&
      subscription_association.status === SUBSCRIPTION_STATUSES.SKIPPED
    ) {
      return false;
    }
    return true;
  };

  const _validateOrderIsNotExpired = (order) => {
    if (order?.is_subscription) {
      return true;
    }
    const now = moment();
    const cartExpiry = moment(order?.created_at).add(CLEAR_CART_AFTER_N_DAYS, 'days');
    return now < cartExpiry;
  };

  const clearCart = (priceList) => {
    if (!!priceList?.id) {
      localStorage.removeItem(`${priceList.id}-${priceList.slug}`);
    } else {
      localStorage.removeItem(`${cartState?.priceList?.id}-${cartState?.priceList?.slug}`);
    }
    resetOrder();
  };

  const getExistingOrCreateOrder = async (priceList, orderChanges) => {
    const priceListStorageId = `${priceList?.id}-${priceList?.slug}`;
    const existingId = localStorage.getItem(priceListStorageId);
    try {
      const { data } = existingId
        ? await patchOrder(existingId, { ...orderChanges })
        : await createOrder({ price_list: priceList?.id, ...orderChanges });
      if (!existingId && data?.id) localStorage.setItem(priceListStorageId, data?.id);

      return data;
    } catch (err) {
      if (
        err?.response?.status === 404 ||
        err?.response?.status === 500 ||
        err?.response?.status === 400
      ) {
        clearCart(priceList);
      }
    } finally {
      _setLoading(false);
    }
  };

  const getExistingOrderByOrderId = async (orderId) => {
    try {
      const _order = await getOrderRequest(orderId);

      return _order;
    } catch (err) {
      if (
        err?.response?.status === 404 ||
        err?.response?.status === 500 ||
        err?.response?.status === 400
      ) {
        clearCart(priceList);
      }
    } finally {
      _setLoading(false);
    }
  };

  const deleteOrder = async () => {
    // TODO: Delete order from localStorage by priceListStorageId
    // TODO: Patch status on API
  };

  const switchOrder = async (orderId, redirect = true) => {
    const _order = await getExistingOrderByOrderId(orderId);
    if (_order?.status !== ORDER_STATUSES.DRAFT) return;
    const _price_list = await fetchPriceList(_order?.price_list?.slug);

    const priceListStorageId = `${_price_list?.id}-${_price_list?.slug}`;

    localStorage.setItem(priceListStorageId, _order?.id);
    setSelectedOrderTab(0);
    if (redirect) push(`/${_price_list?.slug}`);
    initializeOrder(_price_list, _order);
    setFulfillmentWizardActive(false);
  };

  const _createOrder = async (changes) => {
    try {
      return await createOrder({ ...changes, price_list: cartState.order.price_list });
    } catch (err) {
      const { response } = err;
      setErrors((prevState) => ({
        ...prevState,
        ...formatProblemJSONErrors(response?.data),
      }));
      throw new Error(err);
    }
  };

  const _updateOrder = async (changes) => {
    try {
      _setLoading(true);
      return await patchOrder(cartState?.order?.id, changes);
    } catch (err) {
      const { response } = err;
      setToast(response?.data?.detail, 'error');
      const formattedErrors = formatProblemJSONErrors(response?.data);
      setErrors((prevState) => ({
        ...prevState,
        ...formattedErrors,
      }));
      throw new Error(JSON.stringify(formattedErrors));
    } finally {
      _setLoading(false);
    }
  };

  const _setOrderState = (order) => {
    setCartState((prevState) => ({
      ...prevState,
      order: { ...prevState.order, ...order },
    }));
  };

  const _setPriceListState = (priceList) => {
    setCartState((prevState) => ({
      ...prevState,
      priceList,
    }));
  };

  const _setCartActiveState = (active) => {
    setCartState((prevState) => ({
      ...prevState,
      active,
    }));
  };

  const _clearErrors = () => {
    setErrors({});
  };

  const _indicateSuccessfulSubscriptionModification = () => {
    if (hasActiveSubscription) setToast(t('storefront/cart/edit--success'));
  };

  const setSelectedOrderTab = (_selectedOrderTab) =>
    setCartState((prevState) => ({
      ...prevState,
      selectedOrderTab: _selectedOrderTab,
    }));

  const fetchPriceList = async (slug) =>
    await priceListRequest(slug, { expand: 'price_list_order_subscription_settings' });

  const _initialized = async () => {
    // todo: should we just let the 400 handle this?
    if (!order.price_list && !order?.price_list?.id) {
      console.error('Order must be initialized with Price List Id', priceListSlug);
      if (priceListSlug) {
        const response = await fetchPriceList(priceListSlug);
        await initializeOrder(response);
      }
    }
  };

  const { order, priceList, loading, selectedOrderTab } = cartState; // todo: just spread into the return obj

  const _hasFulfillmentStrategy = !!order?.fulfillment?.fulfillment_strategy;
  const _availabilityCriteriaMet =
    order?.fulfillment?.availability_type === FULFILLMENT_AVAILABILITY_TYPES.FLEXIBLE
      ? true
      : !!order?.fulfillment?.fulfillment_date;
  const isFulfillmentComplete = _hasFulfillmentStrategy && _availabilityCriteriaMet;

  const _orderChangesBeforeFulfillmentDefined = (changes) =>
    !isFulfillmentComplete && !changes.fulfillment && !changes.payment;

  const totalUnitQuantity = order?.order_entries?.reduce(
    (a, b) =>
      a + (b?.storefront_unit_quantity > 0 ? b?.storefront_unit_quantity : b?.inventory_quantity),
    0
  );
  const hasActiveSubscription = order?.subscription_plan?.status == ACTIVE;
  const hasPendingOrderEntries = pendingOrderEntries?.length || 0;
  const hasOrderEntries = (order?.order_entries?.length || 0) > 0;
  const hasStartedShopping = hasPendingOrderEntries || hasOrderEntries;

  const _appendPendingOrderEntries = (orderEntries, subscriptionsAllowed) => {
    if (hasPendingOrderEntries === 0) return orderEntries;
    setPendingOrderEntries([]);
    return [
      ...orderEntries,
      ...(subscriptionsAllowed
        ? pendingOrderEntries
        : pendingOrderEntries.filter((entry) => !entry.is_subscription)),
    ];
  };

  const _handleOrderStateFulfillment = (changes) => {
    if (!changes?.fulfillment?.fulfillment_strategy) return changes;
    const { fulfillment_strategy, ...fulfillmentChanges } = changes?.fulfillment;
    return {
      ...changes,
      fulfillment: {
        ...fulfillmentChanges,
        fulfillment_strategy: collapseObjectToValue(fulfillment_strategy),
      },
      order_entries: _appendPendingOrderEntries(
        changes?.order_entries || [],
        fulfillment_strategy?.subscription_enabled
      ),
    };
  };

  const setOrder = async (changes) => {
    return await enqueueUpdate(async () => {
      await _initialized();

      if (_orderChangesBeforeFulfillmentDefined(changes)) {
        _setCartActiveState(true);
        setFulfillmentWizardActive(true);
        setPendingOrderEntries(changes?.order_entries || []);
        return null;
      }

      const orderPayload = _handleOrderStateFulfillment(changes);
      const response = !!cartState?.order?.id
        ? await _updateOrder(orderPayload)
        : await _createOrder(orderPayload);
      _setOrderState(response?.data);
      _indicateSuccessfulSubscriptionModification();
      _clearErrors();
      return response;
    });
  };

  const openOrder = async (options) => await openOrderApi(cartState.order?.id, options);

  const orderEntriesErrors =
    order?.order_entries?.filter((entry) => entry.error !== null).length > 0;

  const isOrderActive = order?.order_entries?.length > 0;

  const quickOpenOrder = async (options) => {
    try {
      await openOrderApi(cartState.order?.id, options);
    } catch (err) {
      const { response } = err;
      const formattedErrors = formatProblemJSONErrors(response?.data);
      setErrors((prevState) => ({
        ...prevState,
        ...formattedErrors,
      }));
      throw new Error();
    }
  };

  return {
    order,
    priceList,
    loading,
    fieldErrors: errors, // backwards compatibility
    setOrder,
    resetOrder,
    openOrder,
    initializeOrder,
    orderEntriesErrors,
    totalUnitQuantity,
    isFulfillmentComplete,
    errors,
    setErrors,
    selectedOrderTab,
    setSelectedOrderTab,
    clearCart,
    switchOrder,
    hasStartedShopping,
    isOrderActive,
    quickOpenOrder,
    isFulfillmentComplete,
  };
};

export const useOrder = (priceListSlug) => useOrderV2();
