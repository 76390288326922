import moment from 'moment';
import {
  SUBSCRIPTION_ORDER_OPTIONS,
  DAY,
  WEEK,
  MONTHLY_BY_DATE,
  MONTHLY_BY_WEEKDAY_OCCURRENCE,
} from './constants';
import { DAY_OF_WEEK } from './localeHelpers';

export const getProductSubscriptionOptions = (packages) => {
  const hasSubscription = packages?.some(
    (package_) =>
      package_.packagepricelistentrysubscriptionsettings?.order_options ===
        SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION ||
      package_.packagepricelistentrysubscriptionsettings?.order_options ===
        SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION
  );

  const hasSubscriptionsOnly = packages?.every(
    (package_) =>
      package_.packagepricelistentrysubscriptionsettings?.order_options ===
      SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
  );

  if (hasSubscription) {
    return hasSubscriptionsOnly
      ? SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
      : SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION;
  }

  return SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME;
};

export const frequencyLabelTranslationMapping = {
  [WEEK]: 'storefront/checkout/subscription-frequency--option--on-day',
  [DAY]: 'storefront/checkout/subscription-frequency--option--day',
  [MONTHLY_BY_DATE]: 'storefront/checkout/subscription-frequency--option--monthly_by_date',
  [MONTHLY_BY_WEEKDAY_OCCURRENCE]:
    'storefront/checkout/subscription-frequency--option--monthly_by_weekday_occurrence',
};

export const getDayOfWeek = (date) => {
  return moment(date).format(DAY_OF_WEEK);
};
