import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footnotes = () => {
  const { t } = useTranslation();

  return (
    <div className="flex my-4 mx-4 md:mx-0">
      <Link
        className="flex-1 text-sm text-Grey400"
        to={{ pathname: 'https://localline.ca/' }}
        target="_blank"
        rel="nofollow">
        {t('Powered by Local Line')}
      </Link>
      <Link
        className="text-sm text-Grey400"
        target="_blank"
        to={{ pathname: t('register/details/terms--links--privacy-policy') }}>
        {t('Privacy')}
      </Link>
      <Link
        className="text-sm text-Grey400 pl-4"
        target="_blank"
        to={{ pathname: t('register/details/terms--links--service') }}>
        {t('Terms')}
      </Link>
    </div>
  );
};

export default Footnotes;
