import { SpinnerSmall } from 'assets/svgIcons';
import { InfoCircleFilled } from 'assets/svgIcons/index';
import { TagMd } from 'assets/svgIcons/index';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import theme from 'styles/theme';
import { FULFILLMENT_TYPES } from 'helpers/constants';
import { roundDecimal } from 'helpers/helpers';
import { formatMoney } from 'helpers/localeHelpers';
import { currencyAtom } from 'state/common/currency';
import { customerAtom } from 'state/storefront/accountState';
import Alert from 'components/styled/Alert/Alert';
import Money from 'components/styled/Money';
import PaymentFees from './PaymentFees';
import SummaryItem from './SummaryItem';
import { useLocation } from 'react-router-dom';
import CartCheckoutPage from './CartCheckoutPage'

const CartTotals = (props) => {
  const {
    loading,
    subtotal,
    tax,
    total,
    subtotal_without_subscription_discount,
    subscription_discount_applied,
    coupon,
    coupon_discount_applied,
    payment,
    fulfillment_fees,
    fulfillment_tax,
    fulfillment,
    is_subscription,
    price_list,
    showStoreCreditAlert,
    subscription_association
  } = props

  const { store_credit_balance } = useRecoilValue(customerAtom) || 0;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const subscriptionDiscountValue = price_list?.price_list_order_subscription_settings?.discount_value;
  const isSubscriptionActive = subscription_association?.status === 'active';
  const isCheckoutForm = pathname.includes('/checkout')

  const currency = useRecoilValue(currencyAtom);
  const storeCreditValue = formatMoney(Number(store_credit_balance || 0), currency)

  if (isCheckoutForm) return <CartCheckoutPage {...props} />

  return (
    <div className="flex flex-col space-y-2">
      <SummaryItem
        label={t('storefront/store/cart--subtotal')}
        value={subtotal_without_subscription_discount || subtotal}
        loading={loading}
      />
      {is_subscription && subscription_discount_applied != 0 && (
        <SummaryItem
          label={`${t('storefront/store/cart--subscription-discount')} · ${roundDecimal(
            subscriptionDiscountValue,
            1
          )}%`}
          value={-subscription_discount_applied}
          loading={loading}
        />
      )}
      <SummaryItem label={t('storefront/store/cart--tax')} value={tax} loading={loading} />

      {coupon_discount_applied != 0 && (
        <div className="flex justify-between items-center mb-3">
          <span className="text-Grey500 inline-flex">
            <TagMd />
            {coupon?.code}
          </span>

          <span className="text-Grey800 flex">
            {loading ? (
              <SpinnerSmall classes="mr-1" />
            ) : (
              <Money value={-coupon_discount_applied} currency={currency} />
            )}
          </span>
        </div>
      )}

      {fulfillment_fees > 0 && (
        <SummaryItem
          label={
            fulfillment?.fulfillment_strategy_type === FULFILLMENT_TYPES.PICKUP
              ? t('storefront/store/cart--pickup-fee')
              : t('storefront/store/cart--delivery-fee')
          }
          value={fulfillment_fees}
          loading={loading}
        />
      )}

      {fulfillment_tax > 0 && (
        <SummaryItem
          label={
            fulfillment?.fulfillment_strategy_type === FULFILLMENT_TYPES.PICKUP
              ? t('storefront/store/cart--pickup-fee-tax')
              : t('storefront/store/cart--delivery-fee-tax')
          }
          value={fulfillment_tax}
          loading={loading}
        />
      )}

      <PaymentFees payment={payment} loading={loading} />

      {!!payment?.payment_strategy_amount && (
        <SummaryItem
          label={t('storefront/store/cart--payment')}
          value={-payment.payment_strategy_amount}
          loading={loading}
        />
      )}

      <SummaryItem
        label={t('storefront/store/cart--total')}
        value={total}
        loading={loading}
        highlight
      />

      {!!showStoreCreditAlert && store_credit_balance > 0 && !isSubscriptionActive && (
        <Alert
          id="store-credit-applied"
          classes="mb-3 py-1 font-medium"
          CustomIcon={() => <InfoCircleFilled fill={theme.colors.Green400} />}
          description={
            <div className="text-sm text-Green700 font-medium">
              {t('storefront/cart/current-order/new-store-credit-applied-at-checkout', {
                amount: storeCreditValue,
              })}
            </div>
          }
          inline
        />
      )}
    </div>
  );
};

CartTotals.propTypes = {
  loading: PropTypes.bool,
  subtotal: PropTypes.number,
  tax: PropTypes.number,
  total: PropTypes.number,
  subtotal_without_subscription_discount: PropTypes.number,
  subscription_discount_applied: PropTypes.number,
  payment: PropTypes.object,
  fulfillment_fees: PropTypes.number,
  fulfillment_tax: PropTypes.number,
  fulfillment: PropTypes.object,
  order_entries: PropTypes.array,
  is_subscription: PropTypes.bool,
  price_list: PropTypes.object,
  showStoreCreditAlert: PropTypes.bool,
};

CartTotals.defaultProps = {
  loading: false,
  subtotal: 0,
  tax: 0,
  total: 0,
  subtotal_without_subscription_discount: 0,
  subscription_discount_applied: 0,
  payment: {},
  fulfillment_fees: 0,
  fulfillment_tax: 0,
  fulfillment: {},
  order_entries: [],
  is_subscription: false,
  price_list: {},
  showStoreCreditAlert: false,
};

export default CartTotals;
