import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ fill, classes }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM9.39365 3.98715C9.50687 3.44659 10.0369 3.10016 10.5774 3.21338C11.6502 3.43808 12.6301 4.10263 13.2225 5.12867C14.3271 7.04184 13.6716 9.4882 11.7584 10.5928L12.2584 11.4588C12.4644 11.8156 12.1747 12.2545 11.7656 12.2052L8.4562 11.8065C8.12526 11.7666 7.92526 11.4202 8.0562 11.1137L9.3656 8.04828C9.52745 7.66938 10.0524 7.63787 10.2584 7.99469L10.7584 8.86072C11.715 8.30843 12.0428 7.08525 11.4905 6.12867C11.1943 5.61577 10.707 5.28391 10.1674 5.17091C9.62686 5.05769 9.28044 4.5277 9.39365 3.98715ZM6.57167 12.0495C6.45845 12.59 5.92847 12.9365 5.38791 12.8233C4.3151 12.5986 3.33518 11.934 2.7428 10.908C1.63823 8.9948 2.29374 6.54844 4.20691 5.44387L3.70691 4.57784C3.5009 4.22102 3.79066 3.78215 4.19973 3.83143L7.50913 4.23015C7.84007 4.27002 8.04007 4.61643 7.90913 4.92297L6.59973 7.98836C6.43788 8.36726 5.91292 8.39877 5.70691 8.04195L5.20691 7.17592C4.25032 7.7282 3.92257 8.95138 4.47485 9.90797C4.77097 10.4209 5.25835 10.7527 5.7979 10.8657C6.33846 10.9789 6.68489 11.5089 6.57167 12.0495Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Green600,
  classes: '',
};

export default Svg;
