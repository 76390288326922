import { api } from '.';

export const readPriceList = (slug, params) => {
  return api.get(`price-lists/${slug}`, { params });
};

export const readPriceListFulfillmentStrategies = async (priceListId, params) =>
  api.get(`/price-lists/${priceListId}/fulfillment-strategies/`, { params });

export const readPriceListPaymentStrategies = async (priceListId, params) =>
  api.get(`/price-lists/${priceListId}/payment-strategies/`, { params });

export const privatePriceListRequestAccess = (slug, data) => {
  return api.post(`price-lists/${slug}/request-access`, data);
};

export const createPriceListMembership = async (priceListId, data) =>
  api.post(`/price-lists/${priceListId}/membership/`, data);

export const subscribe = async (priceListId, data) =>
  api.post(`/price-lists/${priceListId}/subscribe`, data);

export const unsubscribe = async (priceListId, data) =>
  api.post(`/price-lists/${priceListId}/unsubscribe`, data);

export const readPriceLists = (params = {}) =>
  api.get(`price-lists/`, {
    params: {
      fields: 'id,name,description,default,slug,active,public,price_list_membership',
      ...params,
    },
  });
