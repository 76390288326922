import { CheckCircleFilled, SpinnerSmall, XCircleFilled } from 'assets/svgIcons';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import theme from 'styles/theme';
import { TIMEOUTS, KEY_CODES } from 'helpers/constants';
import { toastAtom } from 'state/appState';
import './Toast.css';

const { Green400, Blue400, Yellow400, Red400 } = theme.colors;
const Toast = () => {
  const [toasts, setToasts] = useRecoilState(toastAtom);

  const deleteToast = (toast) => {
    const toastDiv = document.getElementById(`toast-${toast.id}`);
    if (toastDiv) toastDiv.classList.add('slide-right');
    const { id } = toast;
    setTimeout(
      () => setToasts((prevState) => [...prevState].filter((item) => item.id !== id)),
      TIMEOUTS.TIMEOUT_400
    );
  };

  const setTimer = (toast) => {
    const newToast = JSON.parse(JSON.stringify(toast));
    newToast.active = true;
    const newToasts = [...toasts].filter((item) => item.id !== toast.id);
    setTimeout(() => {
      if (toasts.length) {
        deleteToast(toast);
      }
    }, newToast.timer || TIMEOUTS.TIMEOUT_3000);
    setToasts([...newToasts, newToast]);
  };

  useEffect(() => {
    toasts.forEach((toast) => {
      if (!toast.active) setTimer(toast);
    });
  }, [toasts]);

  return (
    <div className="toasts">
      {toasts.map(({ id, type, loading, message, direction }) => {
        let color = Green400;
        let Icon = <CheckCircleFilled fill={color} />;
        if (type === 'info') {
          color = Blue400;
          Icon = <CheckCircleFilled fill={color} />;
        } else if (type === 'warning') {
          color = Yellow400;
          Icon = <CheckCircleFilled fill={color} />;
        } else if (type === 'error') {
          color = Red400;
          Icon = <XCircleFilled fill={color} />;
        }
        const toastDirection = direction || 'right';
        return (
          <div key={id} id={`toast-${id}`} className={`toast toast-direction-${toastDirection}`}>
            <div
              role="button"
              tabIndex={0}
              onClick={() => deleteToast({ id })}
              onKeyDown={(e) => e.keyCode === KEY_CODES.ESCAPE && deleteToast({ id })}
              className={`toast-message ${type}`}>
              {loading ? <SpinnerSmall classes="w-6 h-6" stroke={color} /> : Icon}
              <p>{message}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Toast;
