import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="20"
    className={`${classes}`}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289L15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071C9.90238 14.3166 9.90238 13.6834 10.2929 13.2929L12.5858 11H5C4.44772 11 4 10.5523 4 10C4 9.44772 4.44772 9 5 9H12.5858L10.2929 6.70711C9.90238 6.31658 9.90238 5.68342 10.2929 5.29289Z"
      fill={stroke}
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey300,
  classes: '',
};

export default Svg;
