import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { accountAtom } from 'state/storefront/accountState';
import { STORE_CREDITS } from 'state/backoffice/featureSelectors';
import './Account.css';
import ProtectedCustomerRoute from 'components/ProtectedCustomerRoute';
import SubscriptionHistory from './SubscriptionHistory';

import NavigationTabs from 'components/styled/NavigationTabs';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';

const OrderHistory = lazy(() => import('./OrderHistory'));
const AccountSettings = lazy(() => import('./AccountSettings'));
const SavedCards = lazy(() => import('./SavedCards'));
const StoreCredit = lazy(() => import('./StoreCredit'));
const PriceLists = lazy(() => import('./PriceLists'));

const Account = () => {
  const { t } = useTranslation();
  const { connected_gateways, available_features } = useRecoilValue(accountAtom);
  const storeCreditsFeatureEnabled = available_features?.find(
    (feature) => feature?.name === STORE_CREDITS
  )?.enabled;
  const storefrontSubscriptionsEnabled = useTreatment('storefront_subscriptions') === ON;

  const navigationRoutes = [
    { name: t('Order History'), path: '/order-history', Component: OrderHistory },
    { name: t('Account Settings'), path: '/account-settings', Component: AccountSettings },
    { name: t('Price Lists'), path: '/price-lists', Component: PriceLists },
  ];

  if (storeCreditsFeatureEnabled)
    navigationRoutes.push({
      name: t('Store Credit'),
      path: '/store-credit',
      Component: StoreCredit,
    });

  if (storefrontSubscriptionsEnabled)
    navigationRoutes.splice(1, 0, {
      name: t('Subscriptions'),
      path: '/subscription-history',
      Component: SubscriptionHistory,
    });

  if (connected_gateways?.length >= 1)
    navigationRoutes.push({ name: t('Saved Cards'), path: '/saved-cards', Component: SavedCards });

  return (
    <Suspense fallback={<span>loading</span>}>
      <section className="store--account">
        <NavigationTabs classes="pt-12 mb-6" parentPath="/account" routes={navigationRoutes} />
        <Switch>
          <ProtectedCustomerRoute path="/account/order-history">
            <OrderHistory />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account/subscription-history">
            <SubscriptionHistory />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account/account-settings">
            <AccountSettings />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account/saved-cards">
            <SavedCards />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account/store-credit">
            <StoreCredit />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account/price-lists">
            <PriceLists />
          </ProtectedCustomerRoute>
          <ProtectedCustomerRoute path="/account">
            <Redirect to="/account/order-history" />
          </ProtectedCustomerRoute>
        </Switch>
      </section>
    </Suspense>
  );
};

Account.propTypes = {};

Account.defaultProps = {};

export default Account;
