import PropTypes from 'prop-types';

import { CircularLoadingBar } from 'assets/svgIcons';
import Button from 'components/styled/Button';
import Card from 'components/styled/Card';
import PolkaDotBanner from 'components/styled/PolkaDotBanner';
import { capitalize } from 'helpers/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCAL_PAY } from '.';
import AddCardModal from './AddCardModal';
import SingleSavedCard from './SingleSavedCard';

const SavedCardGateway = ({
  gateway,
  gatewaySavedCards,
  gatewayKey,
  gatewayName,
  isSingleGatway,
  loading,
  handleDeleteCard,
  handleAddCard,
  gatewayPaymentMethods,
}) => {
  const { t } = useTranslation();
  const [addCardModalEnabled, setAddCardModalEnabled] = useState(false);

  const mainTitle = t('storefront/my-account/saved-payments/main-title');
  const gatewayTitle = t('storefront/my-account/saved-payments/gateway', {
    gateway: gatewayName,
  });

  const title = isSingleGatway ? mainTitle : `${mainTitle} · ${gatewayTitle}`;

  const emptyMessage = t('storefront/my-account/saved-payments/empty-message');

  return (
    <>
      {addCardModalEnabled && (
        <AddCardModal
          gateway={gateway}
          closeModal={async () => {
            setAddCardModalEnabled(false);
          }}
          gatewayKey={gatewayKey}
          handleAddCard={handleAddCard}
          gatewayPaymentMethods={gatewayPaymentMethods}
        />
      )}

      <Card title={title} classes="mb-6">
        <hr className="mb-3" />
        {gatewaySavedCards?.length > 0 ? (
          gatewaySavedCards?.map((card) => (
            <SingleSavedCard
              key={`${gateway}-${card?.source_id}`}
              gateway={gateway}
              {...card}
              handleDeleteCard={handleDeleteCard}
            />
          ))
        ) : (
          <PolkaDotBanner
            classes="rounded-none border-0 border-b"
            message={
              <div className="max-w-xs text-center flex flex-col justify-center items-center">
                {loading ? <CircularLoadingBar /> : <p>{emptyMessage}</p>}
              </div>
            }
          />
        )}
        <Button classes="button self-end" onClick={() => setAddCardModalEnabled(true)}>
          {t('storefront/my-account/saved-payments/add-payment')}
        </Button>
      </Card>
    </>
  );
};

SavedCardGateway.propTypes = {
  gateway: PropTypes.string,
  gatewaySavedCards: PropTypes.array,
  gatewayKey: PropTypes.string,
  gatewayName: PropTypes.string,
  isSingleGatway: PropTypes.bool,
  loading: PropTypes.bool,
  handleDeleteCard: PropTypes.func,
  handleAddCard: PropTypes.func,
};

SavedCardGateway.defaultProps = {
  gateway: '',
  gatewaySavedCards: [],
  gatewayKey: '',
  gatewayName: '',
  isSingleGatway: false,
  loading: false,
  handleDeleteCard: () => {},
  handleAddCard: () => {},
};

export default SavedCardGateway;
