import PropTypes from 'prop-types';
import Button from 'components/styled/Button';
import { useTranslation } from 'react-i18next';
import { SpinnerSmall } from 'assets/svgIcons';
import ModalPortal from 'components/styled/ModalPortal/ModalPortal';

const CancelSubscriptionModal = ({ open, onClose, onConfirmCancel, loading }) => {
  const { t } = useTranslation();

  const cancelButtonContent = loading ? (
    <SpinnerSmall width={20} height={20} />
  ) : (
    t('storefront/modals/cancel-subscription-modal--cta')
  );

  if (!open) return null;

  return (
    <ModalPortal
      title={t('storefront/modals/cancel-subscription-modal--title')}
      description={t('storefront/modals/cancel-subscription-modal--desc')}
      handleClose={onClose}
      target="cancel-subscription">
      <div className="modal--actions">
        <Button classes="button button--white" onClick={onClose}>
          {t('Close')}
        </Button>
        <Button classes="button button--danger" onClick={onConfirmCancel} disabled={loading}>
          {cancelButtonContent}
        </Button>
      </div>
    </ModalPortal>
  );
};

CancelSubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CancelSubscriptionModal.defaultProps = {
  loading: false,
};

export default CancelSubscriptionModal;
