import { useTreatments } from '@splitsoftware/splitio-react';
import { OFF } from 'helpers/constants';

const useTreatment = (splitName) => {
  const treatments = useTreatments([splitName]);
  if (splitName in treatments) return treatments[splitName]?.treatment;
  return OFF;
};

export default useTreatment;
