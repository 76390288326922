import { SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ON } from 'helpers/constants';
import { SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { PACKAGES_NEW_ON_SALE_TAG } from 'helpers/featureFlags';
import { debounce, getAvailableInventoryCount } from 'helpers/helpers';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { formatMoney } from 'helpers/localeHelpers';
import { getMaxItemQuantity } from 'helpers/storefrontHelpers';
import { toastAtom } from 'state/appState';
import { currencyAtom } from 'state/common/currency';
import { subscriptionsSelector } from 'state/storefront/accountState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button';
import InputPlusMinus from 'components/styled/InputPlusMinus';
import Money from 'components/styled/Money';
import InventoryCounter from '../CommonView/InventoryCounter';

const PackageCardListView = ({
  id,
  name,
  productName,
  package_price,
  inventory,
  inventory_per_unit,
  track_inventory,
  base_unit,
  charge_unit,
  is_by_weight,
  is_package_weight,
  is_weight_package,
  average_pack_weight,
  unit_price,
  original_package_price,
  packagepricelistentrysubscriptionsettings,
  displayDiscount,
  displayDetails,
  setErrors,
  on_sale,
  on_sale_toggle,
  displayRemainingInventory,
  max_units_per_order,
  strikethrough_display_value,
}) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { order, setOrder, loading } = useOrder(priceListSlug);
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);

  const orderOptions = packagepricelistentrysubscriptionsettings?.order_options;

  const allowOneTimePurchase =
    !orderOptions ||
    orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
    orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME;

  // todo: InputCheckboxRadio
  const [selectedOrderOption, setSelectedOrderOption] = useState(
    allowOneTimePurchase // default to ONE_TIME
      ? SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME
      : SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
  );

  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;

  const [quantity, setQuantity] = useState(1);
  const [loadingAddProduct, setLoadingAddProduct] = useState(false);

  const currency = useRecoilValue(currencyAtom);
  const setToasts = useSetRecoilState(toastAtom);

  const available = getAvailableInventoryCount(inventory, inventory_per_unit);
  const soldOut = track_inventory && !available;
  const cartProduct = order?.order_entries.find(
    (item) => id === item?.package_price_list_entry?.id
  );

  const unitName = is_by_weight
    ? is_package_weight && !is_weight_package
      ? charge_unit
      : base_unit
    : charge_unit;

  // methods
  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(cartProduct?.storefront_unit_quantity);
      }
    }, 400),
    [cartProduct?.storefront_unit_quantity, order?.fulfillment, order?.fulfillment?.id]
  ); // wait 400ms for new input before calling update

  const addPackage = async (e) => {
    e.stopPropagation();
    const toastId = generateRandomNumber();

    try {
      setLoadingAddProduct(true);
      const entry = !!cartProduct
        ? {
            id: cartProduct?.id,
            storefront_unit_quantity: cartProduct?.storefront_unit_quantity + quantity,
          }
        : {
            package_price_list_entry: id,
            storefront_unit_quantity: 1,
            is_subscription: selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION,
          };

      await setOrder({ order_entries: [entry] });
      if (!upfrontFulfillmentEnabled) {
        setToasts((prevState) => [
          ...prevState,
          {
            id: toastId,
            timer: 3000,
            message: t('storefront/store/shop/product--added-to-cart', { productName }),
            type: 'success',
            direction: 'left',
          },
        ]);
      }
    } catch (err) {
      console.error('Error setting product: ', e);
      setErrors((prevState) => [...prevState, err.message]);
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('storefront/store/shop/product--added-to-cart--error', { productName }),
          type: 'error',
          direction: 'left',
        },
      ]);
    }

    setLoadingAddProduct(false);
  };

  // effects
  useEffect(() => {
    if (cartProduct) {
      setQuantity(cartProduct.storefront_unit_quantity);
    }
  }, [cartProduct]);

  const useOnSaleField = useTreatment(PACKAGES_NEW_ON_SALE_TAG) == ON;
  const isOnSale = useOnSaleField ? strikethrough_display_value > 0 : on_sale && displayDiscount;
  const originalPrice = useOnSaleField
    ? on_sale_toggle && strikethrough_display_value > package_price
      ? strikethrough_display_value.toFixed(2)
      : null
    : isOnSale && original_package_price > package_price
    ? original_package_price.toFixed(2)
    : null;

  const formattedUnitPrice = formatMoney(
    is_by_weight && !is_package_weight && is_weight_package
      ? (unit_price / average_pack_weight).toFixed(2)
      : unit_price,
    currency
  );

  return (
    <div className={`flex flex-row justify-between items-center`}>
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center">
          <div className="flex flex-row space-x-1 items-center">
            <h4 className={`font-bold text-xl ${soldOut ? 'text-Grey400' : 'text-Grey800'}`}>
              <Money value={package_price} currency={currency} />
              {originalPrice !== null && (
                <span
                  className={`line-through text-base ml-1 ${
                    soldOut ? 'text-Grey400' : 'text-Grey500'
                  }`}>
                  <Money value={originalPrice} currency={currency} />
                </span>
              )}
            </h4>
            <div className="flex flex-row space-x-2 items-center text-center">
              <span className="text-Grey500">/{name}</span>
            </div>
            {(track_inventory || is_by_weight) && (
              <div className="flex flex-row space-x-1">
                {is_by_weight && (
                  <>
                    <p>&middot;</p>
                    <p>
                      {t('storefront/store/shop/product--package-details', {
                        averagePackWeight: average_pack_weight,
                        unitName,
                        unitPrice: formattedUnitPrice,
                      })}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`flex justify-between space-x-2 items-center`}>
        {!!cartProduct ? (
          <div className="flex flex-col items-end">
            <InputPlusMinus
              id={id}
              name={name}
              disabled={loading || soldOut}
              value={quantity}
              max={getMaxItemQuantity(track_inventory, available, max_units_per_order)}
              handleChange={(storefront_unit_quantity) => {
                setQuantity(storefront_unit_quantity);
                if (!!cartProduct) {
                  if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
                    debouncedSetOrder({
                      order_entries: [{ id: cartProduct?.id, storefront_unit_quantity }],
                    });
                  }
                }
              }}
            />
            <InventoryCounter
              displayRemainingInventory={displayRemainingInventory}
              trackInventory={track_inventory}
              soldOut={soldOut}
              quantityAvailable={available}
              quantityInCart={quantity}
              maxUnitsPerOrder={max_units_per_order}
            />
          </div>
        ) : (
          <Button
            data-dd-action-name="add-product-to-cart"
            disabled={soldOut || !!cartProduct}
            classes="button--storefront-primary w-28 flex-1"
            onClick={(e) => addPackage(e)}>
            {soldOut ? (
              t('storefront/store/shop/product--sold-out')
            ) : loadingAddProduct ? (
              <div className="flex items-center justify-center">
                <SpinnerSmall stroke="white" />
              </div>
            ) : (
              t('storefront/store/shop/product--add-to-cart')
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

PackageCardListView.propTypes = {
  id: PropTypes.number,
  product_price_list_entry: PropTypes.number,
  product_package: PropTypes.number,
  adjustment: PropTypes.bool,
  adjustment_type: PropTypes.number,
  adjustment_value: PropTypes.number,
  name: PropTypes.string,
  inventory_per_unit: PropTypes.number,
  unit_price: PropTypes.number,
  priority_order: PropTypes.number,
  package_price: PropTypes.number,
  price_per_unit: PropTypes.number,
  track_inventory: PropTypes.bool,
  setErrors: PropTypes.func,
  displayRemainingInventory: PropTypes.bool,
};

PackageCardListView.defaultProps = {
  setErrors: () => {},
  displayRemainingInventory: false,
};

export default PackageCardListView;
