const PICKUP = 'pickup';
const DELIVERY = 'delivery';
const REPEAT = 'repeat';
const CUSTOM = 'custom';
const FLEXIBLE = 'flexible';
const RADIUS = 'radius';
const REGION = 'region';
const POSTALCODES = 'postalcodes';
const DELIVERY_TYPES = [RADIUS, REGION, POSTALCODES];
const PICKUP_TYPES = [PICKUP];

export { default } from './FulfillmentStep';
export {
  PICKUP,
  DELIVERY,
  REPEAT,
  CUSTOM,
  FLEXIBLE,
  RADIUS,
  REGION,
  POSTALCODES,
  DELIVERY_TYPES,
  PICKUP_TYPES,
};
