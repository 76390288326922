import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes }) => (
  <svg
    className={classes}
    width="28"
    height="41"
    viewBox="0 0 28 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 13.6667C28 20.7412 23.6075 27.1459 15.6459 38.6257C14.8496 39.7738 13.1504 39.7738 12.3541 38.6257C4.3925 27.1459 0 20.7412 0 13.6667C0 6.11878 6.26801 0 14 0C21.732 0 28 6.11878 28 13.6667Z"
      fill="#3F8262"
    />
    <circle cx="14" cy="14" r="12" fill="#064E3D" />
    <circle cx="14" cy="14" r="2" fill="#C9E2C3" />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: theme.colors.Grey400,
};

export default Svg;
