import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { formatProblemJSONErrors } from 'helpers/helpers';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { toastAtom } from 'state/appState';
import Alert from 'components/styled/Alert';
import Button from 'components/styled/Button';
import Card from 'components/styled/Card';
import InputText from 'components/styled/InputText';

const ResetPassword = ({ clearAuth, resetUser, redirectPath, passwordReset }) => {
  const { uidb64, token } = useParams();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState({ password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [redirect, setRedirect] = useState('');
  const setToasts = useSetRecoilState(toastAtom);

  const resetPassword = async () => {
    setSubmitted(true);
    if (validateConfirmPassword()) return;
    try {
      const resp = await passwordReset({ password: password.password, uidb64, token });
      const toastId = generateRandomNumber();
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('password-reset/successful'),
          type: 'success',
        },
      ]);
      await clearAuth();
      await resetUser();
      setRedirect(redirectPath);
    } catch (e) {
      setErrors(formatProblemJSONErrors(e?.response?.data));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    return setPassword({
      ...password,
      [name]: value,
    });
  };

  const validateConfirmPassword = () => {
    if (password.password !== password.confirmPassword) {
      setErrors({ confirmPassword: true });
      return true;
    } else {
      setErrors();
      return false;
    }
  };

  useEffect(() => {
    if (submitted) {
      validateConfirmPassword();
    }
  }, [password]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Card classes="max-w-6xl mt-14" title="Reset Your Password">
        {!!errors?.invalid ? (
          <Alert
            id="reset-error-alert"
            classes="mb-6"
            type="error"
            description={
              !!errors.invalid[0] && typeof errors.invalid[0] === 'object'
                ? errors.invalid[0]
                : errors.invalid
            }
          />
        ) : null}
        <InputText
          id="password"
          name="password"
          labelText={
            <div className="w-full flex justify-between items-center">
              <h4>{t('global/new-password')}</h4>
              <span
                className="text-Grey400 cursor-pointer"
                onClick={() => setShowPassword((prevState) => !prevState)}>
                {showPassword ? t('Hide') : t('Show')}
              </span>
            </div>
          }
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}
          value={password.password}
          classes="mb-4"
          error={!!errors?.password ? errors.password : null}
        />
        <InputText
          id="confirm-password"
          name="confirmPassword"
          labelText={
            <div className="w-full flex justify-between items-center">
              <h4>{t('global/confirm-password')}</h4>
              <span
                className="text-Grey400 cursor-pointer"
                onClick={() => setShowPassword((prevState) => !prevState)}>
                {showPassword ? t('Hide') : t('Show')}
              </span>
            </div>
          }
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}
          value={password.confirmPassword}
          classes="mb-4"
          error={!!errors?.confirmPassword ? t('global/password-match-error') : null}
          hintClasses={!!errors?.confirmPassword ? 'text-Red700' : ''}
        />
        <div className="flex self-end">
          <Button onClick={() => resetPassword()}>{t('Save new password')}</Button>
        </div>
      </Card>
    </>
  );
};

ResetPassword.propTypes = {
  clearAuth: PropTypes.func,
  resetUser: PropTypes.func,
  redirectPath: PropTypes.string,
  passwordReset: PropTypes.func,
};

ResetPassword.defaultProps = {
  clearAuth: () => {},
  resetUser: () => {},
  redirectPath: '',
  passwordReset: () => {},
};

export default ResetPassword;
