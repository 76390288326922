import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, dimensions }) => (
  <svg
    width={dimensions}
    height={dimensions}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14275 1.01161C6.98541 0.870253 6.11634 2.04551 6.59057 3.11065L7.04486 4.131C2.75629 6.04039 0.827589 11.0648 2.73698 15.3534C3.51482 17.1004 4.81176 18.4578 6.3645 19.3216C6.84712 19.5901 7.45603 19.4165 7.72453 18.9339C7.99303 18.4513 7.81945 17.8424 7.33682 17.5739C6.14961 16.9134 5.15971 15.8778 4.56407 14.5399C3.10395 11.2604 4.57884 7.41821 7.85833 5.95809L8.31262 6.97843C8.78685 8.04357 10.2418 8.18413 10.9111 7.22947L13.2732 3.86054C13.9233 2.93336 13.3509 1.64776 12.2269 1.51046L8.14275 1.01161ZM16.8727 4.57023C16.39 4.30179 15.7811 4.47545 15.5127 4.9581C15.2442 5.44076 15.4179 6.04965 15.9005 6.31809C17.0881 6.97856 18.0782 8.01433 18.674 9.35242C20.1341 12.6319 18.6592 16.4741 15.3797 17.9342L14.9254 16.9139C14.4512 15.8488 12.9963 15.7082 12.3269 16.6629L9.96482 20.0318C9.31473 20.959 9.88712 22.2446 11.0111 22.3819L15.0953 22.8807C16.2527 23.0221 17.1217 21.8468 16.6475 20.7817L16.1932 19.7613C20.4818 17.8519 22.4105 12.8275 20.5011 8.53894C19.7231 6.79155 18.4258 5.43405 16.8727 4.57023Z"
      fill={stroke}
      className="fill-on-hover no-stroke"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey300,
  classes: '',
  dimensions: '24',
};

export default Svg;
