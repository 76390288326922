import PolkaDotBanner from 'components/styled/PolkaDotBanner/PolkaDotBanner';

const ProductImagePlaceholder = () => {
  return (
    <div className="w-full pb-[100%]">
      <PolkaDotBanner classes="absolute inset-0 w-full h-full p-0" />
    </div>
  );
};

export default ProductImagePlaceholder;
