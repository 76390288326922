import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SpinnerSmall } from 'assets/svgIcons';
import { FULFILLMENT_TYPES, PAID } from 'helpers/constants';
import Money from 'components/styled/Money';
import { currencyAtom } from 'state/common/currency';
import { useRecoilValue } from 'recoil';

const PaymentFees = ({ payment, loading }) => {
  const { t } = useTranslation();
  const currency = useRecoilValue(currencyAtom);
  return (
    <>
      {payment?.order_payment_strategy?.fees?.map((fee) => (
        <>
          {fee?.total_fee > 0 && (
            <div className="flex justify-between items-center mb-3">
              <h5 className="text-Grey500">{fee?.name}</h5>
              <h5 className="text-Grey600 flex">
                {loading ? (
                  <SpinnerSmall classes="mr-1" />
                ) : (
                  <Money value={fee?.total_fee} currency={currency} />
                )}
              </h5>
            </div>
          )}
        </>
      ))}
    </>
  );
};

PaymentFees.propTypes = {};

export default PaymentFees;
