import { ChevronHeader } from 'assets/svgIcons';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useLocation } from 'react-router-dom';

const Breadcrumbs = ({ name, category }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { search } = useLocation();
  return (
    <div className="store__product--breadcrumbs">
      <Link
        className="text-Grey500"
        to={{
          pathname: `/${priceListSlug}`,
          search,
        }}>
        {t('Shop')}
      </Link>
      <ChevronHeader width={8} height={8} />
      {!!category && (
        <>
          <span>{category}</span>
          <ChevronHeader width={8} height={8} />
        </>
      )}
      <span className="text-Green600">{name}</span>
    </div>
  );
};

Breadcrumbs.propTypes = {};

export default Breadcrumbs;
