import { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useBackofficeAuth } from 'hooks/backoffice';
import { useStorefrontAuth } from 'hooks/storefront';
import { useHistory, useParams } from 'react-router-dom';
import { SELLER } from '.';

const Enter = ({ actor }) => {
  const history = useHistory();
  const { userId } = useParams();
  const { setAuth: setBackofficeAuth } = useBackofficeAuth();
  const { setAuth: setStorefrontAuth } = useStorefrontAuth();
  const setAuth = actor === SELLER ? setBackofficeAuth : setStorefrontAuth;
  const impersonateUrl = `${process.env.REACT_APP_API_URL}/impersonate/${actor}/${userId}/`;
  const redirectSuccessUrl = actor === SELLER ? '/backoffice/home' : '/';
  const redirectErrorUrl = actor === SELLER ? '/backoffice/login' : '/';

  // methods
  const impersonate = async () => {
    try {
      const resp = await axios(impersonateUrl, { withCredentials: true });
      setAuth(resp.data);
      history.push(redirectSuccessUrl);
    } catch (error) {
      console.error(`There was an error impersonating with the message: ${error.message}`);
      history.push(redirectErrorUrl);
    }
  };

  //effects

  useEffect(() => {
    impersonate();
  }, []);

  return <div />;
};

Enter.propTypes = {
  actor: PropTypes.string,
};

export default Enter;
