import CheckoutContainer from '../CheckoutContainer';
import CheckoutComplete from './CheckoutComplete';
import { Switch, Route, Redirect, useParams } from 'react-router-dom';

const CheckoutRouteHandler = ({ openOrder }) => {
    return (
        <Switch>
            <Route
                path={[
                    `/:priceListSlug/checkout/complete/:orderId`,
                    '/:priceListSlug/checkout/complete',
                ]}>
                <CheckoutComplete openOrder={openOrder} />
            </Route>
            <Route path="/:priceListSlug/checkout">
                <CheckoutContainer />
            </Route>
        </Switch>
    );
}

export default CheckoutRouteHandler;