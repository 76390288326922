import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="10" width="4" height="4" rx="2" fill={stroke} outline={stroke} />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
};

export default Svg;
