import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ORDERING_CATEGORY,
  ORDERING_PRIORITY,
  ORDERING_PRICE,
  ORDERING_CREATED_AT,
  ORDERING_PRODUCT_NAME,
} from '..';
import LabeledDropdown from 'components/styled/LabeledDropdown/LabeledDropdown';

const OrderingButton = ({ ordering, setOrdering }) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('storefront/store/shop/product-ordering--category'),
      value: `${ORDERING_CATEGORY},${ORDERING_PRIORITY}`,
    },
    {
      label: t('storefront/store/shop/product-ordering--price'),
      value: ORDERING_PRICE,
    },
    {
      label: t('storefront/store/shop/product-ordering--created'),
      value: ORDERING_CREATED_AT,
    },
    {
      label: t('storefront/store/shop/product-ordering--alphabetical'),
      value: ORDERING_PRODUCT_NAME,
    },
  ];

  return (
    <div className="flex items-center">
      <LabeledDropdown
        label={t('storefront/store/shop/product-ordering--title')}
        options={options}
        selectedOptionLabel={options.find((o) => o.value === ordering)?.label}
        onChange={({ value }) => setOrdering(value)}
      />
    </div>
  );
};

OrderingButton.propTypes = {
  ordering: PropTypes.string.isRequired,
  setOrdering: PropTypes.func.isRequired,
};

export default OrderingButton;
