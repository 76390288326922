import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import FooterSection from './FooterSection';

const SocialInfo = () => {
  const { t } = useTranslation();
  const { storefront_configuration } = useRecoilValue(accountAtom);
  const { facebook, twitter, instagram, website } = storefront_configuration;

  const socialLinks = [
    {
      label: t('Instagram'),
      link: instagram,
    },
    {
      label: t('Twitter'),
      link: twitter,
    },
    {
      label: t('Facebook'),
      link: facebook,
    },
    {
      label: t('Website'),
      link: website,
    },
  ];

  const hasSocialLinks = socialLinks.some((link) => link.link);

  if (!hasSocialLinks) return null;

  return (
    <FooterSection title={t('global/Social')}>
      {socialLinks
        .filter((social) => social.link)
        .map((social) => (
          <span key={social.label}>
            <Link className="text-Grey800" to={{ pathname: social.link }} target="_blank">
              {social.label}
            </Link>
          </span>
        ))}
    </FooterSection>
  );
};

export default SocialInfo;
