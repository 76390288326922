// constants
const DELIVERY_PLAN_ROUTE = 'delivery-plans';
const PICKUP_LOCATION_ROUTE = 'pickup-locations';
const WEEK = 'week';
const DAY = 'day';
const REGION = 'region'; // fulfillment strategy types
const POSTALCODES = 'postalcodes';
const RADIUS = 'radius';
const FLEXIBLE = 'flexible'; // fulfillment strategy availability types
const CUSTOM = 'custom';
const REPEAT = 'repeat';
const PICKUP = 'pickup';
const KM = 'km';
const MI = 'mi';

export {
  DELIVERY_PLAN_ROUTE,
  PICKUP_LOCATION_ROUTE,
  WEEK,
  DAY,
  REGION,
  POSTALCODES,
  RADIUS,
  FLEXIBLE,
  CUSTOM,
  REPEAT,
  PICKUP,
  KM,
  MI,
};
