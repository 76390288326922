import jwt_decode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { getDomainParts } from 'helpers/helpers';

// TODO: Does it make sense to ONLY store the auth inside of the cookie? Seems
// a bit redundant to also keep a copy inside recoil, which is also just a global state.
// The useAuth hook could expose to our components the value of claims, just as easily as the
// recoil state.

// Keep recoil auth state synced with cookie.
export const useAuth = (name, state, api) => {
  const path = '/'; // make sure cookie is available on each page
  const [cookies, setCookie, removeCookie] = useCookies();
  const [auth, setAuth] = useRecoilState(state);
  const resetAuth = useResetRecoilState(state);

  // If a cookie with valid auth exists for this subdomain, add it to the recoil state.
  const _getFirstCookie = () =>
    Object.entries(cookies).find(([key]) => key.includes(`_${name}`))?.[1];

  const initializeAuth = () => {
    const { subdomain } = getDomainParts();
    let existingCookie;
    if (subdomain.toLowerCase() === 'login' && name === 'backoffice') {
      existingCookie = _getFirstCookie(name);
    } else {
      existingCookie = cookies[`${subdomain}_${name}`];
    }
    if (existingCookie) {
      const { access, refresh, slug, accountId, userId } = existingCookie;
      if (access && refresh) {
        setAuth({ access, refresh, slug, accountId, userId });
        api.defaults.headers.Authorization = `Bearer ${access}`;
      } else {
        console.warn('Authorization: Malformed authState in cookies.');
        // todo: should we just clear the cookie if it's bad?
      }
      return { access, refresh, slug, accountId, userId };
    }
    return { access: null, refresh: null, slug: null, accountId: null, userId: null };
  };

  // Reset the auth recoil state, and clear the cookie
  const clearAuth = () => {
    const { subdomain, domain } = getDomainParts();
    removeCookie(`${subdomain}_${name}`, { path, domain });
    resetAuth();
    api.defaults.headers.Authorization = '';
  };

  // Add new auth to the recoil state, and the cookie
  const saveAuth = ({ access, refresh }) => {
    const { domain } = getDomainParts();
    if (!!access && !!refresh) {
      const { account_id: accountId, account_slug: slug, user_id, exp } = jwt_decode(access);
      const expires = new Date(exp * 1000);
      const userId = typeof user_id === 'number' ? user_id : null;
      setCookie(
        `${slug}_${name}`,
        { access, refresh, slug, accountId, userId },
        { expires, path, domain }
      );
      setAuth({ access, refresh, slug, accountId, userId });
      api.defaults.headers.Authorization = `Bearer ${access}`;
      return { access, refresh, slug, accountId, userId };
    } else {
      clearAuth();
      return { access: null, refresh: null, slug: null, accountId: null, userId: null };
    }
  };

  return { auth, clearAuth, initializeAuth, setAuth: saveAuth };
};
