import { SpinnerSmall } from 'assets/svgIcons';
import { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ERROR_CODES, ON, VIEWPORT_SIZES } from 'helpers/constants';
import { readPriceList } from 'api/storefront/price-lists';
import { screenSizeAtom } from 'state/appState';
import { STOREFRONT } from 'state/backoffice/featureSelectors';
import {
  authAtom,
  customerAtom,
  loginActiveAtom,
  accountAtom,
  priceListSubscriptionsEnabledAtom,
} from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { ClickOutside } from 'hooks/common/events';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import ScrollToTop from 'components/ScrollToTop';
import { SLUG_RESERVED_WORDS } from '.';
import { default as QuickCheckout } from '../Checkout';
import ResetPassword from './ResetPassword';
import './Store.css';
import {
  Header,
  Shop,
  About,
  Login,
  Cart,
  Checkout,
  Register,
  ForgotPassword,
  PrivatePriceList,
  Account,
} from './components';
import DeferredCheckout from './components/DeferredCheckout/DeferredCheckout';
import HamburgerMenu from './components/HamburgerMenu';

const Store = () => {
  const history = useHistory();
  const { priceListSlug } = useParams();
  const checkoutMatch = useRouteMatch(`/${priceListSlug}/checkout`);
  const { storefront_configuration, available_features } = useRecoilValue(accountAtom);
  const storefrontEnabled = available_features
    ? available_features?.find((f) => f.name === STOREFRONT)?.enabled
    : true;
  const { NOT_FOUND, FORBIDDEN } = ERROR_CODES;

  // states
  const auth = useRecoilValue(authAtom);
  const customer = useRecoilValue(customerAtom);
  const [loginActive, setLoginActive] = useRecoilState(loginActiveAtom);
  const [priceList, setPriceList] = useRecoilState(priceListAtomFamily(priceListSlug));
  const setPriceListSubscriptionsEnabled = useSetRecoilState(priceListSubscriptionsEnabledAtom);
  const [previousSlug, setPreviousSlug] = useState('');
  const [cartActive, setCartActive] = useState(false);
  const { setOrder } = useOrder(priceListSlug);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [privateSlug, setPrivateSlug] = useState('');
  const {
    ref: hamburgerRef,
    isComponentVisible: isHamburgerVisible,
    setIsComponentVisible: setIsHamburgerVisible,
  } = ClickOutside(false);
  const [modal, setModal] = useState({});
  const [loading, setLoading] = useState(true);
  const { pathname, search } = useLocation();
  const login = new URLSearchParams(search).get('login');
  const activated = new URLSearchParams(search).get('activated');
  const reset = new URLSearchParams(search).get('reset');
  const isProductDetailRoute = useRouteMatch('/:priceListSlug/product/:productId');
  const mainClass = `store__container ${isProductDetailRoute ? 'bg-white' : ''}`;
  const quickCheckout = useTreatment('storefront-quick-checkout') === ON;

  if (login) setLoginActive(login && !auth.userId);

  // methods
  const fetchPriceList = async () => {
    // check list of reserved words to see if not a pricelist
    const slugIsReservedWord = SLUG_RESERVED_WORDS.find((word) => word === priceListSlug);
    if (!slugIsReservedWord) {
      try {
        setLoading(true);
        const resp = await readPriceList(priceListSlug || 'default');
        setPriceListSubscriptionsEnabled(resp?.data?.subscription_settings_enabled);
        setPriceList(resp?.data);
      } catch (e) {
        if (e?.response?.status == NOT_FOUND && !storefrontEnabled) {
          history.push('/disabled');
        }
        if (e?.response?.status == FORBIDDEN) {
          setPrivateSlug(e?.response?.data?.slug);
        }
        console.error('Error fetching price list.');
      } finally {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  // effects
  useEffect(async () => {
    if (!priceList && pathname !== '/disabled') {
      await fetchPriceList();
    }
  }, [priceListSlug, auth]);

  useEffect(() => {
    if (priceList?.slug) {
      setPreviousSlug(priceList.slug);
    }
    if (!!priceList && !!priceList.slug && priceList.slug !== priceListSlug) {
      // if we are using the default, or the slug doesn't match,
      // re-route the user to the correct price list.
      history.push(`/${priceList.slug}${search}`);
      if (priceList.slug) {
        setPreviousSlug(priceList.slug);
      }
    } else if (!!priceList && localStorage.getItem(`${priceList?.id}-${priceListSlug}`)) {
      // rehydrate order if an order id is in local storage.
      const { email: customer_email } = customer;
      setOrder(!!customer_email ? { customer_email } : {});
    } else if (
      // kick user back to shop on load if no order id is in local storage
      // and they are on a checkout url.
      !!priceList &&
      !localStorage.getItem(`${priceList?.id}-${priceListSlug}`) &&
      checkoutMatch
    ) {
      history.push(`/${priceList.slug}${search}`);
    }
  }, [priceList, customer.id, customer.email]);

  const { width } = useRecoilValue(screenSizeAtom);
  const isMobile = width < VIEWPORT_SIZES.sm;

  const renderShop = () => {
    if (privateSlug) return <PrivatePriceList privatePriceListSlug={privateSlug} />;
    return <Shop modal={modal} setModal={setModal} />;
  };

  return (
    <div className="store" id="store">
      <ScrollToTop />
      <Header
        onCartClick={setCartActive}
        cartActive={cartActive}
        priceList={priceList}
        previousSlug={previousSlug}
        setIsHamburgerVisible={setIsHamburgerVisible}
      />
      <main className={mainClass}>
        {loading ? (
          <SpinnerSmall classes="mt-10" />
        ) : (
          <Switch>
            <Route path="/register">{!customer.id ? <Register /> : <Redirect to="/" />}</Route>
            <Route path="/account">
              <Account />
            </Route>
            {storefront_configuration?.enable_about_us ? (
              <Route path="/about">
                <About />
              </Route>
            ) : (
              <Route path="/about">
                <Redirect to="/" />
              </Route>
            )}
            <Route path="/deferred-checkout/:uidb64/:token">
              <DeferredCheckout isMobile={isMobile} />
            </Route>
            <Route path="/:priceListSlug/reset-password/:uidb64/:token">
              <ResetPassword />
            </Route>
            <Route path="/:priceListSlug/checkout">
              {quickCheckout ? <QuickCheckout /> : <Checkout isMobile={isMobile} />}
            </Route>
            <Route path={['/:priceListSlug/product/:productId', '/:priceListSlug/cart/:orderId']}>
              <Shop modal={modal} setModal={setModal} />
            </Route>
            <Route path={['/:priceListSlug', '/']}>{renderShop()}</Route>
          </Switch>
        )}
        <Cart onClose={() => setCartActive(false)} isOpen={cartActive} />
      </main>
      {loginActive && (
        <Login
          onClose={() => setLoginActive(false)}
          setForgotPassword={setForgotPassword}
          activated={activated}
          setCartActive={setCartActive}
          reset={reset}
        />
      )}
      {forgotPassword && (
        <ForgotPassword setLoginActive={setLoginActive} onReset={() => setForgotPassword(false)} />
      )}
    </div>
  );
};

export default Store;
