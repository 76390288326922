import {
  InfoCircleFilled,
  XCircleFilled,
  X,
  CheckCircleFilled,
  ExclamationTriangleFilled,
} from 'assets/svgIcons';
import PropTypes from 'prop-types';
import theme from 'styles/theme';
import { INFO, SECONDARY, SUCCESS, ERROR, WARNING } from '.';
import './Alert.css';

const { Green400, Blue400, Yellow400, Red400, Beige700, Green500, Blue500, Yellow500, Red500 } =
  theme.colors;

const Alert = ({
  classes,
  title,
  description,
  type,
  hasIcon,
  CustomIcon,
  Actions,
  closeButton,
  handleClose,
  id,
  inline,
}) => {
  let closeColor = Green500;
  if (type === INFO) closeColor = Blue500;
  if (type === WARNING) closeColor = Yellow500;
  if (type === ERROR) closeColor = Red500;
  if (type === SECONDARY) closeColor = Beige700;
  return (
    <div id={id} className={`alert alert-${type} ${inline ? 'alert-inline' : ''} ${classes}`}>
      {inline && <div className="alert-inline--border"></div>}
      {hasIcon && !CustomIcon && (
        <>
          {type === SUCCESS && <CheckCircleFilled fill={Green400} />}
          {type === INFO && <InfoCircleFilled fill={Blue400} />}
          {type === WARNING && <ExclamationTriangleFilled fill={Yellow400} />}
          {type === ERROR && <XCircleFilled fill={Red400} />}
          {type === SECONDARY && <InfoCircleFilled fill={Beige700} />}
        </>
      )}
      {hasIcon && CustomIcon && <CustomIcon />}
      {title || description ? (
        <div className="alert--content">
          <div className="alert--text">
            {title ? <h3 className={`${description ? 'mb-1' : ''}`}>{title}</h3> : ''}
            {description && (
              <>{typeof description === 'string' ? <p>{description}</p> : description}</>
            )}
          </div>
          {Actions && Actions}
        </div>
      ) : null}
      {closeButton && (
        <button type="button" className="alert--close" onClick={(e) => handleClose(e)}>
          <X stroke={closeColor} />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
  hasIcon: PropTypes.bool,
  CustomIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  closeButton: PropTypes.bool,
  handleClose: PropTypes.func,
  Actions: PropTypes.element,
  inline: PropTypes.bool,
};

Alert.defaultProps = {
  classes: '',
  title: '',
  description: '',
  type: 'success', // success, info, warning, error
  hasIcon: true,
  CustomIcon: undefined,
  closeButton: false,
  handleClose: () => {},
  Actions: undefined, // elements to render under text
  inline: false,
};

export default Alert;
