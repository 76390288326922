import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VIEWPORT_SIZES, ON } from 'helpers/constants';
import { screenSizeAtom } from 'state/appState';
import { subscriptionsSelector } from 'state/storefront/accountState';
import { cartActiveSelector } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import Button from 'components/styled/Button';
import Cart from '../Header/Cart';
import { FulfillmentInformation } from './FulfillmentInformation';
import { FulfillmentSelection } from './FulfillmentSelection';
import OrderingButton from './OrderingButton';
import SearchBar from './SearchBar';

const ProductsHeader = forwardRef(
  ({
    setSearch,
    ordering,
    setOrdering,
    setFilterPanelVisible,
    setModal,
    pickupLocations,
    hasDeliveryLocation,
    showSortByOption,
  }) => {
    const { t } = useTranslation();
    const { width } = useRecoilValue(screenSizeAtom);
    const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
    const upfrontFulfillmentEnabled =
      useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
    const [cartActive, setCartActive] = useRecoilState(cartActiveSelector);
    const isCheckout = useRouteMatch('/:priceListSlug/checkout');

    return (
      <>
        <section className="store__products-header">
          <div className="flex items-center justify-between px-3 sm:px-6 py-2 md:py-1 sm:py-2">
            <SearchBar setSearch={setSearch} />
            <div className="flex justify-between items-center flex-1">
              {VIEWPORT_SIZES.lg <= width ? (
                <>
                  <div className="flex items-center">
                    {!!showSortByOption && (
                      <OrderingButton ordering={ordering} setOrdering={setOrdering} />
                    )}
                  </div>
                  <div className="flex">
                    {upfrontFulfillmentEnabled && (
                      <div className="mr-2">
                        <FulfillmentSelection />
                      </div>
                    )}
                    {!isCheckout && <Cart cartActive={cartActive} onCartClick={setCartActive} />}
                  </div>
                  {!upfrontFulfillmentEnabled && (
                    <FulfillmentInformation
                      setModal={setModal}
                      pickupLocations={pickupLocations}
                      hasDeliveryLocation={hasDeliveryLocation}
                    />
                  )}
                </>
              ) : (
                <Button
                  id="store__products-header--filter-button"
                  classes="button button--white ml-3"
                  onClick={() => setFilterPanelVisible((prevState) => !prevState)}>
                  {t('Filter')}
                </Button>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
);

ProductsHeader.displayName = 'ProductsHeader';
ProductsHeader.propTypes = {
  setSearch: PropTypes.func.isRequired,
  ordering: PropTypes.string.isRequired,
  setOrdering: PropTypes.func.isRequired,
  setFilterPanelVisible: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  pickupLocations: PropTypes.array,
  hasDeliveryLocation: PropTypes.bool,
  showSortByOption: PropTypes.bool,
};

ProductsHeader.defaultProps = {
  pickupLocations: [],
  hasDeliveryLocation: false,
  showSortByOption: false,
};

export default ProductsHeader;
