export { default } from './Store';

export const ACCOUNT = 'account';
export const DEFERRED_CHECKOUT = 'deferred-checkout';
export const REGISTER = 'register';
export const CONNECT = 'connect';
export const ABOUT = 'about';
export const DISABLED = 'disabled';

export const SLUG_RESERVED_WORDS = [ACCOUNT, DEFERRED_CHECKOUT, REGISTER, CONNECT, ABOUT, DISABLED];
