import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="20"
    height="20"
    className={classes}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.3335 13.3327L3.3335 14.166C3.3335 15.5467 4.45278 16.666 5.8335 16.666L14.1668 16.666C15.5475 16.666 16.6668 15.5467 16.6668 14.166L16.6668 13.3327M13.3335 6.66602L10.0002 3.33268M10.0002 3.33268L6.66683 6.66602M10.0002 3.33268L10.0002 13.3327"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
};

export default Svg;
