import { PencilAlt } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import theme from 'styles/theme';
import { FULFILLMENT_TYPES } from 'helpers/constants';
import { formatDateTime, DAY_OF_WEEK_MONTH_DAY } from 'helpers/localeHelpers';
import { cartActiveSelector, fulfillmentWizardActiveAtom } from 'state/storefront/storeState';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button/Button';

export const FulfillmentSelection = ({ mobile }) => {
  const { t } = useTranslation();
  const { order } = useOrder();
  const setFulfillmentWizardActive = useSetRecoilState(fulfillmentWizardActiveAtom);
  const setCartActive = useSetRecoilState(cartActiveSelector);
  const fulfillmentComplete = !!order?.fulfillment?.fulfillment_strategy;
  let fulfillmentContent = t('storefront/store/shop/hero--fulfillment-selection-no-selection');

  const fulfillmentSelectionTranslations = {
    [FULFILLMENT_TYPES.PICKUP]: {
      date: 'storefront/store/shop/hero--pickup-selection-date',
      selection: 'storefront/store/shop/hero--pickup-selection',
    },
    [FULFILLMENT_TYPES.DELIVERY]: {
      date: 'storefront/store/shop/hero--delivery-selection-date',
      selection: 'storefront/store/shop/hero--delivery-selection',
    },
  };

  if (fulfillmentComplete) {
    const { fulfillment_date, fulfillment_strategy_type } = order?.fulfillment;
    const type =
      fulfillment_strategy_type === FULFILLMENT_TYPES?.PICKUP
        ? FULFILLMENT_TYPES.PICKUP
        : FULFILLMENT_TYPES.DELIVERY;
    if (fulfillment_date) {
      fulfillmentContent = t(fulfillmentSelectionTranslations[type]?.date, {
        date: formatDateTime(fulfillment_date, DAY_OF_WEEK_MONTH_DAY),
      });
    } else {
      fulfillmentContent = t(fulfillmentSelectionTranslations[type]?.selection);
    }
  }

  return (
    <Button
      id="storefront/store/shop/hero--fulfillment-selection"
      classes={`flex items-center ${
        mobile
          ? 'bg-Green600 w-full text-center rounded-none justify-center focus:outline-none'
          : fulfillmentComplete
          ? 'button--white'
          : 'button--white'
      }`}
      onClick={() => {
        setFulfillmentWizardActive(true);
        setCartActive((prevState) => !prevState);
      }}>
      {fulfillmentContent}
      {fulfillmentComplete && (
        <PencilAlt classes="ml-2 " fill={mobile ? theme.colors.white : theme.colors.Grey400} />
      )}
    </Button>
  );
};

FulfillmentSelection.propTypes = {
  mobile: PropTypes.bool,
};
FulfillmentSelection.defaultProps = {
  mobile: false,
};
