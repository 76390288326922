import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sidebarMinimizedAtom } from 'state/appState';
import { accountAtom } from 'state/storefront/accountState';
import useSearchParamsState from 'hooks/common/searchParamsState';
import { useOrder } from 'hooks/storefront';
import useFilters from 'hooks/storefront/filters';
import useProducts from 'hooks/storefront/products';
import SidebarDivider from 'components/Sidebar/SidebarDivider';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio/InputCheckboxRadio';
import {
  FILTER_ON_SALE_TOGGLE,
  FILTER_SUBSCRIPTIONS_AVAILABLE,
  FILTER_OUT_OF_STOCK,
  FILTER_CATEGORY,
  FILTER_TAGS,
  FILTER_VENDORS,
  FILTER_SEARCH,
} from '..';
import ActiveFilters from './ActiveFilters';
import './FilterPanel.css';
import FilterSet from './FilterSet';

const FilterPanel = ({ backgroundColor }) => {
  const { t } = useTranslation();
  const { priceList } = useOrder();
  // states
  const account = useRecoilValue(accountAtom);
  const [sidebarMinimized, setSidebarMinimized] = useRecoilState(sidebarMinimizedAtom);
  const numberOfCircles = sidebarMinimized ? 12 : 37;
  const { removeFilter, appendFilter, removeFilterValue, getFilterValue } = useSearchParamsState();
  const { onSale, subscriptionsAvailable, hideOutOfStock } = useProducts();
  const search = getFilterValue(FILTER_SEARCH);
  const { filtersSet, activeFilters, setActiveFilters, filters, toggleFilterSet } = useFilters();
  const showOnSaleFilter = priceList?.on_sale_products_count > 0;

  const showSubscriptionsAvailableFilter =
    !!priceList?.subscription_settings_enabled && !!priceList?.subscribable_products_count > 0;

  const isFilterSelected = (type, value) => {
    return filters[type]?.includes(value.toString());
  };

  const removeActiveFilter = (_type, _value) => {
    setActiveFilters((prevState) =>
      prevState.filter(({ value, type }) => (type != _type ? true : value != _value))
    );
  };

  const appendActiveFilter = (_filter) => {
    setActiveFilters((prevState) => [...prevState, _filter]);
  };

  const clearActiveFilters = () => {
    removeFilter(FILTER_CATEGORY);
    removeFilter(FILTER_TAGS);
    removeFilter(FILTER_VENDORS);
    removeFilter(FILTER_ON_SALE_TOGGLE);
    removeFilter(FILTER_SUBSCRIPTIONS_AVAILABLE);
    removeFilter(FILTER_OUT_OF_STOCK);
    removeFilter(FILTER_SEARCH);
    setActiveFilters([]);
  };

  const handleCheckboxFilter = (type, _filter) => {
    const { value } = _filter;
    if (isFilterSelected(type, value)) {
      handleRemoveFilter(type, _filter);
    } else {
      handleAddFilter(type, _filter);
    }
  };

  const handleRemoveFilter = (type, _filter) => {
    const { value } = _filter;
    const isToggle = toggleFilterSet.includes(type);
    if (isToggle) {
      removeFilter(type);
      setActiveFilters((prevState) => prevState.filter(({ type: _type }) => type != _type));
    } else {
      removeFilterValue(type, value);
      removeActiveFilter(type, value);
    }
  };

  const handleAddFilter = (type, _filter) => {
    const { value } = _filter;
    appendFilter(type, value);
    appendActiveFilter({ ..._filter, type });
  };

  const handleToggleFilter = (type, _filter) => {
    const { value } = _filter;
    if (value) {
      handleAddFilter(type, _filter);
    } else {
      handleRemoveFilter(type, _filter);
    }
  };

  const handleUpdateActiveSearchFilter = () => {
    const activeSearchFilter = activeFilters.find(({ type }) => type == FILTER_SEARCH);
    const searchNotInURL = !search;
    const searchNotInActiveFilters = !activeSearchFilter;
    if (!activeSearchFilter || search != activeSearchFilter?.value) {
      if (searchNotInURL) {
        setActiveFilters((prevState) => prevState.filter(({ type }) => type != FILTER_SEARCH));
      } else {
        const _searchFilter = {
          type: FILTER_SEARCH,
          value: search,
          label: search,
        };
        if (searchNotInActiveFilters) {
          setActiveFilters((prevState) => [...prevState, _searchFilter]);
        } else {
          setActiveFilters((prevState) =>
            prevState.map((f) => {
              if (f.type == FILTER_SEARCH) {
                return _searchFilter;
              }
              return f;
            })
          );
        }
      }
    }
  };

  useEffect(handleUpdateActiveSearchFilter, [search]);

  return (
    <div
      className={`storefront-sidebar ${sidebarMinimized ? 'minimized' : ''}`}
      onClick={(e) => e.stopPropagation()}
      style={{ backgroundColor }}>
      <div>
        {filtersSet && (
          <>
            <ActiveFilters
              filters={activeFilters}
              removeActiveFilter={handleRemoveFilter}
              clearActiveFilters={clearActiveFilters}
              dividerSize={numberOfCircles}
            />
            {Object.keys(filtersSet)?.map((filterSet) => {
              const { id, title, data, loadMore, more, expanded } = filtersSet[filterSet];
              if (data?.length > 0) {
                return (
                  <Fragment key={`${id}-${title}`}>
                    <FilterSet
                      title={title}
                      onFilter={(value) => handleCheckboxFilter(filterSet, value)}
                      options={data.map((c) => ({
                        value: c.id,
                        label: c.name,
                        count: c.product_count,
                        checked: isFilterSelected(filterSet, c.id),
                      }))}
                      more={more}
                      loadMore={loadMore}
                      expanded={expanded}
                    />
                    <SidebarDivider numberOfCircles={numberOfCircles} />
                  </Fragment>
                );
              }
            })}
          </>
        )}
        <>
          {/* TODO: Refactor into checkbox component */}
          <div className="flex flex-col items-center space-y-2">
            {showOnSaleFilter && (
              <InputCheckboxRadio
                id="filter-panel--on-sale"
                label={t('storefront/store/shop/filters--on-sale')}
                internalLabelClasses="font-medium text-base text-Grey800"
                checked={onSale}
                labelClasses="font-medium text-base"
                onChange={() =>
                  handleToggleFilter(FILTER_ON_SALE_TOGGLE, {
                    value: !onSale,
                    label: t('storefront/store/shop/filters--on-sale'),
                  })
                }
              />
            )}
            {showSubscriptionsAvailableFilter && (
              <InputCheckboxRadio
                id="filter-panel--subscription-settings-enabled"
                label={t('storefront/store/shop/filters--subscriptions-available')}
                internalLabelClasses="font-medium text-base text-Grey800"
                checked={subscriptionsAvailable}
                onChange={() =>
                  handleToggleFilter(FILTER_SUBSCRIPTIONS_AVAILABLE, {
                    value: !subscriptionsAvailable,
                    label: t('storefront/store/shop/filters--subscriptions-available'),
                  })
                }
              />
            )}
            {account?.storefront_configuration?.display_out_of_stock && (
              <InputCheckboxRadio
                id="filter-panel--hide-out-of-stock"
                label={t('storefront/store/shop/filters--hide-out-of-stock')}
                internalLabelClasses="font-medium text-base text-Grey800"
                checked={hideOutOfStock}
                onChange={() =>
                  handleToggleFilter(FILTER_OUT_OF_STOCK, {
                    value: !hideOutOfStock,
                    label: t('storefront/store/shop/filters--hide-out-of-stock'),
                  })
                }
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
};

FilterPanel.propTypes = {
  backgroundColor: PropTypes.string,
};

FilterPanel.defaultProps = {
  backgroundColor: '',
};

export default FilterPanel;
