import { useTranslation } from 'react-i18next';

const IsByWeight = ({ is_by_weight, average_pack_weight, unitName, formattedUnitPrice }) => {
  const { t } = useTranslation();

  return (
    is_by_weight && (
      <p>
        {t('storefront/store/shop/product--package-details', {
          averagePackWeight: average_pack_weight,
          unitName,
          unitPrice: formattedUnitPrice,
        })}
      </p>
    )
  );
};

export default IsByWeight;
