import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import { accountAtom } from 'state/storefront/accountState';

const CheckoutFooter = ({ classes }) => {
  const { t } = useTranslation();
  const { contact_email, business_name } = useRecoilValue(accountAtom);

  return (
    <div className={`w-full flex flex-col ${classes}`}>
      <hr className="w-full mt-12" />
      <div className="w-full flex justify-between mt-3">
        <Link
          className="text-sm text-Grey400 hover:text-Grey500"
          to={{ pathname: `mailto:${contact_email}` }}
          target="_blank">
          {t('storefront/store/checkout/footer--contact-farm', { name: business_name })}
        </Link>
        <Link
          className="text-sm text-Grey400 hover:text-Grey500"
          to={{ pathname: t('storefront/store/checkout/footer--how-to--link') }}
          target="_blank">
          {t('storefront/store/checkout/footer--how-to--text')}
        </Link>
      </div>
      <div className="mt-2">
        <p className="text-xs text-Grey400 ">
          <Trans i18nKey="storefront/store/checkout/footer--service-policy-text">
            By placing this order you hereby agree to our
            <Link
              className="text-xs text-Grey500 hover:text-Grey600 underline"
              to={{ pathname: t('register/details/terms--links--service') }}
              target="_blank">
              terms of service
            </Link>
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default CheckoutFooter;
