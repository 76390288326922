import { api } from '.';

export const createVendor = async (data, headers) => api.post('vendors/', data, { headers });

export const readVendors = async (params) => api.get('/vendors/', { params });

export const readVendor = (id) => api.get(`vendors/${id}`);

export const inviteVendor = (email) => api.post(`vendors/invite`, { email });

export const updateVendor = (id, data, headers) => api.patch(`vendors/${id}`, data, { headers });

export const deleteVendor = (id) => api.delete(`vendors/${id}`);

export const duplicateVendor = (id, data) => api.post(`vendors/${id}/duplicate`, data);

export const exportVendors = (params) => api.get('vendors/export/', { params });

export const importVendors = async (file) =>
  api.post(`vendors/import`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const connectVendor = (credentials, slug) => {
  if (!credentials.email || !credentials.password) {
    throw new Error('Some fields are missing.');
  }
  const { email, password } = credentials;

  return api.post(
    `vendors/connect`,
    { username: email, password },
    { headers: { Subdomain: slug } }
  );
};

export const readConnectedVendorsExist = async () =>
  await api.get(`vendors/connected-vendors-exist`);
