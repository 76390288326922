import PropTypes from 'prop-types';
import './Card.css';
import { KEY_CODES } from 'helpers/constants';

const Card = ({ children, classes, title, text, onClick, childMarginBottom, id }) => (
  <div
    id={id}
    className={`card ${childMarginBottom ? 'card--child-mb' : ''} ${classes}`}
    aria-hidden
    onKeyDown={(e) => e.keyCode === KEY_CODES.ENTER && onClick(e)}
    onClick={(e) => onClick(e)}>
    {text || title ? (
      <div className="card--content">
        {title ? <h2 className={`${text ? 'mb-1' : ''}`}>{title}</h2> : ''}
        {text ? <p>{text}</p> : ''}
      </div>
    ) : null}
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  childMarginBottom: PropTypes.bool,
  id: PropTypes.string,
};

Card.defaultProps = {
  children: undefined,
  classes: '',
  title: '',
  text: '',
  onClick: () => { },
  childMarginBottom: true,
  id: '',
};

export default Card;
