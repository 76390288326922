import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button/Button';

// todo: deprecate this with conclusion of upfront fulfillment work
export const FulfillmentInformation = ({ setModal, pickupLocations, hasDeliveryLocation }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      {pickupLocations?.length > 0 && (
        <Button
          id="storefront/store/shop/product-ordering--pickup-locations"
          classes="button--white w-full sm:w-auto"
          onClick={() =>
            setModal({
              id: 'pickup-locations',
              title: t('Pickup Locations'),
              description: t('storefront/store/shop/pickup-locations--count', {
                count: pickupLocations?.length,
              }),
              hasCloseButton: true,
            })
          }>
          {t('storefront/store/shop/pickup-locations')}
        </Button>
      )}
      {hasDeliveryLocation && (
        <Button
          id="storefront/store/shop/product-ordering--delivery-availability"
          classes="button--white ml-4 w-full sm:w-auto"
          onClick={() =>
            setModal({
              id: 'delivery-availability',
              title: t('Add Delivery Address'),
              hasCloseButton: true,
            })
          }>
          {t('storefront/store/shop/delivery-availability')}
        </Button>
      )}
    </div>
  );
};
