import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '91d85c4b-504a-474c-8964-db511106166a',
    clientToken: 'pub447cc6924ba4ef03ed83427f316e6667',
    site: 'datadoghq.com',
    service: 'localline-v2',
    env: JSON.stringify(process.env.REACT_APP_DD_ENV),
    version: JSON.stringify(process.env.REACT_APP_DD_APP_VERSION),
    sessionSampleRate: parseInt(process.env.REACT_APP_DD_SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: parseInt(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    enableExperimentalFeatures: ["feature_flags"],
    allowedTracingUrls: [/https:\/\/.*\.localline.ca|co\.com\/api\/*/, /http:\/\/localhost\/api\/*/],
    traceSampleRate: parseInt(process.env.REACT_APP_DD_TRACE_SAMPLE_RATE),
});