import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enAU from './translations/en-AU.translation.json';
import enCA from './translations/en-CA.translation.json';
import enGB from './translations/en-GB.translation.json';
import enNZ from './translations/en-NZ.translation.json';
import en from './translations/en.translation.json';
import es from './translations/es.translation.json';
import fr from './translations/fr.translation.json';
import sl from './translations/sl.translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3', // todo: move to v4 https://www.i18next.com/misc/json-format#i18next-json-v4
    // we init with resources
    resources: {
      'en-GB': {
        translation: enGB,
      },
      'en-AU': {
        translation: enAU,
      },
      'en-CA': {
        translation: enCA,
      },
      'en-NZ': {
        translation: enNZ,
      },
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      sl: {
        translation: sl,
      },
    },
    returnEmptyString: false,
    fallbackLng: 'en',
    debug: false,

    ns: ['translation'],
    defaultNS: 'translation',
    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

i18n.services.formatter?.add(
  'availabilityWeekdays',
  (availability, lng, { style = 'long', type = 'conjunction', abbreviated = false }) => {
    return new Intl.ListFormat(lng, { style, type }).format(
      [
        'repeat_on_monday',
        'repeat_on_tuesday',
        'repeat_on_wednesday',
        'repeat_on_thursday',
        'repeat_on_friday',
        'repeat_on_saturday',
        'repeat_on_sunday',
      ].reduce(
        (days, day) =>
          availability[day] ? [...days, `$t(${day}${abbreviated ? '-abbreviated' : ''})`] : days,
        []
      )
    );
  }
);

export default i18n;
