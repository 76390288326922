import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${classes}`}>
    <rect y="14" width="16" height="2" rx="1" fill={stroke} />
    <rect y="8" width="16" height="2" rx="1" fill={stroke} />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
