import PropTypes from 'prop-types';
import { generateRandomKey } from 'helpers/inputHelpers';
import './MenuDropdownPortal.css';
import Portal from 'components/Portal';
import { forwardRef } from 'react';
import { useCoords } from 'hooks/common/events';
import { useRef } from 'react';

// Uses a portal with the parent button to position at the root
const MenuDropdownPortal = forwardRef(({ children, classes }, ref) => {
  const target = 'menu-dropdown--portal';
  const dropdown = useRef();
  const { top, left } = useCoords(ref?.current, dropdown?.current);

  return (
    <Portal target={target}>
      <div ref={dropdown} className={`menu-dropdown--portal ${classes}`} style={{ top, left }}>
        <ul className="flex flex-col min-w-48">
          {children &&
            children.map((child) =>
              typeof child === 'string' ? <li key={generateRandomKey()}>{child}</li> : child
            )}
        </ul>
      </div>
    </Portal>
  );
});

MenuDropdownPortal.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.string,
  direction: PropTypes.string,
};

MenuDropdownPortal.defaultProps = {
  children: undefined,
  classes: '',
  direction: '',
};

export default MenuDropdownPortal;
