import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes }) => (
  <svg
    className={classes}
    width="27"
    height="41"
    viewBox="0 0 27 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.0098 13.6667C27.0098 20.7164 22.8068 27.101 15.1859 38.5053C14.3933 39.6915 12.6458 39.6915 11.8531 38.5053C4.23228 27.101 0.0292969 20.7164 0.0292969 13.6667C0.0292969 6.11878 6.06908 0 13.5195 0C20.97 0 27.0098 6.11878 27.0098 13.6667Z"
      fill="#C4B7A3"
    />
    <ellipse cx="13.5196" cy="14" rx="11.5631" ry="12" fill="#918065" />
    <ellipse cx="13.5195" cy="14" rx="1.92718" ry="2" fill="#F3EEE4" />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: theme.colors.Grey400,
};

export default Svg;
