import { selector } from 'recoil';
import { isObjectEmpty } from 'helpers/helpers';
import { accountPlanAtom } from './userState';

// Feature gating users depending on their subscription
export const STORE_CREDITS = 'STORE_CREDITS';
export const FEES_ON_SALES_STRIPE = 'FEES_ON_SALES_STRIPE';
export const FULFILLMENT_STRATEGIES = 'FULFILLMENT_STRATEGIES';
export const CUSTOM_PAYMENT_STRATEGIES = 'CUSTOM_PAYMENT_STRATEGIES';
export const ONLINE_PAYMENT_STRATEGIES = 'ONLINE_PAYMENT_STRATEGIES';
export const REPORTS = 'REPORTS';
export const LL_USERS = 'LL_USERS';
export const PRICE_LISTS = 'PRICE_LISTS';
export const ZAPIER_INTEGRATIONS = 'ZAPIER_INTEGRATIONS';
export const PRODUCT_PACKAGES = 'PRODUCT_PACKAGES';
export const NUMBER_OF_PRODUCTS = 'NUMBER_OF_PRODUCTS';
export const LOCAL_PAY = 'LOCAL_PAY';
export const VENDORS = 'VENDORS';
export const STOREFRONT = 'STOREFRONT';
export const ORDERS = 'ORDERS';
export const CUSTOMERS = 'CUSTOMERS';
export const PAYMENT_STRATEGIES = 'PAYMENT_STRATEGIES';
export const SUBSCRIPTION_ORDERS = 'SUBSCRIPTION_ORDERS';
export const WEBSITE_BUILDER = 'WEBSITE_BUILDER';
export const LOCAL_PAY_2_0 = 'LOCAL_PAY_2_0';

const getFeature = (get, name) => {
  const accountPlan = get(accountPlanAtom);
  return accountPlan?.available_features?.find((f) => f.name === name);
};

export const storeCreditSelector = selector({
  key: 'store-credit',
  get: ({ get }) =>
    getFeature(get, STORE_CREDITS) ? getFeature(get, STORE_CREDITS)?.enabled : true,
});

export const fulfillmentStrategiesSelector = selector({
  key: 'fulfillment-strategies',
  get: ({ get }) => getFeature(get, FULFILLMENT_STRATEGIES),
});

export const fulfillmentStrategiesEnabledSelector = selector({
  key: 'fulfillment-strategies-enabled',
  get: ({ get }) =>
    getFeature(get, FULFILLMENT_STRATEGIES)
      ? getFeature(get, FULFILLMENT_STRATEGIES)?.enabled
      : true,
});

export const reportsSelector = selector({
  key: 'reports',
  get: ({ get }) => (getFeature(get, REPORTS) ? getFeature(get, REPORTS)?.enabled : true),
});

export const productPackagesSelector = selector({
  key: 'product-packages',
  get: ({ get }) => getFeature(get, PRODUCT_PACKAGES),
});

export const priceListsSelector = selector({
  key: 'price-lists',
  get: ({ get }) => getFeature(get, PRICE_LISTS),
});

export const priceListsEnabledSelector = selector({
  key: 'price-lists-enabled',
  get: ({ get }) => (getFeature(get, PRICE_LISTS) ? getFeature(get, PRICE_LISTS)?.enabled : true),
});

export const numberOfProductsSelector = selector({
  key: 'number-of-products',
  get: ({ get }) => getFeature(get, NUMBER_OF_PRODUCTS),
});

export const customPaymentStrategiesSelector = selector({
  key: 'custom-payment-strategies',
  get: ({ get }) =>
    getFeature(get, CUSTOM_PAYMENT_STRATEGIES)
      ? getFeature(get, CUSTOM_PAYMENT_STRATEGIES)?.enabled
      : true,
});

export const onlinePaymentStrategiesSelector = selector({
  key: 'online-payment-strategies',
  get: ({ get }) =>
    getFeature(get, ONLINE_PAYMENT_STRATEGIES)
      ? getFeature(get, ONLINE_PAYMENT_STRATEGIES)?.enabled
      : true,
});

export const localPaySelector = selector({
  key: 'local-pay',
  get: ({ get }) => (getFeature(get, LOCAL_PAY) ? getFeature(get, LOCAL_PAY)?.enabled : true),
});

export const localPay2Selector = selector({
  key: 'local-pay-2-0',
  get: ({ get }) =>
    getFeature(get, LOCAL_PAY_2_0) ? getFeature(get, LOCAL_PAY_2_0)?.enabled : true,
});

export const usersSelector = selector({
  key: 'users',
  get: ({ get }) => getFeature(get, LL_USERS),
});

export const vendorsSelector = selector({
  key: 'vendors',
  get: ({ get }) => getFeature(get, VENDORS),
});

export const vendorsCountSelector = selector({
  key: 'account-available-vendors',
  get: ({ get }) => {
    const accountPlan = get(accountPlanAtom);
    if (isObjectEmpty(accountPlan)) return 100; // interpret a lack of plan as 100
    return accountPlan?.available_features?.find((f) => f.name === VENDORS)?.value || 0;
  },
});

export const vendorEnabledSelector = selector({
  key: 'vendors-enabled',
  get: ({ get }) => (getFeature(get, VENDORS) ? getFeature(get, VENDORS)?.enabled : true),
});

export const storefrontSelector = selector({
  key: 'storefront',
  get: ({ get }) => (getFeature(get, STOREFRONT) ? getFeature(get, STOREFRONT)?.enabled : true),
});

export const ordersSelector = selector({
  key: 'orders',
  get: ({ get }) => (getFeature(get, ORDERS) ? getFeature(get, ORDERS)?.enabled : true),
});

export const customersSelector = selector({
  key: 'customers',
  get: ({ get }) => (getFeature(get, CUSTOMERS) ? getFeature(get, CUSTOMERS)?.enabled : true),
});

export const paymentStrategiesSelector = selector({
  key: 'payment-strategies',
  get: ({ get }) =>
    getFeature(get, PAYMENT_STRATEGIES) ? getFeature(get, PAYMENT_STRATEGIES)?.enabled : true,
});

export const subscriptionOrdersSelector = selector({
  key: 'subscription-orders',
  get: ({ get }) =>
    getFeature(get, SUBSCRIPTION_ORDERS) ? getFeature(get, SUBSCRIPTION_ORDERS)?.enabled : true,
});

export const websiteBuilderSelector = selector({
  key: 'website-builder',
  get: ({ get }) =>
    getFeature(get, WEBSITE_BUILDER) ? getFeature(get, WEBSITE_BUILDER)?.enabled : true,
});
