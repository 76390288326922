import ChevronSmallUp from 'assets/svgIcons/ChevronSmallUp';
import ExclamationCircleFilled from 'assets/svgIcons/ExclamationCircleFilled';
import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import theme from 'styles/theme';
import './InputNumber.css';

const InputNumber = forwardRef(
  (
    {
      classes,
      onChange,
      id,
      name,
      inputClasses,
      labelClasses,
      hintClasses,
      value,
      labelText, // Text or component or text + component
      placeholder,
      Icon,
      IconRight,
      error,
      hintText, // Text or component or text + component
      disabled,
      min,
      max,
      step,
      onKeyDown,
      onBlur,
      hasArrowButtons,
      disableDecimals,
    },
    ref
  ) => {
    const internalRef = useRef(null);
    const inputField = ref || internalRef;
    const inputPattern = disableDecimals ? RegExp(/^-{0,1}\d+$/) : RegExp(/^-{0,1}\d*\.?\d*$/);

    const onWheel = (e) => {
      inputField.current.blur(); // blur when user scrolls
    };

    return (
      <div className={`input-number--container w-full ${classes}`}>
        <label htmlFor={id} className={`input-number--label ${labelClasses}`} disabled={disabled}>
          {labelText}
          <div className={`input-number--icon__container ${labelText ? 'mt-1' : ''}`}>
            {Icon && (
              <div className="input-number--icon">
                <Icon />
              </div>
            )}
            <input
              ref={inputField}
              id={id}
              name={name}
              className={`input-number--input ${inputClasses} ${
                error ? 'input-number--input__error' : ''
              }`}
              onChange={(e) => {
                const { value } = e.target;
                if (disabled) return;
                if (!!max && Number(value) > max) return onChange(max);
                if ((!!min || min === 0) && Number(value) < min) return onChange(min);
                onChange(value);
              }}
              onFocus={(e) => {
                if (e.target.value === '0') {
                  e.target.value = '';
                  onChange('');
                }
              }}
              onKeyDown={(e) => onKeyDown(e)}
              onBlur={(e) => onBlur(e)}
              onWheel={(e) => onWheel(e)}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              type="number"
              tabIndex={0}
              min={min}
              max={max}
              step={step}
              onKeyPress={(e) => {
                const { key, metaKey, ctrlKey } = e;
                if (!(metaKey || ctrlKey) && key.length === 1 && !inputPattern.test(key)) {
                  e.preventDefault();
                }
              }}
            />
            {hasArrowButtons && (
              <div className="input-number--buttons">
                <button
                  type="button"
                  disabled={disabled}
                  onClick={() => {
                    if (max && Number(value) + Number(step) > max) return;
                    onChange(value ? Number(value) + Number(step) : Number(step));
                  }}>
                  <ChevronSmallUp />
                </button>
                <button
                  type="button"
                  disabled={disabled}
                  onClick={() => {
                    if (Number(value) - Number(step) < min) return;
                    onChange(value ? Number(value) - Number(step) : -Number(step));
                  }}>
                  <ChevronSmallUp classes="transform rotate-180" />
                </button>
              </div>
            )}
            {error && (
              <div className="input-number--icon input-number--icon__right">
                <ExclamationCircleFilled stroke={theme.colors.Red500} />
              </div>
            )}
            {IconRight && (
              <div
                className={`input-number--icon input-number--icon__right ${
                  hasArrowButtons ? 'mr-8' : ''
                }`}>
                <IconRight />
              </div>
            )}
          </div>
        </label>
        {error ? <p className="input-number--error">{error}</p> : null}
        {hintText ? <p className={`input-number--hint ${hintClasses}`}>{hintText}</p> : null}
      </div>
    );
  }
);

InputNumber.propTypes = {
  classes: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  hintClasses: PropTypes.string,
  labelText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  Icon: PropTypes.elementType,
  IconRight: PropTypes.elementType,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  hasArrowButtons: PropTypes.bool,
  disableDecimals: PropTypes.bool,
};

InputNumber.defaultProps = {
  classes: '',
  value: '',
  onChange: () => {},
  name: '',
  id: '',
  labelClasses: '',
  inputClasses: '',
  hintClasses: '',
  labelText: '',
  placeholder: '',
  Icon: null,
  IconRight: null,
  error: '',
  hintText: '',
  disabled: false,
  min: '',
  max: '',
  step: '1',
  onKeyDown: () => {},
  onBlur: () => {},
  hasArrowButtons: true,
  disableDecimals: false,
};

export default InputNumber;
