import { useTranslation } from 'react-i18next';
import { useOrder } from 'hooks/storefront';
import { formatMoney } from 'helpers/localeHelpers';
import { currencyAtom } from 'state/common/currency';
import { useRecoilValue } from 'recoil';

const CartFulfillmentFreeMessage = () => {
  const { t } = useTranslation();
  const { order } = useOrder();
  const { fulfillment } = order;
  const currency = useRecoilValue(currencyAtom);

  const fulfillmentFeeExemptionAmount =
    fulfillment?.fee_exemption_amount_enabled &&
    order?.subtotal < fulfillment?.fee_exemption_amount &&
    order?.subtotal >= fulfillment?.minimum_purchase_amount
      ? formatMoney(fulfillment?.fee_exemption_amount - order?.subtotal, currency)
      : null;

  if (!fulfillmentFeeExemptionAmount) return null;

  const fulfillmentType = t(fulfillment?.type_display.toLowerCase());

  return (
    <div
      id="storefront/cart/fulfillment-message"
      className="w-full flex items-center justify-center mb-4">
      <p className={`text-Green600 font-bold text-center`}>
        {t('storefront/cart/current-order/tab--free-fulfillment', {
          amount: fulfillmentFeeExemptionAmount,
          fulfillmentType: fulfillmentType,
        })}
      </p>
    </div>
  );
};

export default CartFulfillmentFreeMessage;
