import { readCustomerCards } from 'api/storefront/customers';
import { useEffect, useState } from 'react';
import SavedCardGateway from './SavedCardGateway';
import { PAYMENT_GATEWAYS } from './index';
import { accountAtom, gatewaysSelector } from 'state/storefront/accountState';

import './SavedCards.css';
import { useRecoilValue } from 'recoil';

const SavedCards = () => {
  const [savedCards, setSavedCards] = useState({});
  const [loading, setLoading] = useState(false);
  const paymentGateways = useRecoilValue(gatewaysSelector);

  const splitCardsByGateways = (data) => {
    return PAYMENT_GATEWAYS.reduce((newData, gateway) => {
      const filtered = data?.filter((item) => item?.gateway === gateway);
      if (filtered?.length) return { ...newData, [gateway]: filtered };
      return newData;
    }, {});
  };

  const fetchCustomerCards = async () => {
    setLoading(true);
    try {
      const resp = await readCustomerCards({});
      const saveCardsData = resp?.data;
      setSavedCards(splitCardsByGateways(saveCardsData));
    } catch (e) {
      console.error('Error fetching saved payments: ', e);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCard = (card) => {
    const newSavedCards = { ...savedCards };
    if (!newSavedCards[card?.gateway]) {
      newSavedCards[card?.gateway] = [];
    }
    newSavedCards[card?.gateway].push(card);
    setSavedCards({ ...newSavedCards });
  };

  const handleDeleteCard = (source_id, gateway) => {
    const newSavedCards = { ...savedCards };
    const filteredGatewayCards = newSavedCards[gateway].filter(
      (card) => card.source_id !== source_id
    );
    newSavedCards[gateway] = [...filteredGatewayCards];
    setSavedCards({ ...newSavedCards });
  };

  useEffect(async () => {
    await fetchCustomerCards();
  }, []);

  return (
    <>
      {Object.keys(paymentGateways)?.map((gateway) => (
        <SavedCardGateway
          key={`${gateway}-${savedCards[gateway]?.source_id}`}
          gateway={gateway}
          gatewaySavedCards={savedCards[gateway]}
          gatewayKey={paymentGateways[gateway]?.gateway_key}
          gatewayName={paymentGateways[gateway]?.name}
          gatewayPaymentMethods={paymentGateways[gateway]?.gateway_payment_methods}
          loading={loading}
          fetchCustomerCards={fetchCustomerCards}
          isSingleGatway={Object.keys(paymentGateways)?.length === 1}
          handleAddCard={handleAddCard}
          handleDeleteCard={handleDeleteCard}
        />
      ))}
    </>
  );
};
export default SavedCards;
