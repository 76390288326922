import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button/Button';

const ContactInfo = ({ customerFields, onEdit }) => {
  const { t } = useTranslation();

  const name = [customerFields?.first_name, customerFields?.last_name].filter(Boolean).join(' ');

  const contactDetails = [name, customerFields?.email, customerFields?.phone]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <div className="">
        <div className="flex justify-between mb-2">
          <h4>{t('storefront/store/checkout--payment-contact-info')}</h4>
          {
            <Button classes="button button--none text-Green600" onClick={() => onEdit()}>
              {t('Edit')}
            </Button>
          }
        </div>
        <span className="text-Grey700 pt-0">{contactDetails}</span>
      </div>
    </>
  );
};

export default ContactInfo;
