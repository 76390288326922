import { CircularLoadingBar } from 'assets/svgIcons';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ON } from 'helpers/constants';
import { formatMoney } from 'helpers/localeHelpers';
import { readCustomer } from 'api/storefront/customers';
import { readCustomerCards } from 'api/storefront/customers';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import { currencyAtom } from 'state/common/currency';
import { customerAtom, accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import { checkoutPaymentErrorsAtom, priceListAtomFamily } from 'state/storefront/storeState';
import useApi from 'hooks/common/api';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import {
  CONFIRMATION_BILLING,
  CONFIRMATION_PAYMENT,
  CONFIRMATION_ORDER_NOTE,
  CONFIRMATION_OPENED_AT,
  FULFILLMENT_INSTRUCTIONS,
  CHECKOUT_COMPLETE_PATH,
} from '../../';
import {
  STRIPE_GATEWAY,
  SQUARE_GATEWAY,
  LOCAL_PAY_GATEWAY,
  CUSTOM,
  PAYRIX_GATEWAY,
} from '../../PaymentComponents';
import Pay from '../../PaymentComponents/Pay/Pay';
import CheckoutFooter from '../CheckoutFooter';
import Confirmation from './Confirmation';
import Payment from './Payment';
import SubscriptionFrequency from './SubscriptionFrequency';
import { datadogRum } from '@datadog/browser-rum';

const PaymentStep = ({ isMobile }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { priceListSlug } = useParams();
  const paymentRef = useRef();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  // states
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const paymentErrors = useRecoilValue(checkoutPaymentErrorsAtom);
  const [selectedSavedCardId, setSelectedSavedCardId] = useState(null);
  const [selectedPaymentStrategy, setSelectedPaymentStrategy] = useState(null);
  const [saveCard, setSaveCard] = useState(false);
  const {
    order,
    setOrder,
    openOrder,
    orderEntriesErrors,
    clearCart,
    priceList: priceListHook,
  } = useOrder(priceListSlug);
  const [billing, setBillingValue] = useState(() => ({
    billing_address: order?.billing_address,
  }));
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const [fulfillmentSubscriptionOptions, setFulfillmentSubscriptionOptions] = useState([]);
  const paymentStepComplete = order?.order_entries?.length > 0;
  const isPaymentGateway =
    selectedPaymentStrategy?.type === STRIPE_GATEWAY ||
    selectedPaymentStrategy?.type === SQUARE_GATEWAY ||
    selectedPaymentStrategy?.type === LOCAL_PAY_GATEWAY ||
    selectedPaymentStrategy?.type === PAYRIX_GATEWAY;
  const account = useRecoilValue(accountAtom);
  const { storefront_configuration: configuration } = account;
  const businessName = configuration?.store_name || '';
  const markupPercentage = configuration?.markup_percentage;
  const orderNeedsApproval = order?.order_entries?.some((entry) => entry.needs_approval);
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const hasOutstandingBalance =
    !order?.payment || (!!order?.payment && order?.payment?.balance > 0);
  const currency = useRecoilValue(currencyAtom);
  const formattedBalance = formatMoney(order?.payment?.balance, currency);
  const formattedPaymentBalance = formatMoney(order?.payment?.balance, currency);
  const [shouldUseStoreCredit, setShouldUseStoreCredit] = useState(
    order?.payment?.store_credit_amount > 0
  );
  const [savedCards, setSavedCards] = useState([]);
  const isFullPaidByStoreCredit =
    order?.payment?.store_credit_amount > 0 && order?.payment?.store_credit_amount >= order?.total;

  const { request: readStrategy } = useApi(readPriceListFulfillmentStrategies);

  useEffect(() => {
    if (order?.payment?.store_credit_amount > 0) setShouldUseStoreCredit(true);
  }, [order?.payment?.store_credit_amount]);

  // methods
  const setBilling = async (value) => {
    setBillingValue((prevState) => ({ ...prevState, billing_address: value }));
  };

  const isPayLaterForStrategy = (strategy) => {
    return priceList?.price_list_payment_strategies.find(
      (pLStrategy) => pLStrategy.payment_strategy === strategy?.id
    )?.pay_later;
  };

  const selectPaymentStrategy = async (strategy) => {
    setSaveCard(null);
    setSelectedSavedCardId(null);
    setSelectedPaymentStrategy({ ...strategy, pay_later: isPayLaterForStrategy(strategy) });
    const gatewayPaymentMethod = await _getGatewayPaymentMethod(strategy);
    await setOrder({
      payment: {
        payment_strategy: strategy?.id,
        pay_later: strategy?.type === CUSTOM ? false : undefined,
        gateway_payment_method: gatewayPaymentMethod,
        order_payment_strategy: {
          id: order?.payment?.order_payment_strategy?.id || undefined,
          fees: strategy?.fees?.map(({ id, ...rest }) => rest || []),
        },
      },
    });
  };

  const checkSubscriptionOptions = async (results) => {
    if (
      !!results[0]?.fulfillment_strategy_subscription_frequencies &&
      results[0]?.fulfillment_strategy_subscription_frequencies.length > 0
    ) {
      if (results[0]?.fulfillment_strategy_subscription_frequencies.length === 1) {
        await setOrder({
          subscription_plan: {
            frequency_value: results[0]?.fulfillment_strategy_subscription_frequencies[0].count,
            frequency_unit: results[0]?.fulfillment_strategy_subscription_frequencies[0].unit,
          },
        });
      }
      setFulfillmentSubscriptionOptions(results[0]?.fulfillment_strategy_subscription_frequencies);
    }
  };

  const isBillingOutOfSync = !isEqual(order?.billing_address, billing?.billing_address);

  const isStoreCreditPayment =
    (selectedPaymentStrategy?.type === CUSTOM ||
      order?.payment?.payment_strategy_type === CUSTOM) &&
    order?.payment?.store_credit_amount > 0;

  const PayButton = ({ buttonClasses, classes }) => (
    <Pay
      disabled={!paymentStepComplete || orderEntriesErrors || submittingOrder}
      order={order}
      selectedSavedCardId={selectedSavedCardId}
      redirectToComplete={() =>
        upfrontFulfillmentEnabled
          ? push(`/${priceListSlug}/${CHECKOUT_COMPLETE_PATH}/${order?.id}`)
          : push(`/${priceListSlug}/${CHECKOUT_COMPLETE_PATH}`)
      }
      buttonText={
        selectedPaymentStrategy?.type === CUSTOM ||
          order?.payment?.pay_later ||
          isStoreCreditPayment
          ? t('storefront/store/checkout--payment-place-cta')
          : t('storefront/store/checkout--payment-place-and-pay-cta', {
            amount: formattedPaymentBalance,
          })
      }
      saveCard={saveCard}
      submittingOrder={submittingOrder}
      setSubmittingOrder={setSubmittingOrder}
      paymentRef={paymentRef}
      onPay={async (options) => {
        if (isBillingOutOfSync) await setOrder({ ...billing });
        await openOrder(options);
      }}
      isPaymentGateway={isPaymentGateway}
      setOrder={setOrder}
      buttonClasses={buttonClasses}
      classes={classes}
      payCallback={() => {
        if (upfrontFulfillmentEnabled) {
          datadogRum.addAction('order-completed', { order_id: order?.id, order_total: order?.total })
          datadogRum.stopSession()
          clearCart();
        }
      }}
    />
  );

  const fetchCustomer = async () => {
    try {
      const { data } = await readCustomer();
      setCustomer((prevState) => ({
        ...prevState,
        store_credit_balance: data.store_credit_balance,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchFulfillmentSubscriptionOptions = async () => {
    const hasSubscriptionProducts = order?.order_entries?.some((entry) => {
      return entry?.is_subscription;
    });
    if (!hasSubscriptionProducts) return;

    const { results } = await readStrategy(priceList?.id, {
      id: order?.fulfillment?.fulfillment_strategy,
    });

    await checkSubscriptionOptions(results);
  };

  const _getGatewayPaymentMethod = async (strategy) => {
    const savedCardGatewayPaymentMethod = await _getSavedCardGatewayPaymentMethod(strategy);
    const paymentStrategyGatewayPaymentMethod = strategy?.payment_strategy_gateway_payment_methods
      ?.length
      ? strategy?.payment_strategy_gateway_payment_methods?.[0]?.gateway_payment_method
      : null;
    return savedCardGatewayPaymentMethod || paymentStrategyGatewayPaymentMethod;
  };

  const _getSavedCardGatewayPaymentMethod = async (strategy) => {
    if (customer?.id && strategy?.type !== CUSTOM) {
      const savedCardGatewayPaymentMethod = await fetchCustomerCards(strategy);
      return savedCardGatewayPaymentMethod;
    }
    return null;
  };

  const fetchCustomerCards = async (strategy) => {
    setSavedCards([]);
    try {
      const resp = await readCustomerCards({
        gateway: strategy?.gateway_short,
      });
      if (resp?.data && resp?.data.length > 0) {
        setSavedCards(resp?.data);
        setSelectedSavedCardId(resp?.data[0]?.source_id); // set first
        return resp?.data[0]?.gateway_payment_method?.id;
      }
    } catch (e) {
      console.error('Error fetching saved cards: ', e);
    } finally {
    }
  };

  useEffect(async () => {
    await fetchFulfillmentSubscriptionOptions();
    return await fetchCustomer();
  }, []);

  return (
    <>
      <div className="checkout__confirmation_payment col-span-3 sm:col-span-2">
        {submittingOrder && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-white z-20 flex flex-col items-center justify-center">
            <CircularLoadingBar />
            {t('storefront/store/checkout--payment-submitting-text')}
          </div>
        )}
        {paymentErrors.length > 0 && (
          <Alert
            id="payment-error"
            type="error"
            title={t('Error')}
            classes="mb-6"
            description={
              <ul className="list-disc ml-4">
                {paymentErrors.map((error, i) => (
                  // eslint-disable-next-line
                  <li key={error + i}>{error}</li>
                ))}
              </ul>
            }
          />
        )}
        {orderEntriesErrors && (
          <Alert
            id="payment-error"
            type="error"
            title={t('storefront/store/shop/cart--error-title')}
            classes="mb-6"
            description={t('storefront/store/shop/cart--error')}
          />
        )}
        {fulfillmentSubscriptionOptions.length > 0 && (
          <SubscriptionFrequency fulfillmentSubscriptionOptions={fulfillmentSubscriptionOptions} />
        )}
        <Payment
          ref={paymentRef}
          selectedPaymentStrategy={selectedPaymentStrategy}
          selectPaymentStrategy={selectPaymentStrategy}
          selectedSavedCardId={selectedSavedCardId}
          setSelectedSavedCardId={setSelectedSavedCardId}
          shouldUseStoreCredit={shouldUseStoreCredit}
          setShouldUseStoreCredit={setShouldUseStoreCredit}
          customer={customer}
          hasOutstandingBalance={hasOutstandingBalance}
          orderNeedsApproval={orderNeedsApproval}
          isPaymentGateway={isPaymentGateway}
          isPayLater={order?.payment?.pay_later}
          gatewayPaymentMethod={order?.payment?.gateway_payment_method}
          formattedBalance={formattedBalance}
          saveCard={saveCard}
          setSaveCard={setSaveCard}
          savedCards={savedCards}
          billing={billing}
          setBilling={setBilling}
          isFullPaidByStoreCredit={isFullPaidByStoreCredit}
        />
        <Confirmation
          rowsToHide={[
            CONFIRMATION_BILLING,
            CONFIRMATION_PAYMENT,
            CONFIRMATION_ORDER_NOTE,
            CONFIRMATION_OPENED_AT,
            FULFILLMENT_INSTRUCTIONS,
          ]}
        />
        <div className="mx-4 sm:mx-0">
          <PayButton buttonClasses="xl" />
        </div>
        <CheckoutFooter classes="hidden sm:flex" />
      </div>
      <div className="fixed z-50 left-0 bottom-0 right-0 p-4 border-t bg-White flex justify-end">
        <PayButton buttonClasses="w-full xl" classes="sm:max-w-xs w-full" />
      </div>
    </>
  );
};

PaymentStep.propTypes = {
  isMobile: PropTypes.bool,
};
PaymentStep.defaultProps = {
  isMobile: false,
};

export default PaymentStep;
