import { CircularLoadingBar } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from 'components/styled/Alert';
import useCheckoutOrder from '../hooks/useCheckoutOrder';
import PayButton from './CheckoutPayButton';
import CustomerNote from './CustomerNote';
import Payment from './Payment';
import SubscriptionFrequency from './SubscriptionFrequency';

const PaymentStep = ({ paymentRef }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();

  // states
  const {
    order,
    setOrder,
    orderEntriesErrors,
    setBilling,
    billing,
    isBillingOutOfSync,
    submittingOrder,
    setSubmittingOrder,
    paymentStepComplete,
    isPaymentGateway,
    orderNeedsApproval,
    hasOutstandingBalance,
    formattedBalance,
    formattedPaymentBalance,
    shouldUseStoreCredit,
    setShouldUseStoreCredit,
    savedCards,
    selectedSavedCardId,
    setSelectedSavedCardId,
    fetchCustomerCards,
    selectedPaymentStrategy,
    selectPaymentStrategy,
    isStoreCreditPayment,
    customer,
    saveCard,
    setSaveCard,
    fetchCustomer,
    placeOrder,
    clearCart,
    compareAndUpdateCustomer,
    setCustomerNote,
    customerNote,
    fulfillmentSubscriptionOptions,
    fetchFulfillmentSubscriptionOptions,
    onPay,
    validateCheckoutOrder,
    setErrors,
    isFullPaidByStoreCredit,
  } = useCheckoutOrder(priceListSlug);

  useEffect(async () => {
    if (!order?.billing_address) {
      setBilling(customer?.default_billing_address || order?.fulfillment?.shipping_address);
    } else {
      setBilling(order?.billing_address);
    }
    await fetchFulfillmentSubscriptionOptions();
    await fetchCustomer();
  }, []);

  useEffect(async () => {
    if (order?.fulfillment?.fulfillment_strategy) await fetchFulfillmentSubscriptionOptions();
  }, [order?.fulfillment?.fulfillment_strategy]);

  useEffect(() => {
    if (order?.payment?.store_credit_amount > 0) setShouldUseStoreCredit(true);
  }, [order?.payment?.store_credit_amount]);

  return (
    <>
      {submittingOrder && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-white z-20 flex flex-col items-center justify-center">
          <CircularLoadingBar />
          {t('storefront/store/checkout--payment-submitting-text')}
        </div>
      )}
      {fulfillmentSubscriptionOptions?.length > 0 && (
        <SubscriptionFrequency fulfillmentSubscriptionOptions={fulfillmentSubscriptionOptions} />
      )}
      <Payment
        ref={paymentRef}
        selectedPaymentStrategy={selectedPaymentStrategy}
        selectPaymentStrategy={selectPaymentStrategy}
        selectedSavedCardId={selectedSavedCardId}
        setSelectedSavedCardId={setSelectedSavedCardId}
        shouldUseStoreCredit={shouldUseStoreCredit}
        setShouldUseStoreCredit={setShouldUseStoreCredit}
        customer={customer}
        hasOutstandingBalance={hasOutstandingBalance}
        orderNeedsApproval={orderNeedsApproval}
        isPaymentGateway={isPaymentGateway}
        isPayLater={order?.payment?.pay_later}
        gatewayPaymentMethod={order?.payment?.gateway_payment_method}
        formattedBalance={formattedBalance}
        saveCard={saveCard}
        setSaveCard={setSaveCard}
        savedCards={savedCards}
        billing={billing}
        setBilling={setBilling}
        isFullPaidByStoreCredit={isFullPaidByStoreCredit}
      />
      <CustomerNote note={customerNote} setNote={setCustomerNote} />
      <div className="mx-4 sm:mx-0">
        <PayButton
          buttonClasses="xl"
          paymentStepComplete={paymentStepComplete}
          orderEntriesErrors={orderEntriesErrors}
          submittingOrder={submittingOrder}
          setSubmittingOrder={setSubmittingOrder}
          order={order}
          setOrder={setOrder}
          selectedSavedCardId={selectedSavedCardId}
          priceListSlug={priceListSlug}
          selectedPaymentStrategy={selectedPaymentStrategy}
          isStoreCreditPayment={isStoreCreditPayment}
          formattedPaymentBalance={formattedPaymentBalance}
          saveCard={saveCard}
          paymentRef={paymentRef}
          isBillingOutOfSync={isBillingOutOfSync}
          billing={billing}
          isPaymentGateway={isPaymentGateway}
          placeOrder={placeOrder}
          clearCart={clearCart}
          compareAndUpdateCustomer={compareAndUpdateCustomer}
          customer={customer}
          customerNote={customerNote}
          onPay={onPay}
          validateCheckoutOrder={validateCheckoutOrder}
          setErrors={setErrors}
        />
      </div>
    </>
  );
};

PaymentStep.propTypes = {};
PaymentStep.defaultProps = {};

export default PaymentStep;
