import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from 'components/styled/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import { useRecoilValue } from 'recoil';
import { priceListAtomFamily } from 'state/storefront/storeState';
import { getEarliestAvailableDate } from 'helpers/helpers';
import FulfillmentStartingPrice from './FulfillmentStartingPrice';
import { PICKUP, DELIVERY } from '.';
import { formatDateTime, formatMoney } from 'helpers/localeHelpers';
import Alert from 'components/styled/Alert/Alert';
import { useOrder } from 'hooks/storefront';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';
import { subscriptionsSelector } from 'state/storefront/accountState';
import { currencyAtom } from 'state/common/currency';

const PickupOrDelivery = ({ selectedStrategyType, selectStrategyType, errors, setErrors }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const currency = useRecoilValue(currencyAtom);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const priceListDeprecated = useRecoilValue(priceListAtomFamily(priceListSlug));
  const { priceList: priceListHook } = useOrder();
  const {
    fulfillment_summary: {
      lowest_fee_delivery,
      lowest_fee_exemption_amount_delivery,
      lowest_fee_pickup,
      lowest_fee_exemption_amount_pickup,
      pickup_available_dates,
      delivery_available_dates,
      pickup_option_count,
      delivery_option_count,
    },
  } = upfrontFulfillmentEnabled ? priceListHook : priceListDeprecated;

  // create an array only with available_dates dates, standardizing for getEarliestAvailableDate()
  const deliveryAvailableDates = delivery_available_dates.map((ad) => {
    return ad.date;
  });
  const pickupAvailableDates = pickup_available_dates.map((ad) => {
    return ad.date;
  });

  const earliest_delivery_date = getEarliestAvailableDate(deliveryAvailableDates);
  const earliest_pickup_date = getEarliestAvailableDate(pickupAvailableDates);

  const pickupDate = !!earliest_pickup_date
    ? t('storefront/store/checkout--fulfillment-option-timeline', {
        next: formatDateTime(earliest_pickup_date?.available_date, 'dddd, LL'),
        cutoff: formatDateTime(earliest_pickup_date?.cutoff_datetime, 'LLLL'),
      })
    : t('storefront/store/checkout--fulfillment-option-flexible');

  const pickupHelp =
    lowest_fee_exemption_amount_pickup > 0 && lowest_fee_pickup > 0 ? (
      <span className="mt-1 text-sm text-Green600">
        {t('storefront/store/checkout--fulfillment-free-above', {
          fee: formatMoney(lowest_fee_exemption_amount_pickup, currency),
          type: PICKUP,
        })}
      </span>
    ) : null;

  const deliveryDate = !!earliest_delivery_date
    ? t('storefront/store/checkout--fulfillment-option-timeline', {
        next: formatDateTime(earliest_delivery_date.available_date, 'dddd, LL'),
        cutoff: formatDateTime(earliest_delivery_date?.cutoff_datetime, 'LLLL'),
      })
    : '';

  const deliveryHelp =
    lowest_fee_exemption_amount_delivery > 0 && lowest_fee_delivery > 0 ? (
      <span className="mt-1 text-sm text-Green600">
        {t('storefront/store/checkout--fulfillment-free-above', {
          fee: formatMoney(lowest_fee_exemption_amount_delivery, currency),
          type: DELIVERY,
        })}
      </span>
    ) : null;

  return (
    <div className="col-span-3 sm:col-span-2">
      <Card
        title={t('storefront/store/checkout--fulfillment-option-title')}
        classes="mb-4 sm:mb-6 rounded-none sm:rounded-xl"
        id="storefront/store/checkout--fulfillment">
        <>
          {!!errors.strategy_type && (
            <Alert
              id="fulfillment-strategy-type-alert"
              type="error"
              title={t('storefront/store/shop/cart/details--error-strategy')}
              classes="mb-6"
            />
          )}
          <div className="flex flex-col border rounded-lg mb-0">
            {!!pickup_option_count && (
              <div className="flex items-center px-4 py-4">
                <InputCheckboxRadio
                  id="fulfillment-type-pickup"
                  name="fulfillment-type-pickup"
                  label={t('Pickup')}
                  help={
                    <p className="flex flex-col">
                      {pickupDate}
                      {pickupHelp}
                    </p>
                  }
                  checked={selectedStrategyType === PICKUP}
                  onChange={() => {
                    setErrors((prevState) => ({ ...prevState, strategy_type: '' }));
                    selectStrategyType(PICKUP);
                  }}
                  type="radio"
                />
                <FulfillmentStartingPrice price={lowest_fee_pickup} />
              </div>
            )}
            {!!delivery_option_count && (
              <div className="flex flex-col border-t first:border-none px-4 py-4 ">
                <div className="flex items-center">
                  <InputCheckboxRadio
                    id="fulfillment-type-delivery"
                    name="fulfillment-type-delivery"
                    label={t('Delivery')}
                    help={
                      <p className="flex flex-col">
                        {deliveryDate}
                        {deliveryHelp}
                      </p>
                    }
                    checked={selectedStrategyType === DELIVERY}
                    onChange={() => {
                      setErrors((prevState) => ({ ...prevState, strategy_type: '' }));
                      selectStrategyType(DELIVERY);
                    }}
                    type="radio"
                  />
                  <FulfillmentStartingPrice floor price={lowest_fee_delivery} />
                </div>
              </div>
            )}
          </div>
        </>
      </Card>
    </div>
  );
};

PickupOrDelivery.propTypes = {
  selectedStrategyType: PropTypes.string,
  selectStrategyType: PropTypes.func,
};

PickupOrDelivery.defaultProps = {
  selectedStrategyType: null,
  selectStrategyType: () => {},
};

export default PickupOrDelivery;
