import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.20713 10.9596C7.8166 10.569 7.18344 10.569 6.79291 10.9596C6.40239 11.3501 6.40239 11.9832 6.79291 12.3738L8.20713 10.9596ZM9.16669 13.3333L8.45958 14.0404C8.85011 14.431 9.48327 14.431 9.87379 14.0404L9.16669 13.3333ZM13.2071 10.7071C13.5977 10.3166 13.5977 9.68342 13.2071 9.29289C12.8166 8.90237 12.1834 8.90237 11.7929 9.29289L13.2071 10.7071ZM14.8334 5.83333V15.8333H16.8334V5.83333H14.8334ZM14.1667 16.5H5.83335V18.5H14.1667V16.5ZM5.16669 15.8333V5.83333H3.16669V15.8333H5.16669ZM5.83335 5.16667H7.50002V3.16667H5.83335V5.16667ZM12.5 5.16667H14.1667V3.16667H12.5V5.16667ZM5.83335 16.5C5.46516 16.5 5.16669 16.2015 5.16669 15.8333H3.16669C3.16669 17.3061 4.36059 18.5 5.83335 18.5V16.5ZM14.8334 15.8333C14.8334 16.2015 14.5349 16.5 14.1667 16.5V18.5C15.6394 18.5 16.8334 17.3061 16.8334 15.8333H14.8334ZM16.8334 5.83333C16.8334 4.36057 15.6394 3.16667 14.1667 3.16667V5.16667C14.5349 5.16667 14.8334 5.46514 14.8334 5.83333H16.8334ZM5.16669 5.83333C5.16669 5.46514 5.46516 5.16667 5.83335 5.16667V3.16667C4.36059 3.16667 3.16669 4.36057 3.16669 5.83333H5.16669ZM6.79291 12.3738L8.45958 14.0404L9.87379 12.6262L8.20713 10.9596L6.79291 12.3738ZM9.87379 14.0404L13.2071 10.7071L11.7929 9.29289L8.45958 12.6262L9.87379 14.0404ZM9.16669 3.5H10.8334V1.5H9.16669V3.5ZM10.8334 4.83333H9.16669V6.83333H10.8334V4.83333ZM9.16669 4.83333C8.7985 4.83333 8.50002 4.53486 8.50002 4.16667H6.50002C6.50002 5.63943 7.69393 6.83333 9.16669 6.83333V4.83333ZM11.5 4.16667C11.5 4.53486 11.2015 4.83333 10.8334 4.83333V6.83333C12.3061 6.83333 13.5 5.63943 13.5 4.16667H11.5ZM10.8334 3.5C11.2015 3.5 11.5 3.79848 11.5 4.16667H13.5C13.5 2.69391 12.3061 1.5 10.8334 1.5V3.5ZM9.16669 1.5C7.69393 1.5 6.50002 2.69391 6.50002 4.16667H8.50002C8.50002 3.79848 8.7985 3.5 9.16669 3.5V1.5Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
