import { useParams } from 'react-router-dom';
import { priceListAtomFamily } from 'state/storefront/storeState';
import ModalPortal from 'components/styled/ModalPortal';
import { PickupLocations, DeliveryAvailability } from '.';

const FulfillmentModals = ({ modal, setModal, pickupLocations, loading }) => {
  const { priceListSlug } = useParams();
  const priceList = useRecoilValue(priceListAtomFamily(priceListSlug));

  if (!modal?.id) return null;
  return (
    <ModalPortal
      target={modal.id}
      title={modal.title}
      description={modal.description}
      classes={`p-0 overflow-visible ${modal.id === 'pickup-locations' ? 'pb-4' : ''}`}
      containerClasses="h-screen fixed"
      hasCloseButton={modal.hasCloseButton}
      handleClose={modal.onClose ? () => modal.onClose() : () => setModal({})}>
      {modal.id === 'pickup-locations' && (
        <PickupLocations pickupLocations={pickupLocations} loading={loading} />
      )}
      {modal.id === 'delivery-availability' && <DeliveryAvailability priceListId={priceList?.id} />}
    </ModalPortal>
  );
};

export default FulfillmentModals;
