import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COUNTRIES, GATEWAY_PAYMENT_METHOD } from 'helpers/constants';
import { tokenizeACH } from 'api/storefront/payrix';
import InputCreditCard from 'components/InputCreditCard';
import InputAch from 'components/InputCreditCard/InputAch';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import GatewayHeading from './GatewayHeading';

const GatewayPaymentMethod = forwardRef(
  (
    {
      loading,
      gatewayPaymentMethodName,
      gatewayPaymentMethodId,
      gatewayPaymentMethods, // TODO: this is not needed here
      selectedPaymentMethodName,
      onPaymentMethodChange,
      modalStyling,
      isSinglePaymentMethod,
      gatewayDetails,
      setPaymentErrors,
      tokenizeACH,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const disableStyling = modalStyling && isSinglePaymentMethod;
    const isCard =
      gatewayPaymentMethodName === GATEWAY_PAYMENT_METHOD.CARD &&
      selectedPaymentMethodName === GATEWAY_PAYMENT_METHOD.CARD;

    const isACH =
      gatewayPaymentMethodName === GATEWAY_PAYMENT_METHOD.ACH &&
      selectedPaymentMethodName === GATEWAY_PAYMENT_METHOD.ACH;

    const isGatewayPaymentMethod = isCard || isACH;

    const isEFT = gatewayPaymentMethodName === GATEWAY_PAYMENT_METHOD.ACH && gatewayDetails?.countryCode === COUNTRIES.CA;

    const [error, setError] = useState('');
    return (
      <>
        {gatewayPaymentMethods?.length > 1 && (
          <div className={`flex px-4 py-4 border-t first:border-none`}>
            <InputCheckboxRadio
              type="radio"
              name={gatewayPaymentMethodName}
              id={gatewayPaymentMethodName}
              label={isEFT ? t(`global/eft`) : t(`global/${gatewayPaymentMethodName}`)}
              checked={selectedPaymentMethodName === gatewayPaymentMethodName}
              inputClasses="flex-none"
              loading={loading}
              onChange={() => onPaymentMethodChange(gatewayPaymentMethodId)}
            />
          </div>
        )}
        <>
          {isGatewayPaymentMethod && (
            <div
              className={`${disableStyling ? '' : 'bg-Grey50 border border-Gray200 p-4'} ${gatewayPaymentMethods.length > 1 ? '' : 'rounded-lg'
                }`}>
              {!disableStyling && (
                <GatewayHeading gatewayPaymentMethodName={gatewayPaymentMethodName} />
              )}
              {isCard && (
                <InputCreditCard
                  gatewayDetails={gatewayDetails}
                  ref={ref}
                  setError={(error) => {
                    setError(error);
                    if (!!setPaymentErrors) setPaymentErrors((prevState) => [...prevState, error]);
                  }}
                />
              )}
              {isACH && (
                <InputAch
                  gatewayDetails={gatewayDetails}
                  ref={ref}
                  tokenizeACH={tokenizeACH}
                  setError={(error) => {
                    setError(error);
                    if (!!setPaymentErrors) setPaymentErrors((prevState) => [...prevState, error]);
                  }}
                />
              )}
              {error && <p className="error-text mt-2">{error}</p>}
            </div>
          )}
        </>
      </>
    );
  }
);

GatewayPaymentMethod.propTypes = {
  gatewayPaymentMethodName: PropTypes.string,
  gatewayPaymentMethodId: PropTypes.number,
  selectedPaymentMethodName: PropTypes.string,
  isSinglePaymentMethod: PropTypes.bool,
  loading: PropTypes.bool,
  modalStyling: PropTypes.bool,
  selectedPaymentMethod: PropTypes.object,
  onPaymentMethodChange: PropTypes.func,
  gatewayDetails: PropTypes.object,
  gatewayPaymentMethods: PropTypes.array,
  setPaymentErrors: PropTypes.func,
};

GatewayPaymentMethod.defaultProps = { tokenizeACH };

export default GatewayPaymentMethod;
