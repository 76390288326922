import axios from 'axios';
import { COUNTRIES } from 'helpers/constants';

export const tokenizeACH = (data, headers, countryCode) => {
  return axios.post(
    countryCode === COUNTRIES.CA ? process.env.REACT_APP_PAYRIX_TOKEN_API_CA : process.env.REACT_APP_PAYRIX_TOKEN_API,
    data,
    { headers: headers }
  );
};
