import PropTypes from 'prop-types';

const FilterSetOption = ({ label, count, showCount }) => {
  const labelClasses = `text-base w-full font-medium ${
    count || !showCount ? 'text-Grey800' : 'text-Grey400'
  }`;
  const counterClasses = `font-medium text-base pl-3 ${(count || 0) === 0 ? 'text-Grey400' : 'text-Grey500'}`;

  return (
    <div className="flex flex-row justify-between">
      <span className={labelClasses}>{label}</span>
      {showCount && <span className={counterClasses}>{` ${count || 0}`}</span>}
    </div>
  );
};

FilterSetOption.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  showCount: PropTypes.bool,
};

FilterSetOption.defaultProps = {
  label: '',
  count: 0,
  showCount: true,
};

export default FilterSetOption;
