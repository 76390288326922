import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { PAYRIX } from 'helpers/constants';
import { tokenizeACH } from 'api/storefront/payrix';
import { PayrixInputACH } from './Payrix';

const InputAch = forwardRef(({ gatewayDetails, setError, tokenizeACH }, ref) => {
  const { gatewayShort, gatewayKey, payrixMerchantId, customerId, payrixCustomersApi, countryCode } =
    gatewayDetails;
  return (
    <>
      {gatewayShort === PAYRIX && (
        <PayrixInputACH
          ref={ref}
          payrixPublicKey={gatewayKey}
          merchantID={payrixMerchantId}
          payrixCustomersApi={payrixCustomersApi}
          customerId={customerId}
          setError={setError}
          tokenizeACH={tokenizeACH}
          countryCode={countryCode}
        />
      )}
    </>
  );
});

InputAch.propTypes = {
  setError: PropTypes.func,
  tokenizeACH: PropTypes.func,
};

InputAch.defaultProps = {
  gateway: null,
  setError: () => { },
  tokenizeACH,
};

export default InputAch;
export { PAYRIX };
