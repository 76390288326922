import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Money from 'components/styled/Money';
import { formatMoney } from 'helpers/localeHelpers';
import { useRecoilValue } from 'recoil';
import { currencyAtom } from 'state/common/currency';

const FulfillmentStartingPrice = ({ price, floor }) => {
  const { t } = useTranslation();
  const currency = useRecoilValue(currencyAtom);
  const formattedPrice = formatMoney(price, currency);

  return price ? (
    <span className="whitespace-nowrap">
      {floor ? (
        `${t('storefront/store/checkout--fulfillment-option-from', {
          price: formattedPrice,
        })}`
      ) : (
        <Money value={price} currency={currency} />
      )}
    </span>
  ) : (
    <span className="text-Green600 whitespace-nowrap">
      {t('storefront/store/checkout--fulfillment-option-free')}
    </span>
  );
};

FulfillmentStartingPrice.propTypes = {
  price: PropTypes.number,
  floor: PropTypes.bool,
};

FulfillmentStartingPrice.defaultProps = {
  price: null,
  floor: false,
};

export default FulfillmentStartingPrice;
