import PropTypes from 'prop-types';

import Button from 'components/styled/Button';
import { capitalize } from 'helpers/helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteCardModal from './DeleteCardModal';

const SingleSavedCard = ({
  gateway,
  brand,
  last4,
  exp_month,
  exp_year,
  source_id,
  handleDeleteCard,
}) => {
  const { t } = useTranslation();
  const [deleteCardModalEnabled, setDeleteCardModalEnabled] = useState(false);

  return (
    <>
      {deleteCardModalEnabled && (
        <DeleteCardModal
          gateway={gateway}
          last4={last4}
          source_id={source_id}
          closeModal={() => setDeleteCardModalEnabled(false)}
          handleDeleteCard={handleDeleteCard}
        />
      )}
      <div className="single-saved-card">
        <div className="single-saved-card--left">
          <p>{capitalize(brand)}</p>
        </div>
        <div className="single-saved-card--center">
          <p>{last4}</p>
          {exp_month && exp_year && <p>{`${exp_month}/${exp_year}`}</p>}
        </div>
        <div className="single-saved-card--right">
          <Button onClick={() => setDeleteCardModalEnabled(true)} classes="button button--white">
            {t('Delete')}
          </Button>
        </div>
      </div>
      <hr className="mt-3 mb-3"></hr>
    </>
  );
};

SingleSavedCard.propTypes = {
  gateway: PropTypes.string,
  brand: PropTypes.string,
  last4: PropTypes.string,
  exp_month: PropTypes.number,
  exp_year: PropTypes.number,
  source_id: PropTypes.string,
  handleDeleteCard: PropTypes.func,
};

SingleSavedCard.defaultProps = {
  gateway: '',
  brand: '',
  last4: '',
  exp_month: 0,
  exp_year: 0,
  source_id: '',
  handleDeleteCard: () => {},
};

export default SingleSavedCard;
