import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { frequencyLabelTranslationMapping, getDayOfWeek } from 'helpers/subscriptionHelpers';
import useMonthlyDate from 'hooks/common/monthlyDate';
import { useOrder } from 'hooks/storefront';
import Card from 'components/styled/Card/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio/InputCheckboxRadio';

const SubscriptionFrequency = ({ fulfillmentSubscriptionOptions }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { order, setOrder } = useOrder(priceListSlug);
  const [loading, setLoading] = useState(false);
  const fulfillmentDayOfWeek = getDayOfWeek(order?.fulfillment?.fulfillment_date);
  const { day: dayOfMonth, weekdayMonthOccurrence } = useMonthlyDate(
    order?.fulfillment?.fulfillment_date
  );

  const handleSubscriptionFrequencyChange = async (option) => {
    setLoading(true);
    await setOrder({
      subscription_plan: {
        frequency_value: option.count,
        frequency_unit: option.unit,
      },
    });
    setLoading(false);
  };

  if (!fulfillmentSubscriptionOptions) return null;

  return (
    <div className="col-span-3 sm:col-span-2">
      <Card
        title={t('storefront/checkout/subscription-frequency--title')}
        text={t('storefront/checkout/subscription-frequency--text')}
        classes="mb-4 sm:mb-6 rounded-none sm:rounded-xl"
        id="storefront/store/checkout--fulfillment">
        <>
          <div className="flex flex-col border rounded-lg mb-0">
            {fulfillmentSubscriptionOptions?.map((option) => (
              <div
                className="flex flex-col px-4 py-4 border-t first:border-none"
                onClick={() => handleSubscriptionFrequencyChange(option)}>
                <InputCheckboxRadio
                  id="fulfillment-type-pickup"
                  name="fulfillment-type-pickup"
                  label={t(frequencyLabelTranslationMapping[option?.unit], {
                    count: option?.count,
                    unit: option?.unit,
                    day: fulfillmentDayOfWeek,
                    dayOfMonth: dayOfMonth?.string,
                    weekdayMonthOccurrence: weekdayMonthOccurrence?.string,
                  })}
                  checked={
                    order?.subscription_plan?.frequency_value === option?.count &&
                    order?.subscription_plan?.frequency_unit === option?.unit
                  }
                  type="radio"
                  loading={loading}
                />
              </div>
            ))}
          </div>
        </>
      </Card>
    </div>
  );
};

SubscriptionFrequency.propTypes = {
  fulfillmentSubscriptionOptions: PropTypes.object,
};

SubscriptionFrequency.defaultProps = {
  fulfillmentSubscriptionOptions: null,
};

export default SubscriptionFrequency;
