import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { loadPayengine } from 'payengine';
import './LocalPayInputCreditCard.css';
import theme from 'styles/theme';
const { Red600, Grey300, Grey500, Grey700 } = theme.colors;

const LocalPayInputCreditCard = forwardRef(({ localPayPublishableKey, setError }, ref) => {
  const [form, setForm] = useState(null);
  const cardHolderRef = useRef(null);
  const cardNumberRef = useRef(null);
  const cardCvcRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardZipRef = useRef(null);
  const successColor = theme.colors.green[600];
  const errorColor = Red600;
  const css = {
    fontFamily: 'font-family: "Inter", sans-serif;',
    boxSizing: 'border-box',
    lineHeight: '1.5em',
    fontSize: '14px',
    fontWeight: '200',
    border: `${Grey300} 1px solid`,
    borderRadius: '4px',
    color: '#31325F',
    width: '100%',
    height: '42px',
    padding: '0 10px',
    '&::placeholder': {
      color: Grey500,
    },
    '&:hover': {
      border: `${Grey700} 1px solid`,
    },
    '&:focus': {
      border: `${Grey700} 1px solid`,
    },
  };

  const getValidationErrors = (e) => {
    const errors = [];
    for (const [key, value] of Object.entries(e)) {
      if (typeof value !== 'object') continue;
      if (value?.message) errors.push(value.message);
      else if (value?.errorMessages[0]) errors.push(`${key} ${value.errorMessages[0]}`);
    }

    return errors;
  };

  useImperativeHandle(ref, () => ({
    async tokenize() {
      try {
        const { token, card_data } = await form.createCard();
        const { id, brand, last_4: last4, exp_month, exp_year, name, address_zip } = card_data;
        return {
          token,
          card: {
            id,
            name,
            brand,
            last4,
            exp_month,
            exp_year,
            address_zip,
          },
        };
      } catch (e) {
        console.error('error', e);
        const errors = getValidationErrors(e);
        setError(errors[0]);
      }
    },
  }));

  const initializePayengineForm = async () => {
    if (!form) {
      const pe = await loadPayengine({ publicKey: localPayPublishableKey });
      const f = await pe.SecureFields.create();
      initializeFormFields(f);
      setForm(f);
    }
  };

  const initializeFormFields = (f) => {
    f.field(`#${cardHolderRef?.current?.id}`, {
      type: 'text',
      name: 'card_holder',
      placeholder: 'Card holder',
      validations: ['required'],
      css,
    });
    f.field(`#${cardNumberRef?.current?.id}`, {
      type: 'card-number',
      name: 'card_number',
      successColor,
      errorColor,
      placeholder: 'Card number',
      showCardIcon: true,
      validations: ['required', 'validCardNumber'],
      css,
    });
    f.field(`#${cardCvcRef?.current?.id}`, {
      type: 'card-security-code',
      name: 'card_cvc',
      successColor,
      errorColor,
      placeholder: 'CVC',
      maxLength: 4,
      validations: ['required', 'validCardSecurityCode'],
      css,
    });
    f.field(`#${cardExpiryRef?.current?.id}`, {
      type: 'card-expiration-date',
      name: 'card_exp',
      successColor,
      errorColor,
      placeholder: 'MM / YY',
      validations: ['required', 'validCardExpirationDate'],
      css,
    });
    f.field(`#${cardZipRef?.current?.id}`, {
      type: 'zip-code',
      name: 'address_zip',
      successColor,
      errorColor,
      placeholder: 'Zip Code',
      validations: ['postal_code/us,ca'],
      css,
    });
  };

  useEffect(() => {
    initializePayengineForm();
  }, []);

  return (
    <form className="localpay-input-cc grid gap-2 grid-cols-5">
      <div ref={cardHolderRef} id="card-holder" className="col-span-5"></div>
      <div ref={cardNumberRef} id="card-number" className="md:col-span-3 col-span-5"></div>
      <div className="md:col-span-2 col-span-5  flex">
        <div ref={cardExpiryRef} id="card-expiry"></div>
        <div ref={cardCvcRef} id="card-cvc" className="pl-2"></div>
        <div ref={cardZipRef} id="card-zip" className="pl-2"></div>
      </div>
    </form>
  );
});

export default LocalPayInputCreditCard;
