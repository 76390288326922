import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';
import { useRecoilValue } from 'recoil';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';
import { subscriptionsSelector } from 'state/storefront/accountState';
import Complete from 'pages/storefront/Store/components/Complete/Complete';
import Confirmation from './Confirmation';

const CheckoutComplete = ({ openOrder }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const history = useHistory();
  const { order: hookOrder, resetOrder, priceList } = useOrder(priceListSlug);

  const order = !!openOrder?.id ? openOrder : hookOrder;

  return (
    <Complete
      order={order}
      text={
        <Trans i18nKey="storefront/store/checkout--complete-instructions">
          We've sent an order confirmation and reciept to
          <span className="font-semibold">{{ email: order?.customer?.email }}</span>. If the email
          does not arrive within two minutes please check your spam folder to see if it was routed
          there.
        </Trans>
      }
      children={
        <>
          <h2>{t('storefront/store/checkout--complete-details-title')}</h2>
          <Confirmation readOnly singleOrder={order} />
        </>
      }
      alertTranslationKey={'storefront/store/checkout--complete-order-number'}
    />
  );
};
export default CheckoutComplete;
