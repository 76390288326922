import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={classes}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8333 13.8333H10V10.5H9.16667M10 7.16667H10.0083M17.5 10.5C17.5 14.6421 14.1421 18 10 18C5.85786 18 2.5 14.6421 2.5 10.5C2.5 6.35786 5.85786 3 10 3C14.1421 3 17.5 6.35786 17.5 10.5Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
