import moment from 'moment';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { cartActiveSelector, fulfillmentWizardActiveAtom } from 'state/storefront/storeState';
import Card from 'components/styled/Card/Card';
import FulfillmentEmptyState from './FulfillmentEmptState';
import FulfillmentSummaryAddress from './FulfillmentSummaryAddress';
import FulfillmentSummaryDate from './FulfillmentSummaryDate';
import FulfillmentSummaryInstructions from './FulfillmentSummaryInstructions';
import FulfillmentSummaryTitle from './FulfillmentSummaryTitle';

const FulfillmentSummary = ({ order, isFulfillmentComplete }) => {
  const fulfillmentType = order?.fulfillment?.type_display;
  const fulfillmentName = order?.fulfillment?.fulfillment_strategy_name;
  const fulfillmentDate = order?.fulfillment?.fulfillment_date;
  const pickupTime =
    !!order?.fulfillment?.pickup_start_time && !!order?.fulfillment?.pickup_end_time
      ? `${moment(order.fulfillment.pickup_start_time, 'HH:mm').format('h:mm A')} - ${moment(
          order.fulfillment.pickup_end_time,
          'HH:mm'
        ).format('h:mm A')}`
      : null;
  const address = order?.fulfillment?.fulfillment_address;
  const instructions = order?.fulfillment?.instructions;

  const setFulfillmentWizardActive = useSetRecoilState(fulfillmentWizardActiveAtom);
  const [cartActive, setCartActive] = useRecoilState(cartActiveSelector);

  const editFulfillment = () => {
    setCartActive(true);
    setFulfillmentWizardActive(true);
  };

  useEffect(() => {
    if (!isFulfillmentComplete && !cartActive) {
      editFulfillment();
    }
  }, []);

  return (
    <Card classes="rounded-t-none border-t-0 mb-4">
      {isFulfillmentComplete ? (
        <>
          <FulfillmentSummaryTitle
            fulfillmentType={fulfillmentType}
            fulfillmentName={fulfillmentName}
            editFulfillment={editFulfillment}
          />
          <FulfillmentSummaryDate
            fulfillmentType={fulfillmentType}
            fulfillmentDate={fulfillmentDate}
            pickupTime={pickupTime}
          />
          <FulfillmentSummaryAddress fulfillmentType={fulfillmentType} address={address} />
          <FulfillmentSummaryInstructions
            fulfillmentType={fulfillmentType}
            instructions={instructions}
          />
        </>
      ) : (
        <FulfillmentEmptyState editFulfillment={editFulfillment} />
      )}
    </Card>
  );
};

export default FulfillmentSummary;
