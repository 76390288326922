import PropTypes from 'prop-types';
import { SpinnerSmall } from 'assets/svgIcons';

const Loading = ({ classes, copy }) => (
  <>
    <div className={`w-full flex justify-center ${classes}`}>
      <SpinnerSmall />
    </div>
    <div className="relative w-full flex flex-col items-center mt-2">
      <span className="py-2 px-4 bg-Grey100 text-Grey400 rounded z-10">{copy}</span>
      <hr className="absolute top-1/2 left-0 right-0" />
    </div>
  </>
);

Loading.propTypes = {
  classes: PropTypes.string,
  copy: PropTypes.string,
};

export default Loading;
