import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Complete from '../Complete';

const DeferredCheckoutComplete = ({ order }) => {
  const { t } = useTranslation();

  return (
    <Complete
      order={order}
      text={
        <Trans i18nKey="storefront/store/deferred-checkout--complete-instructions">
          We’ve sent a payment confirmation email to
          <span className="font-semibold">{{ email: order?.customer?.email }}</span>. If the email
          does not arrive within two minutes please check your spam folder to see if it was routed
          there.
        </Trans>
      }
      alertTranslationKey={'storefront/store/deferred-checkout--complete-order-number'}
    />
  );
};
export default DeferredCheckoutComplete;
