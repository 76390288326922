import { useEffect, useState } from 'react';
import { api } from 'api/storefront';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SubscriptionHistoryCard from './SubscriptionHistoryCard';
import { customerAtom } from 'state/storefront/accountState';
import Loading from '../../../Loading';
import Button from 'components/styled/Button';

import { ORDER_HISTORY_PAGE_SIZE } from '..';
import { readOrderSubscriptions } from 'api/storefront/order-subscriptions';

const SubscriptionHistory = () => {
  const { t } = useTranslation();
  const [orderSubscriptions, setOrderSubscriptions] = useState({
    loading: true,
    data: [],
    next: null,
    count: 0,
  });
  const customer = useRecoilValue(customerAtom);

  const fetchOrderSubscriptions = async (resetOrders = false) => {
    setOrderSubscriptions((prevState) => ({ ...prevState, loading: true }));
    try {
      const orderParams = {
        ordering: 'plan_number',
        customer_id: customer?.id,
        page_size: ORDER_HISTORY_PAGE_SIZE,
        is_subscription: true,
      };

      const { data } = orderSubscriptions?.next
        ? await api(orderSubscriptions?.next)
        : await readOrderSubscriptions(orderParams);

      setOrderSubscriptions((prevState) => ({
        ...prevState,
        data: resetOrders ? data?.results : [...prevState.data, ...data?.results],
        next: data?.next,
        count: data?.count,
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setOrderSubscriptions((prevState) => ({ ...prevState, loading: false }));
    }
  };

  useEffect(() => fetchOrderSubscriptions(), []);

  return (
    <section className="flex flex-col w-full">
      <>
        {orderSubscriptions?.loading && orderSubscriptions?.data?.length === 0 ? (
          <Loading copy={t('storefront/my-account/order-history--loading')} />
        ) : (
          <>
            {orderSubscriptions?.data?.length > 0 ? (
              orderSubscriptions.data.map((orderSubscription) => (
                <SubscriptionHistoryCard
                  orderSubscription={orderSubscription}
                  fetchOrderSubscriptions={fetchOrderSubscriptions}
                />
              ))
            ) : (
              <h2>{t('storefront/my-account/order-history--no-orders')}</h2>
            )}
            {orderSubscriptions.next && (
              <div className="w-full relative flex items-center justify-center mt-6">
                <hr className="absolute top-1/2 bottom-1/2 left-0 right-0 h-px bg-Grey200" />
                <Button
                  onClick={() => fetchOrderSubscriptions()}
                  disabled={orderSubscriptions?.loading}>
                  {t('global/Load More')}
                </Button>
              </div>
            )}
            {orderSubscriptions?.data?.length > 0 && (
              <p>
                {t('orders/table/table-footer-text', {
                  from: 1,
                  to: orderSubscriptions?.data?.length,
                  total: orderSubscriptions?.count,
                })}
              </p>
            )}
          </>
        )}
      </>
    </section>
  );
};
export default SubscriptionHistory;
