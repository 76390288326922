import { isObjectEmpty } from 'helpers/helpers';
import { atom, selector } from 'recoil';
const ACTIVE = 'active';
const CANCELED = 'canceled';
const TRIALING = 'trialing';

export const authAtom = atom({
  key: 'auth',
  default: {
    access: null,
    refresh: null,
    slug: null,
  },
});

export const userAtom = atom({
  key: 'user',
  default: {},
});

export const accountAtom = atom({
  key: 'account',
  default: {},
});

export const accountPlanAtom = atom({
  key: 'account-plan',
  default: {},
});

export const accountActiveSelector = selector({
  key: 'account-active',
  get: ({ get }) => {
    const accountPlan = get(accountPlanAtom);
    if (isObjectEmpty(accountPlan)) return true; // interpret a lack of plan as active
    if (
      accountPlan?.subscription_details?.status === ACTIVE ||
      accountPlan?.subscription_details?.status === TRIALING
    )
      return true;
    return false;
  },
});
