import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { formatMoney } from 'helpers/localeHelpers';
import { currencyAtom } from 'state/common/currency';
import { customerAtom } from 'state/storefront/accountState';
import { useOrder } from 'hooks/storefront';
import Alert, { SUCCESS } from 'components/styled/Alert';
import { SECONDARY } from 'components/styled/Alert/index';
import Button from 'components/styled/Button';
import Card from 'components/styled/Card';

const StoreCredit = ({ shouldUseStoreCredit, setShouldUseStoreCredit }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const customer = useRecoilValue(customerAtom);
  const { loading, order, setOrder } = useOrder(priceListSlug);

  const currency = useRecoilValue(currencyAtom);
  const formattedStoreCreditAmount = formatMoney(order?.payment?.store_credit_amount, currency);
  const formattedStoreCreditBalance = formatMoney(
    customer?.store_credit_balance - order.payment?.store_credit_amount,
    currency
  );
  const fullyPaidByStoreCredit = order.payment?.store_credit_amount === order.total;
  const partiallyPaidByStoreCredit =
    order.payment?.store_credit_amount > 0 && !fullyPaidByStoreCredit;
  const availableStoreCreditNotApplied =
    order.payment?.store_credit_amount === 0 && customer?.store_credit_balance > 0;

  const applyStoreCredit = async () =>
    await setOrder({
      payment: { skip_store_credit: false },
    });

  // effects
  useEffect(() => {
    if (order?.payment?.store_credit_amount > 0) setShouldUseStoreCredit(true);
  }, []);

  useEffect(async () => {
    if (shouldUseStoreCredit && order?.payment?.balance !== 0) {
      await applyStoreCredit();
    }
  }, [order?.payment?.balance]);

  const getAlertProps = () => {
    switch (true) {
      case fullyPaidByStoreCredit:
        return {
          id: 'store-credit-applied--full',
          type: SUCCESS,
          description: (
            <div className="text-sm text-Green800 font-medium">
              {t('storefront/store/checkout/payment/store-credit/fully-paid-with-store-credit', {
                storeCreditApplied: formattedStoreCreditAmount,
                storeCreditBalance: formattedStoreCreditBalance,
              })}
            </div>
          ),
        };
      case partiallyPaidByStoreCredit:
        return {
          id: 'store-credit-applied--partial',
          type: SUCCESS,
          description: (
            <div className="text-sm text-Green800 font-medium">
              {t(
                'storefront/store/checkout/payment/store-credit/partially-paid-with-store-credit',
                {
                  storeCreditApplied: formattedStoreCreditAmount,
                  storeCreditBalance: formattedStoreCreditBalance,
                }
              )}
            </div>
          ),
        };
      case availableStoreCreditNotApplied:
        return {
          id: 'store-credit-unapplied',
          type: SECONDARY,
          description: (
            <div className="text-sm text-Beige900 font-medium">
              {t('storefront/store/checkout/payment/store-credit/store-credit-not-applied', {
                storeCreditApplied: formattedStoreCreditBalance,
              })}
            </div>
          ),
        };
      default:
        return null;
    }
  };

  const getButtonProps = () => {
    if (shouldUseStoreCredit) {
      return {
        id: 'storefront/store/checkout/payment/store-credit-unapply',
        disabled: loading,
        classes: 'button--white sm w-min self-end',
        onClick: async () => {
          setShouldUseStoreCredit(false);
          await setOrder({ payment: { store_credit_amount: 0, skip_store_credit: true } });
        },
        children: t('storefront/store/checkout--payment--remove-store-credit'),
      };
    } else {
      return {
        id: 'storefront/store/checkout/payment/store-credit-apply',
        disabled: loading,
        classes: `w-fit self-end sm ${
          availableStoreCreditNotApplied ? 'button--green' : 'button--white'
        }`,
        onClick: async () => {
          await applyStoreCredit();
          setShouldUseStoreCredit(true);
        },
        children: t('storefront/store/checkout--payment--apply-store-credit'),
      };
    }
  };

  const alertProps = getAlertProps();
  const buttonProps = getButtonProps();

  return (
    <Card
      title={t('global/Store Credit')}
      classes="p-0 border-none text-base text-Grey800 font-medium">
      {alertProps && <Alert {...alertProps} />}
      <Button {...buttonProps} />
    </Card>
  );
};

StoreCredit.propTypes = {
  shouldUseStoreCredit: PropTypes.bool.isRequired,
  setShouldUseStoreCredit: PropTypes.func.isRequired,
};

export default StoreCredit;
