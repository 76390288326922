import { useRecoilValue } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { accountAtom } from 'state/storefront/accountState';
import { screenSizeAtom } from 'state/appState';
import { VIEWPORT_SIZES } from 'helpers/constants';
import { Hamburger } from 'assets/svgIcons';
import { ABOUT, ACCOUNT } from './../..';
import { scrollTo } from 'helpers/helpers';
import { useOrder } from 'hooks/storefront';

const Account = ({ setIsHamburgerVisible }) => {
  const { priceList } = useOrder();
  const { priceListSlug } = useParams();
  const { width } = useRecoilValue(screenSizeAtom);
  const account = useRecoilValue(accountAtom);
  const { push } = useHistory();
  const { storefront_configuration: configuration } = account;
  const name = configuration?.store_name || '';
  const logoSrc = configuration?.logo;
  const location = configuration?.store_address
    ? configuration?.store_address?.city
      ? `${configuration?.store_address?.city}, ${configuration?.store_address?.province}`
      : `${configuration?.store_address?.province}`
    : '';

  const goHome = async () => {
    const slug = !!priceListSlug ? priceListSlug : priceList?.slug;
    const home = `/${![ACCOUNT, ABOUT].some((str) => slug?.includes(str)) ? slug : ''}`;

    push(home);
    scrollTo(0, 0);
  };

  return (
    <div className="store__header_account flex mr-6">
      {VIEWPORT_SIZES.lg < width ? (
        <div className="store__header_account_image mr-3 cursor-pointer" onClick={goHome}>
          {!!logoSrc && <img alt="logo" src={logoSrc} className="h-12 w-12 rounded-md" />}
        </div>
      ) : (
        <div
          className="flex items-center"
          onClick={() => setIsHamburgerVisible((prevState) => !prevState)}>
          <Hamburger classes="self-center mr-4" />
        </div>
      )}
      <div>
        <h3 onClick={goHome} className="font-bold text-Grey700 cursor-pointer">
          {name}
        </h3>

        <div className="text-sm text-Grey500"> {location} </div>
      </div>
    </div>
  );
};

export default Account;
