import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BLANK_TEXT_EDITOR } from 'helpers/constants';

const ProductCategoryCard = ({ name, description, count }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="store__product-category-card">
        <div className="flex flex-row items-end">
          <span className="flex-1 font-bold text-Grey800 text-xl md:text-[2rem]">{name}</span>
          <span className="text-Grey500 text-sm flex whitespace-nowrap items-center">
            {t('storefront/store/shop/category--n-items', { n: count })}
          </span>
        </div>
        <div className="flex">
          {description && description !== BLANK_TEXT_EDITOR && (
            <div className="border-0 text-Grey600 pt-4">
              <span
                className="store__product-category-card--description"
                dangerouslySetInnerHTML={{ __html: description }}></span>
            </div>
          )}
        </div>
      </div>

      <hr className="w-[calc(100%-2rem)] sm:w-auto mb-2 sm:mb-6 mt-4 mx-4 sm:mx-0" />
    </>
  );
};

ProductCategoryCard.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
};

ProductCategoryCard.defaultProps = {
  name: '',
  count: 0,
};

export default ProductCategoryCard;
