import PolkaDotBanner from 'components/styled/PolkaDotBanner/PolkaDotBanner';
import { useTranslation } from 'react-i18next';

const EmptyUpcomingSubscriptionCard = () => {
  const { t } = useTranslation();

  return (
    <PolkaDotBanner
      classes="mt-4 text-center"
      message={<p>{t('storefront/my-account/subscription-history/empty-upcoming-order')}</p>}
    />
  );
};

export default EmptyUpcomingSubscriptionCard;
