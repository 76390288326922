import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { customerAtom, loginActiveAtom, accountAtom } from 'state/storefront/accountState';
import Button from 'components/styled/Button';
import DropdownListButton from 'components/styled/DropdownListButton';
import { useStorefrontAuth } from 'hooks/storefront';
import {
  Logout,
  ClipboardCheck,
  User,
  CreditCard,
  Key,
  CurrencyDollarSmall,
  ClipboardDropDown,
  ArrowDoubleRotatingCircle,
} from 'assets/svgIcons';
import { STORE_CREDITS } from 'state/backoffice/featureSelectors';
import useTreatment from 'hooks/common/splitTreatment';
import { ON } from 'helpers/constants';

const Profile = () => {
  const { t } = useTranslation();
  const setLoginActive = useSetRecoilState(loginActiveAtom);
  const customer = useRecoilValue(customerAtom);
  const resetCustomer = useResetRecoilState(customerAtom);
  const { clearAuth } = useStorefrontAuth();
  const { connected_gateways, available_features } = useRecoilValue(accountAtom);
  const storefrontSubscriptionsEnabled = useTreatment('storefront_subscriptions') == ON;

  const storeCreditFeatureEnabled = available_features?.find(
    (feature) => feature.name === STORE_CREDITS
  )?.enabled;

  return !customer?.id ? (
    <Button classes="button--white" onClick={() => setLoginActive(true)}>
      {t('storefront/store/shop/header--login')}
    </Button>
  ) : (
    <DropdownListButton
      Content={t('storefront/store/shop/header--my-account')}
      buttonClasses="button--white relative"
      dropdown>
      {[
        <Link to="/account/order-history" className="flex items-center text-Grey600 w-full">
          <li key="order-history" className="py-2 px-4 w-full">
            <ClipboardCheck classes="mr-3" />
            {t('Order History')}
          </li>
        </Link>,
        storefrontSubscriptionsEnabled && (
          <Link
            to="/account/subscription-history"
            className="flex items-center text-Grey600 w-full">
            <li key="subscription-history" className="py-2 px-4 border-b border-Grey100 w-full">
              <ArrowDoubleRotatingCircle classes="mr-3" />
              {t('storefront/store/shop/header--subscription-history')}
            </li>
          </Link>
        ),
        <Link to="/account/account-settings" className="flex items-center text-Grey600 w-full">
          <li key="account-settings" className="py-2 px-4 w-full">
            <User classes="mr-3" />
            {t('Account Settings')}
          </li>
        </Link>,
        <Link to="/account/price-lists" className="flex items-center text-Grey600 w-full">
          <li key="price-lists" className="py-2 px-4 w-full">
            <ClipboardDropDown classes="mr-3" />
            {t('Price Lists')}
          </li>
        </Link>,
        connected_gateways?.length >= 1 && (
          <Link to="/account/saved-cards" className="flex items-center text-Grey600 w-full">
            <li key="saved-cards" className="py-2 px-4 w-full">
              <CreditCard classes="mr-3" />
              {t('Saved Cards')}
            </li>
          </Link>
        ),
        storeCreditFeatureEnabled && (
          <Link to="/account/store-credit" className="flex items-center text-Grey600 w-full">
            <li key="store-credit" className="py-2 px-4 w-full">
              <CurrencyDollarSmall classes="mr-3" />
              {t('Store Credit')}
            </li>
          </Link>
        ),
        //   <li key="store-credit" className="py-2 px-4 border-b border-Grey100">
        //   <Link
        //     to='/account/store-credit'
        //     className="flex items-center text-Grey600">
        //     <ChatBubble classes="mr-3" />
        //     {t('Contact Us')}
        //   </Link>
        // </li>,
        <li
          key="logout"
          className="flex items-center text-left py-2 px-4 border-t border-Grey100 hover:bg-Grey100"
          onClick={async () => {
            await clearAuth();
            await resetCustomer();
            location.href = '/';
          }}>
          <Logout />
          <span className="ml-2 text-Grey600">{t('storefront/store/shop/header--logout')}</span>
        </li>,
      ]}
    </DropdownListButton>
  );
};

export default Profile;
