import React, { forwardRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { SpinnerSmall } from 'assets/svgIcons';
import { CREATED_PAYMENT_METHOD } from '..';
import theme from 'styles/theme';

const StripeCard = forwardRef(({ setError }, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loaded, setLoaded] = useState(false);
  const { Grey900, Grey400 } = theme.colors;

  useImperativeHandle(ref, () => ({
    async getToken() {
      if (!stripe || !elements) return; // not loaded
      const card = elements.getElement(CardElement);
      if (card == null) return; // no card found
      return card;
    },
    async tokenize() {
      try {
        const card = await this.getToken();

        const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card });

        if (error) {
          throw new Error(error?.message);
        } else {
          return {
            ...CREATED_PAYMENT_METHOD,
            id: paymentMethod?.id,
            token: paymentMethod?.id,
            card: {
              brand: paymentMethod?.card?.brand,
              last4: paymentMethod?.card?.last4,
            },
          };
        }
      } catch (err) {
        setError(err?.message);
      }
    },
  }));

  return (
    <div className="flex">
      <div className="flex-grow">
        <CardElement
          onReady={() => setLoaded(true)}
          options={{
            style: {
              base: {
                fontSize: '14px', // rem is relative to different html
                color: Grey900,
                fontFamily: theme.fontFamily.sans.toString(),
                '::placeholder': {
                  color: Grey400,
                },
              },
            },
          }}
        />
      </div>
      {!loaded && (
        <div className="justify-self-end">
          <SpinnerSmall />
        </div>
      )}
    </div>
  );
});

StripeCard.propTypes = {
  setError: PropTypes.func,
};

StripeCard.defaultProps = {
  setError: () => {},
};

export default StripeCard;
