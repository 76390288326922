import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <rect x="2" y="2" width="16" height="16" rx="8" fill={theme.colors.Beige200} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62861 4.64856C9.86702 4.55319 10.133 4.55319 10.3714 4.64856L15.3714 6.64856C15.751 6.80042 16 7.16813 16 7.57703V12.577C16 12.9859 15.751 13.3536 15.3714 13.5055L10.3714 15.5055C10.133 15.6009 9.86702 15.6009 9.62861 15.5055L4.62861 13.5055C4.24895 13.3536 4 12.9859 4 12.577V7.57703C4 7.16813 4.24895 6.80042 4.62861 6.64856L9.62861 4.64856ZM6 9.05407V11.9L9 13.1V10.2541L6 9.05407ZM11 10.2541V13.1L14 11.9V9.05407L11 10.2541ZM12.3074 7.57703L10 8.5L7.69258 7.57703L10 6.65407L12.3074 7.57703Z"
      fill={theme.colors.Beige700}
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
};

export default Svg;
