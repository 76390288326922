import PropTypes from 'prop-types';
import './Button.css';
import ChevronMediumLeft from 'assets/svgIcons/ChevronMediumLeft';

// gem requires the complicated inline styles
// due to the url png and odd degree of the gradient
const Button = ({ id, children, onClick, classes, type, disabled, name, dropdown, ...props }) => (
  <button
    {...props}
    id={id}
    className={`button ${classes} ${dropdown ? 'button--dropdown' : ''}`}
    type={type}
    disabled={disabled}
    name={name}
    onClick={(e) => onClick(e)}>
    {children}
    {dropdown && <ChevronMediumLeft classes="button--chevron ml-1 transform -rotate-90" />}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  onClick: PropTypes.func,
  classes: PropTypes.string,
  gem: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  dropdown: PropTypes.bool,
};

Button.defaultProps = {
  id: '',
  children: undefined,
  onClick: () => { },
  classes: '',
  gem: false,
  type: 'button',
  disabled: false,
  name: '',
  dropdown: false,
};

export default Button;
