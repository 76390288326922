import { useEffect } from 'react';
import PropTypes from 'prop-types';
import './ModalPortal.css';
import X from 'assets/svgIcons/X';
import { KEY_CODES } from 'helpers/constants';
import Card from '../Card';
import Button from '../Button';
import Portal from '../../Portal';

const ModalPortal = ({
  containerClasses,
  classes,
  handleClose,
  title,
  description,
  children,
  hasCloseButton,
  childMarginBottom,
  target,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  return (
    <Portal target={target}>
      <div className={`modal-portal--container ${containerClasses} `} aria-hidden>
        <div className="modal-portal--close" onClick={handleClose} role="button" aria-hidden></div>
        <Card
          classes={`modal-portal p-0 ${classes}`}
          onClick={(e) => e.stopPropagation()}
          childMarginBottom={childMarginBottom}>
          {hasCloseButton && (
            <Button
              classes="button modal-portal--close-button"
              onClick={(e) => handleClose(e)}
              onKeyDown={(e) => e.keyCode === KEY_CODES.ESCAPE && handleClose(e)}>
              <X />
            </Button>
          )}
          {title && <h3 className="modal-portal--title mb-1 p-6 pb-0">{title}</h3>}
          {description && <p className="modal-portal--description">{description}</p>}
          {children}
        </Card>
      </div>
    </Portal>
  );
};

ModalPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.string,
  containerClasses: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  handleClose: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  childMarginBottom: PropTypes.bool,
  target: PropTypes.string,
};

ModalPortal.defaultProps = {
  children: undefined,
  classes: '',
  containerClasses: '',
  title: '',
  description: '',
  handleClose: () => {},
  hasCloseButton: false,
  childMarginBottom: false,
  target: '',
};

export default ModalPortal;
