import { useTranslation, Trans } from 'react-i18next';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { accountAtom, loginActiveAtom, customerAtom } from 'state/storefront/accountState';
import PropTypes from 'prop-types';
import { privatePriceListRequestAccess } from 'api/storefront/price-lists';
import { useParams, useHistory } from 'react-router-dom';
import Button from 'components/styled/Button';

const PrivatePriceList = ({ privatePriceListSlug }) => {
  const { t } = useTranslation();
  const account = useRecoilValue(accountAtom);
  const [customer, setCustomer] = useRecoilState(customerAtom);
  const setLoginActive = useSetRecoilState(loginActiveAtom);
  const { priceListSlug } = useParams();
  const history = useHistory();

  const title = !customer?.email
    ? t('private-private-list--title--no-auth')
    : customer?.[[`requestSent-${priceListSlug}`]]
    ? t('private-private-list--request-sent-title')
    : t('private-private-list--title');

  const onRequestAccess = () => {
    if (customer?.id) {
      try {
        privatePriceListRequestAccess(privatePriceListSlug, { customer: customer?.id });
        setCustomer((prevState) => ({ ...prevState, [`requestSent-${priceListSlug}`]: true }));
      } catch (e) {}
    } else {
      setLoginActive(true);
    }
  };

  return (
    <div className="private-price-list-request">
      <h1>{title}</h1>
      <div className="private-price-list-request--text">
        {customer?.[[`requestSent-${priceListSlug}`]] ? (
          <Trans
            i18nKey="private-private-list--request-sent-text"
            seller_business_name={account?.business_name}>
            <p>
              You will receive an email from {{ seller_business_name: account?.business_name }} if
              you are granted access.
            </p>
            <p>Thanks for reaching out! Your request to buy from this price list has been sent.</p>
          </Trans>
        ) : (
          <>
            <Trans
              i18nKey="private-private-list--help-text"
              seller_business_name={account?.business_name}>
              <p>
                You cannot view this page because {{ seller_business_name: account?.business_name }}
                has set it to private.
              </p>
            </Trans>
            {customer?.email && <p>{t('private-private-list--request-access')}</p>}
          </>
        )}
      </div>
      {customer?.email ? (
        <>
          {customer?.[[`requestSent-${priceListSlug}`]] ? null : (
            <Button onClick={() => onRequestAccess()}>
              {t('private-private-list--button-text')}
            </Button>
          )}
        </>
      ) : (
        <div className="flex flex-col w-full mt-6">
          <Button classes="button" onClick={() => setLoginActive(true)}>
            {t('Login')}
          </Button>
          <Button
            classes="button button--white mt-4"
            onClick={() => history.push(`/register?redirect=${priceListSlug ?? ''}`)}>
            {t('Create Account')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PrivatePriceList;
