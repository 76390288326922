import Summary from "pages/storefront/Store/components/Summary/Summary";

const CheckoutSummary = ({
    loading,
    openOrder,
    order,
    setOrder,
    isCheckedOut,
    totalUnitQuantity,
}) => {
    return (
        <Summary
            loading={loading}
            order={!!openOrder?.id ? openOrder : order}
            setOrder={setOrder}
            readOnly={isCheckedOut}
            totalUnitQuantity={
                !!openOrder?.id
                    ? openOrder?.order_entries?.reduce(
                        (a, b) =>
                            a +
                            (b?.storefront_unit_quantity > 0
                                ? b?.storefront_unit_quantity
                                : b?.unit_quantity),
                        0
                    )
                    : totalUnitQuantity
            }
        />
    );
};

export default CheckoutSummary;
