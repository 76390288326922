import { useRecoilValue } from 'recoil';
import { IMAGE_WIDTH_TYPES } from 'helpers/constants';
import { removeHtmlTags } from 'helpers/helpers';
import { accountAtom } from 'state/storefront/accountState';
import MetaTags from 'components/MetaTags';
import Footer from './Footer';
import Hero from './Hero';

const About = () => {
  const account = useRecoilValue(accountAtom);
  const {
    about_header_image: aboutHeaderImage,
    about_title: aboutUsTitle,
    about_description: aboutUsDescription,
    about_header_image_width: aboutHeaderImageWidth,
  } = account?.storefront_configuration;

  const seoProps = account?.about_us_seo_tags;

  return (
    <div className="flex flex-col min-h-screen bg-White w-full">
      <MetaTags {...seoProps} />
      {aboutHeaderImageWidth === IMAGE_WIDTH_TYPES.FULL_WIDTH && (
        <div className="flex w-full">
          <Hero banner={aboutHeaderImage} />
        </div>
      )}
      <div className="flex-grow mx-auto px-auto space-y-2 max-w-md md:max-w-2xl pb-28">
        {aboutHeaderImageWidth === IMAGE_WIDTH_TYPES.FIXED_WIDTH && (
          <Hero banner={aboutHeaderImage} />
        )}
        <div className="flex justify-center text-center">
          <h1 className="text-gray-700 text-2xl md:text-[32px] font-bold">{aboutUsTitle}</h1>
        </div>
        <div className="flex justify-center pt-4">
          <p
            className="rich-text-editor-render mx-4 md:mx-0 self-center text-Grey600 text-sm md:text-base max-w-lg w-full"
            dangerouslySetInnerHTML={{
              __html: aboutUsDescription,
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

About.propTypes = {};

export default About;
