import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { formatProblemJSONErrors } from 'helpers/helpers';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { authenticate } from 'api/storefront/auth';
import { createCustomer } from 'api/storefront/customers';
import { readPriceLists } from 'api/storefront/price-lists';
import { toastAtom } from 'state/appState';
import { accountAtom, loginActiveAtom } from 'state/storefront/accountState';
import useFormState from 'hooks/common/formState';
import { useStorefrontAuth } from 'hooks/storefront';
import AddressForm from 'components/AddressForm/AddressForm';
import LabelOptionalRequired from 'components/LabelOptionalRequired';
import Button from 'components/styled/Button';
import Card from 'components/styled/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import InputText from 'components/styled/InputText';

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const account = useRecoilValue(accountAtom);
  const { require_address, require_phone_number, require_business_name } =
    account?.storefront_configuration || {};

  // states
  const { setAuth } = useStorefrontAuth();

  const queryParams = useLocation().search;

  const {
    formState: customer,
    setFormStateValue: setCustomerValue,
    toPayload,
    setFormStateErrors: setErrors,
  } = useFormState(
    {
      email: new URLSearchParams(queryParams).get('email'),
      password: '',
      first_name: '',
      last_name: '',
      company: '',
      accept_terms: false,
      phone: '',
      default_address: {},
    },
    ['email', 'password', 'first_name', 'last_name', 'accept_terms', 'phone', 'default_address']
  );
  const [showPassword, setShowPassword] = useState(false);
  const setToasts = useSetRecoilState(toastAtom);
  const [loginActive, setLoginActive] = useRecoilState(loginActiveAtom);
  const [addressErrors, setAddressErrors] = useState({});

  const redirect = new URLSearchParams(queryParams).get('redirect');

  useEffect(() => setLoginActive(false), []);

  //methods
  const register = async () => {
    try {
      const resp = await createCustomer(toPayload(false));

      const toastId = generateRandomNumber();
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('storefront/store/register--success'),
          type: 'success',
        },
      ]);
      const authResp = await authenticate(email.value, password.value);
      setAuth(authResp.data);
      await redirectAfterRegistration();
    } catch (e) {
      setErrors(e.response?.data?.errors);
      setAddressErrors(formatProblemJSONErrors(e.response?.data));
    }
  };

  const redirectAfterRegistration = async () => {
    if (redirect) {
      history.push(`/${redirect}`);
      location.reload();
    } else {
      await redirectToPriceList();
    }
  };

  const redirectToPriceList = async () => {
    try {
      const serverPriceListsResponse = await readPriceLists();
      const priceLists = serverPriceListsResponse?.data?.results;
      const priceListsLength = priceLists?.length;
      if (priceListsLength > 1) {
        history.push('/account/price-lists');
      } else if (priceListsLength === 1) {
        history.push(`/${priceLists[0]?.slug}`);
      } else {
        history.push('/');
      }
    } catch (e) {
      setErrors((prevState) => ({ ...prevState, ...e.message }));
    }
  };

  const { email, password, first_name, last_name, company, accept_terms, phone, default_address } =
    customer;

  return (
    <div aria-hidden>
      <Card classes="max-w-450 my-12 px-6">
        <div className="store--register__title">
          <h1 className="mb-4">{t('storefront/store/register--title')}</h1>
          <p>{t('storefront/store/register--title--help-text')}</p>
        </div>
        <div className="w-full flex justify-between gap-2 mb-4">
          <InputText
            id="first_name"
            name="first_name"
            labelText={
              <div className="flex">
                {t('storefront/store/register--first-name')}
                <span className="text-Green600">*</span>
              </div>
            }
            onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
            value={first_name.value}
            error={first_name.error}
          />
          <InputText
            id="last_name"
            name="last_name"
            labelText={
              <div className="flex">
                {t('storefront/store/register--last-name')}
                <span className="text-Green600">*</span>
              </div>
            }
            onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
            value={last_name.value}
            error={last_name.error}
          />
        </div>
        <InputText
          id="email"
          name="email"
          type="email"
          labelText={
            <div className="flex">
              {t('storefront/store/login--email')}
              <span className="text-Green600">*</span>
            </div>
          }
          onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
          value={email.value}
          classes="mb-4"
          error={email.error}
        />
        {require_address && (
          <AddressForm
            name="default_address"
            handleChange={(address) => setCustomerValue('default_address', address)}
            address={default_address?.value}
            defaultCountryCode={account?.country_code}
            addressField="default_address"
            errors={addressErrors}
          />
        )}
        {require_phone_number && (
          <InputText
            id="phone"
            name="phone"
            type="tel"
            value={phone.value}
            onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
            required={require_phone_number}
            labelText={
              <div className="flex">
                {t('global/Phone Number')}
                <span className="text-Green600">*</span>
              </div>
            }
            error={phone.error}
          />
        )}
        <InputText
          id="password"
          name="password"
          labelText={
            <div className="w-full flex justify-between items-center">
              <h4>
                {t('storefront/store/register--password')}
                <span className="text-Green600">*</span>
              </h4>
              <span
                className="text-Grey400 cursor-pointer"
                onClick={() => setShowPassword((prevState) => !prevState)}>
                {showPassword ? t('Hide') : t('Show')}
              </span>
            </div>
          }
          hintText={t('storefront/store/register--password--hint-text')}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
          value={password.value}
          classes="mb-4"
          error={password.error}
        />
        <hr className="mb-4" />
        <InputText
          id="company"
          name="company"
          labelText={
            <LabelOptionalRequired
              label={t('storefront/store/register--business-name')}
              required={require_business_name}
            />
          }
          onChange={(e) => setCustomerValue(e.target.name, e.target.value)}
          value={company.value}
          classes="mb-4"
          error={company.error}
        />
        <InputCheckboxRadio
          id="accept_terms"
          name="accept_terms"
          onChange={(e) => setCustomerValue(e.target.name, !accept_terms.value)}
          checked={accept_terms.value}
          error={accept_terms.error}
          label={t('register/details/terms')}
          help={
            <Trans i18nKey="storefront/store/register--terms--links">
              View
              <Link
                className="font-bold"
                to={{ pathname: t('storefront/store/register--terms--links--service') }}
                target="_blank">
                Terms of Service
              </Link>
            </Trans>
          }
        />
        <Button classes="mb-6" onClick={register}>
          {t('storefront/store/register--create-account')}
        </Button>
        <span className="text-sm text-center register--login-account">
          <Trans i18nKey="storefront/store/register--login">
            Or <button onClick={() => setLoginActive(true)}>login to an existing account</button>
          </Trans>
        </span>
      </Card>
    </div>
  );
};

Register.propTypes = { onClose: PropTypes.func };

Register.defaultProps = { onClose: () => {} };

export default Register;
