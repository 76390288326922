import { ChevronMediumLeft } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import Button from './Button/Button';

// TODO: Support dangerouslySetInnerHTML and render rich text
const ReadMoreDescription = ({ classes, title, desc, numberOfLinesToShow, titleClasses, descriptionClasses }) => {
  const { t } = useTranslation();
  const textContainer = useRef();
  const [textContainerOpen, setTextContainerOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [lineHeight, setLineHeight] = useState(16);

  useLayoutEffect(() => {
    if (textContainer.current) {
      const p = textContainer.current;
      // After trial and error, nested elements affect offsetHeight (i.e. dangerouslySetInnerHTML)
      // but text content affects the scroll container height
      const textHeight = p.children[0] ? p.children[0].offsetHeight : p.scrollHeight;
      const pStyles = window.getComputedStyle(p);
      const _lineHeight = parseInt(pStyles.getPropertyValue('line-height'), 10);
      setLineHeight(_lineHeight);

      if (textHeight > _lineHeight * numberOfLinesToShow) {
        setShowButton(true);
      }
    }
  }, [textContainer]);

  if (desc?.length === 0) return null;
  return (
    <div className="read-more-description--container">
      <div className={`read-more-description overflow-hidden ${classes}`}>
        {!!title && (
          <div className="w-full relative">
            <h4 className={`z-20 bg-White relative inline-block pr-2 ${titleClasses}`}>{title}</h4>
            <hr className="w-full absolute top-1/2 right-0 left-0 transform translate-y-1/2" />
          </div>
        )}

        <p
          ref={textContainer}
          // CSS only allows height transitions between two number values (not h-auto)
          // arbitrarily large value max-h workaround until a better solution is discovered
          className={`transition-all h-auto ${descriptionClasses} ${textContainerOpen && showButton ? `max-h-[50rem] overflow-scroll` : `overflow-hidden`
            }`}
          style={
            textContainerOpen && showButton
              ? {}
              : {
                maxHeight: `${lineHeight * numberOfLinesToShow}px`,
              }
          }>
          {desc}
        </p>
        <div className="relative w-full flex flex-col justify-start">
          {showButton && (
            <div
              className={`w-full absolute -top-full h-6 bg-gradient-to-b from-transparent to-white ${textContainerOpen ? 'hidden' : 'visible'
                }`}></div>
          )}
          {showButton && (
            <Button
              classes="button--none flex text-Grey700 mr-1"
              onClick={() => setTextContainerOpen(!textContainerOpen)}>
              {t('global/Read More')}
              <ChevronMediumLeft
                classes={textContainerOpen ? `rotate-90` : `-rotate-90`}
                stroke={theme.colors.Grey400}
              />
            </Button>
          )}
        </div>
      </div>
    </div >
  );
};

ReadMoreDescription.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  classes: PropTypes.string,
  numberOfLinesToShow: PropTypes.number,
  titleClasses: PropTypes.string,
  descriptionClasses: PropTypes.string,
};

ReadMoreDescription.defaultProps = {
  title: '',
  desc: '',
  classes: '',
  numberOfLinesToShow: 3,
  titleClasses: '',
  descriptionClasses: '',
};

export default ReadMoreDescription;
