import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { customerAtom, loginActiveAtom } from 'state/storefront/accountState';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button/Button';
import InputText from 'components/styled/InputText';

const Customer = ({
  customerFields,
  setCustomerFields,
  enablePhoneOnCheckout,
  errors,
  setErrors,
  onSaveChanges,
  updateCustomerInOrder,
  order,
}) => {
  const { t } = useTranslation();
  const customer = useRecoilValue(customerAtom);
  const setLoginActive = useSetRecoilState(loginActiveAtom);
  const { priceListSlug } = useParams();

  //states
  const { fieldErrors, loading } = useOrder(priceListSlug);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setErrors((prevState) => ({ ...prevState, [name]: '' }));
    setCustomerFields((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h4>{t('storefront/store/checkout--customer-title')}</h4>
        {!customer?.id && (
          <span>
            {t('storefront/store/checkout--customer-existing-account')}{' '}
            <a className="cursor-pointer" onClick={() => setLoginActive(true)}>
              {t('storefront/store/checkout--customer-existing-account-login')}
            </a>
          </span>
        )}
      </div>
      <div className="flex flex-col sm:flex-row gap-6">
        <InputText
          id="first_name"
          name="first_name"
          value={customerFields.first_name}
          onChange={(e) => handleChange(e)}
          required
          disabled={loading}
          labelText={
            <div>
              <Trans
                i18nKey="storefront/store/checkout--customer-first-label-green-asterisk"
                components={{ 1: <a /> }}
              />
            </div>
          }
          placeholder={t('storefront/store/checkout--customer-first-placeholder')}
          error={fieldErrors['customer_info.first_name'] || errors?.first_name}
          onBlur={() => updateCustomerInOrder()}
        />
        <InputText
          id="last_name"
          name="last_name"
          value={customerFields.last_name}
          onChange={(e) => handleChange(e)}
          required
          disabled={loading}
          labelText={
            <div>
              <Trans
                i18nKey="storefront/store/checkout--customer-last-label-green-asterisk"
                components={{ 1: <a /> }}
              />
            </div>
          }
          placeholder={t('storefront/store/checkout--customer-last-placeholder')}
          error={fieldErrors['customer_info.last_name'] || errors?.last_name}
          onBlur={() => updateCustomerInOrder()}
        />
      </div>
      <InputText
        id="email"
        name="email"
        type="email"
        value={customerFields.email}
        onChange={(e) => {
          setErrors((prevState) => ({ ...prevState, ['customer_info.email']: '' }));
          handleChange(e);
        }}
        required
        disabled={customer?.id || loading}
        labelText={
          <div>
            <Trans
              i18nKey="storefront/store/checkout--customer-email-label-green-asterisk"
              components={{ 1: <a /> }}
            />
          </div>
        }
        hintText={t('storefront/store/checkout--customer-email-hint')}
        error={fieldErrors['customer_info.email'] || errors?.email}
        onBlur={() => updateCustomerInOrder()}
      />
      <InputText
        id="phone"
        name="phone"
        type="tel"
        value={customerFields.phone}
        onChange={(e) => handleChange(e)}
        required={enablePhoneOnCheckout}
        disabled={loading}
        labelText={
          enablePhoneOnCheckout ? (
            <div>
              <Trans
                i18nKey="storefront/store/checkout--customer-phone-label-green-asterisk"
                components={{ 1: <a /> }}
              />
            </div>
          ) : (
            t('storefront/store/checkout--customer-phone-label--not-required')
          )
        }
        hintText={t('storefront/store/checkout--customer-phone-hint')}
        error={fieldErrors['customer_info.phone'] || errors?.phone}
        onBlur={() => updateCustomerInOrder()}
      />
      {customer?.id && (
        <Button classes="self-end" onClick={() => onSaveChanges()}>
          {t('Save Changes')}
        </Button>
      )}
    </>
  );
};

export default Customer;
