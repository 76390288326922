import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={classes}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 11L5 10M5 10V20C5 20.5523 5.44772 21 6 21H9H15H18C18.5523 21 19 20.5523 19 20V10M5 10L7 6L12 3.5L17 6L19 10M19 10L21 11"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="9" y="14" width="6" height="7" rx="1" stroke={stroke} strokeWidth="2" />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
