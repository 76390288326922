import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 8.33342H17.5M5.83333 12.5001H6.66667M10 12.5001H10.8333M5 15.8334H15C16.3807 15.8334 17.5 14.7141 17.5 13.3334V6.66675C17.5 5.28604 16.3807 4.16675 15 4.16675H5C3.61929 4.16675 2.5 5.28604 2.5 6.66675V13.3334C2.5 14.7141 3.61929 15.8334 5 15.8334Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
