import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
      fill={theme.colors.Green200}
    />
    <path
      d="M7.38791 14.8232C7.92847 14.9364 8.45845 14.59 8.57167 14.0494C8.68489 13.5089 8.33846 12.9789 7.7979 12.8657C7.25835 12.7527 6.77097 12.4208 6.47485 11.9079C5.92257 10.9513 6.25032 9.72816 7.20691 9.17587L7.70691 10.0419C7.91292 10.3987 8.43788 10.3672 8.59973 9.98831L9.90913 6.92293C10.0401 6.61639 9.84007 6.26998 9.50913 6.23011L6.19973 5.83139C5.79066 5.7821 5.50089 6.22098 5.70691 6.5778L6.20691 7.44382C4.29374 8.54839 3.63823 10.9948 4.7428 12.9079C5.33518 13.934 6.3151 14.5985 7.38791 14.8232Z"
      fill={theme.colors.Green700}
    />
    <path
      d="M12.5774 5.21333C12.0369 5.10012 11.5069 5.44654 11.3937 5.9871C11.2804 6.52766 11.6269 7.05764 12.1674 7.17086C12.707 7.28387 13.1943 7.61573 13.4905 8.12862C14.0428 9.08521 13.715 10.3084 12.7584 10.8607L12.2584 9.99465C12.0524 9.63782 11.5274 9.66933 11.3656 10.0482L10.0562 13.1136C9.92526 13.4202 10.1253 13.7666 10.4562 13.8064L13.7656 14.2052C14.1747 14.2544 14.4644 13.8156 14.2584 13.4587L13.7584 12.5927C15.6716 11.4882 16.3271 9.04179 15.2225 7.12862C14.6301 6.10259 13.6502 5.43803 12.5774 5.21333Z"
      fill={theme.colors.Green700}
    />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: '',
};

export default Svg;
