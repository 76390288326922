import {
  FILTER_CATEGORY,
  FILTER_ON_SALE,
  FILTER_UNCATEGORIZED_VALUE,
  FILTER_OUT_OF_STOCK,
  FILTER_SUBSCRIPTIONS_AVAILABLE,
  FILTER_ONE_TIME_AVAILABLE,
  FILTER_PAGE_SIZE,
  FILTER_TAGS,
  FILTER_SEARCH,
  FILTER_VENDORS,
} from 'pages/storefront/Store/components';
import { FILTER_ON_SALE_TOGGLE } from 'pages/storefront/Store/components/index';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  readPriceListCategories,
  readPriceListTags,
  readPriceListVendors,
  readPriceListUnCategorizedCount,
} from 'api/storefront/price-list-products';
import { accountAtom } from 'state/storefront/accountState';
import {
  activeFiltersAtom,
  categoriesFilterAtom,
  tagsFilterAtom,
  vendorsFilterAtom,
} from 'state/storefront/storeState';
import useSearchParamsState from 'hooks/common/searchParamsState';
import { useOrder } from 'hooks/storefront';
import useProducts from './products';

const useFilters = () => {
  const { t } = useTranslation();
  const { priceList } = useOrder();

  const { hideOutOfStock, subscriptionsAvailable, onSale } = useProducts();
  const hideSubscription = !priceList?.subscription_settings_enabled;

  const [categories, setCategories] = useRecoilState(categoriesFilterAtom);
  const [tags, setTags] = useRecoilState(tagsFilterAtom);
  const [vendors, setVendors] = useRecoilState(vendorsFilterAtom);
  const [activeFilters, setActiveFilters] = useRecoilState(activeFiltersAtom);
  const account = useRecoilValue(accountAtom);
  const { getFilterValue, getAllFilterValues } = useSearchParamsState();
  const search = getFilterValue(FILTER_SEARCH);

  const filters = {
    [FILTER_CATEGORY]: getAllFilterValues(FILTER_CATEGORY),
    [FILTER_TAGS]: getAllFilterValues(FILTER_TAGS),
    [FILTER_VENDORS]: getAllFilterValues(FILTER_VENDORS),
  };

  const displayOutOfStockSetting = account?.storefront_configuration?.display_out_of_stock;
  const displayDiscount = priceList?.display_discount;

  const filtersSet = {
    [FILTER_CATEGORY]: {
      title: t('storefront/store/shop/filters--category'),
      data: categories.data,
      more: categories.more,
      page: categories.page,
      set: setCategories,
      loadMore: () => fetchFilterData(FILTER_CATEGORY),
      expanded: true,
      apiReadMethod: readPriceListCategories,
    },
    [FILTER_VENDORS]: {
      title: t('storefront/store/shop/filters--vendor'),
      data: vendors.data,
      more: vendors.more,
      page: vendors.page,
      set: setVendors,
      loadMore: () => fetchFilterData(FILTER_VENDORS),
      expanded: true,
      apiReadMethod: readPriceListVendors,
    },
    [FILTER_TAGS]: {
      title: t('storefront/store/shop/filters--tags'),
      data: tags.data,
      more: tags.more,
      page: tags.page,
      set: setTags,
      loadMore: () => fetchFilterData(FILTER_TAGS),
      expanded: false,
      apiReadMethod: readPriceListTags,
    },
  };
  const toggleFilterSet = [
    FILTER_ON_SALE_TOGGLE,
    FILTER_OUT_OF_STOCK,
    FILTER_SUBSCRIPTIONS_AVAILABLE,
  ];

  const countFilters = async () => {
    for (const filter in filtersSet) countFilter(filter, filtersSet[filter].data);
  };

  const countFilter = async (filter, dataset) => {
    if (!dataset) return;
    const resp = await filtersSet[filter].apiReadMethod(priceList.id, {});

    const results = resp?.data?.results || [];
    filtersSet[filter].set((prev) => ({
      ...prev,
      data: prev.data?.map((c) => {
        const newCount = results.find((r) => r.id === c.id)?.product_count || c?.product_count || 0;

        return { ...c, product_count: newCount };
      }),
    }));
  };

  const addLabelToActiveFilter = async (filter) => {
    const filterValues = filters[filter];
    const missingIds = [];

    filterValues.forEach((value) => {
      if (
        activeFilters.findIndex(({ value: _value, type }) => _value == value && type == filter) > -1
      )
        return;
      missingIds.push(value);
    });

    if (missingIds?.length > 0) {
      const _data = await fetchMissingFilters(filter, missingIds);
      const _filters = _data.map(({ id, name }) => ({ label: name, value: id, type: filter }));

      setActiveFilters((prevState) => [...prevState, ..._filters]);
    }
  };

  // TODO: Refactor, could be from list like filtersSet
  // Do we really need the activeFilters state, other than for labels?
  const addLabelsToToggleFilters = () => {
    const uniqueFilters = [];
    if (onSale)
      uniqueFilters.push({
        label: t('storefront/store/shop/filters--on-sale'),
        value: onSale,
        type: FILTER_ON_SALE_TOGGLE,
      });
    if (hideOutOfStock)
      uniqueFilters.push({
        label: t('storefront/store/shop/filters--subscriptions-available'),
        value: hideOutOfStock,
        type: FILTER_OUT_OF_STOCK,
      });
    if (subscriptionsAvailable)
      uniqueFilters.push({
        label: t('storefront/store/shop/filters--hide-out-of-stock'),
        value: subscriptionsAvailable,
        type: FILTER_SUBSCRIPTIONS_AVAILABLE,
      });

    setActiveFilters((prevState) => [...prevState, ...uniqueFilters]);
  };

  // TODO: Reuse a different fetch method...
  const fetchMissingFilters = async (filter, ids) => {
    const apiReadMethod = filtersSet[filter].apiReadMethod;
    const params = {
      [filter]: ids.join(','),
    };

    const resp = await apiReadMethod(priceList.id, params);
    return resp?.data?.results || [];
  };

  // TODO: Refactor into usable single fetch
  const fetchFilters = async (reset = false) => {
    try {
      if (!priceList.id) return;
      for (const filter in filtersSet) {
        const newPage = !!reset ? 1 : filtersSet[filter].page + 1;
        const resp = await filtersSet[filter].apiReadMethod(priceList.id, {
          page_size: FILTER_PAGE_SIZE,
          page: newPage,
          [FILTER_ON_SALE]: reset ? false : onSale,
          [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? (reset ? false : hideOutOfStock) : true,
          [FILTER_SUBSCRIPTIONS_AVAILABLE]: reset ? false : subscriptionsAvailable,
          [FILTER_ONE_TIME_AVAILABLE]: hideSubscription,
        });

        const data = reset
          ? [...resp?.data?.results]
          : [...filtersSet[filter].data, ...resp?.data?.results];

        await addLabelToActiveFilter(filter);

        filtersSet[filter].set({
          data,
          page: newPage,
          more: Math.max(
            0,
            Math.min(FILTER_PAGE_SIZE, resp?.data?.count - newPage * FILTER_PAGE_SIZE)
          ),
        });

        if (filter === FILTER_CATEGORY)
          await addUncategorizedFilter(resp?.data?.next, resp?.data?.count);
      }
      addLabelsToToggleFilters();
    } catch (err) {
      console.error(err);
    }
  };

  // TODO: Refactor into usable single fetch
  const fetchFilterData = async (filter) => {
    const newPage = filtersSet[filter].page + 1;

    const resp = await filtersSet[filter].apiReadMethod(priceList.id, {
      page_size: FILTER_PAGE_SIZE,
      page: newPage,
      [FILTER_ON_SALE]: displayDiscount ? onSale : false,
      [FILTER_OUT_OF_STOCK]: displayOutOfStockSetting ? hideOutOfStock : true,
      [FILTER_SUBSCRIPTIONS_AVAILABLE]: subscriptionsAvailable,
      [FILTER_ONE_TIME_AVAILABLE]: hideSubscription,
    });

    filtersSet[filter].set((prevState) => ({
      data: newPage > 1 ? [...prevState.data, ...resp?.data?.results] : resp?.data?.results,
      page: newPage,
      more: Math.max(0, Math.min(FILTER_PAGE_SIZE, resp?.data?.count - newPage * FILTER_PAGE_SIZE)),
    }));
    countFilter(filter, [...filtersSet[filter].data, ...resp?.data?.results]);

    if (filter === FILTER_CATEGORY)
      await addUncategorizedFilter(resp?.data?.next, resp?.data?.count);
  };

  const addUncategorizedFilter = async (next, totalCategoryCount) => {
    if (!next && priceList?.display_uncategorized_products && totalCategoryCount > 0) {
      const uncategorizedData = await fetchUncategorizedData();
      if (uncategorizedData?.product_count > 0)
        filtersSet[FILTER_CATEGORY].set((prevState) => ({
          data: [...prevState.data, uncategorizedData],
          page: prevState.page,
          more: prevState.more,
        }));
    }
  };

  const fetchUncategorizedData = async () => {
    const resp = await readPriceListUnCategorizedCount(priceList.id);
    return {
      id: FILTER_UNCATEGORIZED_VALUE,
      product_count: resp?.data?.count,
      name: t('Uncategorized'),
    };
  };

  return {
    countFilters,
    filtersSet,
    fetchFilters,
    activeFilters,
    setActiveFilters,
    filters,
    toggleFilterSet,
  };
};

export default useFilters;
