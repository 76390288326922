import { useState, useEffect, useRef } from 'react';

const LOAD_EVENT = 'load';
const ERROR_EVENT = 'error';
export const IDLE = 'idle';
export const LOADING = 'loading';
export const READY = 'ready';
export const ERROR = 'error';
export const HEAD = 'head';
export const BODY = 'body';

// adapted from https://usehooks.com/useScript/
export const useScript = (src, bindNode = BODY, options = {}) => {
  const [status, setStatus] = useState(LOADING);
  const optionsRef = useRef(options);

  useEffect(() => {
    if (_waitForSrc()) return;
    const script = _getOrCreateScript();
    script.addEventListener(LOAD_EVENT, _setStateFromEvent);
    script.addEventListener(ERROR_EVENT, _setStateFromEvent);
    const removeOnUnmount = optionsRef.current.removeOnUnmount;
    return () => {
      if (!script) return;
      script.removeEventListener(LOAD_EVENT, _setStateFromEvent);
      script.removeEventListener(ERROR_EVENT, _setStateFromEvent);
      if (removeOnUnmount === true) {
        script.remove();
      }
    };
  }, [src]);

  const _waitForSrc = () => {
    if (src) return false;
    setStatus(IDLE);
    return true;
  };

  const _setStateFromEvent = (event) => setStatus(_eventTypeToStatus(event));

  const _eventTypeToStatus = (event) => (event.type === LOAD_EVENT ? READY : ERROR);

  const _getOrCreateScript = () => {
    // script may have been added by another instance of this hook
    let script = document.querySelector(`script[src="${src}"]`);
    if (script) {
      setStatus(script.getAttribute('data-status'));
      return script;
    }

    script = document.createElement('script');
    script.src = src;
    script.defer = true;
    script.setAttribute('data-status', LOADING);
    document[bindNode].appendChild(script);
    // Store status in attribute on script
    // This can be read by other instances of this hook
    const setAttributeFromEvent = (event) => {
      script.setAttribute('data-status', _eventTypeToStatus(event));
    };
    script.addEventListener(LOAD_EVENT, setAttributeFromEvent);
    script.addEventListener(ERROR_EVENT, setAttributeFromEvent);
    return script;
  };

  return status;
};
