import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import InputText from 'components/styled/InputText';

const CustomerNote = ({ note, setNote, initialValue }) => {
  const { t } = useTranslation();

  //states
  const account = useRecoilValue(accountAtom);

  return (
    <InputText
      id="customer-note"
      name="customer-note"
      labelText={t('storefront/store/checkout--fulfillment-date-note-label', {
        name: account?.business_name,
      })}
      hintText={t('storefront/store/checkout--fulfillment-date-note-hint')}
      classes="col-span-2"
      value={note}
      onChange={(e) => setNote(e.target.value)}
      multiLine={1}
      canResize
    />
  );
};

CustomerNote.propTypes = {
  note: PropTypes.string,
  setNote: PropTypes.func,
};

export default CustomerNote;
