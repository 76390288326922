import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import Button from 'components/styled/Button';
import { ChevronMediumLeft } from 'assets/svgIcons';
import DeliveryAddress from './DeliveryAddress';
import PickupOrDelivery from './PickupOrDelivery';
import PickupLocations from './PickupLocations';
import Customer from './Customer';
import { PICKUP, DELIVERY, FLEXIBLE } from '.';
import { customerAtom } from 'state/storefront/accountState';
import { useOrder } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import { findDifferenceBetweenTwoObjects, scrollTo } from 'helpers/helpers';
import { accountAtom } from 'state/storefront/accountState';
import { patchCustomer } from 'api/storefront/customers';
import CheckoutFooter from '../CheckoutFooter';

const FulfillmentStep = ({ isMobile }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const history = useHistory();
  const account = useRecoilValue(accountAtom);
  const enablePhoneOnCheckout = account?.storefront_configuration?.enable_phone_on_checkout;

  // states
  const { order, setOrder, orderEntriesErrors, errors, setErrors } = useOrder(priceListSlug);
  const customer = useRecoilValue(customerAtom);
  const [selectedStrategyType, setSelectedStrategyType] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState();
  const [orderFulfillment, setOrderFulfillment] = useState({ fulfillment_strategy: null });
  const [customerNote, setCustomerNote] = useState('');
  const [customerFields, setCustomerFields] = useState({
    email: order?.customer?.id ? order?.customer?.email : customer?.email ? customer?.email : '',
    first_name: order?.customer?.first_name
      ? order?.customer?.first_name
      : customer?.first_name || '',
    last_name: order?.customer?.last_name ? order?.customer?.last_name : customer?.last_name || '',
    phone: order?.customer?.phone ? order?.customer?.phone : customer?.phone || '',
  });

  // methods
  const selectStrategyType = (type) => {
    setSelectedStrategyType(type);
    setSelectedStrategy({});
    setOrderFulfillment((prevState) => ({
      ...prevState,
      fulfillment_date: null,
    }));
  };

  // effects
  useEffect(() => {
    let existingOrderFulfillment = {};
    if (!!order?.fulfillment?.fulfillment_strategy) {
      setSelectedStrategyType(
        order?.fulfillment?.fulfillment_strategy_type === PICKUP ? PICKUP : DELIVERY
      );
      existingOrderFulfillment = {
        status: 'UNFULFILLED',
        fulfillment_strategy: order?.fulfillment?.fulfillment_strategy,
        fulfillment_date: order?.fulfillment?.fulfillment_date,
        // "pickup_start_time": null,
        // "pickup_end_time": null,
      };
    } else {
      existingOrderFulfillment = {
        fulfillment_strategy: null,
        fulfillment_date: null,
      };
    }
    setOrderFulfillment({ ...existingOrderFulfillment });

    if (customer?.id) {
      const { email: customer_email } = customer;
      setOrder(!!customer_email ? { customer_email } : {});
    }
  }, []);

  useEffect(() => {
    if (customer?.id) {
      const { email: customer_email } = customer;
      setOrder(!!customer_email ? { customer_email } : {});
      setCustomerFields({
        email: customer_email,
        first_name: customer?.first_name || '',
        last_name: customer?.last_name || '',
        phone: customer?.phone || '',
      });
    }
  }, [customer?.id && !customerFields.email]);

  const compareAndUpdateCustomer = async () => {
    try {
      const { id, email, first_name, last_name, phone } = customer;
      const updateCustomer = findDifferenceBetweenTwoObjects(
        { email, first_name, last_name, phone },
        { ...customerFields }
      );
      if (Object.keys(updateCustomer).length) {
        await patchCustomer({ id, ...updateCustomer });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const determineSubmissionErrors = () => {
    const submissionErrors = {};
    const customerRequiredFields = ['email', 'first_name', 'last_name'];
    if (enablePhoneOnCheckout) customerRequiredFields.push('phone');

    for (let field of customerRequiredFields) {
      if (!customerFields[field])
        submissionErrors[field] = t(`storefront/store/shop/cart/details--error-${field}`);
    }

    if (order?.order_entries?.length <= 0)
      submissionErrors.order_entries = t(`storefront/store/shop/cart/details--error-order_entries`);

    if (!selectedStrategyType)
      submissionErrors.strategy_type = t(`storefront/store/shop/cart/details--error-strategy_type`);

    if (!selectedStrategy)
      submissionErrors.strategy = t(`storefront/store/shop/cart/details--error-strategy`);

    if (selectedStrategyType === DELIVERY && !orderFulfillment?.shipping_address?.street_address)
      submissionErrors.shipping_address = t(
        `storefront/store/shop/cart/details--error-shipping_address`
      );

    if (
      !!selectedStrategy?.availability?.type &&
      selectedStrategy.availability.type !== FLEXIBLE &&
      !orderFulfillment?.fulfillment_date
    )
      submissionErrors.fulfillment_date = t(
        `storefront/store/shop/cart/details--error-fulfillment_date`
      );

    return submissionErrors;
  };

  const completeFulfillment = async () => {
    try {
      const allOrderFulfillment = { ...orderFulfillment };

      const submissionErrors = determineSubmissionErrors();

      if (Object.keys(submissionErrors).length > 0) {
        setErrors(submissionErrors);
        throw new Error();
      }
      if (selectedStrategyType === DELIVERY) {
        allOrderFulfillment.shipping_address.first_name = customerFields?.first_name;
        allOrderFulfillment.shipping_address.last_name = customerFields?.last_name;
      }

      if (customer?.id) await compareAndUpdateCustomer();

      await setOrder({
        fulfillment: allOrderFulfillment,
        customer_note: customerNote,
        billing_address: allOrderFulfillment?.shipping_address
          ? {
            ...orderFulfillment?.shipping_address,
            first_name: customerFields?.first_name,
            last_name: customerFields?.last_name,
          }
          : undefined,
        customer_info: {
          ...customerFields,
        },
      });
      history.push(`/${priceListSlug}/checkout/payment`);
    } catch (e) {
      console.error('Unable to proceed.', e);
    } finally {
      scrollTo(0, 0);
    }
  };

  const updateFulfillmentStrategy = async () => {
    if (orderFulfillment?.fulfillment_strategy) {
      await setOrder({
        fulfillment: { fulfillment_strategy: orderFulfillment?.fulfillment_strategy },
      });
    }
  };

  useEffect(updateFulfillmentStrategy, [orderFulfillment?.fulfillment_strategy]);

  const ContinueToPaymentButton = ({ classes }) => (
    <Button
      data-dd-action-name="continue-to-payment"
      disabled={orderEntriesErrors}
      onClick={completeFulfillment}
      classes={`button w-full sm:mb-0 ${classes}`}
      id="storefront/store/checkout--fulfillment-forward">
      {t('storefront/store/checkout--fulfillment-forward')}
    </Button>
  );

  return (
    <>
      <div className="col-span-3 sm:col-span-2">
        {orderEntriesErrors ? (
          <Alert
            id="cart-alert"
            type="error"
            title={t('storefront/store/shop/cart--error-title')}
            classes="col-span-4 mb-4"
            description={t('storefront/store/shop/cart--error')}
          />
        ) : null}
        {Object.values(errors).some((v) => !!v) ? (
          <Alert
            id="submission-errors-alert"
            type="error"
            title={t('storefront/store/shop/cart/details--error-title')}
            classes="col-span-4 mb-4"
            description={
              <ul className="list-disc ml-4">
                {Object.entries(errors).map(
                  (error) => !!error[1] && <li key={error[0] + error[1]}>{error[1]}</li>
                )}
              </ul>
            }
          />
        ) : null}
        <Customer
          customerFields={customerFields}
          setCustomerFields={setCustomerFields}
          enablePhoneOnCheckout={enablePhoneOnCheckout}
          errors={errors}
          setErrors={setErrors}
        />
        <PickupOrDelivery
          selectedStrategyType={selectedStrategyType}
          selectStrategyType={selectStrategyType}
          orderFulfillment={orderFulfillment}
          setOrderFulfillment={setOrderFulfillment}
          errors={errors}
          setErrors={setErrors}
        />
        {selectedStrategyType === PICKUP && (
          <PickupLocations
            orderFulfillment={orderFulfillment}
            setOrderFulfillment={setOrderFulfillment}
            selectedStrategy={selectedStrategy}
            setSelectedStrategy={setSelectedStrategy}
            customerNote={customerNote}
            setCustomerNote={setCustomerNote}
            errors={errors}
            setErrors={setErrors}
          />
        )}
        {selectedStrategyType === DELIVERY && (
          <DeliveryAddress
            orderFulfillment={orderFulfillment}
            setOrderFulfillment={setOrderFulfillment}
            selectedStrategy={selectedStrategy}
            setSelectedStrategy={setSelectedStrategy}
            customerNote={customerNote}
            setCustomerNote={setCustomerNote}
            errors={errors}
            setErrors={setErrors}
          />
        )}
        <div className="flex flex-col-reverse sm:flex-row w-full px-4 sm:px-0 mb-8 sm:mb-0">
          <Link to={`/${priceListSlug}`}>
            <Button
              id="storefront/store/checkout--fulfillment-back"
              classes="button button--white flex items-center justify-center xl sm:mr-2 w-full sm:w-auto">
              <ChevronMediumLeft classes="mr-3" />
              {t('storefront/store/checkout--fulfillment-back')}
            </Button>
          </Link>
          <ContinueToPaymentButton classes="mb-4 xl" />
        </div>
        <CheckoutFooter classes="hidden sm:flex" />
      </div>
      <div className="fixed z-50 bottom-0 left-0 right-0 p-4 border-t bg-White flex justify-end">
        <ContinueToPaymentButton classes="xl sm:max-w-xs w-full" />
      </div>
    </>
  );
};

FulfillmentStep.propTypes = {
  isMobile: PropTypes.bool,
};
FulfillmentStep.defaultProps = {
  isMobile: false,
};

export default FulfillmentStep;
