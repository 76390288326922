import { api } from '.';

export const readPriceListProducts = async (id, params, signal) => {
  return await api.get(`price-lists/${id}/products`, { params, signal });
};

export const readPriceListProduct = async (priceListId, productId, params) => {
  return await api.get(`price-lists/${priceListId}/products/${productId}`, { params });
};

export const readPriceListCategories = async (id, params) => {
  return await api.get(`price-lists/${id}/products/categories`, { params });
};

export const readPriceListUnCategorizedCount = async (id, params) => {
  return await api.get(`price-lists/${id}/products/uncategorized-count`, { params });
};

export const readPriceListTags = async (id, params) => {
  return await api.get(`price-lists/${id}/products/tags`, { params });
};

export const readPriceListVendors = async (id, params) => {
  return await api.get(`price-lists/${id}/products/vendors`, { params });
};
