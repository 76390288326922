import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useOrder } from 'hooks/storefront';
import Card from 'components/styled/Card';
import { PICKUP } from '../FulfillmentStep';
import { BLANK_TEXT_EDITOR } from 'helpers/constants';
import { accountAtom } from 'state/storefront/accountState';
import {
  CONFIRMATION_CUSTOMER,
  CONFIRMATION_FULFILLMENT,
  CONFIRMATION_BILLING,
  CONFIRMATION_PAYMENT,
  CONFIRMATION_ORDER_NOTE,
  CONFIRMATION_CUSTOMER_NOTE,
  CONFIRMATION_OPENED_AT,
  FULFILLMENT_INSTRUCTIONS,
} from '../..';
import { formatDateTime, formatTime } from 'helpers/localeHelpers';

const Confirmation = ({ readOnly, classes, singleOrder, rowsToHide }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const account = useRecoilValue(accountAtom);
  const order = Object.keys(singleOrder).length > 0 ? singleOrder : useOrder(priceListSlug).order;
  const fulfillmentDateText =
    order?.fulfillment?.fulfillment_strategy_type === PICKUP
      ? t('storefront/store/checkout--payment-pickup-date-text')
      : t('storefront/store/checkout--payment-delivery-date-text');
  const fulfillmentDate = order?.fulfillment?.fulfillment_date
    ? `${fulfillmentDateText}: ${formatDateTime(order?.fulfillment?.fulfillment_date, 'dddd, LL')}`
    : '';
  const fulfillmentTitle =
    order?.fulfillment?.fulfillment_strategy_type === PICKUP
      ? t('storefront/store/checkout--payment-pickup-label')
      : t('storefront/store/checkout--payment-deliver-label');

  return (
    <Card
      classes={`px-0 py-0 mb-4 rounded-none sm:rounded-xl ${classes}`}
      childMarginBottom={false}
      id="storefront/store/checkout/confirmation--info">
      {!rowsToHide.includes(CONFIRMATION_CUSTOMER) &&
      (order?.customer?.first_name || order?.customer?.last_name || order?.customer?.email) ? (
        <>
          <div className="confirmation--info">
            <div className="flex justify-between">
              <h4>{t('storefront/store/checkout--payment-contact-info')}</h4>
              {!readOnly && false && <Link to="#">{t('Edit')}</Link>}
            </div>
            <div className="text-Grey700">{`${order?.customer?.first_name} ${order?.customer?.last_name}`}</div>
            <div className="text-Grey700">{order?.customer?.email}</div>
            <div className="text-Grey700">{order?.customer?.phone}</div>
          </div>
          <hr />
        </>
      ) : null}

      {!rowsToHide.includes(CONFIRMATION_FULFILLMENT) && order?.fulfillment && (
        <>
          <div className="confirmation--info">
            <div className="flex justify-between">
              <h4>{fulfillmentTitle}</h4>
              {!readOnly && false && <Link to="#">{t('Edit')}</Link>}
            </div>
            <div className="text-Grey700">
              {order?.fulfillment?.fulfillment_strategy_type === PICKUP
                ? order?.fulfillment?.fulfillment_address
                : order?.fulfillment?.shipping_address?.formatted_address}
              <p>
                {fulfillmentDate}
                {order?.fulfillment?.fulfillment_strategy_type === PICKUP &&
                (order?.fulfillment?.pickup_start_time || order?.fulfillment?.pickup_end_time)
                  ? `, ${formatTime(order?.fulfillment?.pickup_start_time)} - ${formatTime(
                      order?.fulfillment?.pickup_end_time
                    )}`
                  : null}
              </p>
              {!rowsToHide.includes(FULFILLMENT_INSTRUCTIONS) &&
                order?.fulfillment?.instructions &&
                order?.fulfillment?.instructions !== BLANK_TEXT_EDITOR && (
                  <p
                    className="rich-text-editor-render mt-2"
                    dangerouslySetInnerHTML={{ __html: order?.fulfillment?.instructions }}
                  />
                )}
            </div>
          </div>
        </>
      )}

      {!rowsToHide.includes(CONFIRMATION_BILLING) && order?.billing_address?.formatted_address && (
        <>
          <hr />
          <div className="confirmation--info">
            <h4>{t('global/Billing Address')}</h4>
            <p>
              {order?.billing_address?.first_name} {order?.billing_address?.last_name}
            </p>
            <p>{order?.billing_address?.formatted_address}</p>
          </div>
        </>
      )}

      {!rowsToHide.includes(CONFIRMATION_PAYMENT) && order?.payment && (
        <>
          <hr />
          <div className="confirmation--info">
            <div className="flex justify-between">
              <h4>{t('Payment Method')}</h4>
            </div>
            {order?.payment?.authorized_transaction?.gateway_card_brand ? (
              <p className="text-Grey700">
                {t('orders/create/payment--card', {
                  cardBrand: order?.payment?.authorized_transaction?.gateway_card_brand,
                  cardLast4Digits: order?.payment?.authorized_transaction?.gateway_card_last4,
                })}
              </p>
            ) : (
              <p className="text-Grey700">{order?.payment?.payment_strategy_name}</p>
            )}
            {order?.payment?.instructions && <p>{order?.payment?.instructions}</p>}
          </div>
        </>
      )}

      {!rowsToHide.includes(CONFIRMATION_ORDER_NOTE) &&
        order?.order_note &&
        order?.order_note !== BLANK_TEXT_EDITOR && (
          <>
            <hr />
            <div className="confirmation--info">
              <h4>
                {t('storefront/my-account/order-history--order-note--title', {
                  accountName: account?.storefront_configuration?.store_name,
                })}
              </h4>
              <p
                className="rich-text-editor-render mt-2"
                dangerouslySetInnerHTML={{ __html: order?.order_note }}
              />
            </div>
          </>
        )}
      {!rowsToHide.includes(CONFIRMATION_CUSTOMER_NOTE) &&
        order?.customer_note &&
        order?.customer_note !== BLANK_TEXT_EDITOR && (
          <>
            <hr />
            <div className="confirmation--info">
              <h4>{t('storefront/my-account/order-history--customer-note--title')}</h4>
              <p
                className="rich-text-editor-render mt-2"
                dangerouslySetInnerHTML={{ __html: order?.customer_note }}
              />
            </div>
          </>
        )}
      {!rowsToHide.includes(CONFIRMATION_OPENED_AT) && order?.id && order?.opened_at && (
        <>
          <hr />
          <div className="confirmation--info">
            <h4>{t('global/Order Number')}</h4>
            <p className="text-Grey700">#{order?.id}</p>
            <p>
              {t('global/Placed date', {
                date: formatDateTime(order?.opened_at, 'LLLL'),
              })}
            </p>
          </div>
        </>
      )}
    </Card>
  );
};

Confirmation.propTypes = {
  readOnly: PropTypes.bool,
  classes: PropTypes.string,
  singleOrder: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    payment: PropTypes.object,
    order_entries: PropTypes.array,
    status: PropTypes.string,
  }),
  rowsToHide: PropTypes.arrayOf(PropTypes.string),
};

Confirmation.defaultProps = {
  readOnly: false,
  classes: '',
  singleOrder: {},
  rowsToHide: [],
};

export default Confirmation;
