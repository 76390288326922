import { ArrowDoubleRotatingFilledCircle, X } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import theme from 'styles/theme';
import { debounce } from 'helpers/helpers';
import { currencyAtom } from 'state/common/currency';
import MaxUnitsPerOrderReached from 'components/MaxUnitsPerOrderReached';
import InputPlusMinus from 'components/styled/InputPlusMinus';
import Money from 'components/styled/Money';
import Price from './Price';

const CartItem = ({
  classes,
  id,
  product_name,
  product,
  package_price_list_entry: _package,
  price,
  total_price,
  price_per_unit,
  storefront_unit_quantity,
  readOnly,
  error,
  productLink,
  order,
  setOrder,
  loading,
  is_subscription,
}) => {
  const { t } = useTranslation();
  const { Grey300 } = theme.colors;
  const currency = useRecoilValue(currencyAtom);
  const [quantity, setQuantity] = useState(storefront_unit_quantity);
  const maxQuantityInput =
    _package?.max_units_per_order || Math.floor(product?.inventory / _package?.inventory_per_unit);

  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(storefront_unit_quantity);
      }
    }, 400),
    [storefront_unit_quantity, order?.fulfillment, order?.fulfillment?.id]
  );

  useEffect(() => {
    setQuantity(storefront_unit_quantity);
  }, [storefront_unit_quantity]);

  return (
    <Fragment>
      <div
        key={`product-${id}`}
        className={`cart-item relative flex items-start w-full ${classes}`}>
        {product?.thumbnail && (
          <img src={product?.thumbnail} alt={product_name} className="w-12 h-12  mr-2 rounded-sm" />
        )}
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <h4 className={`flex flex-wrap mb-0.5 text-Grey800 ${error ? 'text-Red700' : ''}`}>
              {product_name}
              {readOnly && quantity && quantity > 1 && ` x${quantity}`}
            </h4>
            {productLink && (
              <Link className="text-sm" target="_blank" to={productLink}>
                {t('view product')}
              </Link>
            )}
          </div>

          <div className="flex justify-between items-center">
            <span className="text-Grey500 text-sm mb-2">
              <Money
                value={readOnly ? price_per_unit : _package?.package_price}
                currency={currency}
              />
              {_package?.name ? ` / ${_package?.name}` : ''}
            </span>
            {readOnly && <Price loading={loading} price={price} />}
          </div>

          {is_subscription && (
            <span className="text-Green600 text-sm flex items-center mb-1">
              <ArrowDoubleRotatingFilledCircle classes="mr-1" />
              {t('storefront/product-details/subscription-options/subscription')}
            </span>
          )}

          {!readOnly && (
            <div className="flex justify-between items-center flex-1">
              <InputPlusMinus
                disabled={loading}
                id={`unit-quantity-${id}`}
                name={`unit-quantity-${id}`}
                max={maxQuantityInput}
                value={quantity}
                handleChange={(storefront_unit_quantity) => {
                  setQuantity(storefront_unit_quantity);
                  if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
                    debouncedSetOrder({ order_entries: [{ id, storefront_unit_quantity }] });
                  }
                }}
              />
              <div className="flex flex-row justify-end">
                {is_subscription && price != total_price && (
                  <Price
                    loading={loading}
                    price={total_price}
                    classes="text-Grey400 line-through mr-2"
                  />
                )}

                <Price loading={loading} price={price} />
              </div>
            </div>
          )}
          {_package?.max_units_per_order > 0 && quantity >= _package?.max_units_per_order && (
            <MaxUnitsPerOrderReached maxUnitsPerOrder={_package?.max_units_per_order} />
          )}
          {error && <p className="text-Red700">{error}</p>}
        </div>
        {!readOnly && (
          <span
            className={`absolute top-0 right-0 cursor-pointer ${
              loading ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
            }`}
            onClick={() => setOrder({ order_entries: [{ id, storefront_unit_quantity: 0 }] })}>
            <X stroke={Grey300} />
          </span>
        )}
      </div>
      <hr className="my-2" />
    </Fragment>
  );
};

CartItem.propTypes = {
  classes: PropTypes.string,
  id: PropTypes.number,
  product_name: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority_order: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  package_price_list_entry: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      unit_price: PropTypes.number,
      package_price: PropTypes.number,
    }),
  ]),
  price_per_unit: PropTypes.number,
  total_price: PropTypes.number,
  storefront_unit_quantity: PropTypes.number,
  inventory_quantity: PropTypes.number,
  readOnly: PropTypes.bool,
  productLink: PropTypes.string,
  setOrder: PropTypes.func,
  loading: PropTypes.bool,
};

CartItem.defaultProps = {
  classes: '',
  readOnly: false,
  productLink: '',
  setOrder: undefined,
  loading: false,
};

export default CartItem;
