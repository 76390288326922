import About from './About';
import Account from './Account';
import Cart, { CartItem, CartTotals } from './Cart';
import Checkout from './Checkout';
import Disabled from './Disabled';
import FilterPanel from './FilterPanel';
import Footer from './Footer';
import ForgotPassword from './ForgotPassword';
import Header from './Header';
import Hero from './Hero';
import Login from './Login';
import PrivatePriceList from './PrivatePriceList';
import Product from './Product';
import ProductsContainer from './ProductsContainer';
import ProductsHeader from './ProductsHeader';
import Register from './Register';
import Shop from './Shop';

const CONFIRMATION_CUSTOMER = 'CONFIRMATION_CUSTOMER';
const CONFIRMATION_FULFILLMENT = 'CONFIRMATION_FULFILLMENT';
const CONFIRMATION_BILLING = 'CONFIRMATION_BILLING';
const CONFIRMATION_PAYMENT = 'CONFIRMATION_PAYMENT';
const CONFIRMATION_ORDER_NOTE = 'CONFIRMATION_ORDER_NOTE';
const CONFIRMATION_CUSTOMER_NOTE = 'CONFIRMATION_CUSTOMER_NOTE';
const CONFIRMATION_OPENED_AT = 'CONFIRMATION_OPENED_AT';

const ORDERING_CATEGORY = 'price_list_category__priority_order';
const ORDERING_PRIORITY = 'priority_order';
const ORDERING_PRICE = 'minimum_price';
const ORDERING_CREATED_AT = 'created_at';
const ORDERING_PRODUCT_NAME = 'name';
const FILTER_CATEGORY = 'price_list_category';
const FILTER_VENDORS = 'vendors';
const FILTER_TAGS = 'tags';
const FILTER_SEARCH = 'search';
const FILTER_ON_SALE = 'on_sale';
const FILTER_ON_SALE_TOGGLE = 'on_sale_toggle';
const FILTER_OUT_OF_STOCK = 'hide_out_of_stock';
const FILTER_SUBSCRIPTIONS_AVAILABLE = 'subscriptions_available';
const FILTER_ONE_TIME_AVAILABLE = 'one_time_available';
const FILTER_UNCATEGORIZED = 'uncategorized';
const FILTER_UNCATEGORIZED_VALUE = 'null'; // server expects the string literal 'null' for filter_price_list_category
const FILTER_PAGE_SIZE = 25;
const PAGE_SIZE = 10;

const FULFILLMENT_INSTRUCTIONS = 'fulfillment_instructions';
const CHECKOUT_COMPLETE_PATH = 'checkout/complete';

export {
  About,
  Cart,
  CartItem,
  CartTotals,
  Checkout,
  Disabled,
  FilterPanel,
  Footer,
  Header,
  Hero,
  Login,
  Account,
  Product,
  ProductsContainer,
  ProductsHeader,
  Shop,
  Register,
  ForgotPassword,
  PrivatePriceList,
  CHECKOUT_COMPLETE_PATH,
  CONFIRMATION_CUSTOMER,
  CONFIRMATION_FULFILLMENT,
  CONFIRMATION_BILLING,
  CONFIRMATION_PAYMENT,
  CONFIRMATION_ORDER_NOTE,
  CONFIRMATION_CUSTOMER_NOTE,
  CONFIRMATION_OPENED_AT,
  ORDERING_CATEGORY,
  ORDERING_PRIORITY,
  ORDERING_PRICE,
  ORDERING_CREATED_AT,
  ORDERING_PRODUCT_NAME,
  FILTER_CATEGORY,
  FILTER_VENDORS,
  FILTER_TAGS,
  FILTER_SEARCH,
  FILTER_ON_SALE,
  FILTER_ON_SALE_TOGGLE,
  FILTER_OUT_OF_STOCK,
  FILTER_SUBSCRIPTIONS_AVAILABLE,
  FILTER_ONE_TIME_AVAILABLE,
  FILTER_UNCATEGORIZED,
  FILTER_UNCATEGORIZED_VALUE,
  FULFILLMENT_INSTRUCTIONS,
  FILTER_PAGE_SIZE,
  PAGE_SIZE,
};
