import { ArrowRightCircleFilled } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ON, SUBSCRIPTION_ORDER_OPTIONS, VIEWPORT_SIZES } from 'helpers/constants';
import { PACKAGES_NEW_ON_SALE_TAG } from 'helpers/featureFlags';
import { getAvailableInventoryCount } from 'helpers/helpers';
import { getProductSubscriptionOptions } from 'helpers/subscriptionHelpers';
import { screenSizeAtom } from 'state/appState';
import { accountAtom, subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Tag from 'components/styled/Tag/Tag';
import './ProductImage.css';
import ProductImageHoverCarrousel from './ProductImageHoverCarrousel';
import ProductImagePlaceholder from './ProductImagePlaceholder';

const ProductImage = ({ classes, product }) => {
  const { t } = useTranslation();
  const { width } = useRecoilValue(screenSizeAtom);
  const isMobile = width < VIEWPORT_SIZES.sm;
  const history = useHistory();
  const { search } = useLocation();

  const account = useRecoilValue(accountAtom);
  const displayProductImagesSetting = account?.storefront_configuration?.display_product_images;

  const { priceListSlug } = useParams();
  const { order, priceList: priceListHook } = useOrder(priceListSlug);
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const displayDiscount = priceList?.display_discount;
  const displaySubscription = !!priceList?.subscription_settings_enabled;

  const [isHovering, setIsHovering] = useState(false);

  const {
    id: productId,
    product: productPriceListId,
    package_price_list_entries: packages,
    inventory,
    display,
    thumbnail,
    track_inventory,
  } = product;

  const imageUrl = display || thumbnail;

  const allPackagesQuantityInCart = order?.order_entries.reduce(
    (previousValue, entry) =>
      entry.product.id === productPriceListId
        ? previousValue + entry.storefront_unit_quantity
        : previousValue,
    0
  );

  const packageOptions = packages.map((p) => ({
    ...p,
    available: getAvailableInventoryCount(inventory, p?.inventory_per_unit),
    track_inventory,
  }));

  const soldOut = track_inventory && !packageOptions.some((p) => p.available);

  const productSubscriptionOptions = getProductSubscriptionOptions(packages);

  const useOnSaleField = useTreatment(PACKAGES_NEW_ON_SALE_TAG) == ON;
  const onSaleKey = useOnSaleField ? 'on_sale_toggle' : 'on_sale';
  const onSaleCount = packageOptions.filter(({ [onSaleKey]: on_sale }) => on_sale).length;
  const hasOnSaleTag = useOnSaleField ? onSaleCount > 0 : displayDiscount && onSaleCount > 0;

  const hasSubscriptionOptions =
    displaySubscription &&
    (productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION ||
      productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION);

  const productHasTags = hasSubscriptionOptions || hasOnSaleTag;

  const mobileClasses = isMobile ? 'text-[8px] font-bold leading-none h-fit !px-1 !py-0.5' : '';

  if (!displayProductImagesSetting) return null;

  return (
    <div
      className={`relative cursor-pointer overflow-visible ${classes}`}
      onClick={(e) => {
        e.stopPropagation();
        history.push({
          pathname: `/${priceListSlug}/product/${productId}`,
          search,
        });
      }}>
      {!!imageUrl ? (
        <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
          <ProductImageHoverCarrousel
            productId={productId}
            src={imageUrl}
            alt={productId}
            soldOut={soldOut}
            classes={`w-full h-auto object-cover rounded ${soldOut ? 'opacity-60' : ''}`}
          />
        </div>
      ) : (
        <ProductImagePlaceholder />
      )}
      {allPackagesQuantityInCart > 0 && !isMobile && (
        <div
          className="flex flex-col justify-end pointer-events-none absolute z-20 
            top-0 left-0 w-full h-full items-center rounded text-white 
            font-medium text-xs pb-3 overlay-gradient">
          <span className="text-white text-[20px]">{allPackagesQuantityInCart}</span>
          <span className="text-white text-[16px]">{t('storefront/store/shop/cart/in-cart')}</span>
        </div>
      )}
      {productHasTags && (
        <div className="flex flex-col absolute -left-1 sm:-left-2 top-1 sm:top-2 space-y-0.5 sm:space-y-1 z-20">
          {hasSubscriptionOptions && (
            <div className="w-min">
              <Tag
                name={t('global/Subscribe')}
                classes={`storefront--green sm:text-base sm:px-3 ${mobileClasses}`}
                showButton={false}
              />
            </div>
          )}
          {hasOnSaleTag && (
            <div className="w-min">
              <Tag
                name={t('global/Sale')}
                classes={`storefront--green sm:text-base sm:px-3 ${mobileClasses}`}
                showButton={false}
              />
            </div>
          )}
        </div>
      )}
      {!soldOut && (
        <div
          className={`z-20 absolute bottom-0 right-0 p-2 
          ${isMobile && 'hidden'}
          ${isMobile ? '' : isHovering ? 'animate-slide-top' : 'animate-slide-bottom'}`}>
          <ArrowRightCircleFilled />
        </div>
      )}
    </div>
  );
};

PropTypes.propTypes = {};

PropTypes.defaultProps = {};

export default ProductImage;
