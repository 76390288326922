import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import { DELIVERY } from '../Checkout/FulfillmentStep';
import Alert from 'components/styled/Alert';
import { accountAtom } from 'state/storefront/accountState';
import AddressForm from 'components/AddressForm';
import { useRecoilValue } from 'recoil';

const DeliveryAvailability = ({ priceListId }) => {
  const { t } = useTranslation();
  const [deliveryStrategies, setDeliveryStrategies] = useState([]);
  const [deliverableAddress, setDeliverableAddress] = useState(true);
  const [loading, setLoading] = useState(false);
  const account = useRecoilValue(accountAtom);

  const fetchDeliveryStrategies = async (address) => {
    let strategies = [];
    setLoading(true);
    try {
      const resp = await readPriceListFulfillmentStrategies(priceListId, {
        fulfillment_type: DELIVERY,
        address: JSON.stringify(address),
      });
      strategies = resp?.data?.results;
      setDeliveryStrategies(strategies);
      setDeliverableAddress(!!strategies.length);
    } catch (e) {
      console.error('Error fetching delivery plans', e);
    } finally {
      setLoading(false);
      return strategies;
    }
  };

  return (
    <div className="store-modal__delivery-availability">
      <AddressForm
        handleChange={async (address) => {
          if (address?.street_address) await fetchDeliveryStrategies(address);
        }}
        defaultCountryCode={account?.business_address?.country_code}
      />

      {deliverableAddress && deliveryStrategies.length > 0 && (
        <Alert
          id="delivery-available"
          classes="mt-6"
          title={t('storefront/store/shop/hero--delivery-available')}
        />
      )}
      {!deliverableAddress && (
        <Alert
          id="delivery-unavailable"
          classes="mt-6"
          title={t('storefront/store/shop/hero--delivery-unavailable')}
          type="error"
        />
      )}
    </div>
  );
};

export default DeliveryAvailability;
