import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';
import FooterSection from './FooterSection';

const LinksInfo = () => {
  const { storefront_configuration } = useRecoilValue(accountAtom);
  const { enable_about_us, about_tab_title, custom_links } = storefront_configuration;
  const { t } = useTranslation();

  return (
    <FooterSection title={t('global/Links')}>
      <Link to="/">{t('storefront/store/shop/header--shop')}</Link>
      {!!enable_about_us && <Link to="/about">{about_tab_title}</Link>}
      {custom_links.map(({ title, link }) => (
        <Link key={`${title}-${link}`} to={{ pathname: link }} target="_blank">
          {t(title)}
        </Link>
      ))}
    </FooterSection>
  );
};

export default LinksInfo;
