import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ fill, classes, dimensions }) => (
  <svg
    className={classes}
    width={dimensions}
    height={dimensions}
    viewBox={`0 0 ${dimensions} ${dimensions}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.8089 3.33329C7.1521 2.3623 8.07813 1.66663 9.16665 1.66663H10.8333C11.9218 1.66663 12.8479 2.3623 13.1911 3.33329H14.1666C15.5474 3.33329 16.6666 4.45258 16.6666 5.83329V15.8333C16.6666 17.214 15.5474 18.3333 14.1666 18.3333H5.83331C4.4526 18.3333 3.33331 17.214 3.33331 15.8333V5.83329C3.33331 4.45258 4.4526 3.33329 5.83331 3.33329H6.8089ZM6.8089 4.99996H5.83331C5.37308 4.99996 4.99998 5.37306 4.99998 5.83329V15.8333C4.99998 16.2935 5.37308 16.6666 5.83331 16.6666H14.1666C14.6269 16.6666 15 16.2935 15 15.8333V5.83329C15 5.37306 14.6269 4.99996 14.1666 4.99996H13.1911C12.8479 5.97095 11.9218 6.66663 10.8333 6.66663H9.16665C8.07813 6.66663 7.1521 5.97095 6.8089 4.99996ZM9.16665 3.33329C8.70641 3.33329 8.33331 3.70639 8.33331 4.16663C8.33331 4.62686 8.70641 4.99996 9.16665 4.99996H10.8333C11.2935 4.99996 11.6666 4.62686 11.6666 4.16663C11.6666 3.70639 11.2935 3.33329 10.8333 3.33329H9.16665ZM6.66665 9.99996C6.66665 9.53972 7.03974 9.16663 7.49998 9.16663H7.50831C7.96855 9.16663 8.34165 9.53972 8.34165 9.99996C8.34165 10.4602 7.96855 10.8333 7.50831 10.8333H7.49998C7.03974 10.8333 6.66665 10.4602 6.66665 9.99996ZM9.16665 9.99996C9.16665 9.53972 9.53974 9.16663 9.99998 9.16663H12.5C12.9602 9.16663 13.3333 9.53972 13.3333 9.99996C13.3333 10.4602 12.9602 10.8333 12.5 10.8333H9.99998C9.53974 10.8333 9.16665 10.4602 9.16665 9.99996ZM6.66665 13.3333C6.66665 12.8731 7.03974 12.5 7.49998 12.5H7.50831C7.96855 12.5 8.34165 12.8731 8.34165 13.3333C8.34165 13.7935 7.96855 14.1666 7.50831 14.1666H7.49998C7.03974 14.1666 6.66665 13.7935 6.66665 13.3333ZM9.16665 13.3333C9.16665 12.8731 9.53974 12.5 9.99998 12.5H12.5C12.9602 12.5 13.3333 12.8731 13.3333 13.3333C13.3333 13.7935 12.9602 14.1666 12.5 14.1666H9.99998C9.53974 14.1666 9.16665 13.7935 9.16665 13.3333Z"
      fill={fill}
    />
  </svg>
);

Svg.propTypes = {
  fill: PropTypes.string,
  classes: PropTypes.string,
  dimensions: PropTypes.string,
};

Svg.defaultProps = {
  fill: theme.colors.Grey400,
  classes: '',
  dimensions: '20',
};

export default Svg;
