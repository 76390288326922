import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MaxUnitsPerOrderReached = ({ maxUnitsPerOrder }) => {
  const { t } = useTranslation();

  return (
    <span className="text-xs text-Grey500 mt-1 first-letter:capitalize">
      {t('storefront/product-details/package--max-units-per-order', {
        maxUnitsPerOrder,
      })}
    </span>
  );
};

MaxUnitsPerOrderReached.propTypes = {
  maxUnitsPerOrder: PropTypes.number.isRequired,
};

export default MaxUnitsPerOrderReached;
