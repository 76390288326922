import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { passwordResetRequest } from 'api/storefront/customers';
import { default as ForgotPasswordComponent } from 'components/ForgotPassword';

const ForgotPassword = ({ onReset, setLoginActive }) => {
  useEffect(() => {
    setLoginActive(false);
  }, []);

  return (
    <ForgotPasswordComponent
      onReset={onReset}
      onClose={() => setLoginActive(true)}
      passwordResetRequest={passwordResetRequest}
    />
  );
};

ForgotPassword.propTypes = { onClose: PropTypes.func };

ForgotPassword.defaultProps = { onClose: () => {} };

export default ForgotPassword;
