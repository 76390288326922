import { ArrowUpRight, CircleArrows, SpinnerSmall } from 'assets/svgIcons';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ON, SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { debounce, getAvailableInventoryCount } from 'helpers/helpers';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { getMaxItemQuantity } from 'helpers/storefrontHelpers';
import { getProductSubscriptionOptions } from 'helpers/subscriptionHelpers';
import { toastAtom } from 'state/appState';
import { subscriptionsSelector } from 'state/storefront/accountState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button';
import InputPlusMinus from 'components/styled/InputPlusMinus';
import ProductImage from '../../ProductImage';
import InventoryCounter from '../CommonView/InventoryCounter';
import PackageValueGridView from './PackageValueGridView';

const ProductCardGridView = ({
  product,
  displayDiscount,
  setErrors,
  displaySubscription,
  displayRemainingInventory,
}) => {
  const history = useHistory();
  const { priceListSlug } = useParams();
  const { t } = useTranslation();
  const { search } = useLocation();

  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;

  const {
    id: productId,
    name,
    thumbnail,
    display,
    inventory,
    package_price_list_entries: packages,
    track_inventory,
    vendor,
  } = product;

  const packages_ = displaySubscription
    ? packages
    : packages.filter(
      (p) =>
        p.packagepricelistentrysubscriptionsettings?.order_options ==
        SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME ||
        p.packagepricelistentrysubscriptionsettings?.order_options ==
        SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
        p.packagepricelistentrysubscriptionsettings == null // no packagepricelistentrysubscriptionsettings yet, default is ONE TIME
    );

  const packageOptions = packages_.map((p) => ({
    ...p,
    available: getAvailableInventoryCount(inventory, p?.inventory_per_unit),
    track_inventory,
  }));

  const setToasts = useSetRecoilState(toastAtom);
  const selectedPackage = packageOptions[0];
  const { loading, order, setOrder, priceList } = useOrder(priceListSlug);
  const [quantity, setQuantity] = useState(0);
  const cartProduct = order?.order_entries.find(
    (item) => selectedPackage?.id === item?.package_price_list_entry?.id
  );

  const soldOut = track_inventory && !packageOptions.some((p) => p.available);
  const [loadingAddProduct, setLoadingAddProduct] = useState(false);
  const productSubscriptionOptions = getProductSubscriptionOptions(packages);
  const fulfillmentSupportsSubscriptions = !order?.fulfillment || order?.subscriptions_available;

  const showAddToCartButton =
    packageOptions.length === 1 &&
    productSubscriptionOptions !== SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION &&
    (fulfillmentSupportsSubscriptions ||
      productSubscriptionOptions == SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME);

  const addProduct = async (e) => {
    e.stopPropagation();
    const toastId = generateRandomNumber();
    try {
      setLoadingAddProduct(true);
      await setOrder({
        order_entries: [
          {
            storefront_unit_quantity: 1,
            package_price_list_entry: selectedPackage.id,
            is_subscription: productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION,
          },
        ],
      });
      if (!upfrontFulfillmentEnabled) {
        setToasts((prevState) => [
          ...prevState,
          {
            id: toastId,
            timer: 3000,
            message: t('storefront/store/shop/product--added-to-cart', { productName: name }),
            type: 'success',
            direction: 'left',
          },
        ]);
      }
    } catch (err) {
      setErrors((prevState) => [...prevState, err.message]);
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('storefront/store/shop/product--added-to-cart--error', { productName: name }),
          type: 'error',
          direction: 'left',
        },
      ]);
    }

    setLoadingAddProduct(false);
  };

  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(cartProduct?.storefront_unit_quantity);
      }
    }, 400),
    [cartProduct?.storefront_unit_quantity, order?.fulfillment, order?.fulfillment?.id]
  );

  useEffect(() => {
    if (!!cartProduct) {
      setQuantity(cartProduct.storefront_unit_quantity);
    }
  }, [cartProduct]);

  return (
    <>
      <div
        id="product__card--container"
        data-testid="product__card--container"
        className="flex flex-col">
        <ProductImage classes="mb-3" product={product} />
        <div className="flex w-full">
          {soldOut ? (
            <Button disabled classes="button--storefront-primary flex-1">
              {t('storefront/store/shop/product--sold-out')}
            </Button>
          ) : showAddToCartButton ? (
            !!cartProduct ? (
              <div
                className="flex flex-col items-center justify-center w-full"
                onClick={(e) => e.stopPropagation()}>
                <InputPlusMinus
                  disabled={loading}
                  id={`unit-quantity-mobile-${productId}`}
                  name={`unit-quantity-mobile-${productId}`}
                  classes="w-full"
                  inputNumberClasses="flex flex-grow w-full"
                  inputClasses="flex flex-grow !w-full"
                  value={quantity}
                  max={getMaxItemQuantity(
                    track_inventory,
                    selectedPackage?.available,
                    selectedPackage?.max_units_per_order
                  )}
                  handleChange={(storefront_unit_quantity) => {
                    setQuantity(storefront_unit_quantity);
                    if (!!cartProduct) {
                      if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
                        debouncedSetOrder({
                          order_entries: [{ id: cartProduct?.id, storefront_unit_quantity }],
                        });
                      }
                    }
                  }}
                />
                <InventoryCounter
                  displayRemainingInventory={displayRemainingInventory}
                  trackInventory={track_inventory}
                  soldOut={soldOut}
                  quantityAvailable={selectedPackage?.available}
                  quantityInCart={quantity}
                  maxUnitsPerOrder={selectedPackage?.max_units_per_order}
                />
              </div>
            ) : (
              <Button
                data-dd-action-name="add-product-to-cart"
                classes="button--storefront-primary flex-1 h-min"
                onClick={(e) => addProduct(e)}>
                <div className="flex items-center justify-center">
                  {loadingAddProduct ? (
                    <SpinnerSmall stroke="white" />
                  ) : displaySubscription &&
                    productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION ? (
                    t('global/Subscribe')
                  ) : (
                    t('storefront/store/shop/product--add-to-cart')
                  )}
                  {!loadingAddProduct && (
                    <>
                      {displaySubscription &&
                        productSubscriptionOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION && (
                          <CircleArrows stroke="white" classes="ml-2" dimensions="20" />
                        )}
                    </>
                  )}
                </div>
              </Button>
            )
          ) : (
            <>
              {packageOptions.length > 0 && (
                <Button
                  classes="button--storefront-primary flex-1 justify-center items-center flex flex-row"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push({
                      pathname: `/${priceListSlug}/product/${productId}`,
                      search,
                    });
                  }}>
                  {t('storefront/store/shop/product--view-options')}
                  <ArrowUpRight stroke="white" classes="ml-2" />
                </Button>
              )}
            </>
          )}
        </div>
        <div className="w-full flex flex-col justify-start mt-3">
          <div className="flex items-center justify-start">
            <Link to={`/${priceListSlug}/product/${productId}`}>
              <h2 className="font-bold text-base text-Grey800 mb-0.5">{name}</h2>
            </Link>
          </div>
          <span className="text-Grey500 text-xs flex flex-grow">{vendor?.name}</span>
        </div>
        {!!packageOptions[0] && (
          <div className="flex items-center mb-0" onClick={(e) => e.stopPropagation()}>
            <PackageValueGridView
              t={t}
              package_={packageOptions[0]}
              displayDiscount={displayDiscount}
              soldOut={soldOut}
              displaySoldOut
            />
          </div>
        )}
      </div>
    </>
  );
};

ProductCardGridView.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    inventory: PropTypes.number,
    name: PropTypes.string,
    package_price_list_entries: PropTypes.array,
    price_list: PropTypes.number,
    price_list_category: PropTypes.number,
    priority_order: PropTypes.number,
    product: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    thumbnail: PropTypes.string,
    track_inventory: PropTypes.bool,
  }),
  setErrors: PropTypes.func,
  displayDiscount: PropTypes.bool,
};

ProductCardGridView.defaultProps = {
  setErrors: () => { },
  displayDiscount: false,
};

export default ProductCardGridView;
