import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="6"
    height="20"
    viewBox="0 0 6 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70711 5.29289C6.09763 5.68342 6.09763 6.31658 5.70711 6.70711L2.41421 10L5.70711 13.2929C6.09763 13.6834 6.09763 14.3166 5.70711 14.7071C5.31658 15.0976 4.68342 15.0976 4.29289 14.7071L0.292893 10.7071C-0.0976311 10.3166 -0.0976311 9.68342 0.292893 9.29289L4.29289 5.29289C4.68342 4.90237 5.31658 4.90237 5.70711 5.29289Z"
      fill={stroke}
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
