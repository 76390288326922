import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={classes}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1667 13.3333L17.5 9.99992M17.5 9.99992L14.1667 6.66658M17.5 9.99992L5.83333 9.99992M10.8333 13.3333V14.1666C10.8333 15.5473 9.71405 16.6666 8.33333 16.6666H5C3.61929 16.6666 2.5 15.5473 2.5 14.1666V5.83325C2.5 4.45254 3.61929 3.33325 5 3.33325H8.33333C9.71405 3.33325 10.8333 4.45254 10.8333 5.83325V6.66658"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: theme.colors.Grey400,
};

export default Svg;
