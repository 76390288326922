import { ChevronMediumLeft } from 'assets/svgIcons';
import { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navigation = ({ navigationRoutes }) => {
  const location = useLocation();
  const currentRoute = navigationRoutes.find((r) => r.path === location.pathname);
  const lastRank = Math.max(...navigationRoutes.map((r) => r.rank));

  return (
    <nav className="checkout__navigation">
      {navigationRoutes.map(({ path, label, rank }, i) => (
        <Fragment key={path}>
          {rank < currentRoute?.rank && currentRoute?.rank !== lastRank ? (
            <Link
              to={path}
              className={`hover:text-Green600 ${location.pathname === path ? 'selected' : ''}`}>
              {label}
            </Link>
          ) : (
            <a className={`cursor-none ${location.pathname === path ? 'selected' : ''}`}>{label}</a>
          )}

          {i + 1 !== navigationRoutes.length && <ChevronMediumLeft />}
        </Fragment>
      ))}
    </nav>
  );
};
export default Navigation;
