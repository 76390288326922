import Alert from 'components/styled/Alert';
import moment from 'moment';
import { GATEWAY_PAYMENT_METHOD, ON } from 'helpers/constants';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';

const CheckoutNeedsApprovalMessage = ({
  hasOutstandingBalance,
  orderNeedsApproval,
  isPaymentGateway,
  isPayLater,
  gatewayPaymentMethod,
}) => {
  const { t } = useTranslation();
  const account = useRecoilValue(accountAtom);

  const isCard =
    hasOutstandingBalance &&
    !!orderNeedsApproval &&
    isPaymentGateway &&
    !isPayLater &&
    gatewayPaymentMethod?.payment_method_name === GATEWAY_PAYMENT_METHOD.CARD;

  return (
    <>
      {isCard && (
        <Alert
          id="card-hold-info"
          classes="mb-4 mt-4"
          type="info"
          hasIcon
          description={`${t('storefront/store/checkout-card-needs-approval', {
            seller_name: account?.business_name,
          })}`}
        />
      )}
    </>
  );
};

CheckoutNeedsApprovalMessage.propTypes = {};
CheckoutNeedsApprovalMessage.defaultProps = {};

export default CheckoutNeedsApprovalMessage;
