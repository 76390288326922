import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button';
import InputCreditCard from 'components/InputCreditCard';
import { SQUARE, STRIPE, LOCAL_PAY, PAYRIX } from './index';
import { addCustomerCard } from 'api/storefront/customers';
import ModalPortal from 'components/styled/ModalPortal';
import { formatErrors, formatProblemJSONErrors, getGatewayDetails } from 'helpers/helpers';
import { useRecoilValue } from 'recoil';
import { accountAtom, customerAtom } from 'state/storefront/accountState';
import GatewayPaymentMethods from 'components/GatewayPaymentMethods/index.js';
import SaveCardAchAuthorization from './SaveCardAchAuthorization';
import { GATEWAY_PAYMENT_METHOD } from 'helpers/constants';
import { readPayrixGatewayCustomers } from 'api/storefront/payment-gateways';
import useApi from 'hooks/common/api';
import { useEffect } from 'react';

const AddCardModal = ({
  gateway,
  closeModal,
  gatewayKey,
  handleAddCard,
  gatewayPaymentMethods,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [addCardLoading, setAddCardLoading] = useState(false);
  const account = useRecoilValue(accountAtom);
  const customer = useRecoilValue(customerAtom);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const ref = useRef();

  const addCard = async (event) => {
    event.preventDefault();

    setAddCardLoading(true);
    setError('');
    let response = undefined;
    let source_id = undefined;

    try {
      if (ref?.current) {
        response = await ref.current.getSourceId();
        source_id = gateway === LOCAL_PAY ? response?.card?.id : response?.token;
      }
      const resp = await addCustomerCard({ source_id }, { gateway });
      handleAddCard(resp?.data);
      closeModal();
    } catch (e) {
      setError(formatErrors(formatProblemJSONErrors(e?.response?.data))[0]);
    } finally {
      setAddCardLoading(false);
    }
  };

  const convertedGatewayPaymentMethods = gatewayPaymentMethods?.map(
    ({ id, payment_method_name }) => ({
      gateway_payment_method: id,
      payment_method_name,
    })
  );
  const { request: payrixCustomersRequest } = useApi(readPayrixGatewayCustomers);

  useEffect(() => {
    setSelectedPaymentMethod(gatewayPaymentMethods?.[0]);
  }, []);

  return (
    <ModalPortal
      target={gateway}
      title={t('storefront/my-account/saved-payments/add-modal--title')}
      onClose={closeModal}
      classes="p-0 overflow-y-visible">
      <div className="flex flex-col p-6 pt-4 min">
        {error ? <p className="text-red-600">{error}</p> : null}
        <GatewayPaymentMethods
          ref={ref}
          gatewayDetails={getGatewayDetails(
            gateway,
            gatewayKey,
            account?.payrix_merchant_id,
            customer?.id,
            payrixCustomersRequest,
            account?.country_code
          )}
          gatewayPaymentMethods={convertedGatewayPaymentMethods}
          modalStyling={true}
          selectedPaymentMethod={selectedPaymentMethod}
          onPaymentMethodChange={(gatewayPaymentMethodId) =>
            setSelectedPaymentMethod(
              convertedGatewayPaymentMethods.find(
                (method) => method.gateway_payment_method === gatewayPaymentMethodId
              )
            )
          }
        />
        <SaveCardAchAuthorization
          isAch={selectedPaymentMethod?.payment_method_name === GATEWAY_PAYMENT_METHOD.ACH}
        />
      </div>
      <div className="modal--actions">
        <Button classes="button button--white" onClick={closeModal} disabled={addCardLoading}>
          {t('Cancel')}
        </Button>
        <Button classes="button button--green" onClick={addCard} disabled={addCardLoading}>
          {t('Add')}
        </Button>
      </div>
    </ModalPortal>
  );
};

AddCardModal.propTypes = {
  gateway: PropTypes.string,
  gatewayKey: PropTypes.string,
  closeModal: PropTypes.func,
  handleAddCard: PropTypes.func,
};

AddCardModal.defaultProps = {
  gateway: '',
  source_id: '',
  closeModal: () => { },
  handleAddCard: () => { },
};

export default AddCardModal;
