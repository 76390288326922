import Alert from 'components/styled/Alert';
import moment from 'moment';
import { GATEWAY_PAYMENT_METHOD, ON } from 'helpers/constants';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { accountAtom } from 'state/storefront/accountState';

const SaveCardAchAuthorization = ({ isAch }) => {
  const account = useRecoilValue(accountAtom);

  return (
    <>
      {isAch && (
        <Alert
          id="card-hold-info"
          classes="mb-4 mt-4"
          type="info"
          hasIcon
          description={
            <div>
              <p className="text-justify">
                <Trans i18nKey="global/save-card-ach-authorization">
                  Click "Add" to authorize {{ seller_name: account?.business_name }}to process
                  charges related to products and services you purchase from them. This
                  authorization will remain active until revoked by you.
                </Trans>
              </p>
            </div>
          }
        />
      )}
    </>
  );
};

SaveCardAchAuthorization.propTypes = {};
SaveCardAchAuthorization.defaultProps = {};

export default SaveCardAchAuthorization;
