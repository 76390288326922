// consistent token response should be of this form
const TOKENIZED_CARD_RESPONSE = {
  error: null,
  token: null,
  card: {
    brand: null,
    last4: null,
  },
};

const CREATED_PAYMENT_METHOD = {
  error: null,
  id: null,
  card: {
    brand: null,
    last4: null,
  },
};

export { TOKENIZED_CARD_RESPONSE, CREATED_PAYMENT_METHOD };
export { default, STRIPE, SQUARE, LOCAL_PAY, PAYRIX } from './InputCreditCard';
