import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from 'components/styled/Modal';
import InputText from 'components/styled/InputText';
import Button from 'components/styled/Button';
import { toastAtom } from 'state/appState';
import { useSetRecoilState } from 'recoil';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { formatProblemJSONErrors } from 'helpers/helpers';

const ForgotPassword = ({ onReset, onClose, passwordResetRequest }) => {
  const { t } = useTranslation();

  // states
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const setToasts = useSetRecoilState(toastAtom);

  //methods
  const resetPassword = async () => {
    try {
      const resp = await passwordResetRequest({ email });
      const toastId = generateRandomNumber();
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('login/forgot-password/reset-confirmation'),
          type: 'success',
        },
      ]);
      onReset();
    } catch (e) {
      setErrors(formatProblemJSONErrors(e?.response?.data));
    }
  };

  return (
    <Modal containerClasses="fixed" classes="overflow-y-hidden p-6" onClose={onClose}>
      <div className="">
        <h1 className="mb-4">{t('login/forgot-password')}</h1>
        <p>{t('login/forgot-password/description')}</p>
      </div>
      <InputText
        id="email"
        name="email"
        type="email"
        labelText={t('global/email-address')}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        classes="mb-4"
        error={!!errors?.email ? errors['email'] : null}
      />
      <Button classes="mb-2" onClick={resetPassword}>
        {t('Reset password')}
      </Button>
      <Button classes="mb-2 button button--none" onClick={() => onClose()}>
        {t('Cancel')}
      </Button>
    </Modal>
  );
};

ForgotPassword.propTypes = { onClose: PropTypes.func };

ForgotPassword.defaultProps = { onClose: () => {} };

export default ForgotPassword;
