import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import SidebarDivider from 'components/Sidebar/SidebarDivider';
import Tag from 'components/styled/Tag';

const ActiveFilters = ({ filters, removeActiveFilter, dividerSize, clearActiveFilters }) => {
  const { t } = useTranslation();

  const activeFilters = Object.entries(filters).reduce((a, [_, { value, label, type }]) => {
    return [
      ...a,
      <div className="flex items-center" key={`${type}-${value}`}>
        <Tag
          classes="bg-white border border-1 border-Grey300 text-Grey800 text-xs font-normal rounded-[4px] w-fit p-1 place-self-center my-1"
          onClick={() => removeActiveFilter(type, { value, label })}
          key={`${type}-${value}`}
          name={label}
          iconProps={{ stroke: theme.colors.Grey400 }}
        />
      </div>,
    ];
  }, []);

  return (
    <>
      {activeFilters.length > 0 ? (
        <>
          <div className="flex justify-between my-1">
            <span className="text-Grey800">{t('global/active-filters')}</span>
            {activeFilters.length > 1 && (
              <>
                <span
                  id="store__active-filters--clear-filters"
                  className="text-Green600 cursor-pointer"
                  onClick={clearActiveFilters}>
                  {t('global/remove-all')}
                </span>
              </>
            )}
          </div>
          <div className="flex flex-wrap my-1">{activeFilters}</div>
          {activeFilters.length > 0 && <SidebarDivider numberOfCircles={dividerSize} />}
        </>
      ) : null}
    </>
  );
};

ActiveFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

ActiveFilters.defaultProps = {
  filters: {},
  setFilters: () => {},
};

export default ActiveFilters;
