import { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputPlusMinus from 'components/styled/InputPlusMinus';
import { ArrowDoubleRotatingFilledCircle, X } from 'assets/svgIcons';
import theme from 'styles/theme';
import { debounce } from 'helpers/helpers';
import Price from './Price';
import Money from 'components/styled/Money';
import { currencyAtom } from 'state/common/currency';
import { useRecoilValue } from 'recoil';

const CartItem = ({
  classes,
  id,
  product_name,
  product,
  package_price_list_entry: _package,
  charge_type,
  charge_unit,
  quantity_to_charge,
  price,
  price_per_unit,
  storefront_unit_quantity,
  readOnly,
  error,
  productLink,
  setOrder,
  loading,
  isSubscription,
}) => {
  const { t } = useTranslation();
  const { Grey300 } = theme.colors;
  const currency = useRecoilValue(currencyAtom);

  // states
  const [quantity, setQuantity] = useState(storefront_unit_quantity);

  // methods
  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(storefront_unit_quantity);
      }
    }, 400),
    [storefront_unit_quantity]
  ); // wait 400ms for new input before calling update

  // effects
  useEffect(() => {
    setQuantity(storefront_unit_quantity);
  }, [storefront_unit_quantity]);

  const getQuantityToChargeDisplay = () => {
    if (readOnly && charge_type == 'package' && quantity_to_charge && quantity_to_charge != 1) {
      return ` x ${quantity_to_charge}`;
    }

    if (readOnly && charge_type == 'weight' && quantity_to_charge && quantity_to_charge != 1) {
      return ` x ${quantity_to_charge} ${charge_unit}`;
    }

    return '';
  };

  const getChargeTypeLabel = () => {
    if (charge_type === 'package' && _package?.name) {
      return ` / ${_package.name}`;
    }

    if (charge_type === 'weight' && charge_unit) {
      return ` / ${charge_unit}`;
    }

    return '';
  };

  return (
    <Fragment>
      <div
        key={`product-${id}`}
        className={`cart-item relative flex items-start w-full ${classes}`}>
        {product?.thumbnail && (
          <img src={product?.thumbnail} alt={product_name} className="w-12 h-12  mr-2 rounded-sm" />
        )}
        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <h4 className={`flex flex-wrap mb-0.5 text-Grey800 ${error ? 'text-Red700' : ''}`}>
              {product_name}
              {getQuantityToChargeDisplay()}
            </h4>
            {productLink && (
              <Link className="text-sm" target="_blank" to={productLink}>
                {t('view product')}
              </Link>
            )}
          </div>

          <div className="flex justify-between items-center">
            <span className="text-Grey500 text-sm">
              <Money
                value={readOnly ? price_per_unit : _package?.package_price}
                currency={currency}
              />
              {getChargeTypeLabel()}
            </span>
            {readOnly && <Price loading={loading} price={price} />}
          </div>

          {!readOnly && (
            <div className="flex justify-between items-center flex-1">
              <InputPlusMinus
                disabled={loading}
                id={`unit-quantity-${id}`}
                name={`unit-quantity-${id}`}
                max={Math.floor(product?.inventory / _package?.inventory_per_unit)}
                value={quantity}
                handleChange={(storefront_unit_quantity) => {
                  setQuantity(storefront_unit_quantity);
                  if (!!storefront_unit_quantity || storefront_unit_quantity === 0) {
                    debouncedSetOrder({ order_entries: [{ id, storefront_unit_quantity }] });
                  }
                }}
              />
              <Price loading={loading} price={price} />
            </div>
          )}
          {error && <p className="text-Red700">{error}</p>}
          {isSubscription && (
            <p className="flex items-center text-Green600">
              <ArrowDoubleRotatingFilledCircle classes="mr-1" />
              {t('storefront/my-account/subscription-history/subscription')}
            </p>
          )}
        </div>
        {!readOnly && (
          <span
            className={`absolute top-0 right-0 cursor-pointer ${
              loading ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
            }`}
            onClick={() => setOrder({ order_entries: [{ id, storefront_unit_quantity: 0 }] })}>
            <X stroke={Grey300} />
          </span>
        )}
      </div>
      <hr className="my-2" />
    </Fragment>
  );
};

CartItem.propTypes = {
  classes: PropTypes.string,
  id: PropTypes.number,
  product_name: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    thumbnail: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        priority_order: PropTypes.number,
        name: PropTypes.string,
      })
    ),
  }),
  package_price_list_entry: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      unit_price: PropTypes.number,
      package_price: PropTypes.number,
    }),
  ]),
  charge_unit: PropTypes.string,
  price_per_unit: PropTypes.number,
  storefront_unit_quantity: PropTypes.number,
  inventory_quantity: PropTypes.number,
  readOnly: PropTypes.bool,
  productLink: PropTypes.string,
  setOrder: PropTypes.func,
  loading: PropTypes.bool,
  isSubscription: PropTypes.bool,
};

CartItem.defaultProps = {
  classes: '',
  readOnly: false,
  productLink: '',
  setOrder: undefined,
  loading: false,
  isSubscription: false,
};

export default CartItem;
