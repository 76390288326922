import React from 'react';
import Button from 'components/styled/Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Card from 'components/styled/Card';

const ProductUnavailable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Card classes="mt-16 text-center max-w-34.5 w-full px-2">
      <span className="text-2xl font-bold">
        {t('storefront/store/product/product-unavailable')}
      </span>
      <Button
        classes="button w-full items-center justify-center mt-8"
        onClick={() => history.push('/')}>
        {t('Back to shop')}
      </Button>
    </Card>
  );
};

export default ProductUnavailable;
