import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCard from './StripeCard';
import './StripeInputCreditCard.css';

const StripeInputCreditCard = forwardRef(({ publishableKey, setError }, ref) => {
  const stripePromise = loadStripe(publishableKey);

  return (
    <div className="stripe-input-credit-card">
      <Elements stripe={stripePromise}>
        <StripeCard ref={ref} setError={setError} />
      </Elements>
    </div>
  );
});

StripeInputCreditCard.propTypes = {
  publishableKey: PropTypes.string.isRequired,
};

export default StripeInputCreditCard;
