import { useResetRecoilState } from 'recoil';
import { passwordReset } from 'api/storefront/customers';
import { customerAtom } from 'state/storefront/accountState';
import { useStorefrontAuth } from 'hooks/storefront';
import { default as ResetPasswordComponent } from 'components/ResetPassword';
import { Footer } from './components';

const ResetPassword = () => {
  const { clearAuth } = useStorefrontAuth();
  const resetCustomer = useResetRecoilState(customerAtom);

  return (
    <div className='w-full flex justify-center items-center'>
        <ResetPasswordComponent
          clearAuth={clearAuth}
          resetUser={resetCustomer}
          redirectPath="/?login=true&redirect=account/price-lists"
          passwordReset={passwordReset}
        />
    </div>
  );
};

export default ResetPassword;