import { atom } from 'recoil';

export const alertsAtom = atom({
  key: 'alerts',
  default: [],
});

export const screenSizeAtom = atom({
  key: 'screenSize',
  default: {},
});

export const loadingProgressAtom = atom({
  key: 'loadingProgress',
  default: false,
});

export const toastAtom = atom({
  key: 'toast',
  default: [],
});

export const sidebarMinimizedAtom = atom({
  key: 'sidebarMinimized',
  default: false,
});
