import PropTypes from 'prop-types';
import { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { tokenizeACH } from 'api/storefront/payrix';
import useFormState from 'hooks/common/formState';
import InputNumber from 'components/styled/InputNumber';
import InputSelect from 'components/styled/InputSelect';
import InputText from 'components/styled/InputText';
import './PayrixInputCreditCard.css';

const PayrixInputACH = forwardRef(
  ({ tokenizeACH, payrixPublicKey, merchantID, customerId, payrixCustomersApi, setError, countryCode }, ref) => {
    const EMPTY_STRING = 'EMPTY_STRING';
    const { t } = useTranslation();
    const {
      formState: customerName,
      setFormStateValue: setCustomerNameValue,
      setFormStateValues: setCustomerNameValues,
      toPayload: toCustomerNamePayload,
      setFormStateErrors: setErrors,
      isDirty: isCustomerNameDirty,
    } = useFormState(
      {
        first: '',
        last: '',
      },
      ['first', 'last']
    );
    const [payrixCustomerId, setPayrixCustomerId] = useState();
    const [paymentData, setPaymentData] = useState({ payment: {} });
    const [achErrors, setAchErrors] = useState({});

    const fetchPayrixCustomerId = async (first, last) => {
      const resp = await payrixCustomersApi({
        cusomer: customerId,
        first_name: first || EMPTY_STRING,
        last_name: last || EMPTY_STRING,
        country_code: countryCode,
      });
      return resp?.[0]?.payrix_customer_id;
    };

    const getPayload = async () => {
      let customer;
      if (isCustomerNameDirty()) {
        const newPayrixCustomerId = await fetchPayrixCustomerId(
          customerName?.first?.value,
          customerName?.last?.value
        );
        customer = newPayrixCustomerId || { ...toCustomerNamePayload(false), merchant: merchantID };
      } else {
        customer = payrixCustomerId || { ...toCustomerNamePayload(false), merchant: merchantID };
      }

      return {
        ...paymentData,
        customer,
      };
    };

    const formatAchErrors = (errors) => {
      const formattedErrors = {};

      errors.forEach((error) => {
        const fieldParts = error.field.split('.');
        const lastPart = fieldParts[fieldParts.length - 1];

        formattedErrors[lastPart] = error.msg;
      });

      return formattedErrors;
    };

    const validateName = (payload) => {
      if (typeof payload.customer === 'object') {
        const { first, last } = payload.customer;
        if (!first) {
          setAchErrors({ first: t('First name is required') });
          throw t('First name is required');
        }
        if (!last) {
          setAchErrors({ last: t('Last name is required') });
          throw t('First name is required');
        }
      }
    };

    const tokenize = async () => {
      setAchErrors({});
      const payload = await getPayload();
      try {
        validateName(payload);
        const tokenResponse = await tokenizeACH(payload, {
          APIKEY: payrixPublicKey,
          'Content-Type': 'application/json',
        }, countryCode);
        const { data, errors } = tokenResponse?.data?.response;
        if (errors?.length) {
          const formattedErrors = formatAchErrors(errors);
          setAchErrors(formattedErrors);
          throw formattedErrors[Object.keys(formattedErrors)[0]];
        } else {
          return {
            id: data[0]?.id,
            token: data[0]?.token,
            card: {
              last4: data[0]?.payment?.number,
            },
          };
        }
      } catch (e) {
        console.error('error', e);
        setError(e);
        throw e;
      }
    };

    useImperativeHandle(ref, () => ({
      tokenize,
    }));

    const handleInputChange = (key, value) => {
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        [key]: value,
      }));
    };

    const initializePayrixCustomer = async () => {
      const resp = await payrixCustomersApi({ customer: customerId, country_code: countryCode, });
      const lastPayrixCustomer = resp?.[0];
      setCustomerNameValues({
        first: lastPayrixCustomer?.first_name,
        last: lastPayrixCustomer?.last_name,
      });
      setPayrixCustomerId(lastPayrixCustomer?.payrix_customer_id);
    };

    useEffect(() => {
      initializePayrixCustomer();
    }, []);

    return (
      <div>
        <form className="payrix-input-cc grid gap-2 grid-cols-2">
          <InputText
            inputClasses="box-border leading-6 text-sm font-light border-gray-300 border rounded color-text-primary w-full h-[42px] px-4 text-Grey900"
            id="first-name"
            placeholder="First name"
            disabled={false}
            value={customerName.first?.value}
            onChange={(e) => setCustomerNameValue('first', e.target.value)}
            error={achErrors?.first}
          />
          <InputText
            inputClasses="box-border leading-6 text-sm font-light border-gray-300 border rounded color-text-primary w-full h-[42px] px-4 text-Grey900"
            id="last-name"
            placeholder="Last name"
            disabled={false}
            value={customerName.last?.value}
            onChange={(e) => {
              setCustomerNameValue('last', e.target.value);
            }}
            error={achErrors?.last}
          />
          <InputSelect
            classes="h-full col-span-2"
            id="account-type"
            value={{ value: paymentData.payment.method, label: paymentData.payment.label }}
            options={[
              { label: 'Business Checking', value: 10 },
              { label: 'Business Saving', value: 11 },
              { label: 'Personal Checking', value: 8 },
              { label: 'Personal Saving', value: 9 },
            ]}
            handleChange={(option) =>
              handleInputChange('payment', {
                ...paymentData.payment,
                method: option.value,
                label: option.label,
              })
            }
            placeholder={t('Account Type')}
            error={achErrors?.method}
          />
          <InputNumber
            inputClasses="box-border leading-6 text-sm font-light border-gray-300 border rounded color-text-primary w-full h-[42px] px-4 text-Grey900"
            id="routing"
            placeholder="Routing Number"
            value={paymentData.payment.routing}
            hasArrowButtons={false}
            onChange={(value) => {
              handleInputChange('payment', { ...paymentData.payment, routing: value });
            }}
            error={achErrors?.routing}
          />
          <InputNumber
            inputClasses="box-border leading-6 text-sm font-light border-gray-300 border rounded color-text-primary w-full h-[42px] px-4 text-Grey900"
            id="account-number"
            placeholder="Account Number"
            value={paymentData.payment.number}
            hasArrowButtons={false}
            onChange={(value) => {
              handleInputChange('payment', { ...paymentData.payment, number: value });
            }}
            error={achErrors?.number}
          />
        </form>
      </div>
    );
  }
);

PayrixInputACH.propTypes = {
  tokenizeACH: PropTypes.func,
  payrixPublicKey: PropTypes.string,
  merchantID: PropTypes.string,
  customerId: PropTypes.string,
  payrixCustomersApi: PropTypes.func,
  setError: PropTypes.func,
};

PayrixInputACH.defaultProps = {
  tokenizeACH,
  payrixPublicKey: undefined,
  merchantID: undefined,
  customerId: undefined,
  payrixCustomersApi: () => { },
  setError: () => { },
};

export default PayrixInputACH;
