import Money from 'components/styled/Money';

const Price = ({
  soldOut,
  selectedPackageOptionPrice,
  currency,
  displayStrikethrough,
  strikeThroughValue,
}) => (
  <div className="flex items-center justify-start">
    <h4
      className={`flex flex-row font-bold text-base ${soldOut ? 'text-Grey400' : 'text-Grey800'}`}>
      <Money value={selectedPackageOptionPrice} currency={currency} />
    </h4>
    {displayStrikethrough && (
      <span className={`line-through ${soldOut ? 'text-Grey400' : 'text-Grey500'} ml-1`}>
        <Money value={strikeThroughValue} currency={currency} />
      </span>
    )}
  </div>
);

export default Price;
