import { api } from '.';

export const authenticate = (email, password) => {
  return api.post('/token', {
    email,
    password,
  });
};

export const authenticateAnonymously = () => api.post('/token/anonymous');

export const refreshAccessToken = (refresh) => api.post('/token/refresh', { refresh });

export const verifyAccessToken = (token) => api.post('/token/verify', { token });
