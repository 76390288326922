import { SpinnerSmall } from 'assets/svgIcons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ON } from 'helpers/constants';
import { getEarliestAvailableDate } from 'helpers/helpers';
import { readPriceListFulfillmentStrategies } from 'api/storefront/price-lists';
import { subscriptionsSelector } from 'state/storefront/accountState';
import { priceListAtomFamily } from 'state/storefront/storeState';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Alert from 'components/styled/Alert';
import Card from 'components/styled/Card';
import { PICKUP } from '.';
import { FLEXIBLE } from '.';
import CustomerNote from './CustomerNote';
import FulfillmentAvailability from './FulfillmentAvailability';
import FulfillmentStrategyOption from './FulfillmentStrategyOption';

const PickupLocations = ({
  orderFulfillment,
  setOrderFulfillment,
  selectedStrategy,
  setSelectedStrategy,
  customerNote,
  setCustomerNote,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { priceList: priceListHook } = useOrder();
  const subscriptionsEnabled = useRecoilValue(subscriptionsSelector);
  const upfrontFulfillmentEnabled =
    useTreatment('storefront_upfront_fulfillment') === ON || subscriptionsEnabled;
  // states
  const priceList = upfrontFulfillmentEnabled
    ? priceListHook
    : useRecoilValue(priceListAtomFamily(priceListSlug));
  const { order, setOrder } = useOrder(priceListSlug);

  const [loading, setLoading] = useState(false);
  const [pickupStrategies, setPickupStrategies] = useState([]);

  // methods
  const fetchPickupStrategies = async () => {
    let strategies = [];
    setLoading(true);
    try {
      const resp = await readPriceListFulfillmentStrategies(priceList?.id, {
        fulfillment_type: PICKUP,
        page_size: 150, // temporary page_size
      });
      strategies = resp?.data?.results.filter(
        (strategy) =>
          strategy?.availability?.available_dates?.length > 0 ||
          strategy?.availability?.type === FLEXIBLE
      );
      // for each strategy in strategies, remove it's objects in the availability array
      // where available_date.cutoff_datetime is in the past
      strategies.forEach((strategy) => {
        strategy.availability.available_dates = strategy.availability.available_dates.filter(
          (date) => moment(date.cutoff_datetime).isAfter()
        );
      });
      setPickupStrategies(strategies);
      if (strategies.length === 1) {
        setSelectedStrategy(strategies[0]);
        setOrderFulfillment({
          ...orderFulfillment,
          fulfillment_strategy: strategies[0]?.id,
          fulfillment_date: getEarliestAvailableDate(strategies[0]?.availability?.available_dates)
            ?.available_date,
          time_slot: strategies[0]?.availability?.time_slots_enabled
            ? strategies[0]?.availability?.time_slots[0]?.id
            : undefined,
        });
      } else {
        setSelectedStrategy(null);
        setOrderFulfillment({
          ...orderFulfillment,
          fulfillment_strategy: null,
        });
      }
    } catch (e) {
      console.error('Error fetching pickup locations', e);
    } finally {
      setLoading(false);
      return strategies;
    }
  };

  // effects
  useEffect(async () => {
    const strategies = await fetchPickupStrategies();
    if (order?.fulfillment?.fulfillment_strategy_type === PICKUP) {
      const matchingPickupStrategy = strategies.find(
        (ps) => ps.id === order?.fulfillment?.fulfillment_strategy
      );
      if (!!matchingPickupStrategy) {
        setSelectedStrategy(matchingPickupStrategy);
        const fulfillment_date = order?.fulfillment?.fulfillment_date
          ? order?.fulfillment?.fulfillment_date
          : getEarliestAvailableDate(matchingPickupStrategy?.availability?.available_dates)
              ?.available_date;
        setOrderFulfillment({
          ...orderFulfillment,
          fulfillment_strategy: matchingPickupStrategy?.id,
          fulfillment_date: fulfillment_date,
        });
      }
    }
  }, []);

  return (
    <Card
      id="pickup-locations"
      title={t('storefront/store/checkout--fulfillment-pickup-title')}
      classes="mb-6 rounded-none sm:rounded-xl">
      <>
        {!!errors.strategy && (
          <Alert
            id="fulfillment-strategy-pickup-alert"
            type="error"
            title={t('storefront/store/shop/cart/details--error-strategy')}
            classes="mb-6"
          />
        )}
        <div className="flex flex-col border rounded-lg mb-4">
          {loading && pickupStrategies?.length === 0 ? (
            <SpinnerSmall classes="my-6 self-center" />
          ) : null}
          {pickupStrategies.map((pickupStrategy) => {
            return (
              <FulfillmentStrategyOption
                key={pickupStrategy?.id}
                loading={loading}
                subtotal={order?.subtotal}
                strategy={pickupStrategy}
                selected={selectedStrategy?.id === pickupStrategy?.id}
                onSelect={() => {
                  const earliestDate = getEarliestAvailableDate(
                    pickupStrategy?.availability?.available_dates
                  )?.available_date;
                  setSelectedStrategy(pickupStrategy);

                  // on change pickup plan ensure to change time_slot too
                  const time_slot = pickupStrategy?.availability?.time_slots_enabled
                    ? pickupStrategy?.availability?.time_slots[0]?.id
                    : null;
                  setOrderFulfillment({
                    ...orderFulfillment,
                    fulfillment_strategy: pickupStrategy?.id,
                    fulfillment_date: pickupStrategy?.availability?.available_dates?.length
                      ? earliestDate
                      : null,
                    // can't be null so conditionally spread
                    ...(time_slot ? { time_slot } : {}),
                  });
                  setErrors((prevState) => ({ ...prevState, strategy: '', fulfillment_date: '' }));
                }}
              />
            );
          })}
        </div>
        {!!orderFulfillment?.fulfillment_strategy &&
          selectedStrategy?.id &&
          selectedStrategy?.availability?.type !== FLEXIBLE && (
            <>
              <h2>{t('storefront/store/checkout--fulfillment-pickup-title-info')}</h2>
              {!!errors.fulfillment_date && (
                <Alert
                  id="fulfillment-strategy-fulfillment-date-alert"
                  type="error"
                  title={t('storefront/store/shop/cart/details--error-fulfillment_date')}
                  classes="mb-4"
                />
              )}
              <FulfillmentAvailability
                title={t('storefront/store/checkout--fulfillment-pickup-title-info')}
                availability={selectedStrategy?.availability}
                type={PICKUP}
                orderFulfillment={orderFulfillment}
                setOrderFulfillment={setOrderFulfillment}
                setErrors={setErrors}
              />
            </>
          )}
        {!!orderFulfillment?.fulfillment_strategy && selectedStrategy?.id && (
          <CustomerNote
            initialValue={order?.customer_note}
            note={customerNote}
            setNote={setCustomerNote}
          />
        )}
      </>
    </Card>
  );
};

PickupLocations.propTypes = {
  orderFulfillment: PropTypes.object,
  setOrderFulfillment: PropTypes.func,
  selectedStrategy: PropTypes.object,
  setSelectedStrategy: PropTypes.func,
  customerNote: PropTypes.string,
  setCustomerNote: PropTypes.func,
};

export default PickupLocations;
