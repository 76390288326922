import PropTypes from 'prop-types';
import theme from 'styles/theme';

const Svg = ({ stroke, classes, width, height }) => (
  <svg
    width={width}
    height={height}
    className={classes}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 10L20 14M20 14L16 18M20 14H12C8.68629 14 6 11.3137 6 8V6"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey300,
  classes: '',
  width: '24',
  height: '24',
};

export default Svg;
