import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { findDifferenceBetweenTwoObjects } from 'helpers/helpers';
import { readCustomer } from 'api/storefront/customers';
import { readCustomerCards } from 'api/storefront/customers';
import { patchCustomer } from 'api/storefront/customers';
import { accountAtom, authAtom } from 'state/storefront/accountState';
import {
  customerFieldsAtom,
  savedCardsAtom,
  selectedSavedCardIdAtom,
  savedCardGatewayPaymentMethodAtom,
} from '../CheckoutState';

const useCheckoutCustomer = (order, customer, setCustomer) => {
  const { t } = useTranslation();
  const [customerFields, setCustomerFields] = useRecoilState(customerFieldsAtom);
  const [savedCards, setSavedCards] = useRecoilState(savedCardsAtom);
  const [selectedSavedCardId, setSelectedSavedCardId] = useRecoilState(selectedSavedCardIdAtom);
  const [savedCardGatewayPaymentMethod, setSavedCardGatewayPaymentMethod] = useRecoilState(
    savedCardGatewayPaymentMethodAtom
  );
  const account = useRecoilValue(accountAtom);
  const auth = useRecoilValue(authAtom);
  const enablePhoneOnCheckout = account?.storefront_configuration?.enable_phone_on_checkout;
  const isCustomerFromOrder = auth?.userId === order?.customer?.id;

  const showStoreCredit = () => {
    return (
      !!customer?.id &&
      (customer?.store_credit_balance > 0 || order?.payment?.store_credit_amount > 0)
    );
  };

  const fetchCustomerCards = async (gateway_short) => {
    if (!gateway_short) return;
    setSavedCards([]);
    try {
      const resp = await readCustomerCards({
        gateway: gateway_short,
      });
      if (resp?.data && resp?.data.length > 0) {
        setSavedCards(resp?.data);
        setSelectedSavedCardId(resp?.data[0]?.source_id); // set first
        setSavedCardGatewayPaymentMethod(resp?.data[0]?.gateway_payment_method?.id);
        return resp?.data[0]?.gateway_payment_method?.id;
      }
    } catch (e) {
      console.error('Error fetching saved cards: ', e);
    } finally {
    }
  };

  const fetchCustomer = async () => {
    try {
      const { data } = await readCustomer();
      setCustomer((prevState) => ({
        ...prevState,
        store_credit_balance: data.store_credit_balance,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const compareAndUpdateCustomer = async () => {
    try {
      const { id, email, first_name, last_name, phone } = customer;
      const updateCustomer = findDifferenceBetweenTwoObjects(
        { email, first_name, last_name, phone },
        { ...customerFields }
      );
      if (Object.keys(updateCustomer).length) {
        await patchCustomer({ id, ...updateCustomer });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _updatedCustomerField = (field) => {
    if (customer?.id) {
      return customer?.[field] || ''; // Prioritize customer if customer ID is set
    }
    return order?.customer?.[field] || ''; // Fallback to order's customer
  };

  const updateCustomerFields = () => {
    const updatedCustomerFields = {
      email: _updatedCustomerField('email'),
      first_name: _updatedCustomerField('first_name'),
      last_name: _updatedCustomerField('last_name'),
      phone: _updatedCustomerField('phone'),
    };

    setCustomerFields(updatedCustomerFields);
  };

  const validateCustomerFields = () => {
    const errors = {};
    const requiredCustomerFields = ['email', 'first_name', 'last_name'];

    if (enablePhoneOnCheckout) {
      requiredCustomerFields.push('phone');
    }

    requiredCustomerFields.forEach((field) => {
      if (!customerFields[field]) {
        errors[field] = t(`storefront/store/shop/cart/details--error-${field}`);
      }
    });

    return errors;
  };

  return {
    customerFields,
    setCustomerFields,
    savedCards,
    setSavedCards,
    selectedSavedCardId,
    setSelectedSavedCardId,
    savedCardGatewayPaymentMethod,
    setSavedCardGatewayPaymentMethod,
    showStoreCredit,
    fetchCustomerCards,
    fetchCustomer,
    updateCustomerFields,
    compareAndUpdateCustomer,
    validateCustomerFields,
  };
};

export default useCheckoutCustomer;
