import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ON, SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import { PACKAGES_NEW_ON_SALE_TAG } from 'helpers/featureFlags';
import { debounce, getAvailableInventoryCount } from 'helpers/helpers';
import { formatMoney } from 'helpers/localeHelpers';
import { frequencyLabelTranslationMapping, getDayOfWeek } from 'helpers/subscriptionHelpers';
import { currencyAtom } from 'state/common/currency';
import { accountAtom } from 'state/storefront/accountState';
import useMonthlyDate from 'hooks/common/monthlyDate';
import useTreatment from 'hooks/common/splitTreatment';
import { useOrder } from 'hooks/storefront';
import Card from 'components/styled/Card/Card';
import Money from 'components/styled/Money';
import CTAButton from './CTAButton';
import CartQuantityInput from './CartQuantityInput';
import InventoryRemaining from './InventoryRemaining';
import IsByWeight from './IsByWeight';
import PackageName from './PackageName';
import Price from './Price';
import SubscriptionOptions from './SubscriptionOptions';

const DetailsPackageCard = ({
  id,
  name,
  package_price,
  inventory,
  inventory_per_unit,
  track_inventory,
  base_unit,
  charge_unit,
  is_by_weight,
  is_package_weight,
  is_weight_package,
  average_pack_weight,
  original_package_price,
  packagepricelistentrysubscriptionsettings,
  subscription_price,
  displayDiscount,
  displayDetails,
  availableFulfillmentOptions,
  on_sale,
  on_sale_toggle,
  max_units_per_order,
  strikethrough_display_value,
}) => {
  const { t } = useTranslation();
  const account = useRecoilValue(accountAtom);
  const currency = useRecoilValue(currencyAtom);
  const { priceListSlug } = useParams();
  const [quantity, setQuantity] = useState(1);

  const { order, setOrder, loading, priceList } = useOrder(priceListSlug);
  const { fulfillment } = order;
  const orderOptions = packagepricelistentrysubscriptionsettings?.order_options;
  const displaySubscription = !!priceList?.subscription_settings_enabled;

  const allowOneTimePurchase =
    !orderOptions ||
    orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
    orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME;
  const oneTimeOnly =
    (!!orderOptions && orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME) || !orderOptions;

  const allowSubscription =
    !!orderOptions &&
    !!displaySubscription &&
    (orderOptions === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME_AND_SUBSCRIPTION ||
      orderOptions === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION);

  const [selectedOrderOption, setSelectedOrderOption] = useState(
    allowOneTimePurchase
      ? SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME
      : SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
  );
  const subscriptionFrequencyOptions = availableFulfillmentOptions?.find(
    (option) => option.id === order?.fulfillment?.fulfillment_strategy
  );

  // inventory_per_unit is normalized here and is the average_pack_weight if track by weight
  // ideally inventory_per_unit is null but this fixes LL2-2192
  // storefront takes integer values so Floor is used
  const available = getAvailableInventoryCount(inventory, inventory_per_unit);
  const soldOut = track_inventory && !available;
  const cartProduct = order?.order_entries.find((item) => {
    return (
      id === item?.package_price_list_entry?.id &&
      item?.is_subscription === (selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION)
    );
  });
  const unitName = is_by_weight
    ? is_package_weight && !is_weight_package
      ? charge_unit
      : base_unit
    : charge_unit;
  const displayRemainingInventorySetting =
    account?.storefront_configuration?.display_remaining_inventory;

  const fulfillmentSupportsSubscriptions = !order?.fulfillment || order?.subscriptions_available;

  // methods
  const debouncedSetOrder = useCallback(
    debounce(async (...args) => {
      try {
        await setOrder(...args);
      } catch (error) {
        setQuantity(cartProduct?.storefront_unit_quantity);
      }
    }, 400),
    [cartProduct?.storefront_unit_quantity, order?.fulfillment, order?.fulfillment?.id]
  ); // wait 400ms for new input before calling update

  const addPackage = async () => {
    try {
      const entry = !!cartProduct
        ? {
            id: cartProduct?.id,
            storefront_unit_quantity: cartProduct?.storefront_unit_quantity + 1,
          }
        : {
            package_price_list_entry: id,
            storefront_unit_quantity: 1,
            is_subscription: selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION,
          };
      await setOrder({ order_entries: [entry] });
    } catch (e) {
      console.error('Error setting product: ', e);
    }
  };

  // effects
  useEffect(() => {
    if (cartProduct) {
      setQuantity(cartProduct.storefront_unit_quantity);
    }
  }, [cartProduct]);

  const subscriptionOptionPrice = !!subscription_price ? subscription_price : package_price;
  const formattedSubscriptionPrice = formatMoney(subscriptionOptionPrice, currency);
  const selectedPackageOptionPrice =
    selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
      ? subscriptionOptionPrice
      : package_price;

  const useOnSaleField = useTreatment(PACKAGES_NEW_ON_SALE_TAG) == ON;
  const isOnSale = useOnSaleField ? on_sale_toggle : on_sale && displayDiscount;
  const originalPrice = isOnSale ? original_package_price.toFixed(2) : null;

  const strikeThroughValue = useOnSaleField ? strikethrough_display_value : originalPrice;
  const displayStrikethrough = useOnSaleField
    ? on_sale_toggle && strikethrough_display_value > 0
    : !!originalPrice;

  const formattedUnitPrice = formatMoney(
    is_by_weight
      ? (selectedPackageOptionPrice / average_pack_weight).toFixed(2)
      : selectedPackageOptionPrice,
    currency
  );

  const quantityAddedToCartLabel =
    selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION
      ? 'storefront/store/shop/product--n-packages-in-your-cart--subscription'
      : 'storefront/store/shop/product--n-packages-in-your-cart';

  const getSubscriptionCheckboxHelpText = () => {
    const fulfillmentDayOfWeek = getDayOfWeek(fulfillment?.fulfillment_date);
    const { day: dayOfMonth, weekdayMonthOccurrence } = useMonthlyDate(
      fulfillment?.fulfillment_date
    );

    if (subscriptionFrequencyOptions?.fulfillment_strategy_subscription_frequencies?.length > 0) {
      const option = subscriptionFrequencyOptions.fulfillment_strategy_subscription_frequencies[0];
      return t('storefront/product-details/subscription-options/subscription--label', {
        price: formattedSubscriptionPrice,
        fulfillmentMethod: fulfillment?.type_display,
        fulfillmentFrequencies: t(frequencyLabelTranslationMapping[option?.unit], {
          count: option?.count,
          unit: option?.unit,
          day: fulfillmentDayOfWeek,
          dayOfMonth: dayOfMonth?.string,
          weekdayMonthOccurrence: weekdayMonthOccurrence?.string,
        }),
      });
    } else {
      return <Money value={subscriptionOptionPrice} currency={currency} />;
    }
  };

  return (
    <Card
      childMarginBottom={false}
      classes={`store__product--details__package-card flex flex-col justify-between mb-6 ${
        displayDetails ? 'pb-6' : ''
      }`}>
      {displayDetails && (
        <div className="flex flex-col">
          <PackageName name={name} isOnSale={isOnSale} />

          <IsByWeight
            is_by_weight={is_by_weight}
            average_pack_weight={average_pack_weight}
            unitName={unitName}
            formattedUnitPrice={formattedUnitPrice}
          />

          <InventoryRemaining
            track_inventory={track_inventory}
            is_by_weight={is_by_weight}
            max_units_per_order={max_units_per_order}
            soldOut={soldOut}
            displayRemainingInventorySetting={displayRemainingInventorySetting}
            available={available}
          />

          <Price
            soldOut={soldOut}
            selectedPackageOptionPrice={selectedPackageOptionPrice}
            currency={currency}
            displayStrikethrough={displayStrikethrough}
            strikeThroughValue={strikeThroughValue}
          />
        </div>
      )}
      <div className={`flex flex-col space-x-2 items-center`}>
        <SubscriptionOptions
          id={id}
          oneTimeOnly={oneTimeOnly}
          allowOneTimePurchase={allowOneTimePurchase}
          setSelectedOrderOption={setSelectedOrderOption}
          selectedOrderOption={selectedOrderOption}
          package_price={package_price}
          currency={currency}
          getSubscriptionCheckboxHelpText={getSubscriptionCheckboxHelpText}
          allowSubscription={allowSubscription}
        />
        <div className="flex flex-row justify-between w-full mt-4">
          <CTAButton
            loading={loading}
            soldOut={soldOut}
            cartProduct={cartProduct}
            quantityAddedToCartLabel={quantityAddedToCartLabel}
            fulfillmentSupportsSubscriptions={fulfillmentSupportsSubscriptions}
            selectedOrderOption={selectedOrderOption}
            addPackage={addPackage}
            fulfillment={fulfillment}
          />
          <CartQuantityInput
            id={id}
            name={name}
            loading={loading}
            soldOut={soldOut}
            quantity={quantity}
            setQuantity={setQuantity}
            cartProduct={cartProduct}
            available={available}
            max_units_per_order={max_units_per_order}
            debouncedSetOrder={debouncedSetOrder}
          />
        </div>
      </div>
    </Card>
  );
};

DetailsPackageCard.propTypes = {
  id: PropTypes.number,
  product_price_list_entry: PropTypes.number,
  product_package: PropTypes.number,
  adjustment: PropTypes.bool,
  adjustment_type: PropTypes.number,
  adjustment_value: PropTypes.number,
  name: PropTypes.string,
  inventory_per_unit: PropTypes.number,
  unit_price: PropTypes.number,
  priority_order: PropTypes.number,
  package_price: PropTypes.number,
  price_per_unit: PropTypes.number,
  track_inventory: PropTypes.bool,
  packagepricelistentrysubscriptionsettings: PropTypes.object,
  subscription_price: PropTypes.number,
  displayDiscount: PropTypes.bool,
  displayDetails: PropTypes.bool,
  availableFulfillmentOptions: PropTypes.array,
};

DetailsPackageCard.defaultProps = {
  packagepricelistentrysubscriptionsettings: null,
  subscription_price: null,
  displayDiscount: false,
  displayDetails: true,
  availableFulfillmentOptions: null,
};

export default DetailsPackageCard;
