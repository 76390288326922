
import { useRecoilState } from 'recoil';
import { selectedPaymentStrategyAtom } from '../CheckoutState';
import { CUSTOM } from 'pages/storefront/Store/components/PaymentComponents/index';

const useCheckoutPaymentStrategy = (priceList, order, setOrder, customer, setSaveCard, setSelectedSavedCardId, fetchCustomerCards) => {
    const [selectedPaymentStrategy, setSelectedPaymentStrategy] = useRecoilState(selectedPaymentStrategyAtom);

    const _getSavedCardGatewayPaymentMethod = async (strategy) => {
        if (customer?.id && strategy?.type !== CUSTOM) {
            const savedCardGatewayPaymentMethodValue = await fetchCustomerCards(strategy?.gateway_short)
            return savedCardGatewayPaymentMethodValue;

        }
        return null;
    };

    const _getGatewayPaymentMethod = async (strategy) => {
        const savedCardGatewayPaymentMethod = await _getSavedCardGatewayPaymentMethod(strategy);
        const paymentStrategyGatewayPaymentMethod = strategy?.payment_strategy_gateway_payment_methods
            ?.length
            ? strategy?.payment_strategy_gateway_payment_methods?.[0]?.gateway_payment_method
            : null;
        return savedCardGatewayPaymentMethod || paymentStrategyGatewayPaymentMethod;
    };

    const selectPaymentStrategy = async (strategy) => {
        setSaveCard(null);
        setSelectedSavedCardId(null);
        setSelectedPaymentStrategy({ ...strategy, pay_later: isPayLaterForStrategy(strategy) });
        const gatewayPaymentMethod = await _getGatewayPaymentMethod(strategy);
        await setOrder({
            payment: {
                payment_strategy: strategy?.id,
                pay_later: false,
                gateway_payment_method: gatewayPaymentMethod,
                order_payment_strategy: {
                    id: order?.payment?.order_payment_strategy?.id || undefined,
                    fees: strategy?.fees?.map(({ id, ...rest }) => rest || []),
                },
            },
        });
    };

    const isPayLaterForStrategy = (strategy) => {
        return priceList?.price_list_payment_strategies.find(
            (pLStrategy) => pLStrategy.payment_strategy === strategy?.id
        )?.pay_later;
    };

    const isStoreCreditPayment =
        (selectedPaymentStrategy?.type === CUSTOM ||
            order?.payment?.payment_strategy_type === CUSTOM) &&
        order?.payment?.store_credit_amount > 0;


    return {
        selectedPaymentStrategy,
        setSelectedPaymentStrategy,
        selectPaymentStrategy,
        isStoreCreditPayment,
    };
};

export default useCheckoutPaymentStrategy;
