import { useState } from 'react';
import { formatErrors, formatErrorsToObject, formatProblemJSONErrors } from 'helpers/helpers';

const useApi = (api, keyValueErrors = false) => {
  const [errors, setErrors] = useState([]); // if validation errors array exists
  const [error, setError] = useState(undefined); // full problem json error
  const [loading, setLoading] = useState(false);
  const clearErrors = () => {
    setError(undefined);
    setErrors([]);
  };

  // todo: global error toast (with consistent error responses)
  // todo: abortController implementation

  const request = async (...args) => {
    try {
      clearErrors();
      setLoading(true);
      const response = await api(...args);
      return response?.data;
    } catch (err) {
      const { response } = err;
      setError(response?.data);
      console.error(response?.data);
      if (keyValueErrors) {
        setErrors(formatErrorsToObject(formatProblemJSONErrors(response?.data)));
      } else {
        setErrors(formatErrors(formatProblemJSONErrors(response?.data)));
      }
    } finally {
      setLoading(false);
    }
  };

  return { request, loading, errors, error, clearErrors };
};

export default useApi;
