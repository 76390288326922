import { ArrowDoubleRotatingFilledCircle, SpinnerSmall } from 'assets/svgIcons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FULFILLMENT_TYPES, SUBSCRIPTION_STATUSES } from 'helpers/constants';
import { formatErrors, formatProblemJSONErrors } from 'helpers/helpers';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { DAY_OF_WEEK_MONTH_DAY_LONG, formatDateTime, formatMoney } from 'helpers/localeHelpers';
import { cancelOrderCart } from 'api/storefront/orders';
import { patchOrder as patchOrderAPI } from 'api/storefront/orders';
import { toastAtom } from 'state/appState';
import { currencyAtom } from 'state/common/currency';
import useApi from 'hooks/common/api';
import { useOrder } from 'hooks/storefront';
import { SECONDARY } from 'components/styled/Alert';
import Alert from 'components/styled/Alert/Alert';
import Button from 'components/styled/Button/Button';
import Card from 'components/styled/Card/Card';

const { ACTIVE, SKIPPED } = SUBSCRIPTION_STATUSES;

export const ListItem = ({ text, value, valueClass }) => (
  <div className="flex item-center justify-between w-full mb-2">
    <span className="text-Grey500">{text}</span>
    <span className={!!valueClass ? valueClass : 'text-Grey700'}>{value}</span>
  </div>
);

export default ({ order, fetchOrders }) => {
  const { t } = useTranslation();
  const { switchOrder } = useOrder();
  const { request: cancelOrderCartRequest, loading: cancelOrderCartLoading } =
    useApi(cancelOrderCart);
  const {
    fulfillment,
    price_list,
    subtotal,
    order_entries,
    id,
    is_subscription,
    subscription_association,
  } = order;
  const setToasts = useSetRecoilState(toastAtom);
  const { price_list_order_subscription_settings } = price_list;
  const location = fulfillment?.fulfillment_address;
  const isDelivery = fulfillment?.fulfillment_strategy_type !== FULFILLMENT_TYPES.PICKUP;
  const currency = useRecoilValue(currencyAtom);
  const [patchOrderLoading, setPatchOrderLoading] = useState(false);

  const title = !!fulfillment?.fulfillment_date
    ? t('storefront/store/shop/cart/header--fulfillment-date', {
        type: fulfillment?.type_display,
        date: formatDateTime(fulfillment?.fulfillment_date, DAY_OF_WEEK_MONTH_DAY_LONG),
      })
    : t('storefront/store/shop/cart/header--fulfillment', {
        type: fulfillment?.type_display,
      });
  let text = isDelivery
    ? t('storefront/store/shop/cart/header--deliver', { location })
    : !!fulfillment?.pickup_start_time && !!fulfillment?.pickup_end_time
    ? t('storefront/store/shop/cart/header--pickup-timeslot', {
        location,
        timeslot: `${fulfillment?.pickup_start_time} - ${fulfillment?.pickup_end_time}`,
      })
    : t('storefront/store/shop/cart/header--pickup', { location });

  const totalUnitQuantity = order_entries?.reduce(
    (a, b) =>
      a + (b?.storefront_unit_quantity > 0 ? b?.storefront_unit_quantity : b?.inventory_quantity),
    0
  );

  const subscriptionPlanNumber = order?.subscription_plan?.plan_number;
  const isActiveSubscription = is_subscription && subscription_association?.status === ACTIVE;
  const isSkippedSubscription = is_subscription && subscription_association?.status === SKIPPED;
  const subscriptionOpenOrderAt = subscription_association?.opens_at;
  const formattedSubscriptionOpenOrderAtDate =
    formatDateTime(subscriptionOpenOrderAt, DAY_OF_WEEK_MONTH_DAY_LONG) || '-';
  const formattedSubscriptionOpenOrderAtTime =
    formatDateTime(subscriptionOpenOrderAt, 'h:mma') || '-';
  const formattedFulfillmentDate =
    formatDateTime(fulfillment?.fulfillment_date, DAY_OF_WEEK_MONTH_DAY_LONG) || '-';
  const formattedFulfillmentTime = formatDateTime(fulfillment?.fulfillment_date, 'h:mma') || '-';

  const ActionButtons = () => {
    if (!is_subscription) {
      return (
        <>
          <Button classes="button--white flex-1" onClick={() => handleCancelOrder()}>
            {cancelOrderCartLoading ? (
              <SpinnerSmall classes="self-center w-full" />
            ) : (
              t('storefront/cart/orders/delete')
            )}
          </Button>
          <Button classes="flex-1" onClick={() => switchOrder(id)}>
            {t('storefront/cart/orders/edit-cart')}
          </Button>
        </>
      );
    } else {
      return (
        <>
          {price_list_order_subscription_settings?.customer_can_skip_order && (
            <Button
              classes="button--white flex-1 flex items-center justify-center"
              disabled={patchOrderLoading}
              onClick={() => {
                if (isActiveSubscription) {
                  skipOrUnskipOrder(
                    {
                      subscription_association: {
                        status: SKIPPED,
                      },
                    },
                    t('storefront/cart/orders/skipped--success', {
                      id,
                    })
                  );
                } else {
                  skipOrUnskipOrder(
                    {
                      subscription_association: {
                        status: ACTIVE,
                      },
                    },
                    t('storefront/cart/orders/unskipped--success', {
                      id,
                    })
                  );
                }
              }}>
              {patchOrderLoading ? (
                <SpinnerSmall />
              ) : isActiveSubscription ? (
                t('storefront/cart/orders/skip')
              ) : (
                t('storefront/cart/orders/unskip')
              )}
            </Button>
          )}
          {!isSkippedSubscription && (
            <Button classes="flex-1" onClick={() => switchOrder(id)}>
              {t('storefront/cart/orders/modify-order')}
            </Button>
          )}
        </>
      );
    }
  };

  const skipOrUnskipOrder = async (data, message) => {
    setPatchOrderLoading(true);
    const toastId = generateRandomNumber();
    try {
      await patchOrderAPI(id, data);
      await fetchOrders();
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 2000,
          message,
          type: 'success',
        },
      ]);
    } catch (err) {
      console.error(err);
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 2000,
          message: t('storefront/cart/orders/skipped--failure', {
            id,
            error: formatErrors(formatProblemJSONErrors(err?.response?.data)),
          }),
          type: 'error',
        },
      ]);
    } finally {
      setPatchOrderLoading(false);
    }
  };

  const handleCancelOrder = async () => {
    await cancelOrderCartRequest(id);
    await fetchOrders();
  };

  return (
    <Card key={id} classes="mb-4 p-4 last-of-type:mb-0" childMarginBottom={false}>
      <>
        {isActiveSubscription && (
          <h3 className="text-Green600 font-bold text-sm flex items-center mb-1">
            <ArrowDoubleRotatingFilledCircle classes="mr-1" />
            {t('storefront/store/shop/cart/header--subscription-order')}
          </h3>
        )}
        <h2 className="text-Grey700">{title}</h2>
        <p className="text-Grey500 mb-4">{text}</p>
        {isSkippedSubscription && (
          <Alert
            classes="mb-4 !text-Beige900"
            type={SECONDARY}
            description={t('storefront/store/shop/cart/skip--alert')}
          />
        )}
        {isActiveSubscription ? (
          <ListItem
            text={t('storefront/cart/orders/edit-until')}
            value={t('storefront/cart/orders/edit-until--date-time', {
              date: formattedSubscriptionOpenOrderAtDate,
              time: formattedSubscriptionOpenOrderAtTime,
            })}
          />
        ) : (
          <ListItem
            text={t('storefront/cart/orders/expires-on')}
            value={t('storefront/cart/orders/expires-on--date-time', {
              date: formattedFulfillmentDate,
              time: formattedFulfillmentTime,
            })}
          />
        )}
        <ListItem text={t('storefront/cart/orders/price-list')} value={price_list?.name} />
        <ListItem text={t('storefront/cart/orders/items')} value={totalUnitQuantity} />
        <ListItem
          text={t('storefront/cart/orders/subtotal')}
          value={formatMoney(subtotal, currency)}
        />
        {isActiveSubscription && (
          <ListItem
            text={t('global/subscriptions/subscription-plan')}
            value={subscriptionPlanNumber}
            valueClass="text-green-600"
          />
        )}
        <div className="flex items-center justify-between gap-3 w-full mt-2">{ActionButtons()}</div>
      </>
    </Card>
  );
};
