import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LabelOptionalRequired = ({ label, required }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <>{label}</>
      {required ? (
        <span className="text-Green600">*</span>
      ) : (
        <span className="ml-1 text-Grey400">{t('(Optional)')}</span>
      )}
    </div>
  );
};

LabelOptionalRequired.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

LabelOptionalRequired.defaultProps = {
  label: '',
  required: false,
};

export default LabelOptionalRequired;
