import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputSelect from 'components/styled/InputSelect';
import { useOrder } from 'hooks/storefront';
import { useParams } from 'react-router-dom';
import { PICKUP } from '.';
import { DAY_OF_WEEK_MONTH_DAY_LONG, formatDate, formatTime } from 'helpers/localeHelpers';

const FulfillmentAvailability = ({
  availability,
  type,
  orderFulfillment,
  setOrderFulfillment,
  setErrors,
}) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const { loading } = useOrder(priceListSlug);

  // strings
  const deliveryPlanStrings = {
    date: t('storefront/store/checkout--fulfillment-date-delivery-date-title'),
    time: t('storefront/store/checkout--fulfillment-date-delivery-time-title'),
  };

  const pickupLocationStrings = {
    date: t('storefront/store/checkout--fulfillment-date-pickup-date-title'),
    time: t('storefront/store/checkout--fulfillment-date-pickup-time-title'),
  };

  const strings = type === PICKUP ? { ...pickupLocationStrings } : { ...deliveryPlanStrings };
  const selectTimeslot =
    type === PICKUP && availability?.time_slots_enabled && !!availability?.time_slots?.length;
  const defaultDateValue = !!orderFulfillment?.fulfillment_date
    ? {
        label: formatDate(orderFulfillment?.fulfillment_date, DAY_OF_WEEK_MONTH_DAY_LONG),
        value: orderFulfillment?.fulfillment_date,
      }
    : {};

  const dateOptions = availability?.available_dates
    ?.map((ad) => {
      return {
        label: formatDate(ad.available_date, DAY_OF_WEEK_MONTH_DAY_LONG),
        value: ad.available_date,
      };
    })
    .sort((a, b) => {
      return a.value - b.value;
    });

  const timeSlotOptions = availability?.time_slots.map(({ start, end, id }) => ({
    label: `${formatTime(start)} - ${formatTime(end)}`,
    value: id,
  }));

  // for now select first one, until we can find the matching time_slot
  const defaultTimeValue = timeSlotOptions[0];

  return (
    <div className="grid grid-cols-2 gap-4">
      <InputSelect
        label={strings.date}
        defaultValue={defaultDateValue}
        value={
          !!orderFulfillment?.fulfillment_date
            ? {
                label: formatDate(orderFulfillment?.fulfillment_date, DAY_OF_WEEK_MONTH_DAY_LONG),
                value: orderFulfillment?.fulfillment_date,
              }
            : {}
        }
        options={dateOptions}
        classes={`col-span-2 ${selectTimeslot && 'sm:col-span-1'}`}
        handleChange={({ value: fulfillment_date }) => {
          setOrderFulfillment({
            ...orderFulfillment,
            fulfillment_date,
          });
          setErrors((prevState) => ({ ...prevState, fulfillment_date: '' }));
        }}
        isLoading={loading}
        isDisabled={loading}
      />
      {selectTimeslot && (
        <InputSelect
          label={strings.time}
          defaultValue={defaultTimeValue}
          classes={`col-span-2 ${selectTimeslot && 'sm:col-span-1'}`}
          options={timeSlotOptions}
          value={
            orderFulfillment?.time_slot
              ? timeSlotOptions.find((slot) => slot.value === orderFulfillment?.time_slot)
              : timeSlotOptions[0]
          }
          handleChange={({ value: time_slot }) => {
            setOrderFulfillment({
              ...orderFulfillment,
              time_slot,
            });
          }}
          isLoading={loading}
          isDisabled={loading}
        />
      )}
    </div>
  );
};

FulfillmentAvailability.propTypes = {
  availability: PropTypes.shape({
    type: PropTypes.string,
    timezone: PropTypes.string,
    min_lead_time_of_day_cutoff: PropTypes.string,
    min_lead_days: PropTypes.number,
    max_lead_days: PropTypes.number,
    instructions: PropTypes.string,
    repeat_frequency_unit: PropTypes.string,
    repeat_frequency_count: PropTypes.number,
    repeat_start_date: PropTypes.string,
    repeat_on_monday: PropTypes.bool,
    repeat_on_tuesday: PropTypes.bool,
    repeat_on_wednesday: PropTypes.bool,
    repeat_on_thursday: PropTypes.bool,
    repeat_on_friday: PropTypes.bool,
    repeat_on_saturday: PropTypes.bool,
    repeat_on_sunday: PropTypes.bool,
    custom_dates: PropTypes.array,
    available_dates: PropTypes.array,
    time_slots: PropTypes.array,
    time_slots_enabled: PropTypes.bool,
  }),
  type: PropTypes.string.isRequired,
  orderFulfillment: PropTypes.object,
  setOrderFulfillment: PropTypes.func,
  setErrors: PropTypes.func,
};

FulfillmentAvailability.defaultProps = {
  setErrors: () => {},
};

export default FulfillmentAvailability;
