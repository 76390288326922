import PropTypes from 'prop-types';
import theme from '../../styles/theme';
import { DotsVertical } from '.';

const Svg = ({ stroke, classes }) => (
  <DotsVertical classes={`transform rotate-90 ${classes}`} stroke={stroke} />
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
