export { default } from './Checkout';

const CONFIRMATION_CUSTOMER = 'CONFIRMATION_CUSTOMER';
const CONFIRMATION_FULFILLMENT = 'CONFIRMATION_FULFILLMENT';
const CONFIRMATION_BILLING = 'CONFIRMATION_BILLING';
const CONFIRMATION_PAYMENT = 'CONFIRMATION_PAYMENT';
const CONFIRMATION_ORDER_NOTE = 'CONFIRMATION_ORDER_NOTE';
const CONFIRMATION_CUSTOMER_NOTE = 'CONFIRMATION_CUSTOMER_NOTE';
const CONFIRMATION_OPENED_AT = 'CONFIRMATION_OPENED_AT';
const FULFILLMENT_INSTRUCTIONS = 'fulfillment_instructions';
const PICKUP = 'pickup';
const DELIVERY = 'Delivery';
const CHECKOUT_COMPLETE_PATH = 'checkout/complete';

export {
    CONFIRMATION_CUSTOMER,
    CONFIRMATION_FULFILLMENT,
    CONFIRMATION_BILLING,
    CONFIRMATION_PAYMENT,
    CONFIRMATION_ORDER_NOTE,
    CONFIRMATION_CUSTOMER_NOTE,
    CONFIRMATION_OPENED_AT,
    FULFILLMENT_INSTRUCTIONS,
    PICKUP,
    DELIVERY,
    CHECKOUT_COMPLETE_PATH,
};

