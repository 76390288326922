import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useRouteMatch } from 'react-router-dom';
import { screenSizeAtom } from 'state/appState';
import { VIEWPORT_SIZES } from 'helpers/constants';
import Account from './Account';
import Links from './Links';
import Profile from './Profile';
import Checkout from './Checkout';

const Header = ({ onCartClick, cartActive, previousSlug, setIsHamburgerVisible }) => {
  const isCheckout = useRouteMatch('/:priceListSlug/checkout');
  const { width } = useRecoilValue(screenSizeAtom);
  return (
    <header className={`store__header ${isCheckout ? 'checkout' : ''}`}>
      {isCheckout && <Checkout />}
      {!isCheckout && <Account setIsHamburgerVisible={setIsHamburgerVisible} />}
      {VIEWPORT_SIZES.lg < width && !isCheckout && <Links previousSlug={previousSlug} />}
      <div className="store__header_actions flex flex-1 justify-end">
        {VIEWPORT_SIZES.lg < width && !isCheckout && <Profile />}
      </div>
    </header>
  );
};

Header.propTypes = {
  onCartClick: PropTypes.func,
  cartActive: PropTypes.bool,
  setIsHamburgerVisible: PropTypes.func,
};

Header.defaultProps = {
  onCartClick: () => {},
  cartActive: false,
  setIsHamburgerVisible: () => {},
};

export default Header;
