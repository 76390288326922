import { useRecoilValue } from 'recoil';
import theme from 'styles/theme';
import { accountAtom } from 'state/storefront/accountState';
import AccountInfo from './AccountInfo';
import Footnotes from './Footnotes';
import LinksInfo from './LinksInfo';
import SocialInfo from './SocialInfo';
import StoreInfo from './StoreInfo';

const { transparent } = theme.colors;

const Footer = () => {
  const account = useRecoilValue(accountAtom);
  const backgroundColor =
    account?.storefront_configuration?.custom_background_page_color ?? transparent;

  return (
    <footer style={{ backgroundColor }}>
      <div className="store__footer--container">
        <div className="flex flex-col md:flex-row justify-between text-center items-center md:items-start md:text-left my-6 md:my-10 gap-4">
          <StoreInfo />
          <LinksInfo />
          <SocialInfo />
          <AccountInfo />
        </div>
        <hr />
        <Footnotes />
      </div>
    </footer>
  );
};

export default Footer;
