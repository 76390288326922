import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useOrder } from 'hooks/storefront';
import Card from 'components/styled/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import InputAddress from 'components/InputAddress';
import InputText from 'components/styled/InputText';
import { SHIPPING_ADDRESS, CUSTOM_ADDRESS } from 'helpers/constants';
import { customerAtom, accountAtom } from 'state/storefront/accountState';
import AddressForm from 'components/AddressForm';
import { debounce } from 'helpers/helpers';

const DeliveryBillingAddress = ({ billing, setBilling }) => {
  const { t } = useTranslation();
  const { priceListSlug } = useParams();
  const customer = useRecoilValue(customerAtom);
  const account = useRecoilValue(accountAtom);
  // states
  const [selectedBillingAddressOption, setSelectedBillingAddressOption] =
    useState(SHIPPING_ADDRESS);
  const { order } = useOrder(priceListSlug);

  const debouncedSetBilling = useCallback(
    debounce(async (...args) => {
      try {
        await setBilling(...args);
      } catch (error) {}
    }, 1000),
    [billing?.billing_address]
  );

  return (
    <Card
      title={t('storefront/store/checkout--payment-billing-address-title')}
      text={t('storefront/store/checkout--payment-billing-address--desc')}
      classes="mb-4 rounded-none sm:rounded-xl border-0 p-0"
      id="storefront/store/checkout--payment-billing-address-card">
      <div className="border rounded-lg mb-0">
        <InputCheckboxRadio
          id="shipping-address"
          name="shipping-address"
          label={t('storefront/store/checkout--payment-billing-address-shipping')}
          checked={selectedBillingAddressOption === SHIPPING_ADDRESS}
          onChange={({ target }) => {
            setSelectedBillingAddressOption(target.name);
            setBilling(order?.fulfillment?.shipping_address);
          }}
          type="radio"
          labelClasses="px-4 py-4"
        />
        <InputCheckboxRadio
          id="custom-address"
          name="custom-address"
          label={t('storefront/store/checkout--payment-billing-address-custom')}
          checked={selectedBillingAddressOption === CUSTOM_ADDRESS}
          onChange={({ target }) => {
            setSelectedBillingAddressOption(target.name);
            if (customer?.default_billing_address) {
              setBilling({
                ...customer?.default_billing_address,
                first_name: customer?.first_name,
                last_name: customer?.last_name,
              });
            }
          }}
          type="radio"
          labelClasses="px-4 py-4 border-t"
        />
      </div>
      {selectedBillingAddressOption === CUSTOM_ADDRESS && (
        <>
          <div className="flex mt-6 mb-0">
            <InputText
              labelText={t('storefront/store/checkout--payment-billing-first-name-label')}
              value={billing?.billing_address?.first_name}
              placeholder={t('global/John')}
              onChange={(e) =>
                setBilling({ ...billing.billing_address, first_name: e.target.value })
              }
              classes="mr-8"
            />
            <InputText
              labelText={t('storefront/store/checkout--payment-billing-last-name-label')}
              value={billing?.billing_address?.last_name}
              placeholder={t('global/Doe')}
              onChange={(e) =>
                setBilling({ ...billing.billing_address, last_name: e.target.value })
              }
            />
          </div>
          <div className="pt-6">
            <AddressForm
              handleChange={(address) =>
                debouncedSetBilling({
                  ...address,
                  first_name: billing?.billing_address?.first_name,
                  last_name: billing?.billing_address?.last_name,
                })
              }
              address={billing?.billing_address || {}}
              defaultCountryCode={account?.business_address?.country_code}
            />
          </div>
        </>
      )}
    </Card>
  );
};

DeliveryBillingAddress.propTypes = {
  billing: PropTypes.object,
  setBilling: PropTypes.func,
};

export default DeliveryBillingAddress;
