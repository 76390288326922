import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { SplitFactory } from '@splitsoftware/splitio-react';

import { createBrowserHistory } from 'history';

import './i18n';
import App from './App';
import './styles/tailwind.css';
import './monitoring';

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <RecoilRoot>
        <Router history={history}>
          <SplitFactory
            config={{
              core: {
                authorizationKey: process.env.REACT_APP_SPLIT_IO_KEY,
                key: 'anonymous',
              },
                impressionListener: {
                    logImpression(impressionData) {
                        datadogRum
                            .addFeatureFlagEvaluation(
                                impressionData.impression.feature,
                                impressionData.impression.treatment
                            );
                    }
                }
            }}>
            <App />
          </SplitFactory>
        </Router>
      </RecoilRoot>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
