import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MaxUnitsPerOrderReached from 'components/MaxUnitsPerOrderReached';

const InventoryCounter = ({
  displayRemainingInventory,
  trackInventory,
  soldOut,
  quantityAvailable,
  quantityInCart,
  maxUnitsPerOrder,
}) => {
  const { t } = useTranslation();

  if (soldOut || !trackInventory || !displayRemainingInventory) return null;

  if (maxUnitsPerOrder > 0 && quantityInCart >= maxUnitsPerOrder) {
    return <MaxUnitsPerOrderReached maxUnitsPerOrder={maxUnitsPerOrder} />;
  }

  return (
    <span className="text-center text-Grey500">
      {quantityAvailable &&
        t('storefront/store/shop/product--in-stock-counted', {
          count: quantityAvailable,
        })}
    </span>
  );
};

InventoryCounter.propTypes = {
  displayRemainingInventory: PropTypes.bool,
  trackInventory: PropTypes.bool,
  soldOut: PropTypes.bool,
  quantityAvailable: PropTypes.number,
  quantityInCart: PropTypes.number,
  maxUnitsPerOrder: PropTypes.number,
};

InventoryCounter.defaultProps = {
  displayRemainingInventory: false,
  trackInventory: false,
  soldOut: false,
  quantityAvailable: 0,
  quantityInCart: 0,
  maxUnitsPerOrder: null,
};

export default InventoryCounter;
