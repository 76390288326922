import { useTranslation } from 'react-i18next';
import { SUBSCRIPTION_ORDER_OPTIONS } from 'helpers/constants';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import Money from 'components/styled/Money';

const SubscriptionOptions = ({
  id,
  oneTimeOnly,
  allowOneTimePurchase,
  setSelectedOrderOption,
  selectedOrderOption,
  package_price,
  currency,
  getSubscriptionCheckboxHelpText,
  allowSubscription,
}) => {
  const { t } = useTranslation();

  return (
    !oneTimeOnly && (
      <div className="flex flex-col mt-4 items-center w-full space-y-3">
        {allowOneTimePurchase && !oneTimeOnly && (
          <InputCheckboxRadio
            id={`${id}-one-time`}
            name={`${id}-one-time`}
            checked={selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME}
            type="radio"
            label={t('storefront/product-details/subscription-options/one-time-purchase')}
            help={<Money value={package_price} currency={currency} />}
            onChange={() => {
              setSelectedOrderOption(SUBSCRIPTION_ORDER_OPTIONS.ONE_TIME);
            }}
          />
        )}
        {allowSubscription && (
          <InputCheckboxRadio
            id={`${id}-subscription`}
            name={`${id}-subscription`}
            checked={selectedOrderOption === SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION}
            type="radio"
            label={t('storefront/product-details/subscription-options/subscription')}
            help={getSubscriptionCheckboxHelpText()}
            onChange={() => {
              setSelectedOrderOption(SUBSCRIPTION_ORDER_OPTIONS.SUBSCRIPTION);
            }}
          />
        )}
      </div>
    )
  );
};

export default SubscriptionOptions;
