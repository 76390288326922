import { api } from '.';

export const readLocations = (params) => api.get('/locations/', { params });

export const readAutocomplete = (params) => api.get('/locations/autocomplete/', { params });

export const readGeocode = (params) => api.get('/locations/geocode/', { params });

export const readPlaceDetails = (params) => api.get('/locations/place-details/', { params });

export const readCountries = (params) => api.get('/locations/countries/', { params });
