import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFilters from 'hooks/storefront/filters';
import useProducts from 'hooks/storefront/products';
import ObserverRef from 'components/ObserverRef';
import Loading from '../../Loading';

const Footer = ({ loading }) => {
  const { t } = useTranslation();
  const { fetchProducts, products } = useProducts();
  const { count, results } = products;
  const numberOfProducts = results?.length;
  const { countFilters } = useFilters();

  const fetchProductsAndFilters = async () => {
    await fetchProducts();
    await countFilters();
  };

  return (
    <>
      {loading && <Loading classes="mt-8" copy={t('global/Loading')} />}
      <div className="flex flex-col items-center w-full my-8">
        {count > 50 && (
          <div className="w-full relative flex items-center justify-center">
            <hr className="absolute top-1/2 bottom-1/2 left-0 right-0 h-px bg-Grey200" />
            <ObserverRef
              onIntersect={fetchProductsAndFilters}
              disabled={count === numberOfProducts}
            />
          </div>
        )}
        <span className="mt-3">
          {t('global/number-of-count-name', {
            number: numberOfProducts,
            count,
            name: t('products'),
          })}
        </span>
      </div>
    </>
  );
};

Footer.propTypes = {
  fetchProducts: PropTypes.func,
  count: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
};

export default Footer;
