import PropTypes from 'prop-types';
import React, { Children } from 'react';

const SidebarDivider = ({ numberOfCircles }) => {
  return (
    <div className="circles">
      {Children.toArray(
        [...Array(numberOfCircles)].map((i) => <span key={i} className="circle" />)
      )}
    </div>
  );
};

SidebarDivider.propTypes = {
  numberOfCircles: PropTypes.number,
};

SidebarDivider.defaultProps = {
  numberOfCircles: 37,
};

export default SidebarDivider;
