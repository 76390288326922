import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { usePortal } from 'hooks/common/portal';

const Portal = ({ target, children }) => {
  const portalTarget = usePortal(target);
  return createPortal(children, portalTarget);
};

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  target: PropTypes.string,
};

Portal.defaultProps = {
  children: undefined,
  target: '',
};

export default Portal;
