import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { SpinnerSmall } from 'assets/svgIcons';
import { payments } from '@square/web-sdk';
import theme from 'styles/theme';
import { TOKENIZED_CARD_RESPONSE } from '..';
import './SquareInputCreditCard.css';

const OK = 'OK';

const { Grey300, Grey400, Grey500, Grey700, Red300, Red700, White } = theme.colors;

const SquareInputCreditCard = forwardRef(({ locationId }, ref) => {
  const SQUARE_APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;

  const cardEl = useRef(null);
  // https://github.com/acrazing/square-web-sdk/blob/ebf540d75f8d53d7ea1d6d5d57b3366061ce65b8/square.d.ts#L817
  const cardOptions = {
    style: {
      '.input-container': {
        borderColor: Grey300,
        borderRadius: '4px', //rem not supported
      },
      '.input-container.is-error': {
        borderColor: Red300,
      },
      '.input-container.is-focus': {
        borderColor: Grey700,
      },
      '.message-icon': {
        color: Grey500,
      },
      '.message-icon.is-error': {
        color: Red700,
      },
      '.message-text': {
        color: Grey500,
      },
      '.message-text.is-error': {
        color: Red700,
      },
      input: {
        fontSize: '14px',
        fontFamily: 'sans-serif',
        backgroundColor: White,
      },
      'input.is-error': {
        color: Red700,
      },
      'input::placeholder': {
        color: Grey400,
      },
    },
  };

  // states
  const [cardInstance, setCardInstance] = useState();

  // methods
  useImperativeHandle(ref, () => ({
    async tokenize() {
      const resp = await cardInstance.tokenize();
      if (resp?.status === OK) {
        return {
          ...TOKENIZED_CARD_RESPONSE,
          token: resp?.token,
          card: {
            brand: resp?.details?.card?.brand,
            last4: resp?.details?.card?.last4,
          },
        };
      } else {
        console.log('[error]', resp);
        return { ...TOKENIZED_CARD_RESPONSE, error: resp?.errors };
      }
    },
  }));

  // effects
  useEffect(async () => {
    const pay = await payments(SQUARE_APPLICATION_ID, locationId);
    const card = await pay?.card(cardOptions);
    if (!cardEl.current) return; // if ref isn't mounted, skip this.
    cardEl.current.innerHTML = ''; // clean any re-renders
    await card.attach(`#${cardEl.current.id}`);
    setCardInstance(card);
    return () => {
      card.destroy(); // detach from dom on dismount
    };
  }, []);

  return (
    <div className={`flex ${!cardInstance ? 'h-12 border rounded' : ''}`}>
      <div id="square-card-input" className="flex-grow" ref={cardEl} />
      {!cardInstance && (
        <div className="self-center pr-3">
          <SpinnerSmall />
        </div>
      )}
    </div>
  );
});

SquareInputCreditCard.propTypes = {
  locationId: PropTypes.string.isRequired,
};

export default SquareInputCreditCard;
