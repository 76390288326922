import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ stroke, classes }) => (
  <svg
    className={classes}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 4.99992C9.53992 4.99992 9.16683 4.62682 9.16683 4.16659C9.16683 3.70635 9.53992 3.33325 10.0002 3.33325C10.4604 3.33325 10.8335 3.70635 10.8335 4.16659C10.8335 4.62682 10.4604 4.99992 10.0002 4.99992Z"
      fill={stroke}
    />
    <path
      d="M10.0002 10.8333C9.53992 10.8333 9.16683 10.4602 9.16683 9.99992C9.16683 9.53968 9.53992 9.16658 10.0002 9.16658C10.4604 9.16658 10.8335 9.53968 10.8335 9.99992C10.8335 10.4602 10.4604 10.8333 10.0002 10.8333Z"
      fill={stroke}
    />
    <path
      d="M10.0002 16.6666C9.53992 16.6666 9.16683 16.2935 9.16683 15.8333C9.16683 15.373 9.53992 14.9999 10.0002 14.9999C10.4604 14.9999 10.8335 15.373 10.8335 15.8333C10.8335 16.2935 10.4604 16.6666 10.0002 16.
      6666Z"
      fill={stroke}
    />
    <path
      d="M10.0002 4.16659L10.0002 4.17492M10.0002 9.99992L10.0002 10.0083M10.0002 15.8333L10.0002 15.8416M10.0002 4.99992C9.53992 4.99992 9.16683 4.62682 9.16683 4.16659C9.16683 3.70635 9.53992 3.33325 10.0002 3.33325C10.4604 3.33325 10.8335 3.70635 10.8335 4.16659C10.8335 4.62682 10.4604 4.99992 10.0002 4.99992ZM10.0002 10.8333C9.53992 10.8333 9.16683 10.4602 9.16683 9.99992C9.16683 9.53968 9.53992 9.16659 10.0002 9.16659C10.4604 9.16659 10.8335 9.53968 10.8335 9.99992C10.8335 10.4602 10.4604 10.8333 10.0002 10.8333ZM10.0002 16.6666C9.53992 16.6666 9.16683 16.2935 9.16683 15.8333C9.16683 15.373 9.53992 14.9999 10.0002 14.9999C10.4604 14.9999 10.8335 15.373 10.8335 15.8333C10.8335 16.2935 10.4604 16.6666 10.0002 16.6666Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Svg.propTypes = {
  stroke: PropTypes.string,
  classes: PropTypes.string,
};

Svg.defaultProps = {
  stroke: theme.colors.Grey400,
  classes: '',
};

export default Svg;
