import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/styled/Card';
import InputCheckboxRadio from 'components/styled/InputCheckboxRadio';
import { useTranslation } from 'react-i18next';

const SaveCard = ({ saveCard, setSaveCard }) => {
  const { t } = useTranslation();
  return (
    <Card
      classes="mb-4 mt-4 rounded-none sm:rounded-xl border-0 p-0"
      title={t('storefront/store/checkout--payment-save-card-title')}
      id="storefront/store/checkout--payment-save-card">
      <InputCheckboxRadio
        id="save-card"
        name="save-card"
        checked={saveCard}
        onChange={() => setSaveCard(!saveCard)}
        label={t('storefront/store/checkout--payment-save-card-label')}
      />
    </Card>
  );
};

SaveCard.propTypes = {
  saveCard: PropTypes.bool,
  setSaveCard: PropTypes.func,
};

export default SaveCard;
