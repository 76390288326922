
import Button from 'components/styled/Button/Button';
import { useTranslation } from 'react-i18next';


const FulfillmentSummaryAddress = ({ fulfillmentType, address }) => {
    const { t } = useTranslation();

    const openMap = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank');
    };

    return (
        <div className="">
            <h4 className="text-Grey500">{t(fulfillmentType)} Address</h4>
            <div className="flex items-center space-x-2">
                <h4 className="text-Grey800">{address}</h4>
                <Button classes="button button--none text-Green600" onClick={() => { openMap() }}>{t('Map')}</Button>
            </div>
        </div >
    );
};

export default FulfillmentSummaryAddress;
