import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ACTIVE, BLANK_TEXT_EDITOR, SUBSCRIPTION_STATUSES } from 'helpers/constants';
import { FULFILLMENT_TYPES } from 'helpers/constants';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { formatDateTime, formatTime } from 'helpers/localeHelpers';
import { frequencyLabelTranslationMapping, getDayOfWeek } from 'helpers/subscriptionHelpers';
import { cancelOrderSubscription } from 'api/storefront/order-subscriptions';
import { patchOrder } from 'api/storefront/orders';
import { toastAtom } from 'state/appState';
import { accountAtom } from 'state/storefront/accountState';
import useApi from 'hooks/common/api';
import useMonthlyDate from 'hooks/common/monthlyDate';
import { useOrder } from 'hooks/storefront';
import Button from 'components/styled/Button';
import Card from 'components/styled/Card';
import { CartItem, CartTotals } from '../../Cart';
import CancelSubscriptionModal from '../../Modals/CancelSubscriptionModal';
import UpcomingSubscriptionCard from './UpcomingSubscriptionCard';

const { PICKUP } = FULFILLMENT_TYPES;

const SubscriptionHistoryCard = ({ orderSubscription, fetchOrderSubscriptions }) => {
  const { t } = useTranslation();
  const setToasts = useSetRecoilState(toastAtom);
  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false);
  const { contact_email } = useRecoilValue(accountAtom);
  const {
    request: patchOrderRequest,
    loading: patchOrderLoading,
    errors: patchOrderErrors,
  } = useApi(patchOrder);
  const { request: cancelOrderSubscriptionRequest, loading: cancelOrderSubscriptionLoading } =
    useApi(cancelOrderSubscription);
  const { order, switchOrder, clearCart } = useOrder();

  const {
    id: orderSubscriptionId,
    plan_number,
    frequency_value,
    frequency_unit,
    order_template,
    upcoming_draft_orders,
    status,
    fulfillment_start_date,
  } = orderSubscription;

  const subscriptionStatus = status.toUpperCase();
  const { order_entries, fulfillment } = order_template;

  const isPickup = fulfillment?.fulfillment_strategy_type === PICKUP;
  const fulfillmentAddress =
    fulfillment?.fulfillment_strategy_type === PICKUP
      ? fulfillment?.fulfillment_address || fulfillment?.fulfillment_strategy_name
      : t('storefront/my-account/subscription-history/shipping-address', {
          shippingAddress: fulfillment?.shipping_address?.formatted_address,
          firstName: fulfillment?.shipping_address?.first_name,
          lastName: fulfillment?.shipping_address?.last_name,
        });
  const fulfillmentDayOfWeek = getDayOfWeek(fulfillment_start_date);
  const { day: dayOfMonth, weekdayMonthOccurrence } = useMonthlyDate(fulfillment_start_date);

  const estimated = isPickup
    ? t('storefront/my-account/subscription-history/estimated-pickup')
    : t('storefront/my-account/subscription-history/estimated-delivery');

  const isSubscriptionActive = status === ACTIVE;

  const nextOrderId = upcoming_draft_orders[0]?.order_id;
  const nextOrderStatus = upcoming_draft_orders[0]?.status;

  const handleSkip = async () => {
    const handleSkipTranslation =
      nextOrderStatus === ACTIVE
        ? 'storefront/cart/orders/skipped--success'
        : 'storefront/cart/orders/unskipped--success';

    if (nextOrderStatus === ACTIVE) {
      await _skipOrder();
    } else {
      await _unskipOrder();
    }

    _toastMessage(
      t(handleSkipTranslation, {
        handleSkipTranslation,
        id: nextOrderId,
      })
    );

    await fetchOrderSubscriptions(true);
  };

  const _skipOrder = async () => {
    await _patchOrderSubscriptionStatus(SUBSCRIPTION_STATUSES.SKIPPED);
    if (order?.id === nextOrderId) clearCart();
  };
  const _unskipOrder = () => _patchOrderSubscriptionStatus(SUBSCRIPTION_STATUSES.ACTIVE);

  const handleCancel = async () => {
    await cancelOrderSubscriptionRequest(orderSubscriptionId);
    setCancelSubscriptionModalOpen(false);
    await fetchOrderSubscriptions(true);

    _toastMessage(
      t('storefront/cart/orders/cancelled--success', {
        id: orderSubscriptionId,
      })
    );
  };

  const _patchOrderSubscriptionStatus = async (status) => {
    await patchOrderRequest(nextOrderId, {
      subscription_association: {
        status,
      },
    });
  };

  const _toastMessage = (message, type = 'success', timer = 2000) => {
    setToasts((prevState) => [
      ...prevState,
      {
        id: generateRandomNumber(),
        timer,
        message,
        type,
      },
    ]);
  };

  return (
    <Card classes="store--account__subscriptions--card mb-6" childMarginBottom={false}>
      <div className="flex items-start">
        <div className="flex flex-col flex-1">
          <h3 className="font-bold text-lg">
            {t('storefront/my-account/subscription-history/title', { id: plan_number })}
          </h3>
          <p>
            {t(frequencyLabelTranslationMapping[frequency_unit], {
              count: frequency_value,
              unit: frequency_unit,
              day: fulfillmentDayOfWeek,
              dayOfMonth: dayOfMonth?.string,
              weekdayMonthOccurrence: weekdayMonthOccurrence?.string,
            })}
          </p>
        </div>
        <div className={`store--account__subscriptions--status status-${subscriptionStatus}`}>
          <span>
            {t(
              `storefront/my-account/subscription-history/subscription-status-${subscriptionStatus}`
            )}
          </span>
        </div>
      </div>
      <UpcomingSubscriptionCard
        order={orderSubscription}
        onSkip={handleSkip}
        loading={patchOrderLoading}
      />
      <Button
        onClick={() => (window.location = `mailto:${contact_email}`)}
        classes="button button--white w-full my-6">
        {t('storefront/my-account/order-history--contact-seller')}
      </Button>
      <hr className="mb-4" />
      {order_entries?.map((entry) => (
        <CartItem
          key={entry?.id}
          {...entry}
          storefront_unit_quantity={entry?.unit_quantity}
          readOnly
          isSubscription
          package_price_list_entry={entry?.package_price_list_entry}
          productLink={
            orderSubscription?.price_list?.slug &&
            entry?.package_price_list_entry?.product_price_list_entry &&
            `/${orderSubscription?.price_list?.slug}/product/${entry?.package_price_list_entry?.product_price_list_entry}`
          }
          classes="mb-0"
        />
      ))}
      <CartTotals {...orderSubscription?.order_template} />
      <hr />
      <h4 className="mt-4 mb-2 font-medium text-Grey600">
        {isPickup
          ? t('storefront/my-account/subscription-history/pickup-from')
          : t('storefront/my-account/subscription-history/deliver-to')}
      </h4>
      <p className="text-Grey700">{fulfillmentAddress}</p>
      <p className="text-Grey500">
        {fulfillment?.fulfillment_date
          ? `${estimated}: ${formatDateTime(fulfillment?.fulfillment_date, 'dddd, LL')}`
          : ''}
        {isPickup && (fulfillment?.pickup_start_time || fulfillment?.pickup_end_time)
          ? `${formatTime(fulfillment?.pickup_start_time)} - ${formatTime(
              fulfillment?.pickup_end_time
            )}`
          : null}
      </p>
      {!!fulfillment?.instructions && fulfillment?.instructions !== BLANK_TEXT_EDITOR && (
        <p
          className="rich-text-editor-render mt-2 text-Grey500"
          dangerouslySetInnerHTML={{ __html: fulfillment?.instructions }}
        />
      )}
      <hr className="my-4" />
      <div className="flex flex-col space-y-2">
        {upcoming_draft_orders[0]?.id && nextOrderStatus === ACTIVE && (
          <Button
            classes="button--primary w-full"
            onClick={async () => {
              await switchOrder(upcoming_draft_orders[0]?.order_id);
            }}>
            {t('storefront/my-account/subscription-history/edit-upcoming')}
          </Button>
        )}
        {isSubscriptionActive && (
          <Button
            classes="button button--white"
            onClick={() => setCancelSubscriptionModalOpen(true)}>
            {t('orders/subscriptions/form/header/cancel')}
          </Button>
        )}
      </div>
      <CancelSubscriptionModal
        open={cancelSubscriptionModalOpen}
        onClose={() => setCancelSubscriptionModalOpen(false)}
        onConfirmCancel={handleCancel}
        loading={cancelOrderSubscriptionLoading}
      />
    </Card>
  );
};

SubscriptionHistoryCard.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    payment: PropTypes.object,
    order_entries: PropTypes.array,
    status: PropTypes.string,
  }),
  fetchOrders: PropTypes.func,
};

SubscriptionHistoryCard.defaultProps = {
  order: {},
  fetchOrders: () => {},
};

export default SubscriptionHistoryCard;
