import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from 'components/styled/Button';
import ModalPortal from 'components/styled/ModalPortal';
import { toastAtom } from 'state/appState';
import { generateRandomNumber } from 'helpers/inputHelpers';
import { useSetRecoilState } from 'recoil';
import { deleteCustomerCard } from 'api/storefront/customers';

const DeleteCardModal = ({ gateway, last4, source_id, closeModal, handleDeleteCard }) => {
  const { t } = useTranslation();
  const setToasts = useSetRecoilState(toastAtom);

  const deleteCard = async (event) => {
    event.preventDefault();
    try {
      await deleteCustomerCard(source_id, { gateway });
      const toastId = generateRandomNumber();
      setToasts((prevState) => [
        ...prevState,
        {
          id: toastId,
          timer: 3000,
          message: t('storefront/my-account/saved-payments/delete--toast', { last4 }),
          type: 'success',
        },
      ]);
      handleDeleteCard(source_id, gateway);
      closeModal();
    } catch (e) {}
  };

  return (
    <ModalPortal
      target={`${gateway}-${source_id}`.replace(':', '')}
      title={t('storefront/my-account/saved-payments/delete-modal--title')}
      description={t('storefront/my-account/saved-payments/delete-modal--description')}
      onClose={closeModal}
      classes="p-0 overflow-y-visible">
      <div className="modal--actions">
        <Button classes="button button--white" onClick={closeModal}>
          {t('Cancel')}
        </Button>
        <Button classes="button button--red" onClick={deleteCard}>
          {t('Delete')}
        </Button>
      </div>
    </ModalPortal>
  );
};

DeleteCardModal.propTypes = {
  gateway: PropTypes.string,
  last4: PropTypes.string,
  source_id: PropTypes.string,
  closeModal: PropTypes.func,
  handleDeleteCard: PropTypes.func,
};

DeleteCardModal.defaultProps = {
  gateway: '',
  last4: '',
  source_id: '',
  closeModal: () => {},
  handleDeleteCard: () => {},
};

export default DeleteCardModal;
