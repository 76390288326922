import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'helpers/localeHelpers';

const Money = ({ value, currency, narrowSymbol }) => {
  return <>{formatMoney(Number(value), currency, narrowSymbol)}</>;
};

Money.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currency: PropTypes.string.isRequired,
  narrowSymbol: PropTypes.bool,
};

Money.defaultProps = {
  narrowSymbol: false,
  value: 0,
};

export default Money;
