import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { readAutocomplete, readPlaceDetails } from 'api/storefront/locations';
import useCountries from 'hooks/common/countriesState';
import useFormState from 'hooks/common/formState';
import AddressAutocomplete from 'components/AddressAutocomplete';
import Button from 'components/styled/Button';
import InputSelect from 'components/styled/InputSelect';
import InputText from 'components/styled/InputText';
import { DEFAULT_ADDRESS_FORM_STATE } from '.';

const AddressForm = ({
  handleChange,
  address,
  displayCountrySelector,
  defaultCountryCode,
  errors,
  addressField,
  defaultCountryOptionOnly,
  classes,
  types,
  isTypeRegion,
  showCopyButton,
  readDetailsAPI,
  readAutocompleteAPI,
}) => {
  const { t } = useTranslation();
  const {
    formState: addressState,
    setFormStateValue: setAddressStateValue,
    setFormStateValues: setAddressStateValues,
    toPayload,
  } = useFormState(Object.keys(address).length > 0 ? address : { ...DEFAULT_ADDRESS_FORM_STATE });
  const [inputValue, setInputValue] = useState(
    !isTypeRegion
      ? address?.street_address
      : address.locality || address.administrative_area_level_2
  );
  const { states, countriesList, setStatesForCountry, defaultCountry, stateLabel } = useCountries(
    address?.country_code || defaultCountryCode,
    defaultCountryOptionOnly
  );
  const [sessionToken, setSessionToken] = useState(uuidv4());

  const placeDetails = async (place_id) => {
    try {
      const response = await readDetailsAPI({ place_id, session_token: sessionToken });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddressSelect = async (selectedOption) => {
    if (selectedOption) {
      try {
        const addressObject = await placeDetails(selectedOption.place_id);
        setAddressStateValues(addressObject);
        !isTypeRegion
          ? setInputValue(addressObject.street_address)
          : handelTypeRegion(addressObject);
        setSessionToken(uuidv4());
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handelTypeRegion = (addressObject) => {
    setAddressStateValue('is_type_region', isTypeRegion);
    setInputValue(addressObject.locality || addressObject.administrative_area_level_2);
  };

  const handleAddressChange = (key, value) => {
    setAddressStateValue(key, value);
  };

  const handleCityChange = (value) => {
    if (!addressState.locality.value && !addressState.administrative_area_level_2.value) {
      setAddressStateValue('locality', value);
    } else if (addressState.locality.value) {
      setAddressStateValue('locality', value);
    } else {
      setAddressStateValue('administrative_area_level_2', value);
    }
  };

  const handleRegionChange = (value) => {
    handleCityChange(value);
    setInputValue(value);
  };

  const handleStreetAddressChange = (value) => {
    setAddressStateValue('street_address', value);
    setInputValue(value);
  };

  const handleCountryChange = (country) => {
    handleAddressChange('country', country?.label);
    handleAddressChange('country_code', country?.value);

    // Clear state if country changes
    if (!!addressState?.country?.value && addressState?.country?.value != country?.label) {
      handleAddressChange('administrative_area_level_1_code', '');
      handleAddressChange('administrative_area_level_1', '');
    }
  };

  const handleCountrySelect = async (selectedCountry) => {
    if (selectedCountry) {
      try {
        setStatesForCountry(selectedCountry.value);
        handleCountryChange(selectedCountry);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const country = addressState?.country?.value
    ? { value: addressState?.country_code?.value, label: addressState?.country?.value }
    : defaultCountry;

  const getError = (field) => {
    return errors && addressField && errors[`${addressField}.${field}`];
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(addressState?.formatted_address?.value);
  };

  const setCountryForTypeRegion = (country, city, administrative_area_level_1) => {
    if (!country && isTypeRegion && (city || administrative_area_level_1)) {
      handleCountryChange(defaultCountry);
    }
  };

  const setCountryIfFormFilled = (
    country,
    city,
    administrative_area_level_1,
    postal_code,
    street_address
  ) => {
    if (
      !country &&
      city &&
      administrative_area_level_1 &&
      postal_code &&
      street_address &&
      Object.keys(defaultCountry).length
    ) {
      handleCountryChange(defaultCountry);
    }
  };

  useEffect(() => {
    setAddressStateValue('is_type_region', isTypeRegion);
  }, [isTypeRegion]);

  useEffect(() => {
    const {
      country,
      locality,
      administrative_area_level_2,
      administrative_area_level_1,
      postal_code,
      street_address,
    } = addressState || {};

    setCountryIfFormFilled(
      country?.value,
      locality?.value || administrative_area_level_2?.value,
      administrative_area_level_1?.value,
      postal_code?.value,
      street_address?.value
    );

    setCountryForTypeRegion(
      country?.value,
      locality?.value || administrative_area_level_2?.value,
      administrative_area_level_1?.value
    );

    handleChange(toPayload(false));
  }, [addressState]);

  return (
    <div className={`w-full ${classes}`}>
      {displayCountrySelector && (
        <InputSelect
          id="country"
          name="country"
          label={t('Country')}
          classes="mb-4"
          placeholder={t('global/input-select/placeholder')}
          options={countriesList}
          handleChange={handleCountrySelect}
          value={country}
          isDisabled={defaultCountryOptionOnly}
          error={getError('country')}
        />
      )}
      {!isTypeRegion && (
        <>
          <AddressAutocomplete
            label={t('Address')}
            handleSelect={handleAddressSelect}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleAddressChange={handleStreetAddressChange}
            components={country?.value ? { country: country.value } : undefined}
            sessionToken={sessionToken}
            error={getError('street_address')}
            types={types}
            readAutocompleteAPI={readAutocompleteAPI}
          />
          <InputText
            id="subpremise"
            name="subpremise"
            labelClasses="mt-4"
            inputClasses="mb-4"
            labelText={
              <div className="flex items-center">
                {t('storefront/cart/fulfillment/apartment-unit')}
                <span className="text-Gray400 ml-1">{t('(Optional)')}</span>
              </div>
            }
            value={addressState?.subpremise?.value}
            onChange={(e) => handleAddressChange(e.target.name, e.target.value)}
          />
          <InputText
            id="locality"
            name="locality"
            labelText={t('City')}
            inputClasses=""
            value={
              !addressState?.locality?.value
                ? addressState?.administrative_area_level_2?.value
                : addressState?.locality?.value
            }
            onChange={(e) => handleCityChange(e.target.value)}
            error={getError('city')}
          />
        </>
      )}
      {isTypeRegion && (
        <AddressAutocomplete
          label={t('City')}
          handleSelect={handleAddressSelect}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleAddressChange={handleRegionChange}
          components={country?.value ? { country: country.value } : undefined}
          sessionToken={sessionToken}
          error={getError('city')}
          types={types}
          readAutocompleteAPI={readAutocompleteAPI}
        />
      )}
      <div className="flex space-x-4 mt-4">
        <InputSelect
          id="administrative_area_level_1_code"
          name="administrative_area_level_1_code"
          label={t(stateLabel)}
          classes="w-full"
          options={states}
          handleChange={(state) => {
            handleAddressChange('administrative_area_level_1_code', state.value);
            handleAddressChange('administrative_area_level_1', state.label);
          }}
          value={{
            value: addressState?.administrative_area_level_1?.value,
            label: addressState?.administrative_area_level_1?.value,
          }}
          error={getError('administrative_area_level_1')}
        />
        {!isTypeRegion && (
          <InputText
            id="postal_code"
            name="postal_code"
            labelClasses=""
            labelText={t('Postal Code')}
            value={addressState?.postal_code?.value}
            onChange={(e) => handleAddressChange(e.target.name, e.target.value)}
            error={getError('postal_code')}
          />
        )}
      </div>
      <div className="text-right">
        {!!showCopyButton && (
          <Button
            classes="button--white md:ml-2 mt-4 md:mt-"
            onClick={handleCopyAddress}
            disabled={!addressState?.formatted_address?.value}>
            {t('global/input-address--copy-address')}
          </Button>
        )}
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  displayCountrySelector: PropTypes.bool,
  defaultCountryCode: PropTypes.string,
  defaultCountryOptionOnly: PropTypes.bool,
  classes: PropTypes.string,
  address: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  addressField: PropTypes.string,
  types: PropTypes.string,
  isTypeRegion: PropTypes.bool,
  showCopyButton: PropTypes.bool,
  readDetailsAPI: PropTypes.func,
  readAutocompleteAPI: PropTypes.func,
};

AddressForm.defaultProps = {
  displayCountrySelector: true,
  defaultCountryCode: undefined,
  defaultCountryOptionOnly: false,
  classes: '',
  address: {},
  handleChange: () => {},
  errors: {},
  addressField: undefined,
  types: undefined,
  isTypeRegion: undefined,
  showCopyButton: false,
  readDetailsAPI: readPlaceDetails,
  readAutocompleteAPI: readAutocomplete,
};

export default AddressForm;
